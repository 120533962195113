import { Button } from "@/components/atoms/Button/Button";
import { PopoverContent } from "@/components/atoms/Popover/Popover";
import { FilterListItem } from "./FilterListItem";
import { AudienceStatus, Destination } from "@/types/audience";
import IncompleteIcon from "@/assets/icons/incomplete.svg?react";
import ArchivedIcon from "@/assets/icons/archived.svg?react";
import DraftIcon from "@/assets/icons/draft.svg?react";
import RunningIcon from "@/assets/icons/running.svg?react";
import SyncedIcon from "@/assets/icons/synced.svg?react";
import FacebookIcon from "@/assets/icons/facebook.svg?react";
import LinkedinIcon from "@/assets/icons/linkedin.svg?react";
import GoogleIcon from "@/assets/icons/google.svg?react";
import FavoriteIcon from "@/assets/icons/favorite-true.svg?react";
import { GetAudiencesFilters } from "@/types/api";
import { upperFirst } from "lodash";

const statusFilters = [
    {
        status: AudienceStatus.DRAFT,
        icon: <DraftIcon />,
    },
    {
        status: AudienceStatus.RUNNING,
        icon: <RunningIcon />,
    },
    {
        status: AudienceStatus.SYNCED,
        icon: <SyncedIcon />,
    },
    {
        status: AudienceStatus.INCOMPLETE,
        icon: <IncompleteIcon />,
    },
    {
        status: AudienceStatus.ARCHIVED,
        icon: <ArchivedIcon />,
    },
];

const destinationFilters = [
    {
        name: "Meta Ads",
        icon: <FacebookIcon />,
        destination: Destination.META,
    },
    {
        name: `${upperFirst(Destination.GOOGLE)} Ads`,
        icon: <GoogleIcon />,
        destination: Destination.GOOGLE,
    },
    {
        name: `${upperFirst(Destination.LINKEDIN)} Ads`,
        icon: <LinkedinIcon />,
        destination: Destination.LINKEDIN,
    },
];

interface FiltersPopoverContentProps {
    resetFilters: () => void;
    selectedFilters: GetAudiencesFilters;
    setSelectedFilters: (filters: GetAudiencesFilters) => void;
}

export const FiltersPopoverContent = ({
    resetFilters,
    selectedFilters,
    setSelectedFilters,
}: FiltersPopoverContentProps) => {
    const handleStatusFilterChange = (status: AudienceStatus) => {
        const newFilters = { ...selectedFilters };

        if (selectedFilters.status.includes(status)) {
            newFilters.status = newFilters.status.filter(s => s !== status);
        } else {
            newFilters.status = [...newFilters.status, status];
        }

        setSelectedFilters(newFilters);
    };

    const handleDestinationFilterChange = (destination: Destination) => {
        const newFilters = { ...selectedFilters };

        if (selectedFilters.destinations.includes(destination)) {
            newFilters.destinations = newFilters.destinations.filter(d => d !== destination);
        } else {
            newFilters.destinations = [...newFilters.destinations, destination];
        }

        setSelectedFilters(newFilters);
    };

    return (
        <PopoverContent className="bg-white rounded-lg shadow-filter w-[300px] mr-12 text-ui-700">
            <div className="h-[60px] w-full px-6 flex items-center justify-between">
                <span className="text-lg leading-7">Filters</span>
                <Button
                    title="Reset to default filters"
                    variant="ghost"
                    className="text-blue-800 !px-0"
                    onClick={resetFilters}
                >
                    Reset Default
                </Button>
            </div>
            <div className="border-t border-ui-300/[0.32] py-4 px-6 w-full">
                <div className="py-2 text-xs font-medium">STATUS</div>
                <div>
                    {statusFilters.map(statusFilter => (
                        <FilterListItem
                            key={statusFilter.status}
                            checked={selectedFilters.status.includes(statusFilter.status)}
                            handleCheck={() => handleStatusFilterChange(statusFilter.status)}
                            icon={statusFilter.icon}
                            text={upperFirst(statusFilter.status)}
                        />
                    ))}
                </div>
            </div>
            <div className="border-t border-ui-300/[0.32] py-4 px-6 w-full">
                <div className="py-2 text-xs font-medium">DESTINATIONS</div>
                <div>
                    {destinationFilters.map(destinationFilter => (
                        <FilterListItem
                            key={destinationFilter.name}
                            checked={selectedFilters.destinations.includes(destinationFilter.destination)}
                            handleCheck={() => handleDestinationFilterChange(destinationFilter.destination)}
                            icon={destinationFilter.icon}
                            text={destinationFilter.name}
                        />
                    ))}
                </div>
            </div>
            <div className="border-t border-ui-300/[0.32] py-4 px-6 w-full">
                <FilterListItem
                    checked={selectedFilters.inFavorites}
                    handleCheck={() =>
                        setSelectedFilters({
                            ...selectedFilters,
                            inFavorites: !selectedFilters.inFavorites,
                        })
                    }
                    icon={<FavoriteIcon />}
                    text={"In favorites"}
                />
            </div>
        </PopoverContent>
    );
};
