import React from "react";
import { Button } from "@/components/atoms/Button/Button";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { FilterConfig } from "@primer/filters/types";

interface PoppoverTriggerButtonProps {
    selectedFilterField?: FilterConfig;
}

const FilterFieldSelector = React.forwardRef<HTMLButtonElement, PoppoverTriggerButtonProps>(
    ({ selectedFilterField, ...props }, ref) => {
        const buttonText = selectedFilterField?.displayName ?? selectedFilterField?.name ?? "Add filter";

        return (
            <Button
                ref={ref}
                variant="input"
                aria-readonly={!selectedFilterField}
                aria-required={!!selectedFilterField}
                className="aria-required:w-full aria-readonly:mt-2 justify-between group aria-expanded:outline aria-expanded:outline-2 aria-expanded:outline-blue-300 aria-expanded:border-0"
                {...props}
            >
                <span className="truncate max-w-[350px]">{buttonText}</span>
                {selectedFilterField && (
                    <ChevronDownIcon className="ml-2 h-4 w-4 transition-transform duration-200 group-aria-expanded:rotate-180" />
                )}
            </Button>
        );
    },
);

FilterFieldSelector.displayName = "FilterFieldSelector";

export default FilterFieldSelector;
