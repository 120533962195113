import { Button } from "@/components/atoms/Button/Button";
import { useUpdateOnboardingMutation } from "@/api/users";
import { useGetConnectionsQuery } from "@/api/integrations";
import InfoIcon from "@/assets/icons/info-outline.svg?react";
import { ConnectionStatus, Service } from "@/types/integrations";
import CrmIcon from "@/assets/images/onboarding/crm-connect.svg";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import { getIconForService, getOAuthUrl } from "@/config/integrations";
import { useOnboarding } from "@/components/organisms/onboarding/OnboardingContext";
import { ConnectionStatusPill } from "@/components/organisms/integrations/ConnectionStatus";
import { OnboardingSkeleton, OnboardingWrapper } from "@/components/organisms/onboarding/OnboardingWrapper";
import Checkbox from "@/components/atoms/Checkbox/Checkbox";
import { ApolloIcon, CensusIcon, OutreachIcon, MarketoIcon, HighTouchIcon, HubspotIcon, SalesforceIcon, } from "@/assets/images/onboarding/crm-options";
import { useEffect, useState } from "react";
import { uniq } from "lodash";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { OnboardingStep } from "./Onboarding";
import cn from "classnames";

const NONE_OF_THE_ABOVE = "None of the above";
const options = [
    [
        { value: "Apollo", icon: <ApolloIcon /> },
        { value: "Census", icon: <CensusIcon /> },
        { value: "Hightouch", icon: <HighTouchIcon /> },
        { value: "Hubspot", icon: <HubspotIcon /> },
    ],
    [
        { value: "Marketo", icon: <MarketoIcon /> },
        { value: "Outreach", icon: <OutreachIcon /> },
        { value: "Salesforce", icon: <SalesforceIcon /> },
        { value: NONE_OF_THE_ABOVE },
    ]
]

export const CRMConnect = () => {
    const { setCurrentStep, onboarding } = useOnboarding();
    const [updateOnboarding] = useUpdateOnboardingMutation();
    const [disabledOptions, setDisabledOptions] = useState<string[]>([]);

    const [selectedCrms, setSelectedCrms] = useState<string[] | undefined>([]);

    const toggleCrm = (crm: string) => {
        if(disabledOptions.includes(crm)) return;

        if (!selectedCrms?.includes(crm)) {
            setSelectedCrms(prev => uniq([...(prev ?? []), crm]));
        } else {
            setSelectedCrms(prev => prev?.filter(c => c !== crm));
        }
    }

    const disableConflictingOptions = (selectedCrms?: string[]) => {
        if (selectedCrms?.includes(NONE_OF_THE_ABOVE)) {
            setDisabledOptions(options.flat().filter(o => o.value !== NONE_OF_THE_ABOVE).map(o => o.value));
        } else if (!selectedCrms || selectedCrms?.length === 0) {
            setDisabledOptions([]);
        } else {
            setDisabledOptions([NONE_OF_THE_ABOVE, ...(isConnected ? ["Salesforce"] : [])]);
        }
    }

    useEffect(() => {
        disableConflictingOptions(selectedCrms);
    }, [selectedCrms]);

    const { data: connections, isLoading } = useGetConnectionsQuery(
        { enabled: true },
        { refetchOnMountOrArgChange: true },
    );
    const icon = getIconForService(Service.SALESFORCE, "h-9 w-9");

    const salesForceConnection = connections?.find(({ provider }) => provider === Service.SALESFORCE);

    const isConnected =
        salesForceConnection?.state === ConnectionStatus.CONNECTED ||
        salesForceConnection?.state === ConnectionStatus.INITIATED;

    const automaticallyCheckSelectedCrms = () => {
        setSelectedCrms(prev => [...(prev ?? []), "Salesforce"]);
        setDisabledOptions(prev => [...prev, "Salesforce"]);
    }

    useEffect(() => {
        if (isConnected) {
            automaticallyCheckSelectedCrms();
        }
    }, [isConnected]);

    const connect = () => {
        const connectUrl = getOAuthUrl(Service.SALESFORCE);
        if (connectUrl) window.location.href = connectUrl;
    };

    const nextStep = async () => {
        await updateOnboarding({
            id: onboarding.id,
            ...(!onboarding.crm_connected && isConnected ? { crm_connected: true } : {}),
            crm_sources: selectedCrms,
        });

        setCurrentStep(OnboardingStep.ADD_PLATFORMS_CONNECT);
    };

    useEffect(() => setSelectedCrms(uniq([...(onboarding?.crm_sources ?? []), ...(isConnected ? ["Salesforce"] : [])])), [onboarding]);

    const alreadyConnectedByOtherUser = !isLoading && isConnected && !onboarding.crm_connected;

    if (!connections) return <OnboardingSkeleton />;

    return (
        <OnboardingWrapper imgUrl={CrmIcon}>
            <div className="w-full">
                <h1 className="text-h1 mb-2">Connect your CRM</h1>
                <p className="text-16">
                    Primer needs <span className="text-ui-900 font-semibold">read-only</span> access to your CRM to
                    leverage first-party data for audience creation.
                </p>
            </div>

            <div className="flex items-center justify-between px-6 py-4 w-full bg-white border border-ui-200 rounded-lg">
                <div className="flex items-center gap-4">
                    {icon}
                    <div className="text-16">Salesforce</div>
                </div>

                {!isConnected && (
                    <Button
                        onClick={connect}
                        attributes={{ "data-ph-capture-attribute-service-name": Service.SALESFORCE }}
                    >
                        Connect
                    </Button>
                )}

                {isConnected && (
                    <ConnectionStatusPill status={salesForceConnection.state} service={Service.SALESFORCE} />
                )}
            </div>

            {alreadyConnectedByOtherUser && (
                <div className="w-full flex items-center p-3 rounded-lg bg-blue-50 border border-blue-200 space-x-2 text-blue-870 !mt-2">
                    <InfoIcon className="mb-0.5" />
                    <div className="text-14">Already connected for your org by another user.</div>
                </div>
            )}

            <hr className="w-full border-t-ui-200 my-2" />

            <div>
                Before you continue, would you like to use any of these data sources to build your audiences in the future?
            </div>
            <div className="px-6 py-4 w-full bg-white border border-ui-200 rounded-lg grid grid-cols-2 gap-3">
                {options.map((optionList, index) => (
                    <div className="flex flex-col gap-3" key={`column-${index}`}>
                        {optionList.map(o => (
                            <div key={o.value} className="flex gap-2 items-center">
                                <Checkbox
                                    value={o.value}
                                    checked={selectedCrms?.includes(o.value)}
                                    onCheckedChange={() => toggleCrm(o.value)}
                                    disabled={disabledOptions.includes(o.value)}
                                    customDisabledStyle={true}
                                />
                                <div
                                    className={cn("flex gap-2 items-center", {
                                        "text-ui-300 cursor-default": disabledOptions.includes(o.value),
                                        "cursor-pointer": !disabledOptions.includes(o.value)
                                    })}
                                    role="tab"
                                    onClick={() => toggleCrm(o.value)}
                                >
                                    {!!o.icon && <span className={cn({
                                        "opacity-50": disabledOptions.includes(o.value)
                                    })}>{o.icon}</span>} {o.value}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <Tooltip hidden={!!selectedCrms && selectedCrms?.length > 0} content={"Please select at least one of the options above."} side="right">
                <Button className="flex items-center gap-0.5" onClick={nextStep} disabled={!selectedCrms || selectedCrms?.length === 0}>
                    Next
                    <ArrowRightIcon />
                </Button>
            </Tooltip>
        </OnboardingWrapper>
    );
};
