import { useState } from "react";
import { PlusIcon } from "@radix-ui/react-icons";

import { AccountTableRow } from "./AccountTableRow";
import { Button } from "@/components/atoms/Button/Button";
import { DeleteAccountErrorModal, DeleteAccountModal } from "./DeleteAccountModal";
import { ProcessingDataModal } from "./ProcessingDataModal";
import { useDeleteConnectionMutation } from "@/api/integrations";
import { ErrorMessage } from "@/components/organisms/ErrorMessage";
import { Connection, IntegrationType, Service } from "@/types/integrations";
import { Table, TableHead, TableHeader, TableRow } from "@/components/atoms/Table";
import { getIconForService, getNameForService, getOAuthUrl, getTypeForService } from "@/config/integrations";
import { WarnMessage } from "../InfoMessage";
import { useGetCrmSyncQuery } from "@/api/crm";
import { CrmSyncStatus } from "@/types/api";

const columns = ["Account ID", "Business name", "User ID", "Status", ""];

export const Account = ({ service, account }: { service: Service; account?: Connection }) => {
    const [selectedForDelete, selectForDelete] = useState<string | number | null>(null);
    const [connectionUsedDeleteError, setConnectionUsedDeleteError] = useState<any | null>(null);

    const [deleteConnectionMutation] = useDeleteConnectionMutation();

    const deleteConnection = async (req: { connectionId: string | number }) => deleteConnectionMutation(req).unwrap();

    const serviceType = getTypeForService(service);
    const {
        id = "",
        state = "",
        provider_user_id = "",
        provider_account_id = "",
        provider_organization_name = "",
        error,
    } = account || {};

    const { data: crmSync, isLoading: crmSyncLoading } = useGetCrmSyncQuery(
        { instanceId: provider_account_id },
        {
            skip: !provider_account_id || serviceType !== IntegrationType.CRM,
            refetchOnMountOrArgChange: true,
        },
    );

    const { status, isSyncing, latestSyncJobStatus } = crmSync || {};

    const addAccount = () => {
        const oauthUrl = getOAuthUrl(service);
        if (oauthUrl) window.location.href = oauthUrl;
    };

    const deleteAccount = async () => {
        if (!selectedForDelete) return;
        try {
            setConnectionUsedDeleteError(null);
            await deleteConnection({ connectionId: selectedForDelete });
            selectForDelete(null);
        } catch(error: any) {
            if(error?.status === 400 && error?.data?.includes("Cannot delete connection.")) {
                selectForDelete(null);
                setConnectionUsedDeleteError(error);
            } else {
                throw error;
            } 
        }
    };

    return (
        <div className="bg-white w-full px-8 py-6 rounded-2xl">
            <div className="flex justify-between mb-6">
                <div className="flex space-x-4 items-center">
                    {getIconForService(service!, "size-9 mr-3")}
                    <span className="text-ui-700">{getNameForService(service!)} Account</span>
                </div>

                <Button variant="primary" className="gap-0.5" onClick={addAccount}>
                    <PlusIcon /> Add account
                </Button>
            </div>

            <Table>
                <TableHeader className="bg-ui-50 border-ui-300 border-b">
                    <TableRow className="h-[48px] border-dark-500 border-opacity-20">
                        {columns.map((text, index) => (
                            <TableHead key={index} className="!p-4 text-ui-700 font-medium uppercase text-start">
                                {text}
                            </TableHead>
                        ))}
                    </TableRow>
                </TableHeader>

                {state && (
                    <AccountTableRow
                        name={provider_organization_name}
                        status={state}
                        service={service}
                        id={provider_account_id}
                        key={provider_account_id}
                        userId={provider_user_id}
                        deleteAccount={() => selectForDelete(id)}
                    />
                )}

                {connectionUsedDeleteError && (
                    <DeleteAccountErrorModal onClose={() => setConnectionUsedDeleteError(null)} />
                )}

                {selectedForDelete && (
                    <DeleteAccountModal onCancel={() => selectForDelete(null)} onConfirm={deleteAccount} />
                )}
            </Table>

            {error?.message || error?.redirectUrl ? (
                <ErrorMessage message={error.message} redirect={error.redirectUrl} />
            ) : null}

            {serviceType === IntegrationType.CRM && <ProcessingDataModal service={service} connection={account} />}

            { 
                (!crmSyncLoading && status === CrmSyncStatus.ACTIVE && (isSyncing || latestSyncJobStatus !== "succeeded")) &&
                    <WarnMessage message="Salesforce account connected succesfully. The repository will populate and activate 1st party filters within a few hours. Thank you for your patient." className="mt-4" />               
            }
        </div>
    );
};
