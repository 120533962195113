/* eslint-disable react-hooks/exhaustive-deps */
import { Filter, SourceCriteria } from "@primer/filters/types";
import React, { useState, useCallback } from "react";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { AudienceStatus } from "@/types/audience";
import { filterChanged, validateFilter } from "@/utils/filter";

export interface FilterManagementHook {
    disabledFilters: boolean;
    filters: Partial<Filter>[];
    setFilters: React.Dispatch<React.SetStateAction<Partial<Filter>[]>>;
    editableField: string | undefined;
    updateEditableField: (filterId: string, editable: boolean) => void;
    saveFilter: (filter: Partial<Filter>) => void;
    removeFilter: (filterId: string) => void;
}

export function useFilterManagement(
    saveCriteriaCallback: (filters: Filter[]) => void,
    sourceCriteria?: SourceCriteria,
    status?: AudienceStatus,
): FilterManagementHook {
    const location = useLocation();
    const [newAudience, setNewAudience] = useState<boolean>(location.state?.from === "/audiences/new");
    const [newFilterAdded, setNewFilterAdded] = useState<boolean>(false);

    const getInitialFilters = (newFilters: Partial<Filter>[]): Partial<Filter>[] => {
        const hasEmptyField = newFilters.some(f => f.field === undefined);
        if (!hasEmptyField || newFilters.length === 0) {
            const newFilter = { unique_id: uuidv4() };
            return [...newFilters, newFilter];
        }

        return newFilters;
    };

    const [filters, setFilters] = useState<Partial<Filter>[]>(getInitialFilters(sourceCriteria?.group?.filters ?? []));
    const [editableField, setEditableField] = useState<string | undefined>(undefined);

    const disabledFilters = React.useMemo(
        () => status === AudienceStatus.ARCHIVED || status === AudienceStatus.RUNNING,
        [status],
    );

    React.useEffect(() => updateAudienceFilters(), [sourceCriteria]);
    React.useEffect(() => addEmptyFilter(), [filters, newAudience]);
    React.useEffect(() => focusFieldIfNew(), [newAudience]);

    const updateAudienceFilters = () => {
        if (filterChanged(sourceCriteria?.group?.filters, filters)) {
            setFilters(sourceCriteria?.group?.filters ?? []);
        }
    };

    const focusFieldIfNew = () => {
        if (newAudience) {
            if (sourceCriteria) {
                const firstFilter = sourceCriteria?.group?.filters?.[0];
                if (firstFilter?.unique_id && firstFilter.isEmpty) {
                    setEditableField(firstFilter.unique_id);
                }
            }
            setNewAudience(false);
        }
    };

    const saveFilter = useCallback(
        (filter: Partial<Filter>) => {
            const newFilters: Partial<Filter>[] = filters.map(f => (f.unique_id === filter.unique_id ? filter : f));
            const validatedNewFilter = newFilters.map(f => ({
                ...f,
                ...validateFilter(newFilters, f),
            }));

            setFilters(validatedNewFilter);
            saveCriteriaCallback(validatedNewFilter.filter((f): f is Filter => f.field !== undefined));
        },
        [filters, saveCriteriaCallback],
    );

    const removeFilter = useCallback(
        (filterId: string) => {
            const newFilters = filters.filter(f => f.unique_id !== filterId);
            const validatedNewFilter = newFilters.map(f => ({
                ...f,
                ...validateFilter(newFilters, f),
            }));
            setFilters(validatedNewFilter);
            saveCriteriaCallback(validatedNewFilter.filter((f): f is Filter => f.field !== undefined));
        },
        [filters, saveCriteriaCallback],
    );

    const updateEditableField = useCallback(
        (filterId: string, editable: boolean) => {
            if (editableField === filterId && !editable) setEditableField(undefined);
            if (editable) setEditableField(filterId);
        },
        [editableField],
    );

    const scrollByNewFilter = () => {
        if (!newFilterAdded) return;
        setNewFilterAdded(false);
    };

    const addEmptyFilter = () => {
        if (disabledFilters || newAudience) return;

        scrollByNewFilter();
        const hasEmptyField = filters.some(f => f.field === undefined);
        if (!hasEmptyField) {
            const newFilter = { unique_id: uuidv4() };
            setFilters(previous => [...previous, newFilter]);
            if (filters.length > 0) setNewFilterAdded(true);
        }
    };

    return {
        disabledFilters,
        filters,
        setFilters,
        editableField,
        updateEditableField,
        saveFilter,
        removeFilter,
    };
}
