import React from "react";
import { Root, Item, Indicator, RadioGroupProps as Props } from "@radix-ui/react-radio-group";
import cn from "classnames";
export interface RadioItems {
    description: string;
    value: string;
    disabled?: boolean;
}

export interface RadioProps extends Props {
    items: RadioItems[];
    isContainedTheme?: boolean;
}

const Radio = React.forwardRef<HTMLDivElement, RadioProps>(
    ({ items, className, isContainedTheme, ...props }: RadioProps, ref) => {
        const handleSelect = (value: string) => {
            if (props.onValueChange) {
                props.onValueChange(value);
            }
        };

        return (
            <Root className={cn("flex flex-row gap-2.5", className)} aria-label="View density" {...props}>
                {items.map(item => {
                    const isActive = item.value === props.value;

                    return (
                        <div
                            className={cn(`flex items-center cursor-pointer`, {
                                "p-2 border border-ui-300/[0.19] rounded-lg": isContainedTheme,
                                "bg-blue-50 border-blue-200": isActive && isContainedTheme,
                            })}
                            ref={ref}
                            key={item.value}
                            onClick={() => {
                                handleSelect(item.value);
                            }}
                            onKeyDown={event => event.key === "Enter" && handleSelect(item.value)}
                        >
                            <Item
                                className="bg-white w-5 min-w-5 h-5 min-h-5 rounded-full border-2 border-black
                                aria-checked:border-[7px] aria-checked:border-primary
                                disabled:border-ui-300/[0.32] disabled:aria-checked:border-blue-300 disabled:bg-ui-50"
                                value={item.value}
                                id={item.value}
                                disabled={item.disabled}
                                onClick={e => e.stopPropagation()}
                            >
                                <Indicator className="flex items-center justify-center w-full h-full relative" />
                            </Item>
                            <label
                                className={cn(`text-sm leading-none pl-2 whitespace-nowrap`, {
                                    "cursor-pointer": !item.disabled,
                                    "text-blue-850": isActive,
                                })}
                                htmlFor={item.value}
                            >
                                {item.description}
                            </label>
                        </div>
                    );
                })}
            </Root>
        );
    },
);

export default Radio;
