import React from "react";
import { OnboardingProvider, useOnboarding } from "./OnboardingContext";

import { CRMConnect } from "@/components/organisms/onboarding/CRMConnect";
import { AddPlatformsConnect } from "@/components/organisms/onboarding/AddPlatformsConnect";
import { AudienceTypes } from "@/components/organisms/onboarding/AudienceTypes";

import { OnboardingLastStep } from "@/components/organisms/onboarding/OnboardingLastStep";
import { QualificationQuestions } from "./QualificationQuestions";

export enum OnboardingStep {
    QUALIFICATION_QUESTIONS = 1,
    AUDIENCE_TYPES = 2,
    CRM_CONNECT = 3,
    ADD_PLATFORMS_CONNECT = 4,
    ONBOARDING_LAST_STEP = 5,
}

const withOnboardingProvider = (Component: React.ComponentType) => {
    return function WrapperComponent(props: any) {
        return (
            <OnboardingProvider>
                <Component {...props} />
            </OnboardingProvider>
        );
    };
};

export const Onboarding: React.FC = () => {
    const { currentStep, onboarding } = useOnboarding();
    if (!onboarding) return null;

    switch (currentStep) {
        case OnboardingStep.QUALIFICATION_QUESTIONS:
            return <QualificationQuestions />;
        case OnboardingStep.AUDIENCE_TYPES:
            return <AudienceTypes />;
        case OnboardingStep.CRM_CONNECT:
            return <CRMConnect />;
        case OnboardingStep.ADD_PLATFORMS_CONNECT:
            return <AddPlatformsConnect />;
        case OnboardingStep.ONBOARDING_LAST_STEP:
            return <OnboardingLastStep />;
        default:
            return null;
    }
};

export const OnboardingPage = withOnboardingProvider(Onboarding);
