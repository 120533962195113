import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks/useFeatureFlag";
import { ExtraChartSchema } from "@/types/audience";
import { isEqual, omit } from "lodash";
import React, { useEffect, useMemo } from "react";

export interface SummaryCriteriaChangeManagerResponse {
    updateSelected: (items: SelectedForExclusionItem[]) => Promise<void>;
    selectionState: SelectedForExclusionItem[];
    confirmSummaryCriteriaChange: () => Promise<void>;
    isPendingExclusion: boolean;
}

export interface SelectedForExclusionItem {
    category: string;
    value: string;
    label?: string;
    type: "Exclude" | "Include";
}

interface SummaryCriteriaChangeStateManagerParams {
    confirmSummaryCriteriaChangeCallback: (
        items: SelectedForExclusionItem[],
        isRepoV2Enabled?: boolean,
    ) => Promise<void>;
    summaryValues: ExtraChartSchema[];
}
export const useSummaryCriteriaChangeManager = ({
    confirmSummaryCriteriaChangeCallback,
    summaryValues,
}: SummaryCriteriaChangeStateManagerParams): SummaryCriteriaChangeManagerResponse => {
    const [selectionState, setSelectionState] = React.useState<SelectedForExclusionItem[]>([]);

    useEffect(() => {
        const allPossibleExclusions = summaryValues?.flatMap(t =>
            t.values.map(v => ({
                category: t.category ?? t.name!,
                value: v.key!,
                label: v.label,
            })),
        );
        const newValue = selectionState.filter(p => allPossibleExclusions.some(e => isEqual(e, omit(p, "type"))));
        if (!isEqual(newValue, selectionState)) setSelectionState(newValue);
    }, [summaryValues, selectionState]);

    const updateSelected = async (items: SelectedForExclusionItem[]) => {
        setSelectionState(items);
    };

    const isRepoV2Enabled = useFeatureFlag(FeatureFlagsEnum.PITCH_REPO_V2);
    const confirmSummaryCriteriaChange = async () => {
        await confirmSummaryCriteriaChangeCallback(selectionState, isRepoV2Enabled);
        setSelectionState([]);
    };

    const isPendingExclusion = useMemo(() => selectionState.length > 0, [selectionState]);

    return {
        updateSelected,
        selectionState,
        confirmSummaryCriteriaChange,
        isPendingExclusion,
    };
};
