import { formatDate } from "date-fns";

import { Invoice } from "@/types/subscription";
import { formatStripeAmount } from "@/utils/number";
import DownloadIcon from "@/assets/icons/download.svg?react";
import { InvoiceStatus } from "@/components/organisms/settings/invoices/InvoiceStatus";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/atoms/Table";

interface Props {
    invoices: Invoice[];
    isLoading: boolean;
}

export const InvoicesTable = ({ invoices, isLoading }: Props) => {
    return (
        <Table className="bg-white py-4 px-6 rounded-lg shadow min-w-[1000px]">
            <InvoiceTableHeader />

            <TableBody>
                {!isLoading &&
                    invoices.map(invoice => <InvoiceTableRow key={invoice.invoice_number} invoice={invoice} />)}
            </TableBody>
        </Table>
    );
};

interface RowProps {
    invoice: Invoice;
}

export const InvoiceTableRow = ({ invoice }: RowProps) => {
    const { invoice_number, updated_at, created_at, pdf_url, amount, status, recipient_name } = invoice;

    return (
        <TableRow
            aria-label="Invoice Table Row"
            className="border-dark-500 border-opacity-20 hover:bg-blue-50 hover:cursor-pointer hover:ring-1 hover:ring-blue-200 hover:ring-inset hover:border-transparent"
        >
            <TableCell className="pl-4 w-[250px]">{invoice_number}</TableCell>
            <TableCell className="w-[250px]">{formatDate(updated_at || created_at, "MMM dd, yyyy")}</TableCell>
            <TableCell className="w-[150px]">{formatStripeAmount(+amount)}</TableCell>
            <TableCell className="w-[120px]">
                <InvoiceStatus status={status} />
            </TableCell>
            <TableCell>{recipient_name}</TableCell>
            <TableCell className="w-[150px]">
                {pdf_url && (
                    <div
                        title="Download"
                        aria-label="Download"
                        onClick={() => (window.location.href = pdf_url)}
                        className="flex justify-center group transition-colors items-center px-2.5 py-2 bg-white rounded-lg border border-ui-200 w-max shadow-button hover:bg-green-100"
                    >
                        <DownloadIcon className="text-ui-700 group-hover:text-green-500" />
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
};

export const InvoiceTableHeader = () => {
    const contentClassname = `text-ui-700 text-12 font-medium leading-none tracking-tight !p-2 h-[36px]
  justify-start items-center flex rounded-lg border border-transparent !px-0`;

    return (
        <TableHeader>
            <TableRow className="h-[48px] border-ui-300 bg-ui-50">
                <TableHead className="pl-4">
                    <span className={contentClassname}>NUMBER</span>
                </TableHead>
                <TableHead>
                    <span className={contentClassname}>DATE</span>
                </TableHead>
                <TableHead>
                    <span className={contentClassname}>AMOUNT</span>
                </TableHead>
                <TableHead>
                    <span className={contentClassname}>STATUS</span>
                </TableHead>
                <TableHead>
                    <span className={contentClassname}>RECIPIENT</span>
                </TableHead>
                <TableHead>
                    <span className={contentClassname}>DOWNLOAD</span>
                </TableHead>
            </TableRow>
        </TableHeader>
    );
};
