import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import cn from "classnames";

const Separator = React.forwardRef<
    React.ElementRef<typeof SeparatorPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
    (
        { className, orientation = "horizontal", decorative = true, ...props },
        ref
    ) => (
        <SeparatorPrimitive.Root
            ref={ref}
            decorative={decorative}
            orientation={orientation}
            className={cn(
                "shrink-0 bg-border bg-ui-300/[0.32]",
                {
                    "h-[1px] w-full": orientation === "horizontal",
                    "h-full w-[1px]": orientation === "vertical"
                },
                className
            )}
            role="separator"
            {...props}
        />
    )
);

Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
