import { AudienceStatus as AudienceStatusEnum } from "@/types/audience";
import IncompleteIcon from "@/assets/icons/incomplete.svg?react";
import ArchivedIcon from "@/assets/icons/archived.svg?react";
import DraftIcon from "@/assets/icons/draft.svg?react";
import RunningIcon from "@/assets/icons/running.svg?react";
import SyncedIcon from "@/assets/icons/synced.svg?react";
import cn from "classnames";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";

interface AudienceStatusProps {
    status: AudienceStatusEnum;
    className?: string;
    loading?: boolean;
}

const AudienceStatus = ({ status, className, loading = false }: AudienceStatusProps) => {
    const containerClasses = "flex items-center border py-2 px-3 rounded-lg h-9";
    const textClasses = "text-sm";
    const iconClasses = "w-5 h-5 mr-2";

    if (loading) return <Skeleton className={"h-11 w-20 mr-2 rounded-xl"} />;

    switch (status) {
        case AudienceStatusEnum.ARCHIVED:
            return (
                <div className={cn(containerClasses, "border-ui-300/[0.19] bg-ui-50", className)}>
                    <ArchivedIcon
                        className={cn(iconClasses, "text-ui-300")}
                        title={AudienceStatusEnum.ARCHIVED}
                        aria-label={AudienceStatusEnum.ARCHIVED}
                    />
                    <span id="audience-status" className={cn(textClasses, "text-ui-300")}>Archived</span>
                </div>
            );
        case AudienceStatusEnum.RUNNING:
            return (
                <div className={cn(containerClasses, "border-ui-300/[0.19] bg-blue-200", className)}>
                    <RunningIcon
                        className={cn(iconClasses, "text-blue-870")}
                        title={AudienceStatusEnum.RUNNING}
                        aria-label={AudienceStatusEnum.RUNNING}
                    />
                    <span id="audience-status" className={cn(textClasses, "text-blue-870")}>Running</span>
                </div>
            );
        case AudienceStatusEnum.SYNCED:
            return (
                <div className={cn(containerClasses, "border-ui-300/[0.19] bg-green-100", className)}>
                    <SyncedIcon
                        className={cn(iconClasses, "text-green-500")}
                        title={AudienceStatusEnum.SYNCED}
                        aria-label={AudienceStatusEnum.SYNCED}
                    />
                    <span id="audience-status" className={cn(textClasses, "text-green-500")}>Synced</span>
                </div>
            );
        case AudienceStatusEnum.INCOMPLETE:
            return (
                <div
                    className={cn(
                        containerClasses,
                        "border-ui-300/[0.19] bg-warning-background text-warning",
                        className,
                    )}
                >
                    <IncompleteIcon
                        className={iconClasses}
                        title={AudienceStatusEnum.INCOMPLETE}
                        aria-label={AudienceStatusEnum.INCOMPLETE}
                    />
                    <span id="audience-status" className={textClasses}>Incomplete</span>
                </div>
            );
        case AudienceStatusEnum.DRAFT:
            return (
                <div className={cn(containerClasses, "border-ui-300/[0.19] bg-ui-300/[0.19]", className)}>
                    <DraftIcon
                        className={cn(iconClasses, "text-ui-700")}
                        title={AudienceStatusEnum.DRAFT}
                        aria-label={AudienceStatusEnum.DRAFT}
                    />
                    <span id="audience-status" className={cn(textClasses, "text-ui-700")}>Draft</span>
                </div>
            );
    }
};

export default AudienceStatus;
