import { BrandedModal } from "@/components/organisms/Modal/BrandedModal";
import { getPriceInfo } from "@/components/organisms/subscription/utils";
import { Subscription, SubscriptionProducts } from "@/types/subscription";
import { PlanCard } from "./PlanCard";
import { useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { CheckoutModalV2 } from "./CheckoutModaV2";

interface Props {
    subscription: Subscription;
    subscriptionProducts: SubscriptionProducts;
    closeModal: () => void;
}

export interface PlanConfig {
    planLevel: number;
    forAdSpend: string;
    amount: number;
    originalAmount?: number;
    discount?: number;
    frequency: "monthly" | "yearly";
    audienceLimit: number;
    showUpgradeOptions?: boolean;
    priceId: string;
    audiencePriceId: string;
}

export const UpgradePlanModalV2 = ({ subscription, subscriptionProducts, closeModal }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const checkoutOpened = useMemo(() => searchParams.has("checkout"), [searchParams]);
    const [currentConfig, setCurrentConfig] = useState<PlanConfig | null>(null);
    const { paymentMethod, subscription_items } = subscription;

    const freeTierPrice = getPriceInfo(subscriptionProducts, "grow", "free", "monthly");
    const growMonthlyPrice = getPriceInfo(subscriptionProducts, "grow", "tier1", "monthly");
    const growYearlyPrice = getPriceInfo(subscriptionProducts, "grow", "tier1", "yearly");
    const grow2YearlyPrice = getPriceInfo(subscriptionProducts, "grow", "tier2", "yearly");
    const currentSubscriptionItem = subscription_items.find(({ product_id }) =>
        [subscriptionProducts.grow.product_id, subscriptionProducts.scale.product_id].includes(product_id),
    );
    const currentAudienceSubscriptionItem = subscription_items.find(({ product_id }) =>
        product_id === subscriptionProducts.audiences.product_id,
    );

    const currentAdAudienceSubscriptionItem = subscription_items.find(
        ({ product_id }) => product_id === subscriptionProducts.audiences.product_id,
    );

    const isFree = currentSubscriptionItem?.plan_id === freeTierPrice!.id;
    const isPrimerGrownMonthly = currentSubscriptionItem?.plan_id === growMonthlyPrice!.id;
    const isPrimerGrownYearly = currentSubscriptionItem?.plan_id === growYearlyPrice!.id;
    const isGrowTier2Yearly = currentSubscriptionItem?.plan_id === grow2YearlyPrice!.id;

    if (!currentSubscriptionItem || !currentAdAudienceSubscriptionItem || !growYearlyPrice || !growMonthlyPrice) return null;

    const getCurrentPlanLevel = () => {
        if (isFree) return 0;
        if (isPrimerGrownMonthly) return 1;
        if (isPrimerGrownYearly) return 2;
        if (isGrowTier2Yearly) return 3;
        return 10;
    };

    const currentPlanLevel = getCurrentPlanLevel();

    const close = (closePlans: boolean = false) => {
        if (searchParams.has("checkout")) {
            searchParams.delete("checkout");
            setSearchParams(searchParams);
        }

        closePlans && closeModal();
    };

    const planConfigs: PlanConfig[] = [
        {
            planLevel: 1,
            forAdSpend: "0 - $20K/month",
            amount: 1000,
            frequency: "monthly",
            audienceLimit: growMonthlyPrice.audience_limit,
            showUpgradeOptions: true,
            priceId: subscriptionProducts.grow.tier1.monthly.id,
            audiencePriceId: subscriptionProducts.audiences.monthly.id
        },
        {
            planLevel: 2,
            forAdSpend: "0 - $20K/month",
            amount: 10000,
            originalAmount: 12000,
            discount: 2000,
            frequency: "yearly",
            audienceLimit: growYearlyPrice.audience_limit,
            showUpgradeOptions: true,
            priceId: subscriptionProducts.grow.tier1.yearly.id,
            audiencePriceId: subscriptionProducts.audiences.yearly.id
        },
        {
            planLevel: 3,
            forAdSpend: "$20K+/month",
            amount: 15000,
            frequency: "yearly",
            audienceLimit: 12,
            showUpgradeOptions: false,
            priceId: subscriptionProducts.scale.tier1.yearly.id,
            audiencePriceId: subscriptionProducts.audiences.yearly.id
        }
    ];

    function onCheckout(config: PlanConfig): void {
        setSearchParams({ checkout: "true" });
        setCurrentConfig(config);
    }

    return (
        <BrandedModal isLarge={true} className="w-fit px-10 py-10" onClose={closeModal}>
            <div className="flex gap-6">
                {planConfigs.map((config) => (
                    <PlanCard
                        isTrial={subscription?.status === "trialing"}
                        isPausedOrCanceled={subscription?.status === "canceled" || subscription?.status === "paused"}
                        key={config.planLevel}
                        config={config}
                        currentAudienceSubscriptionItem={currentAudienceSubscriptionItem}
                        currentPlanLevel={currentPlanLevel}
                        onCheckout={onCheckout}
                    />
                ))}
            </div>
            {checkoutOpened && currentConfig && currentAdAudienceSubscriptionItem.id && currentSubscriptionItem.id && paymentMethod && subscriptionProducts && (
                <CheckoutModalV2
                    close={close}
                    currentAddAudienceSubscriptionItemId={currentAdAudienceSubscriptionItem.id}
                    currentSubscriptionItemId={currentSubscriptionItem.id}
                    paymentMethod={paymentMethod}
                    config={currentConfig}
                />
            )}
        </BrandedModal>
    );
};
