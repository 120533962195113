import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import cn from "classnames";
import { ReactElement } from "react";

export interface TooltipProps extends Omit<TooltipPrimitive.TooltipContentProps, "content"> {
    buttonClass?: string;
    className?: string;
    children: ReactElement;
    content: string | ReactElement;
    button?: {
        disabled?: boolean;
        className?: string;
        onClick?: React.MouseEventHandler;
        variant?: string;
        size?: string;
        loading?: boolean;
        role?: string;
        tabIndex?: number;
    };
    open?: boolean;
    fullWidth?: boolean;
    defaultOpen?: boolean;
}

const Tooltip = ({
    className,
    children,
    content,
    button,
    side,
    sideOffset,
    open,
    defaultOpen,
    fullWidth,
    hidden,
}: TooltipProps) => {
    return (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root delayDuration={0} open={open} defaultOpen={defaultOpen}>
                <TooltipPrimitive.Trigger {...button}>{children}</TooltipPrimitive.Trigger>
                <TooltipPrimitive.Content
                    side={side}
                    hidden={hidden}
                    sideOffset={sideOffset}
                    className={cn(
                        "z-50 overflow-hidden rounded-md bg-ui-900 text-white bg-popover p-4 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 left-0 right-0 max-w-[var(--radix-tooltip-content-available-width)px] max-h-[var(--radix-tooltip-content-available-height)px]",
                        className,
                        fullWidth ? "w-fit" : "max-w-64",
                    )}
                >
                    <>
                        {content}
                        <TooltipPrimitive.Arrow />
                    </>
                </TooltipPrimitive.Content>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
};

export { Tooltip };
