import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import Circle from "@/assets/icons/circle.svg?react";
import CircleCheck from "@/assets/icons/circle-check-2.svg?react";
import CheckmarkIcon from "@/assets/icons/checkmark.svg?react";
import CheckBoxIcon from "@/assets/icons/checkbox.svg?react";
import CheckBoxDisabledIcon from "@/assets/icons/checkbox-disabled.svg?react";
import cn from "classnames";

type CheckboxProps = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    asRadio?: boolean;
    customDisabledStyle?: boolean;
};

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    CheckboxProps
>(({ className, asRadio, customDisabledStyle = false, ...props }, ref) => {
    const [checked, setChecked] = React.useState(Boolean(props.checked));

    React.useEffect(() => {
        if (props.checked !== checked) {
            setChecked(Boolean(props.checked));
        }
    }, [props.checked]);

    return (
        <CheckboxPrimitive.Root
            ref={ref}
            className={cn(
                "peer h-5 w-5 shrink-0 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-0 relative",
                className,
            )}
            onCheckedChange={(checked: boolean) => setChecked(checked)}
            {...props}
        >
            {checked
                ? (asRadio
                    ? <CircleCheck />
                    : <CheckmarkIcon />)
                : (asRadio
                    ? <Circle />
                    : customDisabledStyle && props.disabled
                        ? <CheckBoxDisabledIcon />
                        : <CheckBoxIcon />)
            }
        </CheckboxPrimitive.Root>
    );
});

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export default Checkbox;