const columns = ["ON DATE", "EVENT", "DURATION", "DETAILS", "MATCHED SIZE"];

export const RunHeader = () =>
    columns.map(col => (
        <div
            className="bg-ui-50 p-4 border-b border-ui-300 text-12 font-medium leading-4 tracking-[.3px] text-ui-700"
            key={col}
        >
            {col}
        </div>
    ));
