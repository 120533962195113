import { Button } from "@/components/atoms/Button/Button";
import { Modal } from "@/components/organisms/Modal";
import { ModalHeader } from "@/components/organisms/Modal/ModalHeader";

type Props = {
    onClose: () => void;
    onConfirm: () => void;
}

export function CapWarningModal({ onClose, onConfirm }: Props) {

    return (
        <Modal className="w-[600px]" onClose={onClose}>
            <ModalHeader title="Audience records will be capped" onClose={onClose} />
            <div className="grid grid-cols-[200px_170px_120px_auto] text-ui-900 text-14 overflow-auto overflow-x-hidden"></div>
            <div className="flex flex-col justify-between items-center mt-5">
                <div>Audience sizes are capped at 3 millions records. You can proceed and automatically limit your audience size, or cancel and return to adjust your filters manually.</div>
                
                <div className="flex ml-auto mt-10 gap-2">
                    <Button onClick={onClose} variant="secondary">
                        Cancel
                    </Button>
                    <Button onClick={onConfirm}>
                        Proceed and Limit
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
