import React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import { Option } from './FilterMultiSelect';
import { parseFilterName, parseValue } from '@/utils/string';
import { FilterConfig, FilterFields } from '@primer/filters/types';
import Highlight from '@/components/atoms/Highlight/Highlight';

interface OptionItemProps {
    option: Option;
    selectedFilterField: FilterConfig;
    index: number;
    focusIndex: number | undefined;
    searchText?: string;

    onCheckedChanged: (option: Option, checked: boolean | 'indeterminate') => void;
}

const OptionItem: React.FC<OptionItemProps> = ({
    option,
    selectedFilterField,
    onCheckedChanged,
    index,
    focusIndex,
    searchText = "",
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const switchChecked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, option: Option) => {
        event.preventDefault();
        onCheckedChanged(option, !option.checked);
    };

    React.useEffect(() => {
        if (focusIndex === index && ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
        }
    }, [focusIndex]);

    const label = option.label ? option.label : parseFilterName(selectedFilterField, option);

    return (
        <div
            ref={ref}
            id={parseValue(option.value)}
            role="tab"
            aria-selected={focusIndex === index}
            className="group flex items-center px-6 py-3 hover:bg-blue-50 aria-selected:bg-blue-50"
            onClick={(event) => switchChecked(event, option)}>
            <Checkbox.Root
                className="border-2 border-ui-700 flex h-[20px] w-[20px] aria-checked:bg-primary aria-checked:border-primary appearance-none items-center justify-center rounded-[4px] outline-none"
                checked={option.checked}
                onCheckedChange={(checked) => onCheckedChanged(option, checked)}
                value={parseValue(option.value)}
                id={parseValue(option.value)}
            >
                <Checkbox.Indicator className="text-white">
                    <CheckIcon />
                </Checkbox.Indicator>
            </Checkbox.Root>
            <label className="pl-[15px] text-[15px] flex w-full text-sm item-center truncate" htmlFor={parseValue(option.value)}>
                {option.category && selectedFilterField.identifier === FilterFields.DEPARTMENTS && <div className='text-ui-300 text-end mr-1'>
                    {searchText ? <Highlight text={option.category} searchTerm={searchText} className="text-ui-300" /> : option.category} /</div>}
                <div title={parseValue(option.value)} className='group-hover:text-primary group-aria-[focus=true]:text-primary min-w-3/5 text-ui-70 mr-2'>
                    {searchText ? <Highlight text={label} searchTerm={searchText} /> : label}
                </div>
                {option.category && selectedFilterField.identifier !== FilterFields.DEPARTMENTS &&
                    <div className='ml-auto text-ui-300 text-end'>{option.category}</div>}
            </label>
        </div>)
};

export default OptionItem;
