import { PanelResizeHandle } from "react-resizable-panels";
import Caret from "@/assets/icons/caret.svg?react";
import cn from "classnames";
import { useState } from "react";
import { Button } from "@/components/atoms/Button/Button";

interface ResizeHandleProps {
    className?: string;
    isCollapsed?: boolean;
    toggleLeft: () => void;
}

const ResizeHandle = ({ className, isCollapsed, toggleLeft }: ResizeHandleProps) => {
    const [allowClick, setAllowClick] = useState(true);

    const handleDrag = (isDragging: boolean) => {
        if(isDragging) setTimeout(() => setAllowClick(false), 200)
        else setTimeout(() => setAllowClick(true), 200)
    }
    
    const handleClick = () => {
      if (allowClick) {
        toggleLeft();
      }
    };

    return (
        <PanelResizeHandle
            onDragging={handleDrag}
            className={`group h-full flex-none w-6 relative outline-none resize-active:bg-resize-handle ${className}`}
        >
            <div className="absolute top-1 bottom-1 left-1 right-1 rounded-md bg-transparent transition-colors duration-200 ease-linear">
                <div className="flex h-full flex-col items-center">
                    <Button
                        variant="basic"
                        size="custom"
                        onClick={handleClick}
                        className="bg-white h-10 w-4 z-[2] fixed top-[50%] h-24 rounded-xl border-2 border-ui-200 shadow-md border-transparent group-hover:border-blue-200 group-data-[resize-handle-state=drag]:border-blue-200 !cursor-pointer">
                        <Caret className={cn("!cursor-pointer text-ui-300 group-hover:text-blue-800 group-data-[resize-handle-state=drag]:text-blue-800", {
                            "transform transition-transform duration-300 ease-in-out rotate-180 ": isCollapsed
                        })} />
                    </Button>
                    <div className="z-1 mt-[-5px] h-full bg-ui-100 w-[5px] rounded-md group-hover:bg-blue-200 group-data-[resize-handle-state=drag]:bg-blue-200">
                    </div>
                </div>
            </div>
        </PanelResizeHandle>
    );
}

export default ResizeHandle;
