import { GetAudienceSortOrder } from "@/types/api";
import { useQueryParams, withDefault } from "use-query-params";

export function useSort<T>(columns: string[], defaultColumn: T) {
    //TODO GetAudienceSortOrder should be changed to more general type like SortOrder
    const orderArray = Object.values(GetAudienceSortOrder);
    const defaultOrder = GetAudienceSortOrder.DESC;

    const [query, setQuery] = useQueryParams(
        {
            sortColumn: withDefault(
                {
                    encode(value: string) {
                        return value;
                    },
                    decode(sortColumn?: string | (string | null)[] | null) {
                        const indexOfCol =
                            !sortColumn || Array.isArray(sortColumn)
                                ? -1
                                : columns.map(c => c.toLowerCase()).indexOf(sortColumn?.toLowerCase());

                        return (indexOfCol !== -1 ? columns[indexOfCol] : defaultColumn) as T;
                    },
                },
                defaultColumn,
            ),

            sortOrder: withDefault(
                {
                    encode(value: string) {
                        return value;
                    },
                    decode(sortOrder?: string | (string | null)[] | null) {
                        const indexOfCol =
                            !sortOrder || Array.isArray(sortOrder)
                                ? -1
                                : orderArray.map(o => o.toLowerCase()).indexOf(sortOrder?.toLowerCase());

                        return indexOfCol !== -1 ? orderArray[indexOfCol] : defaultOrder;
                    },
                },
                defaultOrder,
            ),
        },
        { removeDefaultsFromUrl: true },
    );

    const handleSort = (sortColumn: T, sortOrder: GetAudienceSortOrder) => {
        setQuery({ sortColumn, sortOrder });
    };

    const resetSort = () => setQuery({ sortColumn: undefined, sortOrder: undefined });

    return { ...query, handleSort, resetSort };
}
