interface HeaderProps {
    title: string;
    leftTools?: JSX.Element;
    rightTools?: JSX.Element;
}

export const Header = ({ title, leftTools, rightTools }: HeaderProps) => (
    <header className="py-6 px-12 m-1.5 gap-4 flex justify-between bg-white rounded-2xl shadow-main sticky z-10">
        {leftTools}
        <h1 className="flex-1 text-h1 text-ui-900">{title}</h1>
        {rightTools}
    </header>
);
