import React from "react";
import { FilterConfig } from "@primer/filters/types";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { NoFiltersFound } from "./NoFiltersFound";

interface FilterFieldSelectorTabContentOptionsProps {
    fields: FilterConfig[];
    setSelectedFilterField: (filterField: FilterConfig) => void;
}

const FilterFieldTabContentOption = React.forwardRef<HTMLDivElement, FilterFieldSelectorTabContentOptionsProps>(
    ({ fields, setSelectedFilterField, ...props }, ref) => {
        return (
            <div ref={ref} {...props}>
                {fields.map(filterField => (
                    <Tooltip
                        content={filterField.tooltip}
                        className="w-[300px]"
                        key={filterField.entityType + filterField.identifier}
                        side="right"
                        sideOffset={10}
                        button={{
                            className:
                                "w-full group font-medium hover:bg-primary/10 hover:text-primary flex items-center h-[44px] cursor-pointer",
                        }}
                    >
                        <div
                            role="menuitem"
                            tabIndex={0}
                            onClick={() => setSelectedFilterField(filterField)}
                            onKeyDown={event => event.key === "Enter" && setSelectedFilterField(filterField)}
                            className="w-full h-full flex items-center text-left"
                        >
                            <span className="flex-1 px-6 text-ui-900 text-sm font-normal leading-tight">
                                {filterField.name}
                            </span>
                            <div className="flex-1 px-6 text-ui-300 font-normal text-sm group-hover:text-primary text-start">
                                {filterField.sampleData}
                            </div>
                        </div>
                    </Tooltip>
                ))}
                {fields.length === 0 && (
                    <NoFiltersFound />
                )}
            </div>
        );
    },
);

FilterFieldTabContentOption.displayName = "FilterFieldTabContentOption";

export default FilterFieldTabContentOption;
