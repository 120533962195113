import { CsvMappingFields } from "@/types/csv";
import { FilterEntityTypes } from "@primer/filters/types";

type GroupConfig = {
    and?: CsvMappingFields[];
    or: CsvMappingFields[];
};

type GroupsConfig = {
    [key in FilterEntityTypes]: GroupConfig;
};

export const groupsConfig: GroupsConfig = {
    [FilterEntityTypes.PERSON]: {
        and: [CsvMappingFields.FIRST_NAME, CsvMappingFields.LAST_NAME],
        or: [CsvMappingFields.COMPANY_DOMAIN, CsvMappingFields.LINKEDIN_URL, CsvMappingFields.EMAIL],
    },
    [FilterEntityTypes.COMPANY]: {
        or: [CsvMappingFields.COMPANY_DOMAIN, CsvMappingFields.LINKEDIN_URL],
    },
};
