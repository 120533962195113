import { parse } from "papaparse";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ChangeEvent, useRef, useState } from "react";

import { CsvFile } from "@/types/csv";
import { Button } from "@/components/atoms/Button/Button";
import { useToast } from "@/components/atoms/Toast/useToast";
import { CSVRecord, setFileName, setHeaders, setRecords, setAudienceId, setImportError } from "@/store/csv-import";
import { useNavigation } from "@/context/NavigationContext";

const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25 MB

export const ImportCsv = ({ files, disabled = false }: { files: CsvFile[]; disabled?: boolean }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [loading, setLoading] = useState(false);

    const { toast, dismiss } = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { audienceId } = useNavigation();

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        try {
            setLoading(true);
            dismiss();

            const file = event.target.files?.[0];
            if (!file) return;

            if (file.size > MAX_FILE_SIZE)
                return toast({
                    description: "Max file size is 25MB, please use another one.",
                    variant: "destructive",
                });

            //check is there a existing uploaded file with the same name
            if (files.findIndex(({ name }) => name === file.name) !== -1)
                return toast({
                    description: "A file with this name has already been uploaded. Please choose a different name.",
                    variant: "destructive",
                });

            parse(file, {
                header: true,
                complete: results => {
                    const headers = results.meta.fields as string[];
                    const data = results.data as CSVRecord[];

                    dispatch(setFileName(file.name));
                    dispatch(setImportError(false));
                    dispatch(setHeaders(headers));
                    dispatch(setRecords(data));

                    audienceId && dispatch(setAudienceId(audienceId));

                    navigate("/audiences/import-csv", { state: { file } });
                },
                error: (error: unknown) => {
                    toast({
                        description: "Error happened while parsing the file You uploaded, please use another one.",
                        variant: "destructive",
                    });

                    console.error(error);
                },
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button variant="secondary" onClick={() => inputRef.current?.click()} loading={loading} disabled={disabled}>
            <input
                type="file"
                accept=".csv"
                disabled={disabled}
                ref={inputRef}
                onChange={handleFileChange}
                className="absolute opacity-0 z-[-1]"
            />
            Import CSV
        </Button>
    );
};
