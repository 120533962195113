import { api } from "@/api";
import CrmSync, { CrmSyncRequest } from "@/types/api";
import { CrmMatchRates } from "@/types/audience";

export const crmApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getCrmSync: builder.query<CrmSync, CrmSyncRequest>({
            query: ({ instanceId }) => `/crm/syncs/${instanceId}`,
        }),

        getCrmMatchRates: builder.query<CrmMatchRates, { instanceId: string }>({
            query: ({ instanceId }) => `/crm/entity-mappings/${instanceId}`,
        }),
    }),
});

export const { useGetCrmSyncQuery, useGetCrmMatchRatesQuery } = crmApiSlice;
