import { FilterEntityTypes } from "@primer/filters/types";

export type CsvFileStatus = "initiated" | "completed" | "failed";

export enum CsvMappingFields {
    EMAIL = "email",
    LAST_NAME = "lastName",
    FIRST_NAME = "firstName",
    LINKEDIN_URL = "linkedinUrl",
    COMPANY_DOMAIN = "companyDomain",
}

export interface FieldMappings {
    [CsvMappingFields.EMAIL]?: string;
    [CsvMappingFields.LAST_NAME]?: string;
    [CsvMappingFields.FIRST_NAME]?: string;
    [CsvMappingFields.LINKEDIN_URL]?: string;
    [CsvMappingFields.COMPANY_DOMAIN]?: string;
}

export interface JsonlRecord {
    [CsvMappingFields.EMAIL]?: string;
    [CsvMappingFields.LAST_NAME]?: string;
    [CsvMappingFields.FIRST_NAME]?: string;
    [CsvMappingFields.LINKEDIN_URL]?: string;
    [CsvMappingFields.COMPANY_DOMAIN]?: string;
}

export interface CsvFile {
    id: string;
    name: string;
    inputCount: number;
    validCount: number;
    status: CsvFileStatus;
    invalidCount: number;
    entityType: FilterEntityTypes;
    fieldMappings: FieldMappings;
    mappingTable?: string;
    error?: {
        message: string;
    };
}
