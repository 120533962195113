import { AccessibleIcon } from "@radix-ui/react-accessible-icon";
import ErrorIcon from "@/assets/icons/error-gray.svg?react";

export const ErrorBoundaryFallback = () => {
    return (
        <div className="w-full mt-52">
            <div className="mx-auto flex flex-col items-center justify-center text-center w-[600px] text-ui-700 text-16 space-y-2 leading-6">
                <AccessibleIcon label="Error">
                    <ErrorIcon title="Error" aria-label="Error" />
                </AccessibleIcon>

                <h1 className="text-h1 text-ui-900 mt-2">Something went wrong...</h1>

                <p>
                    Our team is working to untangle this glitch, and we're sorry for the surprise inconvenience. For
                    immediate assistance,{" "}
                    <a className="text-primary" href="https://support.sayprimer.com/primer/">
                        contact
                    </a>{" "}
                    our Support team.
                </p>

                <p>
                    In the meantime, feel free to take a breather, grab a snack, or even do a quick dance. We'll have
                    things back to normal pronto. Thanks for your patience!
                </p>
            </div>
        </div>
    );
};
