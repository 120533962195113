import cn from "classnames"

import { FilterConfig } from "@primer/filters/types";
import { getCSVFilterConfig } from "@primer/filters/configs";
import CsvFileIcon from "@/assets/icons/csv.svg?react";
import DirectoryIcon from "@/assets/icons/directory-with-search.svg?react";
import WarningIcon from "@/assets/icons/warning.svg?react";

import { ImportCsv } from "./ImportCsv";

import { CsvFile } from "@/types/csv";
import { SourceCriteriaFilter } from "@/types/audience";

interface Props {
    files: CsvFile[];
    usedFields: SourceCriteriaFilter[],
    setSelectedFilterField: (value: FilterConfig) => void;
    selectedFilterField?: FilterConfig;
    searchText: string;
}

export const CSVImportTabContent = ({ files, usedFields, setSelectedFilterField, selectedFilterField, searchText }: Props) => {
    const filteredFiles = files.filter(f => !searchText || f.name.toLowerCase().includes(searchText.toLowerCase()));
    
    if (!files.length || !filteredFiles.length)
        return (
            <div className="h-[235px] gap-4 flex flex-col justify-center items-center text-ui-700 text-sm">
                <DirectoryIcon />

                <div className="flex flex-col items-center">
                    <p>{!files.length ? "Looks like you don't have any CSV files yet." : "No CSV available with the selected filter"}</p>
                    <p> Import and upload {!files.length && "your first"} one:</p>
                </div>

                <ImportCsv files={files} />
            </div>
        );

    const handleClick = (file: CsvFile) => {
        const config = getCSVFilterConfig({
            entity_type: file.entityType,
            field: file.name,
            mappingTable: file.mappingTable,
        });
        setSelectedFilterField(config);
    };

    const existingDataset = usedFields.find(field => field.mappingTable);
    const currentFilterDataset = selectedFilterField?.mappingTable;
    const disableDatasetSelection = Boolean(existingDataset?.mappingTable && existingDataset.mappingTable !== currentFilterDataset);

    return (
        <div className="h-full w-full">
            <div className="flex justify-between items-center px-5 py-1.5 bg-ui-50 border-y border-ui-100">
                <span className="text-sm text-ui-300">
                    Choose from the uploaded files below or import a new CSV file:
                </span>

                <ImportCsv files={files} disabled={disableDatasetSelection} />
            </div>

            {disableDatasetSelection
                ? (
                    <div className="flex justify-center items-center h-[48px] bg-warning-background text-warning">
                        <WarningIcon className="mr-2" />
                        <span className="text-sm">Only one CSV file is allowed per audience</span>
                    </div>
                )
                : (
                    <div className="flex flex-col flex-1 overflow-y-scroll max-h-[265px]">
                        {filteredFiles.map((file) => (
                            <div
                                key={file.id}
                                role="menuitem"
                                tabIndex={0}
                                onKeyDown={event => event.key === "Enter" && handleClick(file)}
                                className={cn(
                                    "w-full flex items-center gap-2 px-5 py-4 text-sm",
                                    !disableDatasetSelection && "hover:bg-blue-50 hover:text-blue-800 cursor-pointer",
                                    disableDatasetSelection && "text-ui-300 cursor-default"
                                )}
                                onClick={() => !disableDatasetSelection && handleClick(file)}
                            >
                                <CsvFileIcon className="min-w-fit" />
                                {file.name}
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    );
};
