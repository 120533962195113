import { omit } from "lodash";

import { JsonlRecord } from "@/types/csv";

type TransformedJsonlRecord = Omit<JsonlRecord, "email"> & {
    id: number;
    emails: { type: "unknown"; email: string; source: "csv" }[];
};

export const transformToJsonl = (records: JsonlRecord[]) => {
    return records
        .map((record, i) => {
            const recordObj: TransformedJsonlRecord = { ...omit(record, "email"), emails: [], id: i + 1 };

            if (record.email) {
                recordObj.emails = [
                    {
                        type: "unknown",
                        email: record.email,
                        source: "csv",
                    },
                ];
            }

            return JSON.stringify(recordObj);
        })
        .join("\n");
};
