import { Link } from "react-router-dom";

import { getIconForService } from "@/config/integrations";
import StatusWarningIcon from "@/assets/icons/status-warning.svg?react";
import { Connection } from "@/types/integrations";

export const IntegrationErrorMessage = ({ failedConnections }: { failedConnections: Connection[] }) => {
    return (
        <div className="mx-1.5 mb-2 md:mx-12">
            {failedConnections
                .map(({ provider }) => {
                    //@ts-expect-error types not same, but values are
                    const icon = getIconForService(provider, "mr-2");
                    return (
                        <p className="flex rounded-[8px] py-2 px-2 mb-2 border-ui-300/[0.19] bg-warning-background text-warning text-sm">
                            {icon || <StatusWarningIcon className="mr-2" />}
                            <span>
                                <span className="capitalize font-semibold">{provider}</span> connection lost.&nbsp; To
                                update your data{" "}
                                <Link to="/integrations" className="text-blue-800">
                                    go to Integrations page
                                </Link>{" "}
                                and reconnect.
                            </span>
                        </p>
                    );
                })}
        </div>
    );
};
