import { AppState } from "@/store";
import { useSelector } from "react-redux";
import { formatNumber } from "@/utils/number";
import WarningIcon from "@/assets/icons/warning.svg?react";

export const ErrorMessages = () => {
    const { validPercentage, records, progress, errors } = useSelector((state: AppState) => state["csv-import"]);

    if (!progress) return;

    return (
        <div className="px-4 pt-2 pb-3 my-6 bg-warning-background rounded-lg flex-col justify-start items-start gap-2 flex">
            <div className="flex items-center">
                <WarningIcon />
                <h4 className="ml-2 flex text-xs leading-none">
                    <div className="text-ui-900 text-xs font-medium leading-none">{formatNumber(records?.length)}</div>
                    &nbsp;records found and&nbsp;
                    <div className="text-ui-900 text-xs font-medium leading-none">{validPercentage}%</div>
                    &nbsp;of these are valid and will be imported.
                </h4>
            </div>

            <div className="h-[1px] w-full bg-ui-300/[.19]" />
            <ul className="flex flex-col gap-2 pl-4">
                {errors?.map((error, index) => (
                    <li key={`${error}_${index}`} className="list-disc text-xs leading-tight">
                        {error}
                    </li>
                ))}
            </ul>
        </div>
    );
};
