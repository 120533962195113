/* eslint-disable react-hooks/exhaustive-deps */
import { ClipboardEvent, MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import {
    FilterMultiSelectProps,
    Option,
    SelectedOption,
} from "@/components/organisms/FilterMultiSelect/FilterMultiSelect";
import { useFindFilterFieldValuesMutation, useGetFilterFieldValuesQuery } from "@/api/filters";
import { MIN_TYPEAHEAD_LENGTH } from "@/constants/filters";
import { toLower, isEqual, uniqBy, isNumber } from "lodash";
import { FindFieldValuesRequest, FindFieldValuesResponse } from "@/types/api";
import { parseValue } from "@/utils/string";
import {
    AddressLocation,
    Department,
    FilterConfig,
    FilterDataTypes,
    FilterFields,
    FilterOperators,
    SourceCriteriaFilterValue,
} from "@primer/filters/types";
import { useDebounce } from "react-use";
import { getFilterMaxSupportedValuesForField } from "@primer/filters/util/normalize";
import { FeatureFlagsEnum, useFeatureFlag } from "./useFeatureFlag";

const isExceeded = (field: FilterConfig, lengthOfValues: number) => {
    const maxValues = getFilterMaxSupportedValuesForField(field.identifier);
    return maxValues ? lengthOfValues >= maxValues : false;
};

export const useMultiSelect = ({
    selectedFilterField,
    selectedOperator,
    selectedValues,
    dropdownOpen,
    closeEditMode,
    setSelectedValues,
    setDropdownOpen,
    setIsValidating,
    staticOptions,
}: FilterMultiSelectProps) => {
    const isRepoV2Enabled = useFeatureFlag(FeatureFlagsEnum.PITCH_REPO_V2);
    const inputRef = useRef<HTMLInputElement>(null);
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
    const [overLimit, setOverLimit] = useState(false);
    const [isExactMatchOperator, setIsExactMatchOperator] = useState<boolean>(false);
    const [isSimpleFilter, setIsSimpleFilter] = useState<boolean>(
        ![FilterDataTypes.LOCATION, FilterDataTypes.NUMBER].includes(selectedFilterField?.dataType) &&
            (!isRepoV2Enabled || selectedFilterField.identifier !== FilterFields.DEPARTMENTS),
    );
    const [focusIndex, setFocusIndex] = useState<number>();
    const isFirstParty = !!selectedFilterField.instanceId;

    const [searchValue, setSearchValue] = useState<string>("");
    const [finalSearchValue, setFinalSearchValue] = useState<string>("");
    useDebounce(() => setFinalSearchValue(searchValue.trim().toLowerCase()), 300, [searchValue]);
    const {
        data: filterFieldValues,
        isLoading,
        isFetching,
    } = useGetFilterFieldValuesQuery(
        { fieldId: selectedFilterField.identifier, q: finalSearchValue },
        {
            skip:
                (searchValue.length < MIN_TYPEAHEAD_LENGTH && options.length > 0) ||
                !isExactMatchOperator ||
                isFirstParty,
        },
    );

    const [findFilterFieldValuesMutation] = useFindFilterFieldValuesMutation();
    const findFilterFieldValues = async (req: FindFieldValuesRequest): Promise<FindFieldValuesResponse[]> =>
        findFilterFieldValuesMutation(req).unwrap();

    useEffect(() => onUpdateSelectedFilterField(), [selectedFilterField]);
    useEffect(() => onUpdateSelectedOperator(), [selectedOperator]);
    useEffect(() => syncSelectedOptionsWithValues(), [selectedValues]);
    useEffect(() => handleDropdownOpenState(), [dropdownOpen]);
    useEffect(() => onUpdateValue(), [selectedOperator, selectedValues, selectedOptions, searchValue]);
    useEffect(() => onSelectedOptionsUpdate(), [selectedOptions, selectedOperator]);
    useEffect(() => updateFilterOptionsValues(), [filterFieldValues, staticOptions]);
    useEffect(() => handlePageKeyDown(), [options, focusIndex]);

    const showLoading = useMemo(
        () => (isLoading || (isFetching && options.length === 0)) && !isFirstParty,
        [isLoading, isFetching, options],
    );
    useEffect(() => updateDropdownOnFetching(), [showLoading]);

    const onUpdateSelectedOperator = () => {
        if (!selectedOperator) return;

        const isExactMatch =
            selectedOperator.id === FilterOperators.IS || selectedOperator.id === FilterOperators.IS_NOT;
        setDropdownOpen(isExactMatch);
        setIsExactMatchOperator(isExactMatch);
        const updatedSelectedValues = selectedValues.map(v => ({
            ...v,
            exceeded: v.exceeded && !isExactMatch,
        }));
        if (!isEqual(updatedSelectedValues, selectedValues)) setSelectedValues(updatedSelectedValues);
    };

    const onUpdateValue = () => {
        const updatedSelectedValues = selectedValues.map((value, index) => ({
            ...value,
            exceeded: isExceeded(selectedFilterField, index),
        }));
        const someExceeded = updatedSelectedValues.some(v => v.exceeded);
        if (!isEqual(selectedValues, updatedSelectedValues)) {
            setSelectedValues(updatedSelectedValues);
            setOverLimit(true);
        } else if (!someExceeded) {
            setOverLimit(false);
        }
    };

    const onUpdateSelectedFilterField = () => {
        setOptions([]);
        setSelectedOptions([]);
        setIsSimpleFilter(
            ![FilterDataTypes.LOCATION, FilterDataTypes.NUMBER].includes(selectedFilterField?.dataType) &&
                (!isRepoV2Enabled || selectedFilterField.identifier !== FilterFields.DEPARTMENTS),
        );

        inputRef?.current?.focus();
    };

    const syncSelectedOptionsWithValues = () => {
        const currentSelectedOptionsValues = selectedOptions.map(s => s.label);
        const selectedValuesSplitted = selectedValues.map(s => s.label || s.value).filter(v => !!v) ?? [];
        if (selectedValuesSplitted.length > 0 && !isEqual(currentSelectedOptionsValues, selectedValuesSplitted)) {
            const newSelectedOptions = selectedValues.map(({ value, label, invalid, exceeded, category }) => ({
                value,
                label,
                invalid,
                exceeded,
                category,
                checked: true,
            }));
            setSelectedOptions(newSelectedOptions);
        }

        setOverLimit(selectedValues.some(v => v.exceeded));
    };

    const onSelectedOptionsUpdate = () => {
        updateOptionsBasedOnSelection();
    };

    const updateOptionsBasedOnSelection = async () => {
        setOptions(prevValue =>
            prevValue.map(option => ({
                ...option,
                checked: selectedOptions.some(selectedOption =>
                    isNumber(option.value)
                        ? option.value === Number(selectedOption.value)
                        : isEqual(selectedOption.value, option.value),
                ),
            })),
        );
        const optionsPendingValidation = selectedOptions.filter(selectedOption => selectedOption.invalid === undefined);

        setSelectedValues(selectedOptions);
        if (optionsPendingValidation.length) {
            setIsValidating(true);
            const validatedFields = await validateFields(optionsPendingValidation);
            setSelectedOptions(validatedFields);
            setTimeout(() => setIsValidating(false), 500);
        }
    };

    const validateFields = async (optionsPendingValidation: SelectedOption[]) => {
        // Dynamic filter options that come from the server
        if (!isFirstParty) {
            const response = await findFilterFieldValues({
                fieldId: selectedFilterField.identifier,
                values: optionsPendingValidation.map(v => v.value),
            });
            const finalResult = selectedOptions.map((selectedOption: SelectedOption) => {
                const invalidValidated =
                    selectedOption.invalid === undefined &&
                    optionsPendingValidation.some(pendingValidation =>
                        isEqual(pendingValidation.value, selectedOption.value),
                    );
                if (!invalidValidated) return selectedOption;

                const currentMatch = response.find(
                    r => toLower(selectedOption?.value?.toString()) === (r.label ? toLower(r.label) : toLower(r.value)),
                );
                return {
                    ...selectedOption,
                    ...currentMatch,
                    label: currentMatch?.label ?? currentMatch?.value,
                    invalid: !currentMatch,
                };
            });
            return finalResult;
        }

        // static options were provided, we do no validation on these
        else {
            return selectedOptions.map(selectedOption => ({
                ...selectedOption,
                invalid: false,
            }));
        }
    };

    const handleDropdownOpenState = () => {
        if (!dropdownOpen) {
            setSearchValue("");
        }
    };

    const updateFilterOptionsValues = () => {
        // Options are either the staticOptions filtered by search term
        // OR the filterFieldValues returned from the API
        const filterOptions = uniqBy(
            (
                staticOptions?.filter(o => o.label?.toLowerCase().includes(finalSearchValue.toLowerCase())) ??
                filterFieldValues
            )?.map(v => ({
                value: v.value,
                label: v.label,
                category: v.category,
                checked: !!selectedOptions.find(s =>
                    isNumber(v.value) ? v.value === Number(s.value) : isEqual(s.value, v.value),
                ),
            })) ?? [],
            item => `${toLower(parseValue(item.value))}-${toLower(item.category)}`,
        );

        if (isEqual(options, filterOptions)) return;
        setOptions(filterOptions);

        // Don't close staticOptions dropdown
        if (staticOptions) return;
        setDropdownOpen(isExactMatchOperator);
        setFocusIndex(undefined);
    };

    const checkSubmitAction = (allowClose = false) => {
        if (searchValue !== "" && isSimpleFilter) {
            const searchValues = searchValue.split(selectedFilterField?.join ?? ",");
            const newItem = searchValues?.[0];
            const option = options.find(o => isEqual(o.value, newItem));
            const newOption = {
                ...(option ?? {
                    value: toLower(newItem.trim()),
                    label: newItem.trim(),
                    category: "",
                    checked: true,
                }),
                exceeded: isExceeded(selectedFilterField, selectedOptions.length),
                invalid: !option && !selectedFilterField.allowCustomInput ? undefined : false,
            };
            addItem(newOption);
            setSearchValue("");
            setDropdownOpen(isExactMatchOperator);
        } else if (searchValue === "" && allowClose) {
            closeEditMode();
        }
    };

    const updateDropdownOnFetching = () => {
        if (showLoading) setDropdownOpen(isExactMatchOperator);
    };

    const onCheckedChanged = (option: Option, checked: boolean | "indeterminate") => {
        const selectedOption = { ...option, invalid: false };
        checked ? addItem(selectedOption) : removeItem(selectedOption);
        if (checked) {
            setSearchValue("");
            inputRef.current?.focus();
        }
    };

    const addItem = (option: SourceCriteriaFilterValue) => {
        setSelectedOptions(current =>
            !current
                ? [option]
                : uniqBy([...current, option], item => `${toLower(parseValue(item.value))}}`).map((v, index) => ({
                      ...v,
                      exceeded: isExceeded(selectedFilterField, index),
                  })),
        );
    };

    const removeItem = (option: SelectedOption) => {
        setSelectedOptions(current =>
            current
                ?.filter(c => !isEqual(c.value, option.value))
                .map((v, index) => ({
                    ...v,
                    exceeded: isExceeded(selectedFilterField, index),
                })),
        );
    };

    const onClickDropdownAnchor: MouseEventHandler = () => {
        if (searchValue && isExactMatchOperator) updateDropdownOpenState(true);
    };

    const handleSearchInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && (!dropdownOpen || focusIndex === undefined)) {
            checkSubmitAction(true);
        } else if (event.key === (selectedFilterField.join ?? ",") && isSimpleFilter) {
            event.preventDefault();
            checkSubmitAction();
            inputRef.current?.focus();
        } else if (event.key === "Backspace" && searchValue === "") {
            setSelectedOptions(previous => previous.slice(0, -1));
        }
    };

    const handlePageKeyDown = () => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "ArrowUp") {
                if (!focusIndex) setFocusIndex(0);
                else setFocusIndex(focusIndex - 1);
            } else if (event.key === "ArrowDown") {
                if (focusIndex === undefined) setFocusIndex(0);
                else if (focusIndex < options.length) setFocusIndex(focusIndex + 1);
            } else if (event.key === "Escape" && focusIndex !== undefined) {
                setFocusIndex(undefined);
            } else if ((event.key === " " || event.key === "Enter") && focusIndex !== undefined) {
                const currentOption = options[focusIndex];
                onCheckedChanged(currentOption, !currentOption.checked);
            }
        };

        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    };

    const addSplittedValues = (values: string[]) => {
        const newValues = uniqBy(
            [
                ...selectedOptions,
                ...values.map(v => ({
                    value: toLower(v),
                    label: v,
                    checked: true,
                    exceeded: false,
                    invalid: !selectedFilterField.allowCustomInput ? undefined : false,
                })),
            ],
            item => `${toLower(item.value?.toString())}`,
        );

        if (isExceeded(selectedFilterField, newValues.length)) {
            const updatedSelectedValues = newValues.map((value, index) => ({
                ...value,
                exceeded: isExceeded(selectedFilterField, index),
            }));
            setSelectedOptions(updatedSelectedValues);
        } else {
            setSelectedOptions(newValues);
        }
    };

    const onPasteValues = (event: ClipboardEvent<HTMLInputElement>) => {
        if (!isSimpleFilter) return;

        const pasteContent = event.clipboardData.getData("text");
        const values = pasteContent
            .split(selectedFilterField.split ?? selectedFilterField.join ?? ",")
            .map(s => s.trim())
            .filter(Boolean);
        if (values.length > 1) {
            addSplittedValues(values);
            event.preventDefault();
        }
    };

    const updateDropdownOpenState = (open: boolean) => {
        setDropdownOpen(open && searchValue.length >= MIN_TYPEAHEAD_LENGTH);
    };

    const updateValue = (before: string | number | AddressLocation | Department, after: string) => {
        setSelectedOptions(prevValue =>
            prevValue.map(p => {
                if (isEqual(p.value, before)) {
                    return {
                        value: toLower(after.trim()),
                        label: after.trim(),
                        category: "",
                        checked: true,
                    };
                } else {
                    return p;
                }
            }),
        );
        inputRef.current?.focus();
    };

    return {
        inputRef,
        options,
        selectedOptions,
        searchValue,
        showLoading,
        dropdownOpen,
        isExactMatchOperator,
        overLimit,
        focusIndex,
        setFocusIndex,
        addItem,
        removeItem,
        updateDropdownOpenState,
        setSelectedOptions,
        setSearchValue,
        onPasteValues,
        onClickDropdownAnchor,
        handleSearchInputKeyDown,
        onCheckedChanged,
        setDropdownOpen,
        updateValue,
    };
};
