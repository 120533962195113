import { useState } from "react";
import { formatDate } from "date-fns";
import { useNavigate } from "react-router-dom";
import ScalePlanIcon from "@/assets/icons/scale-plan.svg?react";
import GrowPlanIcon from "@/assets/icons/grow-plan-large.svg?react";
import { UpgradePlan } from "@/components/organisms/subscription/UpgradePlan";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { SubscriptionStatisticsOutput } from "@/types/audience";
import { Subscription, SubscriptionInterval, SubscriptionProducts, SubscriptionStatus } from "@/types/subscription";
import { CancelPlanModal } from "@/components/organisms/subscription/CancelPlan";
import { Button } from "@/components/atoms/Button/Button";
import { formatStripeAmount } from "@/utils/number";
import { getPriceInfo } from "../subscription/utils";

interface Props {
    subscription: Subscription;
    subscriptionProducts: SubscriptionProducts;
    statistics: SubscriptionStatisticsOutput;
}

export const PlanPreview = ({ subscription, subscriptionProducts, statistics }: Props) => {
    const navigate = useNavigate();

    const [cancelPlanModalOpened, setCancelPlanModalOpened] = useState(false);

    const { audiencesUsed, audiencesMaxLimit } = statistics || {};
    const freeTierMonthly = getPriceInfo(subscriptionProducts, "grow", "free", "monthly");
    const currentSubscriptionItem = subscription.subscription_items.find(({ product_id }) =>
        [subscriptionProducts.grow.product_id, subscriptionProducts.scale.product_id].includes(product_id),
    );
    const isFree = currentSubscriptionItem?.price === freeTierMonthly?.id;

    const isCanceled = subscription.status === SubscriptionStatus.CANCELED;
    const isPaused = subscription.status === SubscriptionStatus.PAUSED;

    const planEndDate = subscription.currentPeriodEnd
        ? formatDate(subscription.currentPeriodEnd * 1000, "MMMM dd, yyyy")
        : "";

    const isTrial = (subscription.status === SubscriptionStatus.TRIALING && !isCanceled) || isFree;
    const isActive = subscription.status === SubscriptionStatus.ACTIVE;

    const isScale = currentSubscriptionItem?.product_id === subscriptionProducts.scale.product_id;

    const isYearly = subscription.interval === SubscriptionInterval.YEAR;

    const audiencesRemaining = audiencesMaxLimit - audiencesUsed;
    const allAudiencesUsed = audiencesRemaining < 1;
    const percentageRemaining = (audiencesUsed / audiencesMaxLimit) * 100;
    const hasPaymentMethod = Object.keys(subscription.paymentMethod).length > 0;

    const seeActiveAudiences = () => {
        navigate("/audiences?status=running&status=synced&status=incomplete&tooltip=filter");
    };

    const tooltipText = allAudiencesUsed
        ? "No audiences remaining in your plan. Show your active audiences."
        : "Show your active audiences on Dashboard.";

    return (
        <div className="w-full min-w-full flex flex-col gap-6 px-8 py-6 rounded-2xl shadow-main bg-white overflow-x-auto">
            <div className="flex space-x-6 w-full">
                <div className="flex gap-4 items-center min-w-80">
                    {isScale ? (
                        <ScalePlanIcon className={`shrink-0 ${isCanceled && "opacity-40"}`} />
                    ) : (
                        <GrowPlanIcon className={`shrink-0 ${isCanceled && "opacity-40"}`} />
                    )}

                    <div className="truncate">
                        <div className="text-ui-300 text-10 uppercase">Your Plan</div>
                        <div className="text-h1 text-ui-900">{currentSubscriptionItem?.product_name}</div>
                        {isTrial && (
                            <div className="text-12 text-center text-ui-900 bg-yellow-500 px-2 py-0.5 rounded font-medium w-max">
                                FREE TRIAL
                            </div>
                        )}
                        {isCanceled && (
                            <div className="text-12 text-center text-ui-300 bg-ui-300/[0.19] px-2 py-0.5 rounded font-medium w-max">
                                CANCELED
                            </div>
                        )}
                        {isPaused && (
                            <div className="text-12 text-center text-ui-300 bg-ui-300/[0.19] px-2 py-0.5 rounded font-medium w-max">
                                PAUSED
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-[1px] bg-ui-200 shrink-0"></div>
                <div className="flex-1 flex items-start space-x-16 px-2">
                    <div className="min-w-24">
                        <div className="flex flex-col px-4 py-2 border border-transparent rounded-lg">
                            <div className="text-ui-300 text-10 uppercase mb-1.5 text-nowrap">Active audiences</div>
                            <Tooltip content={tooltipText} side="bottom" className="w-[280px]">
                                <div onClick={seeActiveAudiences}>
                                    <div
                                        className={`flex items-center text-14 ${allAudiencesUsed ? "text-warning" : "text-primary"}`}
                                    >
                                        <div className="flex items-center gap-0.5 cursor-pointer">
                                            <div className="div">{audiencesUsed}</div>
                                            <div className="div">/</div>
                                            <div className="div">{audiencesMaxLimit}</div>
                                        </div>
                                    </div>
                                    <div className="h-1 rounded bg-ui-200 mt-2">
                                        <div
                                            style={{
                                                width: `${percentageRemaining < 100 ? percentageRemaining : 100}%`,
                                            }}
                                            className={`h-full rounded ${allAudiencesUsed ? "bg-orange-700" : "bg-blue-800"}`}
                                        ></div>
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>

                    <div className="flex flex-col min-w-24 py-2">
                        <div className="text-ui-300 text-10 uppercase mb-1.5">Billed</div>
                        <div className="text-14 font-medium text-ui-900">{isTrial ? "-" : isYearly ? "Yearly" : "Monthly"}</div>
                    </div>
                    <div className="flex flex-col min-w-24 py-2">
                        <div className="text-ui-300 text-10 uppercase mb-1.5">Billing Amount</div>
                        <div className="text-14 font-medium text-ui-900">
                            {isTrial ? "FREE" : formatStripeAmount(
                                isYearly
                                    ? currentSubscriptionItem!.plan_amount / 12 / 100
                                    : currentSubscriptionItem!.plan_amount / 100,
                                0,
                            )}
                            {isTrial ? "" : "/month"}
                        </div>
                    </div>
                    <div className="flex flex-col min-w-24 py-2">
                        <div className="text-ui-300 text-10 uppercase mb-1.5 text-nowrap">
                            {!isTrial
                                ? "Next billing date"
                                : !hasPaymentMethod
                                    ? "Free Trial Ends On"
                                    : "Billing Starts On"}
                        </div>
                        <div className="text-14 font-medium text-ui-900 max-w-[130px]">
                            {isFree ? "Trial starts after first audience sync" : isCanceled ? "-" : planEndDate}
                        </div>
                    </div>
                </div>

                <div className="w-[1px] bg-ui-200 shrink-0"></div>
                <div className="justify-center flex flex-col space-y-1.5 px-4">
                    <UpgradePlan />
                    {(isTrial || isActive) && !isCanceled && (
                        <Button
                            variant="ghost"
                            className="!text-primary"
                            onClick={() => setCancelPlanModalOpened(true)}
                        >
                            Cancel Plan
                        </Button>
                    )}
                </div>
            </div>

            {cancelPlanModalOpened && <CancelPlanModal close={() => setCancelPlanModalOpened(false)} />}
        </div>
    );
};
