import { ReactNode, createContext, useContext, useEffect, useState } from "react";

import { useGetConnectionsQuery } from "@/api/integrations";
import { Connection } from "@/types/integrations";

interface ConnectionsContextProps {
    connections: Connection[];
}

const ConnectionsContext = createContext<ConnectionsContextProps | undefined>(undefined);

export const ConnectionsProvider = ({ children }: { children: ReactNode }) => {
    const [connections, setConnections] = useState<Connection[]>([]);
    const { data: apiConnections } = useGetConnectionsQuery({ enabled: true }, {
        pollingInterval: 15000
    });

    useEffect(() => {
        setConnections(apiConnections as Connection[]);
    }, [apiConnections]);

  return (
    <ConnectionsContext.Provider value={{ connections }}>
      {children}
    </ConnectionsContext.Provider>
  );
};

export const useConnections = (): ConnectionsContextProps => {
    const context = useContext(ConnectionsContext);
    if (context === undefined) {
        throw new Error("useConnections must be used within an ConnectionsProvider");
    }

    return context;
};
