import { Link } from "react-router-dom";
import { addDays, differenceInCalendarDays } from "date-fns";
import { Subscription } from "@/types/subscription";
import { Button } from "@/components/atoms/Button/Button";
import GrowPlanIcon from "@/assets/icons/grow-plan.svg?react";
import { SUBSCRIPTION_TRIAL_PERIOD } from "@/constants/subscriptions";

interface Props {
    subscription: Subscription;
    audiencesLimit?: number;
}

export const TrialStartedBanner = ({ subscription }: Props) => {
    const { trialEnd: trialEndDate, startDate: trialStartDate } = subscription;

    const currentDate = new Date();
    const startDate = new Date(trialStartDate * 1000);
    const endDate = new Date(trialEndDate * 1000);

    const yesterday = addDays(currentDate, -1);
    const lastWeek = addDays(endDate, -7);
    const isFirstDay = startDate > yesterday;
    const isLastWeek = currentDate > lastWeek;
    const trialDaysLeft = differenceInCalendarDays(endDate, currentDate);

    const trialDaysLeftPercents = trialDaysLeft < SUBSCRIPTION_TRIAL_PERIOD ? (trialDaysLeft / SUBSCRIPTION_TRIAL_PERIOD) * 100 : 100;

    if (Object.keys(subscription.paymentMethod).length || (!isFirstDay && !isLastWeek)) return null;

    return (
        <div className="flex items-center mx-12 mb-2 px-6 py-4 bg-blue-900 space-x-6 rounded-md">
            <div className="flex items-center gap-3 shrink-0">
                <GrowPlanIcon />

                <div>
                    <div className="text-white text-16 font-medium">Primer Grow</div>
                    <div className="text-12 text-center text-ui-900 bg-yellow-500 px-2 py-0.5 rounded font-medium">
                        FREE TRIAL
                    </div>
                </div>
            </div>

            <p data-testid="trial-text" className="flex-1 flex flex-col text-14 text-white truncate">
                Upgrade now to unlock more audiences and keep using Primer after your trial ends.
            </p>

            <div>
                <div className="flex items-center gap-1 mb-2">
                    <div className="text-blue-300 text-16 font-semibold">{trialDaysLeft}</div>
                    <div className="text-14 text-white">trial days left</div>
                </div>
                <div className="w-40 h-1 rounded bg-ui-200">
                    <div className="h-full rounded bg-blue-300" style={{ width: `${trialDaysLeftPercents}%` }}></div>
                </div>
            </div>

            <Button asChild variant="secondary">
                <Link to="/settings">Upgrade</Link>
            </Button>
        </div>
    );
};
