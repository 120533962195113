/* eslint-disable react-hooks/exhaustive-deps */
import { CriteriaCard } from "@/pages/AudienceNew/CriteriaCard.tsx";
import { AudienceName } from "@/components/molecules/AudienceName/AudienceName.tsx";
import { DestinationCard } from "@/pages/AudienceNew/DestinationCard.tsx";
import { useLocation, useNavigate, useParams } from "react-router";
import {
    useCreateAudienceMutation,
    useCreateAudienceShapeMutation,
    useFavoriteAudienceMutation,
    useGetAudienceQuery,
    useRunAudienceMutation,
    useUpdateAudienceMutation
} from "@/api/audiences.ts";
import {
    AudienceStatus as AudienceStatusEnum,
    Destination,
    DestinationsSchema,
    SourceCriteriaFilter
} from "@/types/audience";
import AudienceHeaderCTA from "@/pages/AudienceNew/AudienceHeaderCTA.tsx";
import ArrowLeftIcon from "@/assets/icons/arrow-left.svg?react";
import SearchIcon from "@/assets/icons/search.svg?react";
import { Button } from "@/components/atoms/Button/Button.tsx";
import { AccessibleIcon } from "@radix-ui/react-accessible-icon";
import AudienceStatus from "@/components/molecules/AudienceStatus/AudienceStatus.tsx";
import AudienceFavorite from "@/components/molecules/AudienceFavorite/AudienceFavorite.tsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { first, isEmpty, isEqual } from "lodash";
import { checkIfEstimateWillRemaingSame, getNewAudienceName } from "@/utils/audience.ts";
import {
    AudiencesEnrichmentCriteria,
    CreateAudienceRequest,
    CreateAudienceResponse,
    CreateAudienceShapeRequest,
    CreateAudienceShapeResponse,
    FavoriteAudienceRequest,
    RunAudienceRequest,
    UpdateAudienceRequest
} from "@/types/api";
import ErrorMessage from "@/components/molecules/ErrorMessage/ErrorMessage";
import { useToast } from "@/components/atoms/Toast/useToast.tsx";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { getHttpStatusCode } from "@/api";
import compact from "lodash/compact";
import { Filter, FilterEntityTypes, GroupLogicalOperator, SourceCriteria } from "@primer/filters/types";
import { useGetConnectionsHash } from "@/hooks/useGetConnectionsHash";
import { activeDestinations } from "@/constants/audiences";
import postHog from "posthog-js";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import { useGetCrmMatchRatesQuery } from "@/api/crm";
import { AppState } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import { setDestinations, setName } from "@/store/audience";
// import { useQueryParams, withDefault } from "use-query-params";
import { Tabs } from "@/components/molecules/Tabs/Tabs";
import { SummaryCard } from "./SummaryCard";
import { PreviewCard } from "./Preview/PreviewCard";
import { useAudienceFilterValidation } from "@/hooks/useAudienceFilterValidation";
import { getNewFilterWithCriteriaChanges } from "@/utils/summary";
import {
    SummaryCriteriaChangeController
} from "@/components/atoms/SummaryCriteriaChangeController/SummaryCriteriaChangeController";
import { SelectedForExclusionItem, useSummaryCriteriaChangeManager } from "@/hooks/useSummaryCriteriaChangeManager";
import ResizablePanels from "@/components/molecules/ResizablePanels/ResizablePanels";
import { Card } from "@/components/atoms/Card/Card";
import { AudienceEstimateCardHorizontal } from "./AudienceEstimateCardHorizontal";
import { useNavigation } from "@/context/NavigationContext";
import { Input } from "@/components/atoms/Input/Input";
import Select from "@/components/atoms/Select/Select";
import { useDebounce } from "react-use";
import { formatNumber } from "@/utils/number";
import { setupWebVitalsMetrics } from "@/utils/webVitals";
import { useCriteriaAssetsManager } from "@/hooks/useCriteriaAssetsManager";
import { useFilterManagement } from "@/hooks/useFilterManagement";

export enum AudienceTabs {
    SUMMARY = "Summary",
    PREVIEW = "Preview",
}

const PREVIEW_SEARCH_FIELD_OPTIONS = [
    { description: "Job Title", value: "job_title" },
    { description: "Company", value: "company_name" },
    { description: "Industry", value: "company_industry" },
    { description: "Keywords", value: "company_keywords" },
];

export const AudienceMain = () => {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const { id: paramId } = useParams();
    const { audienceId: id, setAudienceId } = useNavigation();
    const isNewAudience = useMemo(() => isEmpty(id), [id]);
    const [previewSearchField, setPreviewSearchField] = useState(PREVIEW_SEARCH_FIELD_OPTIONS[0].value);
    const [previewSearchValue, setPreviewSearchValue] = useState("");
    const previousPreviewSearchValueRef = useRef(previewSearchValue);
    const currentEditableFieldRef = useRef<string | undefined>("");

    const {
        data: audience,
        isLoading,
        error: getError,
    } = useGetAudienceQuery(
        { id },
        {
            skip: isNewAudience,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
            pollingInterval: 30000,
        },
    );
    const [preventCriteriaChange, setPreventCriteriaChange] = useState(
        (!audience?.status && !!paramId) ||
            audience?.status === AudienceStatusEnum.ARCHIVED ||
            audience?.status === AudienceStatusEnum.RUNNING,
    );
    const [connectionsHash] = useGetConnectionsHash();
    const [createAudienceMutation, { error: createAudienceError }] = useCreateAudienceMutation();
    const createAudience = async (req: CreateAudienceRequest): Promise<CreateAudienceResponse> =>
        createAudienceMutation(req).unwrap();

    const [createAudienceShapeMutation] = useCreateAudienceShapeMutation();
    const createAudienceShape = async (req: CreateAudienceShapeRequest): Promise<CreateAudienceShapeResponse> =>
        createAudienceShapeMutation(req).unwrap();

    const [updateAudienceMutation, { isLoading: isUpdating, error: updateAudienceError }] = useUpdateAudienceMutation();
    const updateAudience = async (req: UpdateAudienceRequest): Promise<CreateAudienceResponse> =>
        updateAudienceMutation(req).unwrap();

    const [favoriteAudienceMutation, { error: favoriteAudienceError }] = useFavoriteAudienceMutation();
    const favoriteAudience = async (req: FavoriteAudienceRequest): Promise<null> =>
        favoriteAudienceMutation(req).unwrap();

    const [runAudienceMutation, { isLoading: isBuilding, error: buildAudienceError }] = useRunAudienceMutation();
    const runAudience = async (req: RunAudienceRequest): Promise<null> => runAudienceMutation(req).unwrap();

    const isSummaryEnabled = useFeatureFlag(FeatureFlagsEnum.SUMMARY);
    const isPreviewEnabled = useFeatureFlag(FeatureFlagsEnum.PREVIEW);
    const tabs = Object.values(AudienceTabs);
    const enabledTabs = tabs.filter(
        tab => (tab === AudienceTabs.SUMMARY && isSummaryEnabled) || (tab === AudienceTabs.PREVIEW && isPreviewEnabled),
    );

    // const AudienceTabsParam = withDefault(
    //     {
    //         encode(value: AudienceTabs) {
    //             return value?.toString();
    //         },
    //         decode(strValue) {
    //             if (!strValue || !Object.values(AudienceTabs).includes(strValue as AudienceTabs)) {
    //                 return first(enabledTabs)?.toString() ?? AudienceTabs.SUMMARY;
    //             }
    //             return strValue as AudienceTabs;
    //         },
    //     },
    //     first(enabledTabs) ?? AudienceTabs.SUMMARY,
    // );
    // const [query, setQuery] = useQueryParams({ tab: AudienceTabsParam }, { removeDefaultsFromUrl: true });
    const [tab, setTab] = useState(first(enabledTabs) ?? AudienceTabs.SUMMARY);
    const [audienceType] = useState(FilterEntityTypes.PERSON);
    const showLoading = isLoading && !!paramId;

    const { name, destinations } = useSelector((state: AppState) => state["audience"]);

    const sourceCriteriaRef = useRef(audience?.shape?.source_criteria);

    const [favorite, setFavorite] = useState(audience?.isFavorite ?? false);

    const crmInstanceId = audience?.shape?.source_criteria?.group?.filters.find(
        (f: SourceCriteriaFilter) => !!f.instanceId,
    )?.instanceId;

    const { data: crmMatchRates } = useGetCrmMatchRatesQuery(
        { instanceId: crmInstanceId! },
        {
            skip: !crmInstanceId,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
            pollingInterval: 30000,
        },
    );

    const criteriaAssetsManager = useCriteriaAssetsManager({
        audienceId: id,
        shapeId: audience?.shape?.id,
        audience,
        preventCriteriaChange,
    });

    const { criteriaError, showCriteriaError } = useAudienceFilterValidation({
        audience,
        audienceSize: criteriaAssetsManager.criteriaAssets?.people_count,
    });

    const summaryChangeStateManager = useSummaryCriteriaChangeManager({
        confirmSummaryCriteriaChangeCallback: confirmSummaryCriteriaChange,
        summaryValues: criteriaAssetsManager.summaries,
    });

    useEffect(() => onLoadAction(), []);
    useEffect(() => setAudienceId(paramId ?? ""), [paramId]);
    useEffect(() => onUpdateConnection(), [connectionsHash]);
    useEffect(() => updateAudienceDependencies(), [audience]);
    useEffect(() => updateErrorAndNotifications(), [toast, getError, navigate]);
    useEffect(
        () => updateErrors(),
        [
            toast,
            getError,
            navigate,
            createAudienceError,
            updateAudienceError,
            favoriteAudienceError,
            buildAudienceError,
        ],
    );

    const onLoadAction = () => {
        setupWebVitalsMetrics("audience");
        setAudienceId(paramId ?? "");
        postHog.capture("Audience page viewed", { audienceId: id, newAudience: isNewAudience });

        dispatch(setName(isNewAudience || !audience?.name ? getNewAudienceName() : audience?.name));
    };

    const onUpdateConnection = () => {
        if (!isNewAudience) return;

        const connectedDestinations: DestinationsSchema = {};
        Object.keys(connectionsHash).forEach(c => {
            const connection = connectionsHash[c as Destination];

            if (connection && activeDestinations.includes(c as Destination)) {
                connectedDestinations[c as Destination] = true;
            }
        });

        dispatch(setDestinations(connectedDestinations));
    };

    const updateAudienceDependencies = () => {
        if (audience) {
            sourceCriteriaRef.current = audience?.shape?.source_criteria;
            setFavorite(audience.isFavorite);
            dispatch(setName(audience.name));
            setPreventCriteriaChange(
                (!audience?.status && !!paramId) ||
                    audience.status === AudienceStatusEnum.ARCHIVED ||
                    audience.status === AudienceStatusEnum.RUNNING,
            );
            if (audience.destinations) dispatch(setDestinations(audience.destinations));
        }
    };

    const updateErrorAndNotifications = () => {
        if (!getError) return;

        const fetchBaseQueryError = getError as FetchBaseQueryError;
        const statusCode = getHttpStatusCode(getError);

        toast({ description: fetchBaseQueryError.data as string, variant: "destructive" });

        if (statusCode && statusCode < 500) navigate("/audiences");
    };

    const updateErrors = () => {
        const errors = compact([createAudienceError, updateAudienceError, favoriteAudienceError, buildAudienceError]);

        errors.forEach(e => {
            const fetchBaseQueryError = e as FetchBaseQueryError;
            const defaultError = "An error occurred. Please retry.";
            toast({ description: (fetchBaseQueryError.data as string) || defaultError, variant: "destructive" });
        });
    };

    const handleBackClick = () => {
        /**
         * If there is a previous state in the history stack, go back to the previous state
         * If there is no previous state in the history stack (user opened this page directly), go back to the dashboard
         */
        if (location.state) {
            navigate(-1);
        } else {
            navigate("/audiences");
        }
    };

    const requestCreatedAudience = async (requestBody: CreateAudienceRequest) => {
        const newAudience = await createAudience(requestBody);
        if (newAudience) {
            setAudienceId(newAudience.id);
            window.history.replaceState(null, "Primer", `/audiences/${newAudience.id}${location.search}`);
        }

        return newAudience;
    };

    const handleNameChange = async (name: string) => {
        dispatch(setName(name));
        if (isNewAudience) {
            await requestCreatedAudience({ name, destinations });
        } else {
            await updateAudience({ id, name });
        }
    };

    const handleFavorite = async (favorite: boolean) => {
        setFavorite(favorite);
        if (id) await favoriteAudience({ audienceId: id, favorite });
    };

    const handleDestinationChange = async (newSelectedDestinations: DestinationsSchema) => {
        dispatch(setDestinations(newSelectedDestinations));
        const newProperties = { name, destinations: newSelectedDestinations };
        if (isNewAudience) {
            await requestCreatedAudience(newProperties);
        } else {
            await updateAudience({ id, destinations: newSelectedDestinations });
        }
    };

    const shouldLoadNewEstimate = (
        currentFilters: SourceCriteriaFilter[],
        newFilters: SourceCriteriaFilter[],
    ): boolean => {
        const estimateWontChange = checkIfEstimateWillRemaingSame(currentFilters, newFilters);

        return criteriaAssetsManager.isLoadingSize || !estimateWontChange;
    };

    // Debounce can't handle this logic because of extra complexity
    // https://github.com/sayprimer/primer-platform/pull/4258#discussion_r1775911221
    const delayedEstimateCall = async (
        createShapeResponse: CreateAudienceShapeResponse,
        criteria: any,
        skipDelay: boolean,
    ) => {
        if (!skipDelay) {
            const delay = async (ms: number) => await new Promise(resolve => setTimeout(resolve, ms));

            await delay(1500);
            while (
                currentEditableFieldRef.current !== undefined &&
                currentEditableFieldRef?.current !== filterManager.editableField
            ) {
                await delay(250);
            }
        }

        if (!sourceCriteriaRef.current?.group?.filters) return;

        const clone = (obj: any) => JSON.parse(JSON.stringify(obj));
        const requestFilters_Before = clone(criteria?.group?.filters);
        const currentFilter_After = clone(sourceCriteriaRef.current?.group?.filters);
        const updateEstimate = shouldLoadNewEstimate(requestFilters_Before, currentFilter_After);
        if (isEqual(requestFilters_Before, currentFilter_After) || !updateEstimate) {
            callEstimate(createShapeResponse);
        }
    };

    const callEstimate = useCallback(
        async (createShapeResponse: CreateAudienceShapeResponse) => {
            await criteriaAssetsManager.callAllEstimates(
                {
                    audience_id: id,
                    shape_id: createShapeResponse?.shape?.id,
                },
                {
                    previewParams: {
                        offset: 0,
                        searchField: previewSearchField,
                        searchValue: previewSearchValue || undefined,
                    },
                },
            );
        },
        [id, previewSearchField, previewSearchValue, criteriaAssetsManager.summaries],
    );

    const saveCriteria = async (filters: Filter[], skipDelay = false) => {
        const source_criteria = {
            target_entity_type: audienceType,
            group: {
                operator: GroupLogicalOperator.AND,
                filters,
                groups: [],
            },
        };
        const currentAudienceFilters = sourceCriteriaRef.current?.group?.filters;
        const updateEstimate = shouldLoadNewEstimate(currentAudienceFilters ?? [], filters ?? []);
        sourceCriteriaRef.current = source_criteria;
        if (isNewAudience) {
            // we have atleast one complete filter, now we can create audience and estimate
            if (updateEstimate) {
                const newAudience = await requestCreatedAudience({
                    name,
                    source_criteria,
                    destinations,
                });
                criteriaAssetsManager.callAllEstimates(
                    {
                        audience_id: newAudience.id,
                        shape_id: newAudience?.shape?.id,
                    },
                    {
                        previewParams: {
                            offset: 0,
                            searchField: previewSearchField,
                            searchValue: previewSearchValue || undefined,
                        },
                    },
                );
            }
        } else {
            const createShapeResponse = await createAudienceShape({
                audienceId: id,
                enrichment_criteria: {
                    categories: [AudiencesEnrichmentCriteria.AD],
                },
                source_criteria,
                updateEstimate,
            });
            if (
                criteriaAssetsManager.isLoadingSize ||
                criteriaAssetsManager.isLoadingPreview ||
                criteriaAssetsManager.summariesLoading.length > 0 ||
                updateEstimate
            ) {
                await delayedEstimateCall(createShapeResponse, source_criteria, skipDelay);
            }
        }
    };

    async function confirmSummaryCriteriaChange(items: SelectedForExclusionItem[], isRepoV2Enabled?: boolean) {
        postHog.capture(`Changing ${items.length} items`, { items });
        const newFilters = getNewFilterWithCriteriaChanges(
            items,
            sourceCriteriaRef.current?.group?.filters ?? [],
            !!isRepoV2Enabled,
        );
        await saveCriteria(
            newFilters.map(f => f as Filter),
            true,
        );
    }

    const filterManager = useFilterManagement(
        saveCriteria,
        sourceCriteriaRef.current as SourceCriteria,
        audience?.status,
    );

    const CriteriaCardMemo = useMemo(() => {
        return (
            <CriteriaCard
                sourceCriteria={sourceCriteriaRef.current as SourceCriteria}
                filterManager={filterManager}
                audienceType={audienceType}
                showCriteriaError={showCriteriaError}
                criteriaError={criteriaError}
            />
        );
    }, [audience?.status, sourceCriteriaRef.current, showCriteriaError, criteriaError, saveCriteria]);

    const onPreviewSearch = (searchValueOverride?: string) => {
        postHog.capture(`Preview Search`, { previewSearchField, previewSearchValue });
        criteriaAssetsManager.callEstimatePreview({
            audience_id: audience?.id,
            shape_id: audience?.shape?.id,
            searchField: previewSearchField,
            searchValue: searchValueOverride ?? previewSearchValue,
        });
    };

    const onPreviewPageChange = useCallback(
        (offset: number) => {
            criteriaAssetsManager.callEstimatePreview({
                audience_id: audience?.id,
                shape_id: audience?.shape?.id,
                searchField: previewSearchValue ? previewSearchField : undefined,
                searchValue: previewSearchValue || undefined,
                offset: Number(offset),
            });
        },
        [audience, previewSearchValue, previewSearchField],
    );

    const [isReady] = useDebounce(
        () => {
            if (previousPreviewSearchValueRef.current !== previewSearchValue) onPreviewSearch();
        },
        500,
        [previewSearchValue],
    );

    useDebounce(
        () => {
            currentEditableFieldRef.current = filterManager.editableField;
        },
        1500,
        [filterManager.editableField],
    );

    const onPreviewSearchFieldChange = useCallback(
        (value: string) => {
            setPreviewSearchField(value);

            if (previewSearchValue) {
                previousPreviewSearchValueRef.current = "";
                setPreviewSearchValue("");
                onPreviewSearch("");
            }
        },
        [onPreviewSearch],
    );

    return (
        <>
            <header
                id="audience-header"
                className="pt-6 px-12 my-1.5 mx-5 bg-white rounded-2xl shadow-main sticky top-0 z-10"
            >
                <div className="flex-none pb-4 flex items-center justify-between">
                    <div className="flex items-center flex-1 overflow-hidden">
                        <Button
                            variant="secondary"
                            size="icon"
                            className="mr-4 !h-9"
                            title="Back"
                            onClick={handleBackClick}
                        >
                            <AccessibleIcon label="Back">
                                <ArrowLeftIcon />
                            </AccessibleIcon>
                        </Button>
                        <AudienceStatus
                            loading={showLoading}
                            status={audience?.status || AudienceStatusEnum.DRAFT}
                            className="mr-2"
                        />
                        <AudienceFavorite
                            favorite={favorite}
                            disabled={isNewAudience}
                            buttonClassName="mr-2"
                            onAudienceFavorite={handleFavorite}
                        />
                        <AudienceName
                            loading={showLoading}
                            name={name}
                            onNameChange={handleNameChange}
                            disabled={false}
                        />
                    </div>

                    <AudienceHeaderCTA
                        audience={audience}
                        criteriaAssets={criteriaAssetsManager.criteriaAssets}
                        criteriaAssetsManager={criteriaAssetsManager}
                        loading={showLoading}
                        isBuilding={isBuilding}
                        isUpdating={isUpdating}
                        isLoadingEstimateInfo={criteriaAssetsManager.isLoadingSomeEstimateAsset}
                        isPendingExclusion={summaryChangeStateManager.isPendingExclusion}
                        status={audience?.status || AudienceStatusEnum.DRAFT}
                        audienceId={id}
                        runAudience={runAudience}
                        // lastUpdatedAt={audience?.lastUpdatedAt}
                        submittedAt={audience?.submittedAt}
                    />
                </div>
                {audience?.hasError && (
                    <div className="mt-4">
                        <ErrorMessage error="General audience error" />
                    </div>
                )}
            </header>
            <SummaryCriteriaChangeController changeStateManager={summaryChangeStateManager} />
            <div className="px-1.5 pt-2 flex-grow gap-4 flex justify-center mx-3.5">
                <ResizablePanels
                    leftPanel={{
                        content: (
                            <div className="flex flex-col gap-2">
                                <DestinationCard
                                    loading={showLoading}
                                    disabled={
                                        audience?.status === AudienceStatusEnum.ARCHIVED ||
                                        audience?.status === AudienceStatusEnum.RUNNING
                                    }
                                    selectedDestinations={destinations}
                                    setSelectedDestinations={handleDestinationChange}
                                />
                                {CriteriaCardMemo}
                            </div>
                        ),
                        minSize: 440,
                        collapsible: true,
                    }}
                    rightPanel={{
                        content: (
                            <div className="flex flex-col gap-2">
                                <AudienceEstimateCardHorizontal
                                    audience={audience}
                                    criteriaAssets={criteriaAssetsManager.criteriaAssets}
                                    isCriteriaAssetsLoading={criteriaAssetsManager.isLoadingSize}
                                    crmMatchRates={crmMatchRates}
                                    isLoadingAudience={isLoading}
                                    isLoadingMatchRate={criteriaAssetsManager.isLoadingMatchRate}
                                />
                                <Card className="mb-3 !p-[8px]">
                                    <div
                                        id="summary-preview-tab"
                                        className="pb-2 pt-4 px-2 flex justify-between items-center"
                                    >
                                        <Tabs
                                            selectedTab={tab}
                                            selectTab={option => {
                                                // setQuery({ tab: option });
                                                setTab(option as AudienceTabs);
                                                postHog.capture(`Audience ${option} Tab`);
                                            }}
                                            options={enabledTabs.map(value => ({ key: value, value }))}
                                        />
                                        {tab === AudienceTabs.PREVIEW && (
                                            <div className="flex items-center">
                                                {previewSearchValue &&
                                                    !criteriaAssetsManager.isLoadingPreview &&
                                                    Number(criteriaAssetsManager.preview?.count) !==
                                                        criteriaAssetsManager.size?.people_count &&
                                                    isReady() === true && (
                                                        <>
                                                            <span
                                                                id="previewSearchResultCount"
                                                                className="rounded-lg text-ui-700 bg-blue-200 p-2 mr-2 leading-5 text-sm font-medium min-w-10 text-center"
                                                            >
                                                                {formatNumber(
                                                                    Number(criteriaAssetsManager.preview?.count),
                                                                    [10000, 1000000],
                                                                    "",
                                                                )}
                                                            </span>
                                                            <span className="text-ui-300 leading-5 mr-2">
                                                                people found for
                                                            </span>
                                                        </>
                                                    )}
                                                <div className="flex items-center px-2 border rounded-lg border-ui-200/[0.32]">
                                                    <SearchIcon className="flex-none" />
                                                    <Select
                                                        className="!pl-2 !pr-0"
                                                        fullWidth={true}
                                                        border={false}
                                                        items={PREVIEW_SEARCH_FIELD_OPTIONS}
                                                        value={previewSearchField}
                                                        onValueChange={onPreviewSearchFieldChange}
                                                    />
                                                    <Input
                                                        placeholder="Search"
                                                        className="border-none"
                                                        value={previewSearchValue}
                                                        onChange={e => {
                                                            previousPreviewSearchValueRef.current = previewSearchValue;
                                                            setPreviewSearchValue(e.target.value);
                                                        }}
                                                        onKeyDown={e => {
                                                            e.key === "Enter" && onPreviewSearch();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {enabledTabs.includes(AudienceTabs.SUMMARY) && tab === AudienceTabs.SUMMARY && (
                                        <SummaryCard
                                            criteriaAssetsManager={criteriaAssetsManager}
                                            summaryChangeStateManager={summaryChangeStateManager}
                                        />
                                    )}
                                    {enabledTabs.includes(AudienceTabs.PREVIEW) && tab === AudienceTabs.PREVIEW && (
                                        <PreviewCard
                                            isPreviewLoading={criteriaAssetsManager.isLoadingPreview}
                                            preview={criteriaAssetsManager.preview}
                                            onPageChange={onPreviewPageChange}
                                        />
                                    )}
                                </Card>
                            </div>
                        ),
                        minSize: 750,
                        collapsible: true,
                    }}
                />
            </div>
        </>
    );
};
