import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import { PopoverClose } from "@/components/atoms/Popover";

export const UpgradePlanAction = ({ openPlan }: { openPlan: () => void }) => {
    return (
        <PopoverClose>
            <div
                onClick={openPlan}
                className="py-4 hover:bg-blue-50 transition-colors cursor-pointer border-t border-ui-300/[0.19]"
            >
                <div className="mt-2 px-6 py-2 space-y-2">
                    <div className="flex gap-1 items-center text-primary">
                        <ArrowRightIcon className="text-primary mb-0.5 -rotate-45" />
                        <div className="ml-1 font-medium">Upgrade Plan</div>
                    </div>
                    <div className="text-left">Upgrade your plan to increase your audience limit.</div>
                </div>
            </div>
        </PopoverClose>
    );
};
