import React, { KeyboardEventHandler } from 'react';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { Input } from '@/components/atoms/Input/Input';

export interface FilterFieldSearchProps {
    searchText: string;
    setSearchText: (searchText: string) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    setOpen:(open: boolean) => void;
}

const FilterFieldSearch = React.forwardRef<HTMLDivElement, FilterFieldSearchProps>(({ searchText, setSearchText, inputRef, setOpen}, ref) => {
    const onKeyDownEventHandler: KeyboardEventHandler = (event) => {
        event.stopPropagation();
        if(event.key === "Escape") setOpen(false);
    }

    return (
        <div className="flex items-center p-4 pb-2" ref={ref}>
            <MagnifyingGlassIcon className="fas fa-user absolute ml-2 text-gray-400 w-5 h-5"></MagnifyingGlassIcon>
            <Input
                ref={inputRef}
                type="text"
                autoFocus
                value={searchText}
                onKeyDown={onKeyDownEventHandler}
                onChange={e => setSearchText(e.target.value)}
                containerClassName="w-full"
                className="w-full pl-8 pr-3" />
        </div>
    )
});

FilterFieldSearch.displayName = "FilterFieldSearch";

export default FilterFieldSearch;