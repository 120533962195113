import { isEmpty } from "lodash";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip"

export const CsvDataSample = ({ samples = [] }: { samples: string[] }) => {
    if (isEmpty(samples)) return "-";

    const content = (
        <span className="flex flex-col">
            <span className="text-ui-300">Sample data:</span>
            {
                samples.map((sample) => (
                    <span key={sample}>
                        {sample}
                    </span>
                ))
            }
        </span>
    )

    return (
        <Tooltip
            side="bottom"
            content={content}
        >
            <span className="underline cursor-pointer">{samples[0]}</span>
        </Tooltip>
    )
}
