import { CategoryConfig, FilterCategory } from "@primer/filters/types";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks/useFeatureFlag";

export const useGetFilterCategories = (): CategoryConfig[] => {
    const csvImportEnabled = useFeatureFlag(FeatureFlagsEnum.CSV_IMPORT);

    return [
        {
            id: FilterCategory.RECENTLY_USED,
            name: "Recently Used",
            disabled: false,
        },
        {
            id: FilterCategory.PRIMER,
            name: "Primer",
            disabled: false,
        },
        {
            id: FilterCategory.SALESFORCE,
            name: "Salesforce",
            disabled: false,
        },
        {
            id: FilterCategory.CSV_FILES,
            name: "CSV Files",
            disabled: !csvImportEnabled,
        },
    ];
};
