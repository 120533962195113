import { DEFAULT_MAX_FILTER_VALUES, filterMaxSupportedValues } from "../configs";
import { FilterFields } from "../types";

export const trimQuotes = (str: string) => str.slice(1, -1);

export const isSubstring = (str1: string, str2: string) => trimQuotes(str1).includes(trimQuotes(str2));

export const normalizeContainValues = (values: string[]) => {
    const sortedValues = values.sort((s1, s2) => s2.length - s1.length);
    const finalValues = new Set<string>();

    for (let i = 0; i < sortedValues.length; i++) {
        const firstValue = sortedValues[i];
        let substringFound = false;

        for (let j = i + 1; j < sortedValues.length; j++) {
            const secondValue = sortedValues[j];

            if (isSubstring(firstValue, secondValue)) {
                finalValues.add(secondValue);
                if (finalValues.has(firstValue)) finalValues.delete(firstValue);
                substringFound = true;
            }
        }

        if (!substringFound) {
            finalValues.add(firstValue);
        }
    }

    return Array.from(finalValues);
};

// A field identifier might exist in our FilterFields enum, and so we have a config.
// Otherwise, we return the default max values
export const getFilterMaxSupportedValuesForField = (fieldIdentifier: FilterFields | string) => {
    return Object.values(FilterFields).includes(fieldIdentifier as FilterFields)
        ? filterMaxSupportedValues[fieldIdentifier as FilterFields]
        : DEFAULT_MAX_FILTER_VALUES;
};

export const prettifyFirstPartyName = (name: string) => {
    return name
        .replace("__c", "")
        .replace(/_/g, " ")
        .replace(/([A-Z])/g, " $1")
        .trim()
        .replace("  ", " ");
};
