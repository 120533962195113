import { useEffect, useMemo, useState } from "react";
import { upperFirst } from "lodash";

import {
    FilterConfig,
    FilterCategory,
    FilterOperators,
    FilterDataTypes,
    FilterEntityTypes,
} from "@primer/filters/types";
import { prettifyFirstPartyName } from "@primer/filters/util/normalize";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar";
import { GetFirstPartyFiltersResponse, FirstPartyFilterResponseItem } from "@/types/api";

import { NoFiltersFound } from "./NoFiltersFound";
import FirstPartyObjectSelectPanel, { FirstPartyTab } from "./FirstPartyObjectSelectPanel";

export interface FirstPartyTabContentProps {
    setSelectedFilterField: (value: FilterConfig) => void;
    searchText: string;
    setSearchText: (searchText: string) => void;
    onSelectAllObjects: (value: FilterConfig) => void;
    firstPartyFilters?: GetFirstPartyFiltersResponse;
    isLoading: boolean;
}

const FirstPartyTabContent = ({
    setSelectedFilterField,
    searchText,
    setSearchText,
    onSelectAllObjects,
    firstPartyFilters,
    isLoading,
}: FirstPartyTabContentProps) => {
    const [selectedCategory, setSelectedCategory] = useState<FirstPartyTab>(FirstPartyTab.ALL);

    useEffect(() => {
        if (selectedCategory !== FirstPartyTab.ALL) {
            setSearchText("");
        }
    }, [setSearchText, selectedCategory]);

    // Memoize the pretty display names for the filter ID's
    const prettyFirstPartyIds = useMemo(() => {
        const prettyIds: { [key: string]: string } = {};
        firstPartyFilters?.forEach(f => (prettyIds[f.id] = prettifyFirstPartyName(f.id)));

        return prettyIds;
    }, [firstPartyFilters]);

    const getFilterConfig = (f: FirstPartyFilterResponseItem) => ({
        identifier: f.id,
        name: f.name || f.id,
        displayName: f.name || f.id,
        tooltip: "",
        dataType: f.dataType as FilterDataTypes,
        entityType: FilterEntityTypes.PERSON,
        allowedOperators: f.allowedOperators as FilterOperators[],
        allowCustomInput: false,
        categories: [FilterCategory.SALESFORCE],
        picklistValues: f.metadata?.picklist ?? undefined,
        objectType: f.objectType,
        instanceId: f.instanceId,
        path: f.path,
    });

    const handleSelectAllObjects = (objectType: FirstPartyTab) => {
        const idFilter = firstPartyFilters?.find(f => f.id === "Id" && f.objectType === objectType);
        idFilter && onSelectAllObjects(getFilterConfig(idFilter));
    }

    // Filter filters by selected object type and search term
    const filteredFilters =
        firstPartyFilters?.filter(
            f =>
                (selectedCategory === FirstPartyTab.ALL ||
                    f.objectType.toLowerCase() === selectedCategory.toLowerCase()) &&
                (
                    searchText.length === 0 ||
                    prettyFirstPartyIds[f.id].toLowerCase().includes(searchText.toLowerCase()) ||
                    f.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                    f.id.toLowerCase().includes(searchText.toLowerCase())
                ),
        ) ?? [];

    return (
        <>
            <FirstPartyObjectSelectPanel
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                onSelectAllObjects={handleSelectAllObjects}
            />
            <div className="flex flex-col flex-1 overflow-y-scroll max-h-[265px]">
                {filteredFilters.map((f: FirstPartyFilterResponseItem) => {
                    const getFilterDisplayText = () => (
                        <div className="flex justify-between w-full">
                            <span className="text-ui-900 shrink-1 truncate mr-2">
                                {f.name || f.id}
                            </span>
                            <span className="text-ui-300">
                                {upperFirst(f.objectType)}
                                {f.path && ` → ${f.path}`}
                            </span>
                        </div>
                    );
                    const getFilterTooltipText = () => (
                        <>
                            <span className="text-white">
                                {upperFirst(f.objectType)}
                                {f.path && ` → ${f.path}`}.&nbsp;
                            </span>
                            <span className="text-white">
                                {f.name || f.id}
                            </span>
                        </>
                    )

                    const filterField: FilterConfig = getFilterConfig(f);

                    return (
                        <Tooltip
                            content={getFilterTooltipText()}
                            key={`${f.objectType}-${f.path}-${f.id}`}
                            side="right"
                            sideOffset={10}
                            fullWidth
                        >
                            <div
                                id={f.id}
                                className="flex justify-between text-sm leading-tight py-4 px-5 h-[unset] bg-white hover:bg-primary/10 rounded-none"
                                role="menuitem"
                                tabIndex={0}
                                onKeyDown={event => event.key === "Enter" && setSelectedFilterField(filterField)}
                                onClick={() => {
                                    setSelectedFilterField(filterField);
                                }}
                            >
                                {getFilterDisplayText()}
                            </div>
                        </Tooltip>
                    );
                })}
                {filteredFilters.length === 0 && !isLoading && <NoFiltersFound />}
                {isLoading && (
                    <div className="h-full flex fle-row justify-center items-center m-2">
                        <CircularProgressBar size="small" />
                    </div>
                )}
            </div>
        </>
    );
};

export { FirstPartyTabContent };
