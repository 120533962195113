import { ReactNode } from "react";
import { useAuth } from "@clerk/clerk-react";
import PrimerLogo from "@/assets/icons/logo.svg?react";
import { Button } from "@/components/atoms/Button/Button";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { OnboardingNavigation } from "@/components/organisms/onboarding/OnboardingNavigation";

interface Props {
    imgUrl?: string;
    children: ReactNode;
    width?: string;
}

export const OnboardingWrapper = ({ imgUrl, children, width = "w-[500px]" }: Props) => {
    const { signOut } = useAuth();
    return (
        <div className="grid grid-cols-[auto_1fr_auto] pl-10 pr-12 py-5">
            <PrimerLogo className="w-[130px] h-[22px]" />

            <div className={`mx-auto flex flex-col items-center justify-center gap-y-5 text-ui-700 ${width}`}>
                <OnboardingNavigation />
                {imgUrl && (
                    <div className="w-[500px] h-[200px] rounded-3xl bg-ui-300/[0.19]">
                        <img src={imgUrl} className="w-full h-full" />
                    </div>
                )}
                {children}
            </div>
            <Button variant="ghost" className="!text-16 !text-ui-300" onClick={() => signOut()}>
                Logout
            </Button>
        </div>
    );
};

export const OnboardingSkeleton = () => {
    return (
        <div className="grid grid-cols-[auto_1fr_auto] pl-10 pr-12 py-5">
            <PrimerLogo className="w-[130px] h-[22px]" />

            <div className={`mx-auto flex flex-col items-center justify-center space-y-6 text-ui-700 w-[500px]`}>
                <OnboardingNavigation />
                <Skeleton className="w-[500px] h-[198px] rounded-2xl" />
                <div>
                    <Skeleton className="w-[250px] h-9 mb-3" />

                    <Skeleton className="w-[500px] h-5 mb-0.5" />
                    <Skeleton className="w-[400px] h-5 mb-0.5" />
                    <Skeleton className="w-[300px] h-5 mb-6" />

                    <div className="animate-pulse flex items-center justify-between px-6 h-16 bg-ui-300/[0.19] border border-ui-200 rounded-lg">
                        <div className="flex items-center gap-4">
                            <Skeleton className="h-9 w-9" />

                            <Skeleton className="h-5 w-20" />
                        </div>
                        <Skeleton className="h-5 w-20" />
                    </div>
                </div>
                <Skeleton className="h-9 w-24" />
            </div>
            <Button variant="ghost" className="!text-16 !text-ui-300">
                Logout
            </Button>
        </div>
    );
};
