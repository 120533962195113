import Image from "@/assets/images/audiences-empty-results.svg?react";
import { Button } from "@/components/atoms/Button/Button";

interface EmptySearchFilterResultsProps {
    resetSearchAndFilters: () => void;
}

export const EmptySearchFilterResults = ({ resetSearchAndFilters }: EmptySearchFilterResultsProps) => {
    return (
        <div className="flex-gazrow flex flex-col items-center mt-[80px]">
            <Image />
            <div className="my-4 flex flex-col">
                <span className="text-center text-ui-900 text-2xl leading-9">Welcome to Primer Audiences</span>
                <span className="mt-2 text-center text-ui-700 text-base leading-tight">
                    Try adjust your search or filter to find what you're looking for,
                    <br />
                    or&nbsp;
                    <Button title="Reset to default search and filters" variant="ghost" className="text-blue-800 !px-0" onClick={resetSearchAndFilters}>
                        reset to default
                    </Button>
                    &nbsp;state.
                </span>
            </div>
        </div>
    );
};
