import { differenceBy, find, isEqual } from "lodash";

import { Destination, SourceCriteriaFilter } from "@/types/audience";
import { getFormattedDateForAudienceName } from "@/utils/date.ts";
import { FilterOperators } from "@primer/filters/types";
import { AdPlainStats } from "@/types/api";
import { formatNumberFloor } from "./number";

export function getNewAudienceName() {
    return `Audience - ${getFormattedDateForAudienceName(new Date())}`;
}

export function allowEmpty(operator?: FilterOperators) {
    return (
        !!operator &&
        [
            FilterOperators.IS_KNOWN,
            FilterOperators.IS_UNKNOWN,
            FilterOperators.IS_WITHIN,
            FilterOperators.EXCLUDE,
        ].includes(operator)
    );
}

const onlyChangedInvalidFilter = (before: SourceCriteriaFilter[], after: SourceCriteriaFilter[]): boolean => {
    return before.every(beforeItem => {
        const afterItem = find(after, { unique_id: beforeItem.unique_id });
        if (afterItem?.customError === undefined) delete afterItem?.customError;

        return (
            !!afterItem &&
            !isEqual(beforeItem, afterItem) &&
            (beforeItem.isEmpty || beforeItem.isDuplicated || beforeItem.customError) &&
            (afterItem.isEmpty || afterItem.isDuplicated || afterItem.customError)
        );
    });
};

export const checkIfEstimateWillRemaingSame = (
    before: SourceCriteriaFilter[],
    after: SourceCriteriaFilter[],
): boolean => {
    const afterLength = after.length;
    const beforeLength = before.length;

    // New filter
    if (afterLength > beforeLength) {
        const newItems = differenceBy(after, before, "unique_id");
        return newItems.every(newItem => newItem.isEmpty || newItem.isDuplicated || !!newItem.customError);
    }

    // Deleted invalid filter
    if (afterLength < beforeLength) {
        const difference = before.filter(
            beforeItem =>
                !after.some(currentFilter => currentFilter.unique_id === beforeItem.unique_id) &&
                (beforeItem.isEmpty || beforeItem.isDuplicated || !!beforeItem.customError),
        );

        return difference.length > 0;
    }

    if (after.some(filter => filter.values?.some(v => v.invalid === undefined))) return true;

    // The change was from invalid filter to still invalid filter
    const changedInvalidKeptInvalid = onlyChangedInvalidFilter(before, after);

    return changedInvalidKeptInvalid;
};

export const getMatchRate = (
    destination: Destination.GOOGLE | Destination.META | Destination.LINKEDIN | "display",
    adPlainStats?: AdPlainStats,
    upperBound?: number,
) => {
    switch (destination) {
        case Destination.META: {
            const approximateCountUpperBound = adPlainStats?.stats?.approximateCountUpperBound;
            const approximateCountLowerBound = adPlainStats?.stats?.approximateCountLowerBound;

            if (approximateCountLowerBound || approximateCountUpperBound) {
                const upperNumber = upperBound
                    ? Math.min(upperBound, approximateCountUpperBound || 0)
                    : approximateCountUpperBound || 0;
                const lowerNumber = Math.min(approximateCountLowerBound || 0, upperNumber);
                return `${formatNumberFloor(lowerNumber)}-${formatNumberFloor(upperNumber)}`;
            }

            return "—";
        }
        case Destination.LINKEDIN: {
            const audienceSize = adPlainStats?.audienceSize;

            if (audienceSize) {
                const number = upperBound && audienceSize > upperBound ? upperBound * 0.9 : audienceSize;
                return formatNumberFloor(number);
            }

            return "—";
        }
        case Destination.GOOGLE: {
            const sizeForSearch = adPlainStats?.sizeForSearch;

            if (sizeForSearch) {
                const number = upperBound ? Math.min(upperBound, sizeForSearch || 0) : sizeForSearch || 0;
                return formatNumberFloor(number);
            }

            return "—";
        }
        case "display": {
            const sizeForDisplay = adPlainStats?.sizeForDisplay;

            if (sizeForDisplay) {
                const number = upperBound ? Math.min(upperBound, sizeForDisplay || 0) : sizeForDisplay || 0;
                return formatNumberFloor(number);
            }

            return "—";
        }
        default:
            return "";
    }
};
