import { useEffect, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { Modal } from "@/components/organisms/Modal";
import { PaymentMethod } from "@/types/subscription";
import CloseIcon from "@/assets/icons/close.svg?react";
import { Button } from "@/components/atoms/Button/Button";
import { useRemovePaymentMethodMutation } from "@/api/users";
import { useToast } from "@/components/atoms/Toast/useToast";
import { ModalHeader } from "@/components/organisms/Modal/ModalHeader";

interface MethodAddedProps {
    paymentMethod: PaymentMethod;
    edit: () => void;
}
export const PaymentMethodCard = ({ paymentMethod, edit }: MethodAddedProps) => {
    const { toast } = useToast();
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);

    const { card, last4, id } = paymentMethod;

    const [removePaymentMethod, { isLoading: isRemoving, error: removePaymentMethodError }] =
        useRemovePaymentMethodMutation();

    const remove = async () => {
        await removePaymentMethod(id);
        setConfirmModalOpened(false);

        toast({
            variant: "default",
            description: "Payment Method removed successfully",
        });
    };

    useEffect(() => {
        if (!removePaymentMethodError) return;
        const fetchBaseQueryError = removePaymentMethodError as FetchBaseQueryError;

        toast({
            variant: "destructive",
            description: (fetchBaseQueryError.data as string) || "An error occurred. Please retry",
        });
    }, [toast, removePaymentMethodError]);

    return (
        <div className="flex gap-4 items-center">
            <div className="w-16 h-10">
                <img alt={card} title={card} src={`cards/${card}.png`} />
            </div>

            <div className="flex-1 truncate">
                <div className="text-16 text-ui-900">{`**** **** **** ${last4}`}</div>
            </div>

            <div className="flex items-center gap-2">
                <Button variant="secondary" onClick={edit}>
                    Edit
                </Button>

                <Button size="icon" className="!h-9" variant="secondary" onClick={() => setConfirmModalOpened(true)}>
                    <CloseIcon className="text-ui-300 w-4 h-4" title="Delete Payment Method" />
                </Button>
            </div>

            {confirmModalOpened && (
                <DeletePaymentConfirmModal
                    onConfirm={remove}
                    isRemoving={isRemoving}
                    onClose={() => setConfirmModalOpened(false)}
                />
            )}
        </div>
    );
};

interface Props {
    onClose: () => void;
    onConfirm: () => void;
    isRemoving: boolean;
}

export const DeletePaymentConfirmModal = ({ onClose, onConfirm, isRemoving }: Props) => {
    return (
        <Modal className="w-[480px]" onClose={onClose}>
            <ModalHeader title="Delete Credit Card?" onClose={onClose} />
            <div className="grid grid-cols-[200px_170px_120px_auto] text-ui-900 text-14 overflow-auto overflow-x-hidden"></div>
            <div className="flex flex-col justify-between items-center mt-2">
                <div className="text-14 text-ui-700">
                    Deleting this credit card will stop automatic billing for your account. Please note that you'll need
                    to add a new payment method to avoid service interruptions.
                </div>

                <div className="flex ml-auto mt-10 gap-2">
                    <Button disabled={isRemoving} onClick={onClose} variant="secondary">
                        No, cancel
                    </Button>
                    <Button loading={isRemoving} onClick={onConfirm}>
                        Yes, delete
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
