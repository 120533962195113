import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
    format,
    formatDistanceToNowStrict,
    isSameYear,
    isTomorrow,
    isValid,
    isYesterday,
} from "date-fns";

const formatDate = (date: Date, formatStr: string) => {
    if (isValid(date)) {
        return format(date, formatStr);
    }

    return "N/A";
};

export const getFormattedDateForAudienceName = (date: Date) => {
    return formatDate(date, "yyyy-MM-dd 'at' HH:mm");
};

export const getFormattedDate = (date: Date) => {
    return formatDate(date, "yyyy-MM-dd");
};

export const getFormattedDateAndTime = (date: Date) => {
    return formatDate(date, "yyyy-MM-dd HH:mm");
};

export const getRelativeDateString = (date: Date) => {
    if (isValid(date)) {
        return `${formatDistanceToNowStrict(date)} ago`;
    }

    return "N/A";
};

export type Time = {
    hours: number;
    minutes: number;
    seconds: number;
};

export const getTimeDifference = (startDate: Date, endDate: Date) => ({
    hours: differenceInHours(endDate, startDate),
    minutes: differenceInMinutes(endDate, startDate) % 60,
    seconds: differenceInSeconds(endDate, startDate) % 60,
});

export const formatTimeToShortNotation = ({ hours, minutes, seconds }: Time) => {
    if (hours) return `${hours}h ${minutes}m ${seconds}s`;

    if (minutes) return `${minutes}m ${seconds}s`;

    return `${seconds}s`;
};

export const getPastRelativeDateString = (date: Date) => {
    const now = new Date();
    const diffSeconds = differenceInSeconds(now, date);
    const diffDays = differenceInDays(now, date);

    if (diffSeconds < 60) {
        return "just now";
    } else if (isYesterday(date)) {
        return "yesterday";
    } else if (diffDays < 30) {
        return `${formatDistanceToNowStrict(date)} ago`;
    } else if (isSameYear(date, now)) {
        return `on ${formatDate(date, "MMM d")}`;
    }

    return `on ${formatDate(date, "MMM d, yyyy")}`;
};

export const getFutureRelativeDateString = (date: Date) => {
    const now = new Date();
    const diffDays = differenceInDays(date, now);
    const diffSeconds = differenceInSeconds(date, now);

    if (diffSeconds < 60) {
        return "in 1 minute";
    } else if (isTomorrow(date)) {
        return "tomorrow";
    } else if (diffDays < 30) {
        return `in ${formatDistanceToNowStrict(date)}`;
    } else if (isSameYear(date, now)) {
        return `on ${formatDate(date, "MMM d")}`;
    }

    return `on ${formatDate(date, "MMM d, yyyy")}`;
};
