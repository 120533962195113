import { useCallback, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { Helmet } from "react-helmet-async";
import routeMetadata from "@/config/routeMetadata";
import { useLocation } from "react-router-dom";

export const AppHelmet = () => {
    const {
        VITE_FAVICON_BASE = "favicon",
        MODE,
        VITE_SENTRY_DSN,
        VITE_SENTRY_ENABLED = "false",
        VITE_SENTRY_PROXY,
        VITE_INTERCOM_APP_ID,
    } = import.meta.env;
    const location = useLocation();
    const initSentry = useCallback(() => {
        if (JSON.parse(VITE_SENTRY_ENABLED))
            Sentry.init({
                dsn: VITE_SENTRY_DSN,
                tunnel: VITE_SENTRY_PROXY,
                environment: MODE,
            });
    }, [VITE_SENTRY_ENABLED, VITE_SENTRY_DSN, MODE]);

    const seoData = useMemo(() => {
        return routeMetadata[location.pathname] || routeMetadata["/"];
    }, [location.pathname]);

    useEffect(() => {
        initSentry();
    }, [initSentry]);

    return (
        <Helmet>
            <link rel="icon" type="image/png" sizes="32x32" href={`/${VITE_FAVICON_BASE}-32x32.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`/${VITE_FAVICON_BASE}-16x16.png`} />
            <title>{seoData.title}</title>
            <meta name="description" content={seoData.description} />
            <script src="https://js.chilipiper.com/marketing.js" type="text/javascript" async></script>
            <script>
                {`
                    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${VITE_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
                `}
            </script>
        </Helmet>
    );
};
