import { Button } from "@/components/atoms/Button/Button";
import { Modal } from "@/components/organisms/Modal";
import { ModalHeader } from "@/components/organisms/Modal/ModalHeader";

type Props = {
    onClose: () => void;
    onConfirm: () => void;
}

export function TrialStartWarningModal({ onClose, onConfirm }: Props) {

    return (
        <Modal className="w-[600px]" onClose={onClose}>
            <ModalHeader title="Ready to Start Your Free Trial?" onClose={onClose} />
            <div className="grid grid-cols-[200px_170px_120px_auto] text-ui-900 text-14 overflow-auto overflow-x-hidden"></div>
            <div className="flex flex-col justify-between items-center mt-5">
                <div>By submitting the first audience your 30-day free trial will begin. Ready to get started?</div>

                <div className="flex ml-auto mt-10 gap-2">
                    <Button onClick={onClose} variant="secondary">
                        No, cancel
                    </Button>
                    <Button onClick={onConfirm}>
                        Yes, start trial
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
