import { Audience, AudienceStatus as AudienceStatusEnum, Destination } from "@/types/audience";
import ThreeDots from "@/assets/icons/three-dots.svg?react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AudienceStatus from "@/components/molecules/AudienceStatus/AudienceStatus";
import { TableCell, TableRow } from "@/components/atoms/Table";
import AccountIcon from "@/components/molecules/AccountIcon/AccountIcon";
import { getPastRelativeDateString } from "@/utils/date";
import AudienceFavorite from "@/components/molecules/AudienceFavorite/AudienceFavorite.tsx";
import { useFavoriteAudienceMutation } from "@/api/audiences.ts";
import { useEffect, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { useToast } from "@/components/atoms/Toast/useToast.tsx";
import { DropdownMenu, DropdownMenuTrigger } from "@/components/atoms/DropdownMenu/DropdownMenu.tsx";
import { Button } from "@/components/atoms/Button/Button";
import { AudienceContextMenu } from "@/components/molecules/AudienceContextMenu/AudienceContextMenu";
import { AudienceSize } from "@/components/molecules/AudienceSize/AudienceSize";
import { FilterEntityTypes } from "@primer/filters/types";
import { TooltipMessageWrapper } from "@/components/organisms/audiences/AudiencesInfoBar/TooltipMessageWrapper";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import postHog from "posthog-js";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { isEmpty } from "lodash";
import { getMatchRate } from "@/utils/audience";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";

interface AudienceTableRowProps {
    audience: Audience;
    firstActiveAudience?: Audience;
    handleFavoriteCacheUpdate: (id: Audience["id"], isFavorite: boolean) => void;
    handleViewRuns: (audienceId: string) => void;
}

export const AudienceTableRow = ({
    handleFavoriteCacheUpdate,
    firstActiveAudience,
    audience: {
        id,
        name,
        isFavorite,
        lastUpdatedAt,
        status,
        destinations,
        companies,
        people,
        companies_build,
        people_build,
        match_rate,
    },
    handleViewRuns,
}: AudienceTableRowProps) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { toast } = useToast();
    const [favoriteAudienceMutation, { error: favoriteAudienceError }] = useFavoriteAudienceMutation();
    const [isRowHovered, setIsRowHovered] = useState(false);
    const showAudienceSize = ![AudienceStatusEnum.DRAFT, AudienceStatusEnum.RUNNING].includes(status);
    const isMatchRatesEnabled = useFeatureFlag(FeatureFlagsEnum.MATCH_RATES_ACTUAL);

    const [, setQuery] = useQueryParams(
        { tooltip: withDefault(StringParam, undefined) },
        { removeDefaultsFromUrl: true },
    );

    useEffect(() => {
        if (!favoriteAudienceError) return;

        const fetchBaseQueryError = favoriteAudienceError as FetchBaseQueryError;

        toast({ description: fetchBaseQueryError.data as string, variant: "destructive" });
    }, [toast, favoriteAudienceError]);

    const onRowClick = () => {
        navigate(`/audiences/${id}`, { state: searchParams.toString() });
        postHog.capture("Audience Dashboard row clicked", {
            audienceId: id,
        });
    };

    return (
        <TableRow
            className="border-dark-500 border-opacity-20 hover:bg-blue-50 hover:cursor-pointer hover:ring-1 hover:ring-blue-200 hover:ring-inset hover:border-transparent"
            onClick={onRowClick}
            aria-label="Audience Table Row"
            onMouseEnter={() => setIsRowHovered(true)}
            onMouseLeave={() => setIsRowHovered(false)}
        >
            <TableCell>
                <div className="flex justify-center w-[40px]">
                    {(isFavorite || isRowHovered) && (
                        <AudienceFavorite
                            favorite={isFavorite}
                            disabled={false}
                            onAudienceFavorite={() => {
                                handleFavoriteCacheUpdate(id, !isFavorite);
                                favoriteAudienceMutation({
                                    audienceId: id,
                                    favorite: !isFavorite,
                                });
                            }}
                            buttonClassName="w-[40px] !h-[36px]"
                        />
                    )}
                </div>
            </TableCell>
            <TableCell className="text-ui-900 text-sm leading-tight">
                <div className="text-ellipsis line-clamp-2">{name}</div>
            </TableCell>
            <TableCell>
                <Tooltip
                    hidden={
                        !isMatchRatesEnabled
                            ? true
                            : isEmpty(match_rate) ||
                              ![AudienceStatusEnum.SYNCED, AudienceStatusEnum.INCOMPLETE].includes(status)
                    }
                    side="bottom"
                    sideOffset={12}
                    content={
                        <div>
                            <span className="text-white font-medium">Actual Matched Size</span>
                            <div className="flex flex-row gap-4 mt-2 items-center">
                                {match_rate?.facebook &&
                                    getMatchRate(
                                        Destination.META,
                                        match_rate?.facebook?.adPlainStats,
                                        people_build || 0,
                                    ) != "—" && (
                                        <div className="flex flex-row gap-1">
                                            <AccountIcon service={Destination.META} />
                                            <span>
                                                Meta:{" "}
                                                {getMatchRate(
                                                    Destination.META,
                                                    match_rate?.facebook?.adPlainStats,
                                                    people_build || 0,
                                                )}
                                            </span>
                                        </div>
                                    )}
                                {match_rate?.google &&
                                    getMatchRate(
                                        Destination.GOOGLE,
                                        match_rate?.google?.adPlainStats,
                                        people_build || 0,
                                    ) != "—" && (
                                        <div className="flex flex-row gap-1">
                                            <AccountIcon service={Destination.GOOGLE} />
                                            <span>
                                                Google:{" "}
                                                {getMatchRate(
                                                    Destination.GOOGLE,
                                                    match_rate?.google?.adPlainStats,
                                                    people_build || 0,
                                                )}
                                            </span>
                                        </div>
                                    )}
                                {match_rate?.linkedin &&
                                    getMatchRate(
                                        Destination.LINKEDIN,
                                        match_rate?.linkedin?.adPlainStats,
                                        people_build || 0,
                                    ) != "—" && (
                                        <div className="flex flex-row gap-1">
                                            <AccountIcon service={Destination.LINKEDIN} />
                                            <span>
                                                LinkedIn:{" "}
                                                {getMatchRate(
                                                    Destination.LINKEDIN,
                                                    match_rate?.linkedin?.adPlainStats,
                                                    people_build || 0,
                                                )}
                                            </span>
                                        </div>
                                    )}
                            </div>
                        </div>
                    }
                >
                    <div className="flex gap-2">{destinations?.map(d => <AccountIcon service={d} key={d} />)}</div>
                </Tooltip>
            </TableCell>
            <TableCell className="text-dark-400">
                <div className="flex">
                    <AudienceSize
                        className="mr-2"
                        hideLabel
                        darkText
                        entityType={FilterEntityTypes.COMPANY}
                        loading={false}
                        estimate={status === AudienceStatusEnum.SYNCED ? companies_build : companies}
                        showAudienceSize={showAudienceSize}
                    />
                    <AudienceSize
                        hideLabel
                        darkText
                        entityType={FilterEntityTypes.PERSON}
                        loading={false}
                        estimate={status === AudienceStatusEnum.SYNCED ? people_build : people}
                        showAudienceSize={showAudienceSize}
                    />
                </div>
            </TableCell>
            <TableCell>
                <AudienceStatus className="w-[140px]" status={status} />
            </TableCell>
            <TableCell title={lastUpdatedAt}>{getPastRelativeDateString(new Date(lastUpdatedAt))}</TableCell>
            <TableCell className="flex items-center justify-end">
                {firstActiveAudience?.id === id && (
                    <TooltipMessageWrapper anchor="archive" content="Archive an active audience to free up a slot" />
                )}
                <DropdownMenu onOpenChange={open => open && setQuery({ tooltip: undefined })}>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon" id="threeDotsIcon">
                            <ThreeDots className="rotate-90" />
                        </Button>
                    </DropdownMenuTrigger>
                    <AudienceContextMenu
                        audienceId={id}
                        page={"dashboard"}
                        status={status}
                        handleViewRuns={handleViewRuns}
                    />
                </DropdownMenu>
            </TableCell>
        </TableRow>
    );
};
