import cn from "classnames";
import ArrowRightIcon from "@/assets/icons/arrow-right-sm.svg?react";
import CloseIcon from "@/assets/icons/close.svg?react";

import { Button } from "@/components/atoms/Button/Button";

const RowAction: React.FC<{
    disabled: boolean;
    onClick: () => void;
    isOther: boolean;
    allowExpand: boolean;
    isExpanding?: boolean;
    isSelectedForExclusion: boolean;
}> = ({ onClick, isOther, allowExpand, isExpanding, isSelectedForExclusion, disabled }) => {
    const handleClick = (e: any) => {
        e.stopPropagation();
        (e.target as HTMLButtonElement).blur();
        onClick();
    }

    const button = (
        <Button
            disabled={disabled}
            onClick={(e) => handleClick(e)}
            onKeyDown={(e) => e.preventDefault()}
            tabIndex={-1}
            variant="basic"
            size="custom"
            role="button"
            className={cn(`!flex items-center justify-center h-[16px] w-[14px] group-hover:visible !border-transparent !disabled:bg-transparent hover:text-ui-700`, {
                'visible': isSelectedForExclusion,
                'invisible': !isSelectedForExclusion,
                "text-ui-300": !isSelectedForExclusion,
                "text-[#B32424]": isSelectedForExclusion,
            })}>

            <CloseIcon />
        </Button>);

    if (isOther && allowExpand && !isExpanding) {
        return (
            <Button
                variant="basic"
                size="custom"
                className={cn("!flex items-center justify-center h-[16px] w-[12px] !bg-transparent !border-transparent text-ui-300 group-hover:text-blue-800")}
            >
                <ArrowRightIcon />
            </Button>
        );
    } else if (isOther) {
        return <div className="py-0 px-1 h-[16px] w-[12px]"></div>;
    } else {
        return button;
    }
};

export default RowAction;
