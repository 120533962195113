import React from "react";
import { GetAudienceResponse } from "@/types/api";
import { FilterOperators } from "@primer/filters/types";
import { map, intersection, some } from "lodash";
import { SourceCriteriaFilter } from "@/types/audience";
import { parseValue } from "@/utils/string";
import { filterConfigs } from "@primer/filters/configs";

export enum ErrorType {
    ERROR = "error",
    WARNING = "warning",
}
export interface CriteriaError {
    message: string;
    errorType: ErrorType;
}

const hasNotContainsConflict = (filterA: SourceCriteriaFilter, filterB: SourceCriteriaFilter): boolean => {
    const valuesA = map(filterA.values, v => parseValue(v.value));
    const valuesB = map(filterB.values, v => parseValue(v.value));
    if (
        ((filterA.operator === FilterOperators.IS || filterA.operator === FilterOperators.CONTAINS) &&
            filterB.operator === FilterOperators.NOT_CONTAINS) ||
        ((filterB.operator === FilterOperators.IS || filterB.operator === FilterOperators.CONTAINS) &&
            filterA.operator === FilterOperators.NOT_CONTAINS)
    ) {
        const baseValues = filterA.operator === FilterOperators.NOT_CONTAINS ? valuesB : valuesA;
        const negatedValues = filterA.operator === FilterOperators.NOT_CONTAINS ? valuesA : valuesB;

        return some(baseValues, baseVal => some(negatedValues, negateVal => baseVal.includes(negateVal)));
    }

    return false;
};

const hasIsNotConflict = (filterA: SourceCriteriaFilter, filterB: SourceCriteriaFilter): boolean => {
    const valuesA = map(filterA.values, v => parseValue(v.value));
    const valuesB = map(filterB.values, v => parseValue(v.value));
    if (
        (filterA.operator === FilterOperators.IS && filterB.operator === FilterOperators.IS_NOT) ||
        (filterA.operator === FilterOperators.IS_NOT && filterB.operator === FilterOperators.IS)
    ) {
        return intersection(valuesA, valuesB).length > 0;
    }

    return false;
};

const hasNumericConflict = (filterA: SourceCriteriaFilter, filterB: SourceCriteriaFilter): boolean => {
    const allNumeric = [FilterOperators.NOT_BETWEEN, FilterOperators.BETWEEN, FilterOperators.GE, FilterOperators.LE];
    const possibleConflict = [FilterOperators.BETWEEN, FilterOperators.GE, FilterOperators.LE];
    if (
        filterA.operator &&
        filterB.operator &&
        ((allNumeric.includes(filterA.operator) && possibleConflict.includes(filterB.operator)) ||
            (possibleConflict.includes(filterA.operator) && allNumeric.includes(filterB.operator)))
    ) {
        return true;
    }

    return false;
};

export const useAudienceFilterValidation = ({
    audienceSize,
    audience,
}: {
    audienceSize?: number;
    audience?: GetAudienceResponse;
}) => {
    const [criteriaError, setCriteriaError] = React.useState<CriteriaError>();

    const findConflictingFilterFields = React.useCallback((filters?: SourceCriteriaFilter[]): string[] => {
        const conflictingFields = new Set<string>();
        if (!filters?.length) return [];

        for (let i = 0; i < filters.length; i++) {
            for (let j = i + 1; j < filters.length; j++) {
                const filterA = filters[i];
                const filterB = filters[j];

                if (filterA.field === filterB.field) {
                    const hasConflict =
                        hasNotContainsConflict(filterA, filterB) ||
                        hasIsNotConflict(filterA, filterB) ||
                        hasNumericConflict(filterA, filterB);

                    if (hasConflict && filterA.field) {
                        conflictingFields.add(filterA.field);
                    }
                }
            }
        }

        return Array.from(conflictingFields);
    }, []);

    React.useEffect(() => {
        const conflicts = findConflictingFilterFields(audience?.shape?.source_criteria?.group?.filters);
        if (conflicts?.length) {
            const conflictingFields = conflicts.map(conflict => filterConfigs[conflict]?.name).join(", ");
            if (audienceSize === 0) {
                setCriteriaError({
                    errorType: ErrorType.ERROR,
                    message: `There is a conflict in your ${conflictingFields} criteria likely causing the 0 size estimate.`,
                });
            } else {
                setCriteriaError({
                    errorType: ErrorType.WARNING,
                    message: `Value conflicts between ${conflictingFields} filters might be reducing your audience size.`,
                });
            }
        } else {
            setCriteriaError(undefined);
        }
    }, [audience?.shape?.source_criteria?.group?.filters, audienceSize, findConflictingFilterFields]);

    const showCriteriaError = React.useMemo(() => {
        return !!audience?.shape && !!criteriaError;
    }, [audience?.shape, criteriaError]);

    return {
        criteriaError,
        showCriteriaError,
    };
};
