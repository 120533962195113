import { sub } from "date-fns";

import {
    AddressLocation,
    Department,
    Filter,
    FilterCategory,
    FilterConfig,
    FilterDataTypes,
    FilterEntityTypes,
    FilterFields,
    FilterOperators,
    OperatorConfig,
    RelativeDateRangeOperator,
    RelativeDateRangeUnit,
} from "./types";
import { normalizeContainValues } from "./util/normalize";
import { isValidType } from "./util/validation";

export const filtersFields: FilterConfig[] = [
    {
        identifier: FilterFields.JOB_TITLE,
        name: "Job Title",
        sampleData: "Project Manager",
        tooltip: "Works best with when focused on very specific roles. Don't forget to use exclusion job titles too.",
        dataType: FilterDataTypes.STRING,
        entityType: FilterEntityTypes.PERSON,
        allowedOperators: [
            FilterOperators.CONTAINS,
            FilterOperators.NOT_CONTAINS,
            FilterOperators.IS,
            FilterOperators.IS_NOT,
            FilterOperators.IS_KNOWN,
            FilterOperators.IS_UNKNOWN,
        ],
        allowCustomInput: true,
        categories: [FilterCategory.PRIMER],
    },
    {
        identifier: FilterFields.ANNUAL_REVENUE,
        name: "Annual Revenue",
        sampleData: "$10,000,000+",
        tooltip:
            "Many companies are private and do not disclose revenue figures. Consider utilizing headcount data instead.",
        dataType: FilterDataTypes.NUMBER,
        entityType: FilterEntityTypes.COMPANY,
        alternativeColumnName: {
            [FilterOperators.IS]: "annual_revenue_bucket",
            [FilterOperators.IS_NOT]: "annual_revenue_bucket",
        },
        allowedOperators: [
            FilterOperators.GE,
            FilterOperators.LE,
            FilterOperators.BETWEEN,
            FilterOperators.NOT_BETWEEN,
            FilterOperators.IS_KNOWN,
            FilterOperators.IS_UNKNOWN,
        ],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
    },
    {
        identifier: FilterFields.COMPANY_LOCATION,
        name: "Company Location",
        sampleData: "United States",
        tooltip: "Filter by Country, State, or City of the company's location",
        dataType: FilterDataTypes.LOCATION,
        entityType: FilterEntityTypes.COMPANY,
        allowedOperators: [FilterOperators.IS, FilterOperators.IS_NOT],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
        join: ";",
    },
    {
        identifier: FilterFields.DOMAIN,
        name: "Domain",
        sampleData: "ibm.com",
        tooltip: "Use for targeting specific companies (ex: ibm.com)",
        dataType: FilterDataTypes.STRING,
        entityType: FilterEntityTypes.COMPANY,
        pureValue: true,
        allowedOperators: [
            FilterOperators.IS,
            FilterOperators.IS_NOT,
            FilterOperators.IS_KNOWN,
            FilterOperators.IS_UNKNOWN,
        ],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
        split: /[\s,]+/,
    },
    {
        identifier: FilterFields.FOUNDED_YEAR,
        name: "Founded Year",
        sampleData: "2020",
        tooltip: "Optimal for targeting companies based on their relative maturity level.",
        dataType: FilterDataTypes.NUMBER,
        entityType: FilterEntityTypes.COMPANY,
        allowedOperators: [
            FilterOperators.IS,
            FilterOperators.IS_NOT,
            FilterOperators.LE,
            FilterOperators.GE,
            FilterOperators.BETWEEN,
            FilterOperators.NOT_BETWEEN,
            FilterOperators.IS_KNOWN,
            FilterOperators.IS_UNKNOWN,
        ],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
        props: {
            maxlength: 4,
        },
    },
    {
        identifier: FilterFields.HEADCOUNT,
        name: "Headcount",
        alternativeColumnName: {
            [FilterOperators.IS]: "headcount_bucket",
            [FilterOperators.IS_NOT]: "headcount_bucket",
        },
        sampleData: "500,000",
        tooltip: "Number of employees at a company",
        dataType: FilterDataTypes.NUMBER,
        entityType: FilterEntityTypes.COMPANY,
        allowedOperators: [
            FilterOperators.GE,
            FilterOperators.LE,
            FilterOperators.BETWEEN,
            FilterOperators.NOT_BETWEEN,
            FilterOperators.IS_KNOWN,
            FilterOperators.IS_UNKNOWN,
        ],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
    },
    {
        identifier: FilterFields.INDUSTRY,
        name: "Industry",
        sampleData: "Computer Network Security",
        tooltip: "Include or exclude companies from specific industries",
        dataType: FilterDataTypes.STRING,
        entityType: FilterEntityTypes.COMPANY,
        repoV2ColumnName: "industry",
        allowedOperators: [
            FilterOperators.IS,
            FilterOperators.IS_NOT,
            FilterOperators.IS_KNOWN,
            FilterOperators.IS_UNKNOWN,
        ],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
    },
    {
        identifier: FilterFields.KEYWORDS,
        name: "Keywords",
        sampleData: "Marketing",
        tooltip: "Limited coverage of keywords. Reserve this filter for hyper-focused campaigns.",
        dataType: FilterDataTypes.ARRAY,
        entityType: FilterEntityTypes.COMPANY,
        allowedOperators: [FilterOperators.IS, FilterOperators.IS_NOT],
        allowCustomInput: true,
        categories: [FilterCategory.PRIMER],
    },
    {
        identifier: FilterFields.TECHNOLOGIES,
        name: "Technology",
        sampleData: "Salesforce",
        tooltip:
            "Best suited for technologies listed on a website. Limited coverage for back-end technologies such as CRMs, HRIS, etc.",
        dataType: FilterDataTypes.ARRAY,
        entityType: FilterEntityTypes.COMPANY,
        allowedOperators: [FilterOperators.IS, FilterOperators.IS_NOT],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
    },
    {
        identifier: FilterFields.DEPARTMENTS,
        tableName: "all_departments",
        name: "Department, subdepartment",
        sampleData: "Medical Health, Nursing",
        tooltip: "Best used if a list of job titles isn't exhaustive. Often paired with Seniority Level.",
        dataType: FilterDataTypes.ARRAY,
        entityType: FilterEntityTypes.PERSON,
        allowedOperators: [FilterOperators.IS, FilterOperators.IS_NOT],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
        // join: ";", // Change to this once repov2 goes to all users
    },
    {
        identifier: FilterFields.PERSON_LOCATION,
        name: "Person Location",
        sampleData: "United States",
        tooltip: "Filter by Country, State, or City of the person's location",
        dataType: FilterDataTypes.LOCATION,
        entityType: FilterEntityTypes.PERSON,
        allowedOperators: [FilterOperators.IS, FilterOperators.IS_NOT],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
        join: ";",
    },
    {
        identifier: FilterFields.SENIORITY,
        name: "Seniority Level",
        sampleData: "Director",
        tooltip: "Use this to target specific seniority levels. Often paired with Department.",
        dataType: FilterDataTypes.STRING,
        entityType: FilterEntityTypes.PERSON,
        allowedOperators: [FilterOperators.IS, FilterOperators.IS_NOT],
        allowCustomInput: false,
        categories: [FilterCategory.PRIMER],
    },
];

export const getCSVFilterConfig = (filter: Partial<Filter>): FilterConfig => ({
    entityType: filter.entity_type ?? FilterEntityTypes.PERSON,
    tooltip: "",
    categories: [],
    name: filter.field!,
    allowCustomInput: false,
    identifier: filter.field!,
    dataType: FilterDataTypes.STRING,
    mappingTable: filter.mappingTable!,
    allowedOperators: [FilterOperators.IS_WITHIN, FilterOperators.EXCLUDE],
});

export const filterConfigs = filtersFields.reduce(
    (obj, item) => {
        obj[item.identifier] = item;
        return obj;
    },
    {} as { [key: string]: FilterConfig },
);

export const DEFAULT_MAX_FILTER_VALUES = 50;
export const PREVIEW_MAX_RECORDS = 250;
export const PREVIEW_PAGE_SIZE = 25;
export const PREVIEW_MAX_OFFSET = PREVIEW_MAX_RECORDS - PREVIEW_PAGE_SIZE;

export const filterMaxSupportedValues = {
    [FilterFields.DOMAIN]: 1000,
    [FilterFields.PERSON_LOCATION]: DEFAULT_MAX_FILTER_VALUES,
    [FilterFields.COMPANY_LOCATION]: DEFAULT_MAX_FILTER_VALUES,
    [FilterFields.INDUSTRY]: 100,
    [FilterFields.KEYWORDS]: DEFAULT_MAX_FILTER_VALUES,
    [FilterFields.TECHNOLOGIES]: DEFAULT_MAX_FILTER_VALUES,
    [FilterFields.DEPARTMENTS]: 150,
    [FilterFields.JOB_TITLE]: 200,
    [FilterFields.SENIORITY]: DEFAULT_MAX_FILTER_VALUES,
    [FilterFields.ANNUAL_REVENUE]: null,
    [FilterFields.FOUNDED_YEAR]: null,
    [FilterFields.HEADCOUNT]: null,
    [FilterFields.CSV]: 1,
};

export const filterOperatorConfigs: { [K in FilterOperators]: OperatorConfig } = {
    [FilterOperators.IS]: {
        id: FilterOperators.IS,
        name: "is",
        placeholders: ["Start typing..."],
        tooltip: "Returns exact matches for selected filter and values.",
        query: {
            [FilterDataTypes.STRING]: "{FIELD} IN ({VALUES})",
            [FilterDataTypes.ARRAY]: "hasAny({FIELD}, [{VALUES}])",
            [FilterDataTypes.NUMBER]: "{FIELD} IN ({VALUES})",
            [FilterDataTypes.DATE]: "equals({FIELD}, {VALUES})",
            [FilterDataTypes.DATE_TIME]: "equals({FIELD}, {VALUES})",
            [FilterDataTypes.BOOLEAN]: "equals({FIELD}, {VALUES})",
            [FilterDataTypes.LOCATION]: "",
        },
        queryValuesBuilder: {
            [FilterDataTypes.STRING]: (values, query, normalized = false) =>
                query.replace("{VALUES}", values.map(v => (normalized ? v : `lower(${v})`)).join(", ")),
            [FilterDataTypes.NUMBER]: (values, query, normalized = false) =>
                query.replace("{VALUES}", values.map(v => (normalized ? v : `${parseInt(v)}`)).join(", ")),
            [FilterDataTypes.DATE]: (values, query) =>
                !values[1] || values[1] === RelativeDateRangeOperator.EXACT_DATE
                    ? query.replace("{VALUES}", `parseDateTimeBestEffortOrNull('${values[0]}')`)
                    : query.replace("equals", "greater").replace(
                          "{VALUES}",
                          "parseDateTimeBestEffortOrNull('" +
                              sub(new Date(), {
                                  days: values[2] === RelativeDateRangeUnit.DAYS ? Number(values[0]) : undefined,
                                  weeks: values[2] === RelativeDateRangeUnit.WEEKS ? Number(values[0]) : undefined,
                                  months: values[2] === RelativeDateRangeUnit.MONTHS ? Number(values[0]) : undefined,
                                  years: values[2] === RelativeDateRangeUnit.YEARS ? Number(values[0]) : undefined,
                              }).toISOString() +
                              "')",
                      ),
            [FilterDataTypes.DATE_TIME]: (values, query) =>
                !values[1] || values[1] === RelativeDateRangeOperator.EXACT_DATE
                    ? query.replace("{VALUES}", `parseDateTimeBestEffortOrNull('${values[0]}')`)
                    : query.replace("equals", "greater").replace(
                          "{VALUES}",
                          "parseDateTimeBestEffortOrNull('" +
                              sub(new Date(), {
                                  days: values[2] === RelativeDateRangeUnit.DAYS ? Number(values[0]) : undefined,
                                  weeks: values[2] === RelativeDateRangeUnit.WEEKS ? Number(values[0]) : undefined,
                                  months: values[2] === RelativeDateRangeUnit.MONTHS ? Number(values[0]) : undefined,
                                  years: values[2] === RelativeDateRangeUnit.YEARS ? Number(values[0]) : undefined,
                              }).toISOString() +
                              "')",
                      ),
        },
        isValid: (filter: Filter) => {
            if (![FilterDataTypes.DATE, FilterDataTypes.DATE_TIME].includes(filter.dataType)) {
                return filter.values.length > 0;
            }

            return filter.values?.[1]?.value === RelativeDateRangeOperator.WITHIN_LAST
                ? filter.values.length === 3
                : filter.values.length > 0;
        },
    },
    [FilterOperators.IS_NOT]: {
        id: FilterOperators.IS_NOT,
        placeholders: ["Start typing..."],
        tooltip: "Excludes records that match the selected filter and values.",
        name: "is not",
        query: {
            [FilterDataTypes.STRING]: "{FIELD} NOT IN ({VALUES})",
            [FilterDataTypes.ARRAY]: "hasAny({FIELD}, [{VALUES}]) = 0",
            [FilterDataTypes.NUMBER]: "{FIELD} NOT IN ({VALUES})",
            [FilterDataTypes.DATE]: "notEquals({FIELD}, {VALUES})",
            [FilterDataTypes.DATE_TIME]: "notEquals({FIELD}, {VALUES})",
            [FilterDataTypes.LOCATION]: "",
        },
        queryValuesBuilder: {
            [FilterDataTypes.STRING]: (values, query, normalized = false) =>
                query.replace("{VALUES}", values.map(v => (normalized ? v : `lower(${v})`)).join(", ")),
            [FilterDataTypes.NUMBER]: (values, query, normalized = false) =>
                query.replace("{VALUES}", values.map(v => (normalized ? v : `${parseInt(v)}`)).join(", ")),
            [FilterDataTypes.DATE]: (values, query) =>
                !values[1] || values[1] === RelativeDateRangeOperator.EXACT_DATE
                    ? query.replace("{VALUES}", `parseDateTimeBestEffortOrNull('${values[0]}')`)
                    : query.replace("equals", "less").replace(
                          "{VALUES}",
                          "parseDateTimeBestEffortOrNull('" +
                              sub(new Date(), {
                                  days: values[2] === RelativeDateRangeUnit.DAYS ? Number(values[0]) : undefined,
                                  weeks: values[2] === RelativeDateRangeUnit.WEEKS ? Number(values[0]) : undefined,
                                  months: values[2] === RelativeDateRangeUnit.MONTHS ? Number(values[0]) : undefined,
                                  years: values[2] === RelativeDateRangeUnit.YEARS ? Number(values[0]) : undefined,
                              }).toISOString() +
                              "')",
                      ),
            [FilterDataTypes.DATE_TIME]: (values, query) =>
                !values[1] || values[1] === RelativeDateRangeOperator.EXACT_DATE
                    ? query.replace("{VALUES}", `parseDateTimeBestEffortOrNull('${values[0]}')`)
                    : query.replace("equals", "less").replace(
                          "{VALUES}",
                          "parseDateTimeBestEffortOrNull('" +
                              sub(new Date(), {
                                  days: values[2] === RelativeDateRangeUnit.DAYS ? Number(values[0]) : undefined,
                                  weeks: values[2] === RelativeDateRangeUnit.WEEKS ? Number(values[0]) : undefined,
                                  months: values[2] === RelativeDateRangeUnit.MONTHS ? Number(values[0]) : undefined,
                                  years: values[2] === RelativeDateRangeUnit.YEARS ? Number(values[0]) : undefined,
                              }).toISOString() +
                              "')",
                      ),
        },
        isValid: (filter: Filter) => {
            if (![FilterDataTypes.DATE, FilterDataTypes.DATE_TIME].includes(filter.dataType)) {
                return filter.values.length > 0;
            }

            return filter.values?.[1]?.value === RelativeDateRangeOperator.WITHIN_LAST
                ? filter.values.length === 3
                : filter.values.length > 0;
        },
    },
    [FilterOperators.CONTAINS]: {
        id: FilterOperators.CONTAINS,
        placeholders: ["Type in value"],
        tooltip: "Returns records where the selected field includes the specified value.",
        name: "contains",
        query: {
            [FilterDataTypes.STRING]: "multiMatchAny({FIELD}, [{VALUES}])",
            [FilterDataTypes.ARRAY]: "arrayExists(x -> multiSearchAny(x, [{VALUES}]), {FIELD})",
        },
        queryValuesBuilder: {
            [FilterDataTypes.STRING]: (values, query) =>
                query.replace("{VALUES}", normalizeContainValues(values).join(", ")),
            [FilterDataTypes.ARRAY]: (values, query) =>
                query.replace("{VALUES}", normalizeContainValues(values).join(", ")),
        },
        isValid: (filter: Filter) => filter.values.length > 0 && isValidType(filter, FilterDataTypes.STRING),
    },
    [FilterOperators.NOT_CONTAINS]: {
        id: FilterOperators.NOT_CONTAINS,
        name: "does not contain",
        placeholders: ["Type in value"],
        tooltip: "Excludes records where the selected field includes the specified value",
        query: {
            [FilterDataTypes.STRING]: "multiMatchAny({FIELD}, [{VALUES}]) = 0",
            [FilterDataTypes.ARRAY]: "arrayExists(x -> multiSearchAny(x, [{VALUES}]), {FIELD}) = 0",
        },
        queryValuesBuilder: {
            [FilterDataTypes.STRING]: (values, query) =>
                query.replace("{VALUES}", normalizeContainValues(values).join(", ")),
            [FilterDataTypes.ARRAY]: (values, query) =>
                query.replace("{VALUES}", normalizeContainValues(values).join(", ")),
        },
        isValid: (filter: Filter) => filter.values.length > 0 && isValidType(filter, FilterDataTypes.STRING),
    },
    [FilterOperators.GE]: {
        id: FilterOperators.GE,
        name: "greater than",
        customName: {
            [FilterFields.FOUNDED_YEAR]: "after",
        },
        placeholders: ["Type in value"],
        tooltip: "Returns records where the selected field's value is more than to the specified number or date.",
        query: {
            [FilterDataTypes.NUMBER]: "greater({FIELD}, {VALUES})",
            [FilterDataTypes.DATE]: "greater({FIELD}, {VALUES})",
            [FilterDataTypes.DATE_TIME]: "greater({FIELD}, {VALUES})",
        },
        numberOfParams: 1,
        isValid: (filter: Filter) =>
            filter.values.length === 1 &&
            (isValidType(filter, FilterDataTypes.NUMBER) ||
                [FilterDataTypes.DATE, FilterDataTypes.DATE_TIME].includes(filter.dataType)),
    },
    [FilterOperators.LE]: {
        id: FilterOperators.LE,
        name: "less than",
        customName: {
            [FilterFields.FOUNDED_YEAR]: "before",
        },
        placeholders: ["Type in value"],
        tooltip: "Returns records where the selected field's value is less than to the specified number or date.",
        query: {
            [FilterDataTypes.NUMBER]: "less({FIELD}, {VALUES})",
            [FilterDataTypes.DATE]: "less({FIELD}, {VALUES})",
            [FilterDataTypes.DATE_TIME]: "less({FIELD}, {VALUES})",
        },
        numberOfParams: 1,
        isValid: (filter: Filter) =>
            filter.values.length === 1 &&
            (isValidType(filter, FilterDataTypes.NUMBER) ||
                [FilterDataTypes.DATE, FilterDataTypes.DATE_TIME].includes(filter.dataType)),
    },
    [FilterOperators.BETWEEN]: {
        id: FilterOperators.BETWEEN,
        name: "between",
        placeholders: ["Min", "Max"],
        tooltip:
            "Returns records where the selected field's value falls within the specified range, inclusive of the start and end values.",
        query: {
            [FilterDataTypes.NUMBER]: "{FIELD} BETWEEN {VALUE0} AND {VALUE1}",
            [FilterDataTypes.DATE]: "{FIELD} BETWEEN {VALUE0} AND {VALUE1}",
            [FilterDataTypes.DATE_TIME]: "{FIELD} BETWEEN {VALUE0} AND {VALUE1}",
        },
        numberOfParams: 2,
        queryValuesBuilder: {
            [FilterDataTypes.NUMBER]: (values, query) =>
                query.replace("{VALUE0}", values?.[0]).replace("{VALUE1}", values?.[1]),
            [FilterDataTypes.DATE]: (values, query) =>
                query.replace("{VALUE0}", values?.[0]).replace("{VALUE1}", values?.[1]),
            [FilterDataTypes.DATE_TIME]: (values, query) =>
                query.replace("{VALUE0}", values?.[0]).replace("{VALUE1}", values?.[1]),
        },
        isValid: (filter: Filter) =>
            filter.values.length === 2 &&
            (isValidType(filter, FilterDataTypes.NUMBER) ||
                [FilterDataTypes.DATE, FilterDataTypes.DATE_TIME].includes(filter.dataType)),
        join: " —",
    },
    [FilterOperators.NOT_BETWEEN]: {
        id: FilterOperators.NOT_BETWEEN,
        name: "not between",
        placeholders: ["Min", "Max"],
        tooltip:
            "Returns records where the selected field's value that do not falls within the specified range, exclusive of the start and end values.",
        query: {
            [FilterDataTypes.NUMBER]: "{FIELD} NOT BETWEEN {VALUE0} AND {VALUE1}",
            [FilterDataTypes.DATE]: "{FIELD} NOT BETWEEN {VALUE0} AND {VALUE1}",
            [FilterDataTypes.DATE_TIME]: "{FIELD} NOT BETWEEN {VALUE0} AND {VALUE1}",
        },
        numberOfParams: 2,
        queryValuesBuilder: {
            [FilterDataTypes.NUMBER]: (values, query) =>
                query.replace("{VALUE0}", values?.[0]).replace("{VALUE1}", values?.[1]),
            [FilterDataTypes.DATE]: (values, query) =>
                query.replace("{VALUE0}", values?.[0]).replace("{VALUE1}", values?.[1]),
            [FilterDataTypes.DATE_TIME]: (values, query) =>
                query.replace("{VALUE0}", values?.[0]).replace("{VALUE1}", values?.[1]),
        },
        isValid: (filter: Filter) =>
            filter.values.length === 2 &&
            (isValidType(filter, FilterDataTypes.NUMBER) ||
                [FilterDataTypes.DATE, FilterDataTypes.DATE_TIME].includes(filter.dataType)),
        join: " —",
    },
    [FilterOperators.IS_KNOWN]: {
        id: FilterOperators.IS_KNOWN,
        name: "is known",
        placeholders: [],
        tooltip: "Returns records where the selected field has a value (not empty or null).",
        query: {
            [FilterDataTypes.STRING]: "notEmpty({FIELD})",
            [FilterDataTypes.ARRAY]: "notEmpty({FIELD})",
            [FilterDataTypes.NUMBER]: "{FIELD} != 0",
            [FilterDataTypes.DATE]: "{FIELD} is not null",
            [FilterDataTypes.DATE_TIME]: "{FIELD} is not null",
        },
        numberOfParams: 0,
        isValid: () => true,
    },
    [FilterOperators.IS_UNKNOWN]: {
        id: FilterOperators.IS_UNKNOWN,
        name: "is unknown",
        tooltip: "Returns records where the selected field lacks a value (empty or null).",
        placeholders: [],
        query: {
            [FilterDataTypes.STRING]: "empty({FIELD})",
            [FilterDataTypes.ARRAY]: "empty({FIELD})",
            [FilterDataTypes.NUMBER]: "{FIELD} = 0",
            [FilterDataTypes.DATE]: "{FIELD} is null",
            [FilterDataTypes.DATE_TIME]: "{FIELD} is null",
        },
        numberOfParams: 0,
        isValid: () => true,
    },
    [FilterOperators.IS_WITHIN]: {
        id: FilterOperators.IS_WITHIN,
        name: "is within",
        tooltip: "",
        placeholders: [],
        query: {
            [FilterDataTypes.STRING]: "",
        },
        numberOfParams: 0,
        isValid: () => true,
    },
    [FilterOperators.EXCLUDE]: {
        id: FilterOperators.EXCLUDE,
        name: "exclude all",
        tooltip: "",
        placeholders: [],
        query: {
            [FilterDataTypes.STRING]: "",
        },
        numberOfParams: 0,
        isValid: () => true,
    },
};

export const hardcodedFilterFieldValuesMap = {
    [FilterFields.INDUSTRY]: {
        "accounting": "Accounting",
        "marketing & advertising": "Advertising Services",
        "agriculture": "Agriculture / Construction / Mining Machinery Manufacturing",
        "airlines/aviation": "Airlines And Aviation",
        "alternative dispute resolution": "Alternative Dispute Resolution",
        "alternative medicine": "Alternative Medicine",
        "animation": "Animation And Post-production",
        "apparel & fashion": "Apparel Manufacturing",
        "architecture & planning": "Architecture And Planning",
        "industrial automation": "Automation Machinery Manufacturing",
        "aviation & aerospace": "Aviation And Aerospace Component Manufacturing",
        "banking": "Banking",
        "biotechnology": "Biotechnology Research",
        "publishing": "Book And Periodical Publishing",
        "broadcast media": "Broadcast Media Production And Distribution",
        "management consulting": "Business Consulting And Services",
        "capital markets": "Capital Markets",
        "chemicals": "Chemical Manufacturing",
        "civic & social organization": "Civic And Social Organizations",
        "civil engineering": "Civil Engineering",
        "computer & network security": "Computer And Network Security",
        "computer games": "Computer Games",
        "computer hardware": "Computer Hardware Manufacturing",
        "computer networking": "Computer Networking Products",
        "construction": "Construction",
        "consumer goods": "Consumer Goods Rental",
        "consumer services": "Consumer Services",
        "dairy": "Dairy Product Manufacturing",
        "defense & space": "Defense And Space Manufacturing",
        "design": "Design Services",
        "e-learning": "E-learning Providers",
        "program development": "Economic Programs",
        "education management": "Education",
        "electrical/electronic manufacturing": "Electrical Equipment Manufacturing",
        "entertainment": "Entertainment Providers",
        "environmental services": "Environmental Services",
        "business supplies & equipment": "Equipment Rental Services",
        "events services": "Events Services",
        "executive office": "Executive Offices",
        "facilities services": "Facilities Services",
        "farming": "Farming",
        "financial services": "Financial Services",
        "fine art": "Fine Art",
        "food & beverages": "Food And Beverage",
        "food production": "Food Production",
        "package/freight delivery": "Freight And Package Transportation",
        "fund-raising": "Fundraising",
        "furniture": "Furniture And Home Furnishings Manufacturing",
        "gambling & casinos": "Gambling Facilities And Casinos",
        "glass, ceramics & concrete": "Glass / Ceramics / Concrete Manufacturing",
        "government administration": "Government Administration",
        "government relations": "Government Relations Services",
        "graphic design": "Graphic Design",
        "human resources": "Health And Human Services",
        "higher education": "Higher Education",
        "hospitality": "Hospitality",
        "hospital & health care": "Hospitals And Health Care",
        "individual & family services": "Individual And Family Services",
        "information services": "Information Services",
        "insurance": "Insurance",
        "international affairs": "International Affairs",
        "international trade & development": "International Trade And Development",
        "investment banking": "Investment Banking",
        "investment management": "Investment Management",
        "information technology & services": "IT And Services",
        "computer software": "It System Custom Software Development",
        "judiciary": "Judiciary",
        "law enforcement": "Law Enforcement",
        "law practice": "Law Practice",
        "commercial real estate": "Leasing Non-residential Real Estate",
        "legal services": "Legal Services",
        "legislative office": "Legislative Offices",
        "libraries": "Libraries",
        "machinery": "Machinery",
        "maritime": "Maritime Transportation",
        "market research": "Market Research",
        "mechanical or industrial engineering": "Mechanical/Industrial Engineering",
        "media production": "Media Production",
        "medical devices": "Medical Equipment Manufacturing",
        "medical practice": "Medical Practices",
        "mental health care": "Mental Health Care",
        "mining & metals": "Metal Ore Mining",
        "military": "Military And International Affairs",
        "automotive": "Motor Vehicle Manufacturing",
        "motion pictures & film": "Movies And Sound Recording",
        "museums & institutions": "Museums / Historical Sites / Zoos",
        "music": "Musicians",
        "nanotechnology": "Nanotechnology Research",
        "newspapers": "Newspaper Publishing",
        "nonprofit organization management": "Non-profit Organizations",
        "oil & energy": "Oil / Gas / Mining",
        "online media": "Online Audio And Video Media",
        "outsourcing/offshoring": "Outsourcing And Offshoring Consulting",
        "packaging & containers": "Packaging And Containers Manufacturing",
        "paper & forest products": "Paper And Forest Product Manufacturing",
        "performing arts": "Performing Arts",
        "cosmetics": "Personal Care Product Manufacturing",
        "pharmaceuticals": "Pharmaceutical Manufacturing",
        "philanthropy": "Philanthropic Fundraising Services",
        "photography": "Photography",
        "plastics": "Plastics And Rubber Product Manufacturing",
        "political organization": "Political Organizations",
        "primary/secondary education": "Primary And Secondary Education",
        "printing": "Printing Services",
        "professional training & coaching": "Professional Training And Coaching",
        "public policy": "Public Policy Offices",
        "public relations & communications": "Public Relations And Communications Services",
        "public safety": "Public Safety",
        "railroad manufacture": "Railroad Equipment Manufacturing",
        "ranching": "Ranching",
        "real estate": "Real Estate",
        "religious institutions": "Religious Institutions",
        "renewables & environment": "Renewables And Environment",
        "research": "Research Services",
        "restaurants": "Restaurants",
        "retail": "Retail",
        "arts & crafts": "Retail Art Dealers",
        "supermarkets": "Retail Groceries",
        "luxury goods & jewelry": "Retail Luxury Goods And Jewelry",
        "security & investigations": "Security And Investigations",
        "semiconductors": "Semiconductor Manufacturing",
        "shipbuilding": "Shipbuilding",
        "sports": "Spectator Sports",
        "sporting goods": "Sporting Goods Manufacturing",
        "recreational facilities & services": "Sports And Recreation Instruction",
        "staffing & recruiting": "Staffing And Recruiting",
        "internet": "Technology / Information / Internet",
        "telecommunications": "Telecommunications",
        "textiles": "Textile Manufacturing",
        "think tanks": "Think Tanks",
        "tobacco": "Tobacco Manufacturing",
        "translation & localization": "Translation And Localization",
        "logistics & supply chain": "Transportation / Logistics / Supply Chain / Storage",
        "transportation/trucking/railroad": "Transportation / Trucking / Railroad",
        "leisure, travel & tourism": "Travel / Tourism",
        "utilities": "Utilities",
        "venture capital & private equity": "Venture Capital And Private Equity Principals",
        "veterinary": "Veterinary Services",
        "warehousing": "Warehousing And Storage",
        "health, wellness & fitness": "Wellness And Fitness Services",
        "wholesale": "Wholesale",
        "wine & spirits": "Wholesale Alcoholic Beverages",
        "consumer electronics": "Wholesale Appliances / Electrical / Electronics",
        "building materials": "Wholesale Building Materials",
        "fishery": "Wholesale Food And Beverage",
        "import & export": "Wholesale Import And Export",
        "wireless": "Wireless Services",
        "writing & editing": "Writing And Editing",
    },
    [FilterFields.SENIORITY]: {
        "owner": "Owner",
        "founder": "Founder",
        "c_suite": "Chief X Officer",
        "partner": "Partner",
        "vp": "Vice President",
        "head": "Lead",
        "director": "Director",
        "manager": "Manager",
        "senior": "Senior",
        "entry": "Entry Level",
        "intern": "Internship",
    },
    [FilterFields.DEPARTMENTS]: {
        "c_suite": "C Level",
        "executive": "Corporate Executive",
        "finance_executive": "Finance Executives",
        "founder": "Founder",
        "medical_health_executive": "Healthcare Executive",
        "human_resources_executive": "Human Resources Leadership",
        "information_technology_executive": "Information Technology Leadership",
        "legal_executive": "Legal Leadership",
        "marketing_executive": "Marketing Leadership",
        "operations_executive": "Operations Leadership",
        "sales_executive": "Sales Leadership",
        "consulting": "Consulting",
        "consultant": "Consultant",
        "design": "Design",
        "all_design": "Design All",
        "graphic_design": "Graphic Design",
        "product_ui_ux_design": "Product Design",
        "education": "Education",
        "professor": "Professor Academics",
        "principal": "School Administration",
        "superintendent": "Supervior Superintendent",
        "teacher": "Teaching",
        "master_engineering_technical": "Engineering Department",
        "bioengineering": "Biomedical Engineering",
        "biometrics": "Biometric Technology",
        "business_intelligence": "Business Inisghts",
        "chemical_engineering": "Chemical Engineering",
        "cloud_mobility": "Cloud Infrastructure",
        "data_science": "Data Science",
        "devops": "Devops Development Operations",
        "digital_transformation": "Digital Transformation",
        "engineering_technical": "Engineering",
        "industrial_engineering": "Industrial Engineering Design",
        "artificial_intelligence_machine_learning": "Machine Learning Artificial Intelligence",
        "mechanic": "Mechanic",
        "mobile_development": "Mobile App Development",
        "emerging_technology_innovation": "New Technology Development",
        "project_management": "Project Management",
        "test_quality_assurance": "Quality Assurance Test",
        "research_development": "Research And Development",
        "scrum_master_agile_coach": "Scrum Master",
        "software_development": "Software Development",
        "support_technical_services": "Support and Technical Services",
        "technology_operations": "Tech Operations",
        "technician": "Technical Support",
        "ui_ux": "Ui Ux Design",
        "web_development": "Web Development Design",
        "master_finance": "Finance Department",
        "accounting": "Accounting",
        "finance": "Finance",
        "financial_systems": "Financial Infrastructure",
        "financial_risk": "Financial Management",
        "treasury": "Financial Management Treasury",
        "financial_planning_analysis": "Financial Planning And Analysis",
        "financial_reporting": "Financial Reporting",
        "financial_strategy": "Financial Strategy",
        "internal_audit_control": "Internal Audit",
        "investor_relations": "Investor Relations Ir",
        "mergers_acquisitions": "Mergers And Acquisitions",
        "sourcing_procurement": "Procurement",
        "real_estate_finance": "Real Estate Investment Finance",
        "shared_services": "Service Sharing",
        "tax": "Tax",
        "medical_health": "Healthcare",
        "anesthesiology": "Anesthesiology",
        "chiropractics": "Chiropractic Care",
        "dentistry": "Dental Health",
        "pathology": "Diagnostic Services",
        "first_responder": "Emergency Services",
        "epidemiology": "Epidemiology",
        "clinical_systems": "Healthcare It Systems",
        "medical_administration": "Healthcare Management",
        "medical_research": "Healthcare Research",
        "infectious_disease": "Infectious Disease Unit",
        "medical_education_training": "Medical Training",
        "psychiatry": "Mental Health Services",
        "neurology": "Neuroscience",
        "nursing": "Nursing Elderly Care",
        "nutrition_dietetics": "Nutritional Services",
        "oncology": "Oncology Cancer Treatment",
        "opthalmology": "Opthalmology",
        "optometry": "Optometry",
        "orthopedics": "Orthopedics",
        "pediatrics": "Pediatrics",
        "medicine": "Pharmaceutical",
        "pharmacy": "Pharmacy And Drug Store",
        "physical_therapy": "Physical Therapy Rehabilitation",
        "doctors_physicians": "Physicians",
        "psychology": "Psychology",
        "public_health": "Public Health Policy",
        "radiology": "Radiology",
        "dermatology": "Skin Care",
        "social_work": "Social Worker",
        "obstetrics_gynecology": "Women'S Health",
        "master_human_resources": "Human Resources Department",
        "compensation_benefits": "Benefits Compensation And Remuneration Management",
        "culture_diversity_inclusion": "Culture Diversity Inclusion",
        "employee_labor_relations": "Employee Relations",
        "health_safety": "Health And Safety",
        "hr_business_partner": "Hr Business Partnership",
        "people_operations": "Hr People Operations",
        "talent_management": "Human Capital Management",
        "human_resource_information_system": "Human Resource Software",
        "human_resources": "Human Resources",
        "learning_development": "Learning And Development",
        "organizational_development": "Organizational Strategy",
        "recruiting_talent_acquisition": "Recruiting",
        "workforce_mangement": "Workforce Management",
        "master_information_technology": "Information Technology Department",
        "application_development": "App Development",
        "enterprise_architecture": "Business Architecture",
        "collaboration_web_app": "Collaboration App",
        "information_security": "Cyber Security",
        "data_center": "Data Center",
        "database_administration": "Data Management",
        "storage_disaster_recovery": "Data Recovery",
        "data_warehouse": "Data Warehouse And Storage",
        "ecommerce_development": "Ecommerce Development",
        "hr_financial_erp_systems": "Enterprise Resource Planning Systems",
        "servers": "Hosting Servers",
        "information_technology": "Information Technology",
        "it_procurement": "Information Technology Procurement",
        "it_training": "Information Technology Training",
        "infrastructure": "Infrastructure",
        "it_audit_it_compliance": "It Audit Compliance",
        "it_asset_management": "It Inventory Management",
        "it_operations": "It Operations",
        "networking": "Networking Infrastructure",
        "project_program_management": "Program Management",
        "quality_assurance": "Quality Assurance",
        "retail_store_systems": "Retail Technology",
        "business_service_management_itsm": "Service Delivery Management",
        "it_strategy": "Tech Strategy",
        "help_desk_desktop_services": "Tech Support Help Desk",
        "telecommunications": "Telecommunication",
        "virtualization": "Virtual Infrastructure",
        "master_legal": "Legal Department",
        "corporate_secretary": "Company Secretary",
        "compliance": "Compliance",
        "contracts": "Contracts Legal Documents",
        "acquisitions": "Corporate Acquisition",
        "privacy": "Data Privacy",
        "ediscovery": "Electronic Discovery",
        "ethics": "Ethics",
        "governmental_affairs_regulatory_law": "Government Relations",
        "intellectual_property_patent": "Ip Management",
        "labor_employment": "Labor",
        "lawyer_attorney": "Lawyer and Attorney",
        "legal": "Legal",
        "legal_counsel": "Legal Counsel",
        "litigation": "Legal Disputes Litigation",
        "legal_operations": "Legal Operations",
        "governance": "Organizational Governance",
        "master_marketing": "Marketing Department",
        "advertising": "Advertising",
        "brand_management": "Brand Strategy",
        "customer_experience": "Client Experience",
        "customer_marketing": "Consumer Outreach",
        "content_marketing": "Content Marketing Strategy",
        "strategic_communications": "Corporate Communications",
        "lead_generation": "Demand Creation",
        "demand_generation": "Demand Generation",
        "ecommerce_marketing": "Digital Commerce Marketing",
        "digital_marketing": "Digital Marketing",
        "event_marketing": "Event Experiential Marketing",
        "field_marketing": "Field Marketing",
        "marketing": "Marketing",
        "marketing_analytics_insights": "Marketing Analytics",
        "marketing_communications": "Marketing Communications",
        "marketing_operations": "Marketing Operations",
        "product_marketing": "Product Marketing",
        "public_relations": "Public Relations And Communications",
        "search_engine_optimization_pay_per_click": "Search Engine Marketing",
        "social_media_marketing": "Social Media Marketing",
        "technical_marketing": "Tech Marketing",
        "master_operations": "Operations Department",
        "office_operations": "Administrative Operations",
        "construction": "Construction",
        "enterprise_resource_planning": "Corporate Planning",
        "corporate_strategy": "Corporate Strategy",
        "customer_service_support": "Customer Service",
        "call_center": "Customer Support Center",
        "facilities_management": "Facilities",
        "logistics": "Logistics",
        "operations": "Operations",
        "project_development": "Project Development",
        "quality_management": "Quality Management",
        "real_estate": "Real Estate",
        "leasing": "Rental Services Leasing",
        "store_operations": "Retail Operations",
        "safety": "Safety Risk Manament",
        "physical_security": "Site Security",
        "supply_chain": "Supply Chain Management",
        "product_management": "Product",
        "product_mangement": "Product Management",
        "product_development": "Rsearch And Development",
        "master_sales": "Sales Department",
        "account_management": "Account Management",
        "business_development": "Business Development",
        "channel_sales": "Channel Partner Sales",
        "customer_retention_development": "Customer Relationship Management",
        "customer_success": "Customer Success",
        "field_outside_sales": "Direct Sales",
        "inside_sales": "Inside Sales",
        "partnerships": "Partnerships",
        "revenue_operations": "Revenue Operations",
        "sales": "Sales",
        "sales_training": "Sales Enablement",
        "sales_engineering": "Sales Engineering Support",
        "sales_operations": "Sales Operations",
        "sales_enablement": "Sales Support",
    },
};

export function isLocation(filter: any): filter is AddressLocation {
    return filter && typeof filter?.country === "string";
}

export function isDepartment(filter: any): filter is Department {
    return filter && typeof filter?.primary_department === "string";
}
