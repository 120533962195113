import { IntegrationType, Service } from "@/types/integrations";
import { IntegrationCard } from "./IntegrationCard";

interface Props {
    services: {
        name: string;
        service: Service;
        description: string;
        type: IntegrationType;
        availableSoon?: boolean;
        icon: JSX.Element | null;
        connectUrl: string | null;
    }[];
}

export const IntegrationGroup = ({ services }: Props) => {
    return (
        <div className="space-x-6 flex">
            {services.map(props => (
                <IntegrationCard key={props.name} {...props} />
            ))}
        </div>
    );
};
