import { DoublePaneLayout } from "@/components/layout";
import SideHeroBanner from "@/SideHeroBanner";
import CustomSignUp from "./CustomSignUp";
import { useAuth, useSignUp } from "@clerk/clerk-react";
import { useMemo } from "react";
import { useLocation } from "react-use";
import EmailVerified from "./EmailVerified";
import EmailVerificationLinkExpired from "./EmailVerificationLinkExpired";

const Signup = () => {
    const { isLoaded: isLoadedAuth, isSignedIn } = useAuth();
    const { signUp, isLoaded } = useSignUp();
    const location = useLocation();

    const isWaitingForCode = useMemo(() => location?.hash?.includes("/verify-email-address"), [location]);
    const clerkVerificationStatus = new URLSearchParams(window.location.search).get('__clerk_status');
    const isVerified = clerkVerificationStatus === "verified";
    const isNotVerified = clerkVerificationStatus && clerkVerificationStatus !== "verified";

    return (
        <>
            {isLoadedAuth && !isSignedIn && isVerified && <EmailVerified />}
            {isLoadedAuth && !isSignedIn && isNotVerified && <EmailVerificationLinkExpired />}
            {(!clerkVerificationStatus || isSignedIn) &&
                <DoublePaneLayout hide2ndPanel={isWaitingForCode}>
                    <CustomSignUp isLoaded={isLoaded} openEmail={!!signUp?.status} isWaitingForCode={isWaitingForCode} />
                    <SideHeroBanner />
                </DoublePaneLayout>
            }
        </>
    );
};

export default Signup;
