import pako from "pako";

export const gzipFile = (file: File): Promise<Uint8Array> => {
    if (!file) return Promise.reject(new Error("No file provided"));

    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = event => {
            const fileContent = event?.target?.result;
            if (!fileContent || !(fileContent instanceof ArrayBuffer)) {
                reject(new Error("Failed to read file content"));
                return;
            }

            try {
                const gzipped = pako.gzip(new Uint8Array(fileContent));
                resolve(gzipped);
            } catch (error) {
                reject(error);
            }
        };

        fileReader.onerror = error => {
            reject(error);
        };

        fileReader.readAsArrayBuffer(file);
    });
};

export const gzipString = (data: string) => pako.gzip(data);
