import React, { createContext, useContext, useRef, useEffect, ReactNode, useState } from 'react';
import { useLocation, Location } from 'react-router-dom';

interface NavigationContextType {
    getLastDashboardLocation: () => Location | undefined;
    audienceId: string;
    setAudienceId: (id: string) => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

interface NavigationProviderProps {
  children: ReactNode;
}

const dashboardPaths = ["/audiences", "/"];
const noAudiencePaths = [...dashboardPaths, "/audiences/new"];

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  const location = useLocation();
  const lastDashboardLocationRef = useRef<Location | undefined>(undefined);
  const [audienceId, setAudienceId] = useState<string>("");

  useEffect(() => {
    if(dashboardPaths.includes(location.pathname)) {
      lastDashboardLocationRef.current = location;
    }
    if(noAudiencePaths.includes(location.pathname)) {
      setAudienceId("");
    }
  }, [location]);

  const getLastDashboardLocation = () => lastDashboardLocationRef.current;

  return (
    <NavigationContext.Provider value={{ getLastDashboardLocation, audienceId, setAudienceId }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
