import { Button } from "@/components/atoms/Button/Button";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import QualificationQuestionsIcon from "@/assets/images/onboarding/qualificationQuestion.svg";
import { OnboardingWrapper } from "@/components/organisms/onboarding/OnboardingWrapper";
import { useOnboarding } from "./OnboardingContext";
import { useEffect, useState } from "react";
import cn from "classnames";
import Radio from "@/components/atoms/Radio/Radio";
import { Input } from "@/components/atoms/Input/Input";
import { useUpdateOnboardingMutation } from "@/api/users";
import { OnboardingStep } from "./Onboarding";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/atoms/DropdownMenu/DropdownMenu";
import { ChevronDownIcon } from "@radix-ui/react-icons";

export const QualificationQuestions = () => {
    const { setCurrentStep, onboarding } = useOnboarding();
    const [updateOnboarding] = useUpdateOnboardingMutation();

    const [adSpend, setAdSpend] = useState<string>();
    const [role, setRole] = useState<string>();
    const [customRole, setCustomRole] = useState<string>();
    const [source, setSource] = useState<string>();

    const nextStep = async () => {
        adSpend && role && source && (await updateOnboarding({ id: onboarding.id, qualification_questions: {
            ad_spend: adSpend,
            role,
            custom_role: customRole,
            source
        } }));

        setCurrentStep(OnboardingStep.AUDIENCE_TYPES);
    };

    useEffect(() => {
        setAdSpend(onboarding?.qualification_questions?.ad_spend);
        setRole(onboarding?.qualification_questions?.role);
        setCustomRole(onboarding?.qualification_questions?.custom_role);
        setSource(onboarding?.qualification_questions?.source);
    }, [onboarding]);

    return (
        <OnboardingWrapper imgUrl={QualificationQuestionsIcon}>
            <div className="w-full flex flex-col gap-4">
                <h1 className="text-h1">Help us personalize your experience</h1>
                <div>
                    <h2 className="text-h2">What is your total monthly ad spend?</h2>
                    <span className="text-ui-300 text-sm">(Across digital channels like LinkedIn, Meta and Google)</span>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <div className={cn("flex items-center p-2 border border-ui-300/[0.32] group rounded-md h-[36px] cursor-pointer aria-expanded:outline aria-expanded:outline-2 aria-expanded:outline-blue-300 aria-expanded:border-0 w-full mt-3 bg-white", {
                                "text-ui-300": !adSpend
                            })}>
                                <span className="text-14">{adSpend || "Select your monthly ad spend"}</span>
                                <ChevronDownIcon className="ml-auto transition-transform duration-200 group-aria-expanded:rotate-180" />
                            </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" className="bg-white border-0 data-[side=top]:shadow-dropdown-t data-[side=bottom]:shadow-dropdown-b w-[var(--radix-popper-anchor-width)]">
                            {[
                                "$0-$19K",
                                "$20-$49K", 
                                "$50-$74K",
                                "$75-$99K",
                                "$100-$149K",
                                "$150-$199K",
                                "$200-$299K",
                                "$300K+"
                            ].map((spend) => (
                                <DropdownMenuItem
                                    key={spend}
                                    aria-selected={spend === adSpend}
                                    className="hover:bg-transparent px-4 !py-3 hover:text-blue-800 aria-selected:text-blue-800 text-14 w-full"
                                    onSelect={() => setAdSpend(spend)}>
                                    {spend}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>

                </div>
                <div>
                    <h2 className="text-h2 mb-2">What is your role?</h2>
                    <Radio
                        required
                        className="min-w-fit w-fit flex flex-col gap-2"
                        items={[
                            { value: "Marketing/Growth", description: "Marketing/Growth" },
                            { value: "Marketing/Revenue Operations", description: "Marketing/Revenue Operations" },
                            { value: "Agency Partner", description: "Agency Partner" },
                            { value: "Other", description: "Other" },
                        ]}
                        value={role}
                        onValueChange={(value) => {
                            setCustomRole(undefined);
                            setRole(value);
                        }} />
                    {role === "Other" && <>
                        <Input required placeholder="Please specify" className="mt-2 w-full" value={customRole} onChange={e => setCustomRole(e.target.value)}></Input>
                    </>}
                </div>
                <div>
                    <h2 className="text-h2 mb-2">How did you hear about Primer?</h2>
                    <Input required placeholder="Please specify" className="mt-2 w-full" value={source} onChange={e => setSource(e.target.value)}></Input>
                </div>
            </div>

            <Button
                className="flex items-center gap-0.5"
                onClick={nextStep}
                disabled={!adSpend || !role || !source || (role === "Other" && !customRole)}
            >
                Next
                <ArrowRightIcon />
            </Button>
        </OnboardingWrapper >
    );
};
