import { SubscriptionStatus } from "@/types/subscription";
import PrimerLogoIcon from "@/assets/icons/primer-union-logo.svg?react";
import { UpgradePlan } from "@/components/organisms/subscription/UpgradePlan";

interface Props {
    status: SubscriptionStatus;
}

export const SubscriptionExpiredBanner = ({ status }: Props) => {
    const message =
        status === SubscriptionStatus.PAUSED
            ? "Your free trial is over"
            : `Your subscription has been ${status.replace(/_/g, " ")}`;

    return (
        <div
            style={{ backgroundImage: "linear-gradient(3deg, rgba(255,255,255) 40%, rgba(214,228,255) 100%)" }}
            className="flex items-center mx-12 mb-2 px-6 py-4 space-x-6 rounded-md border border-purple-300"
        >
            <PrimerLogoIcon className="shrink-0" />

            <div className="text-14 text-ui-900 flex-1 space-y-0.5 truncate">
                <div className="font-bold">{message}</div>
                <div>All audiences have stopped updating. But feel free to keep exploring (or upgrade your plan) !</div>
            </div>

            <UpgradePlan />
        </div>
    );
};
