import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { TableCell, TableRow } from "@/components/atoms/Table";
import { memo } from "react";

const PreviewSkeletonRow = memo(() => (
    <TableRow className="border-none">
        {Array(7)
            .fill(0)
            .map(() => (
                <>
                    <TableCell>
                        <Skeleton className="w-56 md:w-64 lg:w-72 xl:w-96 h-4 rounded-xl" />
                    </TableCell>
                </>
            ))}
    </TableRow>
));

export default PreviewSkeletonRow;
