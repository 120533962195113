import { SignIn } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import LogoIcon from "@/assets/icons/logo-basic.svg?react";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar";

const CustomLogin = ({ isLoaded }: { isLoaded: boolean }) => {
    return (
        <div className="px-4 md:px-0">
            <div className="sign-up-page flex items-center flex-col [&_.cl-socialButtonsBlockButton>div]:w-fit">
                <div className="max-w-[400px] flex flex-col text-center items-center justify-center">
                    <LogoIcon />
                    <div className="flex flex-col my-10 gap-2">
                        <span className="text-[44px] font-eighties font-semibold">Login at Primer</span>
                        <span className="text-lg text-ui-700">and start building powerful custom audiences
                            that match to major ad platforms:</span>
                    </div>
                </div>

                {isLoaded && <SignIn
                    appearance={{
                        elements: {
                            dividerText: { display: "none" },
                            footer: { display: "none" },
                            header: { display: "none" },
                            logoBox: { display: "none" },
                            socialButtonsBlockButton: {
                                justifyContent: "center",
                            },
                            socialButtonsBlockButtonText: {
                                fontSize: "14px",
                            },
                            card: {
                                width: '400px',
                                padding: '0px',
                            }
                        },
                    }}
                />}

                {!isLoaded && <CircularProgressBar className="w-8 h-8" />}
                <div className="max-w-[400px] text-ui-700 text-center text-sm mt-8">
                    <p className="mt-8 md:mt-12">
                        No account?{' '}
                        <Link to="/signup" className="text-blue-800">
                            Sign Up.
                        </Link>
                    </p>

                </div>
            </div>
        </div>
    );
};

export default CustomLogin;
