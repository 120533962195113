import { api } from "@/api";
import { Onboarding, PostHogPersonProperties, UpdateOnboardingRequest } from "@/types/api.ts";
import {
    GetInvoicesRequest,
    GetInvoicesResponse,
    Subscription,
    SubscriptionProducts,
    SubscriptionUpdateItem,
} from "@/types/subscription";

export interface ClerkUser {
    sessionClaims: Claims;
    sessionId: string;
    userId: string;
    orgId: string;
    orgRole: string;
    orgSlug: string;
    orgPermissions: any[];
    claims: Claims;
}

export interface Claims {
    azp: string;
    exp: number;
    first_name: string;
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    org_id: string;
    org_permissions: any[];
    org_role: string;
    org_slug: string;
    organization_id: string;
    organization_name: string;
    sid: string;
    sub: string;
    user_id: string;
}

export const usersApiSlice = api.injectEndpoints({
    endpoints: build => ({
        clerkUser: build.query<ClerkUser, void>({
            query: () => `/auth`,
        }),
        bootstrapFeatureFlags: build.query<Record<string, string | boolean>, PostHogPersonProperties>({
            query: ({ ClerkUserId, ...rest }) => {
                const params = new URLSearchParams(rest);
                return `/feature-flags/bootstrap/${ClerkUserId}?${params.toString()}`;
            },
        }),
        subscription: build.query<Subscription, undefined>({
            query: () => `/subscription`,
            providesTags: ["subscription"],
        }),

        addPaymentMethod: build.mutation<null, string>({
            query: paymentMethodId => ({
                url: `/subscription/payment_method`,
                method: "POST",
                body: { paymentMethodId },
            }),
            invalidatesTags: ["subscription"],
        }),

        removePaymentMethod: build.mutation<null, string>({
            query: paymentMethodId => ({
                url: `/subscription/payment_method`,
                method: "DELETE",
                body: { paymentMethodId },
            }),
            invalidatesTags: ["subscription"],
        }),

        getInvoices: build.query<GetInvoicesResponse, GetInvoicesRequest>({
            query: params => {
                const { limit, offset } = params;
                const searchParams = new URLSearchParams();

                if (limit) searchParams.set("limit", String(limit));
                if (offset) searchParams.set("offset", String(offset));

                return `/subscription/invoices?${searchParams.size ? searchParams.toString() : ""}`;
            },
        }),

        getSubscriptionProducts: build.query<SubscriptionProducts, void>({
            query: () => "/subscription/products",
        }),

        upgradeSubscription: build.mutation<null, SubscriptionUpdateItem[]>({
            query: items => ({
                url: `/subscription`,
                method: "POST",
                body: { items },
            }),
            invalidatesTags: ["subscription", "statistics"],
        }),

        cancelSubscription: build.mutation<void, void>({
            query: () => ({
                url: `/subscription`,
                method: "DELETE",
            }),
        }),

        getOnboarding: build.query<Onboarding, void>({
            query: () => "/onboarding",
            providesTags: ["onboarding"],
        }),

        updateOnboarding: build.mutation<void, UpdateOnboardingRequest>({
            query: ({ id, ...rest }) => ({
                url: `/onboarding/${id}`,
                method: "PATCH",
                body: rest,
            }),
            invalidatesTags: ["connections", "onboarding"],
        }),
        checkUserExist: build.query<boolean, void>({
            query: () => {
                return `/users/exist`;
            },
        }),
    }),
});

export const {
    useClerkUserQuery,
    useBootstrapFeatureFlagsQuery,
    useSubscriptionQuery,
    useAddPaymentMethodMutation,
    useRemovePaymentMethodMutation,
    useGetInvoicesQuery,
    useGetSubscriptionProductsQuery,
    useUpgradeSubscriptionMutation,
    useCancelSubscriptionMutation,
    useGetOnboardingQuery,
    useUpdateOnboardingMutation,
    useCheckUserExistQuery,
} = usersApiSlice;
