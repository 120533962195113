import cn from "clsx";
import { useStatisticsQuery } from "@/api/audiences";
import InfoIcon from "@/assets/icons/info.svg?react";
import WarnIcon from "@/assets/icons/warning.svg?react";

export const InfoBar = ({ open }: { open: boolean }) => {
    const { data: statistics, isLoading } = useStatisticsQuery(void true, { refetchOnMountOrArgChange: true });
    if (!statistics || isLoading) return null;

    const { audiencesUsed, audiencesMaxLimit } = statistics;

    const allAudiencesUsed = audiencesMaxLimit <= audiencesUsed;
    const percentageRemaining = (audiencesUsed / audiencesMaxLimit) * 100;

    return (
        <div
            className={cn(
                "flex flex-col px-4 py-2 border border-transparent rounded-lg hover:!border-blue-200",
                open && "!border-blue-200",
            )}
        >
            <div className={`flex items-center text-14 ${allAudiencesUsed ? "text-warning" : "text-ui-700"}`}>
                {allAudiencesUsed && <WarnIcon className="w-5 h-5 mb-1 mr-1" />}
                <div data-testid="info-bar" className="mr-1">
                    Audiences
                </div>
                <InfoIcon className="shrink-0 mr-6" />

                <div className="flex items-center gap-0.5">
                    <div className="div" id="used-audiences">{audiencesUsed}</div>
                    <div className="div">/</div>
                    <div className="div" id="total-audiences">{audiencesMaxLimit}</div>
                </div>
            </div>

            <div className="h-1 rounded bg-ui-200 mt-2">
                <div
                    style={{ width: `${percentageRemaining < 100 ? percentageRemaining : 100}%` }}
                    className={`h-full rounded ${allAudiencesUsed ? "bg-orange-700" : "bg-blue-800"}`}
                ></div>
            </div>
        </div>
    );
};
