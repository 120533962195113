import { Modal } from "@/components/organisms/Modal";
import { Button } from "@/components/atoms/Button/Button";
import { ModalHeader } from "../Modal/ModalHeader";

interface Props {
    onCancel: () => void;
    onConfirm: () => void;
}

export const DeleteAccountModal = ({ onCancel, onConfirm }: Props) => (
    <Modal className="w-[480px]">
        <ModalHeader title="Are you sure you want to disconnect this account?" onClose={onCancel} />

        <div className="flex w-full justify-end mt-16 gap-4">
            <Button variant="secondary" onClick={onCancel}>
                No, cancel
            </Button>
            <Button onClick={onConfirm}>Yes, disconnect</Button>
        </div>
    </Modal>
);

interface PropsDelete {
    onClose: () => void;
}

export const DeleteAccountErrorModal = ({ onClose }: PropsDelete) => (
    <Modal className="w-[480px]">
        <ModalHeader title="Removing Not Allowed" onClose={onClose} />

        <div className="text-sm text-ui-700 mt-2">
            This connection is currently in use by running audiences. Please wait until the audiences have finished before trying again.
        </div>

        <div className="flex w-full justify-end mt-8 gap-4">
            <Button onClick={onClose}>Close</Button>
        </div>
    </Modal>
);
