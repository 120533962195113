import { useDebounce } from "react-use";
import { Dispatch, SetStateAction, useState } from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

type ReturnType = [string, string, Dispatch<SetStateAction<string>>];

export const useSearch = (initialValue: string, timeout = 300): ReturnType => {
    const [query, setQuery] = useQueryParams(
        { searchTerm: withDefault(StringParam, "") },
        { removeDefaultsFromUrl: true },
    );

    const [searchValue, setSearchValue] = useState(() => query.searchTerm || initialValue);

    useDebounce(() => setQuery({ searchTerm: searchValue }), timeout, [searchValue]);

    return [searchValue, query.searchTerm, setSearchValue];
};
