import { useClerk } from "@clerk/clerk-react";
import { useEffect, useMemo, useState } from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import { useNavigate } from "react-router";
import { compact } from "lodash";

import { useCreateAudienceMutation } from "@/api/audiences";
import { useGetConnectionsQuery } from "@/api/integrations";
import { useUpdateOnboardingMutation } from "@/api/users";
import { Button } from "@/components/atoms/Button/Button";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import LastStepImage from "@/assets/images/onboarding/last-step-bg.png";
import { useOnboarding } from "@/components/organisms/onboarding/OnboardingContext";
import { OnboardingWrapper } from "@/components/organisms/onboarding/OnboardingWrapper";
import { Destination } from "@/types/audience";
import { ConnectionStatus, Service } from "@/types/integrations";
import { CreateAudienceRequest, CreateAudienceResponse } from "@/types/api";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { toast } from "@/components/atoms/Toast/useToast";

export const OnboardingLastStep = () => {
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const { user } = useClerk();
    const { firstName, lastName } = user!;

    const { onboarding } = useOnboarding();
    const [updateOnboarding] = useUpdateOnboardingMutation();
    const { data: connections } = useGetConnectionsQuery({ enabled: true }, { refetchOnMountOrArgChange: true });
    const [saving, setSaving] = useState(false);
    const [imgLoading, setImgLoading] = useState(true);
    const [confettiHasFallen, setConfettiHasFallen] = useState(false);

    const [createAudienceMutation, { error: createAudienceError }] = useCreateAudienceMutation();
    const createAudience = async (req: CreateAudienceRequest): Promise<CreateAudienceResponse> =>
        createAudienceMutation(req).unwrap();

    const connectedDestinations = useMemo(
        () =>
            connections?.reduce((prev, connection) => {
                const { state, provider } = connection;
                if (state === ConnectionStatus.CONNECTED && provider !== Service.SALESFORCE) {
                    const key = provider === Service.LINKEDIN ? Destination.LINKEDIN : provider;
                    return { ...prev, [key]: true };
                } else return prev;
            }, {}),
        [connections],
    );

    const handleGoToPrimer = async () => {
        setSaving(true);

        const audience = await createAudience({
            name: `Onboarding Audience - ${firstName} ${lastName}`,
            destinations: connectedDestinations,
        });
        updateOnboarding({ id: onboarding.id, onboarding_completed: true, audience_id: audience.id });

        navigate(`/audiences/${audience.id}?onboardingCompleted=true`);
    };

    useEffect(() => {
        if (imgLoading) return;
        const timer = setTimeout(() => {
            setConfettiHasFallen(true);
        }, 4000);

        return () => clearTimeout(timer);
    }, [imgLoading]);

    useEffect(() => {
        const errors = compact([createAudienceError]);

        errors.forEach(e => {
            const fetchBaseQueryError = e as FetchBaseQueryError;
            const defaultError = "An error occurred. Please retry.";
            toast({ description: (fetchBaseQueryError.data as string) || defaultError, variant: "destructive" });
        });
    }, [createAudienceError]);

    return (
        <OnboardingWrapper width="min-w-[518px]">
            <div>
                <h1 className="text-h1 mb-2">After you create an audience it will sync to your ad accounts</h1>
            </div>

            <div className={`w-[1000px] h-[518] hidden ${!imgLoading && "!block"}`}>
                <img src={LastStepImage} className="w-full h-full" onLoad={() => setImgLoading(false)} />
            </div>

            <div
                className={`w-[1000px] h-[518] bg-ui-300/[0.19] rounded-3xl animate-pulse  ${!imgLoading && "hidden"}`}
            >
                <img src={LastStepImage} className="w-full h-full" />
            </div>

            {!imgLoading && (
                <Button className="flex items-center gap-0.5 !mt-0" onClick={handleGoToPrimer} loading={saving}>
                    Create Audience
                    <ArrowRightIcon />
                </Button>
            )}

            {!imgLoading && (
                <Confetti
                    width={width}
                    height={600}
                    opacity={0.3}
                    gravity={0.07}
                    numberOfPieces={confettiHasFallen ? 0 : 200}
                />
            )}
        </OnboardingWrapper>
    );
};
