import { GetAudiencesFilters } from "@/types/api";
import { AudienceStatus, Destination } from "@/types/audience";
import { useQueryParams, withDefault, BooleanParam } from "use-query-params";

type DecodeFilterValue = string | (string | null)[] | null;

function decodeStringArrayFilter(validValues: string[], filter?: DecodeFilterValue): string[] {
    const lowerCaseValidValues = validValues.map(v => v.toLowerCase());

    if (!filter) {
        return [];
    }
    // multiple selections
    else if (Array.isArray(filter)) {
        const returnFilters: string[] = [];
        filter.forEach(f => {
            const indexOfF = f ? lowerCaseValidValues.indexOf(f.toLowerCase()) : -1;

            if (indexOfF !== -1) {
                returnFilters.push(validValues[indexOfF]);
            }
        });

        return returnFilters;
    }
    // single selection
    else if (lowerCaseValidValues.includes(filter.toLowerCase())) {
        return [validValues[lowerCaseValidValues.indexOf(filter.toLowerCase())]];
    } else {
        return [];
    }
}

export function useAudienceResultsFilter(): {
    filtersValue: GetAudiencesFilters;
    setFilters: (filters: GetAudiencesFilters) => void;
    resetFilters: () => void;
} {
    const [query, setQuery] = useQueryParams(
        {
            status: withDefault(
                {
                    encode(value: string) {
                        return value;
                    },
                    decode(filter?: DecodeFilterValue) {
                        return decodeStringArrayFilter(Object.values(AudienceStatus), filter);
                    },
                },
                [],
            ),
            destinations: withDefault(
                {
                    encode(value: string) {
                        return value;
                    },
                    decode(filter?: DecodeFilterValue) {
                        return decodeStringArrayFilter(Object.values(Destination), filter);
                    },
                },
                [],
            ),
            inFavorites: withDefault(BooleanParam, false),
        },
        { removeDefaultsFromUrl: true },
    );
    const setFilters = (filters: GetAudiencesFilters) => {
        setQuery(filters);
    };

    const resetFilters = () => setQuery({ status: [], destinations: [], inFavorites: false });

    return { filtersValue: query as GetAudiencesFilters, setFilters, resetFilters };
}
