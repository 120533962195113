import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useGetOnboardingQuery } from "@/api/users";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";

export const OnboardingMiddleware = () => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const onboardingPitchEnabled = useFeatureFlag(FeatureFlagsEnum.ONBOARDING_PITCH);
    const { data: onboarding } = useGetOnboardingQuery(undefined, { skip: !onboardingPitchEnabled });

    useEffect(() => {
        if ((!onboarding || !onboardingPitchEnabled) && pathname === "/onboarding") return navigate("/audiences");
        if (onboarding && search.includes("onboardingCompleted=true")) return;
        if (onboarding && onboarding?.onboarding_completed && pathname === "/onboarding") return navigate("/audiences");
        if ((pathname.includes("/integrations") && search.includes("code")) || pathname === "/onboarding") return;

        if (onboarding && !onboarding?.onboarding_completed) {
            const { crm_connected, add_platform_connected } = onboarding!;
            const currentStep = !crm_connected ? 1 : !add_platform_connected ? 2 : 3;
            navigate(`/onboarding?step=${currentStep}`);
        }
    }, [onboarding, onboardingPitchEnabled, navigate]);

    return <Outlet />;
};
