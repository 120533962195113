import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, VariantProps } from "class-variance-authority";
import Loader from "@/components/atoms/Button/Loader.tsx";

// Quick example of how we can build component using tailwind + Radix UI primitives
//TODO Add shadows, focused styles and rest of the styles to completely match button design from figma

const disabledClasses =
    "disabled:text-ui-300 disabled:border disabled:pointer-events-none disabled:bg-ui-50 disabled:border-ui-300/[0.19]";

const buttonVariants = cva(
    `inline-flex items-center justify-center whitespace-nowrap rounded-lg transition-colors text-14 leading-[21px] ${disabledClasses}`,
    {
        variants: {
            variant: {
                primary: "bg-ui-900 text-white hover:bg-ui-700 active:bg-ui-900 active:shadow-none",
                secondary:
                    "bg-white text-ui-700 border border-ui-700/[0.32] shadow-button hover:shadow-buttonHover active:shadow-none active:border-ui-300 active:bg-dark-100 disabled:[&>path]:fill-ui-300",
                ghost: "hover:bg-accent hover:text-accent-foreground",
                outline: "text-ui-700 border border-ui-700/[0.32] hover:bg-ui-100 hover:text-ui-900",
                input: "text-ui-700 border border-ui-300/[0.32] hover:bg-ui-100 hover:text-ui-900",
                basic: ""
            },
            size: {
                custom: "",
                default: "h-9 py-2 px-4",
                icon: "h-10 w-10",
                small: "py-0 px-0",
                "icon-sm": "h-4 w-4",
                "icon-xs": "h-3 w-3",
            },
        },
        defaultVariants: {
            variant: "primary",
            size: "default",
        },
    },
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    loading?: boolean;
    attributes?: { [key: string]: string };
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, loading = false, asChild = false, attributes, ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        if (loading) {
            return (
                <Comp
                    disabled={true}
                    className={buttonVariants({ variant: "secondary", size, className })}
                    ref={ref}
                    {...props}
                >
                    <Loader />
                </Comp>
            );
        }
        return <Comp className={buttonVariants({ variant, size, className })} ref={ref} {...attributes} {...props} />;
    },
);
Button.displayName = "Button";

export { Button };
