import { DropdownMenuContent, DropdownMenuItem } from "@/components/atoms/DropdownMenu/DropdownMenu.tsx";
import { AudienceStatus } from "@/types/audience";
import CalendarIcon from "@/assets/icons/calendar.svg?react";
import DuplicateIcon from "@/assets/icons/duplicate.svg?react";
import ArchivedIcon from "@/assets/icons/archived.svg?react";
import RerunIcon from "@/assets/icons/running.svg?react";
import { useUpdateAudience } from "@/hooks/useUpdateAudience";
import { useDuplicateAudienceMutation, useRunAudienceMutation } from "@/api/audiences";
import { useNavigate } from "react-router";

export enum AudienceContextMenuItems {
    "VIEW_HISTORY" = "view_history",
    "DUPLICATE" = "duplicate",
    "ARCHIVE" = "archive",
    "RERUN" = "rerun",
}

type ParentPage = "audience" | "dashboard";

interface AudienceContextMenuProps {
    page: ParentPage;
    status: AudienceStatus;
    audienceId: string;
    handleViewRuns?: (audienceId: string) => void;
}

const MenuItem = ({ icon, text, onClick }: { text: string; icon: JSX.Element; onClick: () => void }) => {
    return (
        <DropdownMenuItem
            className="hover:bg-ui-50 focus:bg-ui-50 cursor-pointer flex items-center text-ui-700"
            onClick={e => {
                e.stopPropagation();
                onClick();
            }}
        >
            {icon}
            <span className="text-sm ml-2">{text}</span>
        </DropdownMenuItem>
    );
};

const MenuItems = ({ page, status, audienceId, handleViewRuns }: AudienceContextMenuProps) => {
    const { archiveAudience } = useUpdateAudience(audienceId);
    const [duplicateAudience] = useDuplicateAudienceMutation();
    const [runAudience] = useRunAudienceMutation();
    const navigate = useNavigate();

    const menuItems = {
        [AudienceContextMenuItems.VIEW_HISTORY]: {
            key: "view_history",
            text: "View Run Details",
            icon: <CalendarIcon />,
            onClick: () => handleViewRuns && handleViewRuns(audienceId),
        },
        [AudienceContextMenuItems.DUPLICATE]: {
            key: "duplicate",
            text: "Duplicate",
            icon: <DuplicateIcon />,
            onClick: async () => {
                const result = await duplicateAudience({ audienceId });
                if (result?.data?.id) {
                    navigate(`/audiences/${result.data.id}`);
                }
            },
        },
        [AudienceContextMenuItems.ARCHIVE]: {
            key: "archive",
            text: "Archive",
            icon: <ArchivedIcon />,
            onClick: archiveAudience,
        },
        [AudienceContextMenuItems.RERUN]: {
            key: "rerun",
            text: "Rerun",
            icon: <RerunIcon />,
            onClick: () => runAudience({ audienceId }),
        },
    };

    const audienceMenuItems = [<MenuItem {...menuItems[AudienceContextMenuItems.DUPLICATE]} />];

    if (![AudienceStatus.ARCHIVED, AudienceStatus.RUNNING].includes(status)) {
        audienceMenuItems.push(<MenuItem {...menuItems[AudienceContextMenuItems.ARCHIVE]} />);
    }

    if (page === "dashboard") {
        if ([AudienceStatus.RUNNING, AudienceStatus.SYNCED, AudienceStatus.ARCHIVED].includes(status)) {
            audienceMenuItems.unshift(<MenuItem {...menuItems[AudienceContextMenuItems.VIEW_HISTORY]} />);
        }
        if (status === AudienceStatus.INCOMPLETE) {
            audienceMenuItems.unshift(
                <MenuItem {...menuItems[AudienceContextMenuItems.VIEW_HISTORY]} text={"View Errors"} />,
            );
        }
        if (status === AudienceStatus.SYNCED) {
            audienceMenuItems.unshift(<MenuItem {...menuItems[AudienceContextMenuItems.RERUN]} />);
        }

        return audienceMenuItems;
    }
    // Audience page
    if ([AudienceStatus.SYNCED, AudienceStatus.INCOMPLETE].includes(status)) {
        audienceMenuItems.unshift(<MenuItem {...menuItems[AudienceContextMenuItems.VIEW_HISTORY]} />);
    }

    return audienceMenuItems;
};

export const AudienceContextMenu = (props: AudienceContextMenuProps) => {
    return (
        <DropdownMenuContent className="border border-ui-300/[.19] bg-white w-[300px] mr-12 shadow-dropdown py-2">
            <MenuItems {...props} />
        </DropdownMenuContent>
    );
};
