import { api } from "@/api";
import {
    GetRecentlyUsedFiltersReponse,
    GetFieldValuesRequest,
    GetFieldValuesResponse,
    FindFieldValuesRequest,
    FindFieldValuesResponse,
    GetFirstPartyFiltersResponse,
    GetFirstPartyFiltersRequest,
} from "@/types/api";

export const filtersApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getRecentlyUsedFilters: builder.query<GetRecentlyUsedFiltersReponse[], undefined>({
            query: () => `/filters/recently-used`,
            providesTags: () => [{ type: "primer-filters" }],
        }),
        findFilterFieldValues: builder.mutation<FindFieldValuesResponse[], FindFieldValuesRequest>({
            query: body => ({
                url: "/filters/find-values",
                method: "POST",
                body,
            }),
        }),
        getFilterFieldValues: builder.query<GetFieldValuesResponse[], GetFieldValuesRequest>({
            query: ({ fieldId, q }) => {
                const searchParams = new URLSearchParams();
                searchParams.set("fieldId", String(fieldId));
                if (q) searchParams.set("q", q);

                return `/filters/field-values?${searchParams.size ? searchParams.toString() : ""}`;
            },
        }),
        getFirstPartyFilters: builder.query<GetFirstPartyFiltersResponse, GetFirstPartyFiltersRequest>({
            query: ({ instanceId }) => `/filters/first-party/${instanceId}`,
            providesTags: () => [{ type: "first-party-filters" }],
        }),
    }),
});

export const {
    useGetRecentlyUsedFiltersQuery,
    useFindFilterFieldValuesMutation,
    useGetFilterFieldValuesQuery,
    useGetFirstPartyFiltersQuery,
} = filtersApiSlice;
