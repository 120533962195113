import { RefObject, useEffect, useState } from "react";

/**
 * If the provided element's scrollHeight is larger than its height,
 * then it is considered to be overflowing, and true is returned.
 */
const useIsElementOverflowing = (ref: RefObject<HTMLElement> | null, dependencies: unknown[]) => {
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (!ref?.current) return;
        const resizeObserver = new ResizeObserver(() => {
            const container = ref?.current;
            if (!container) return false;

            setIsOverflowing(container.getBoundingClientRect().height < container.scrollHeight);
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect();
    }, [...dependencies]);

    return isOverflowing;
};

export { useIsElementOverflowing };
