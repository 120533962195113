import React from "react";
import { Content } from "@radix-ui/react-tabs";
import TabContentOption from "./TabContentOption";
import { CategoryConfig, FilterConfig } from "@primer/filters/types";

export interface FilterFieldTabContentProps extends React.HTMLAttributes<HTMLDivElement> {
    setSelectedFilterField: (filterField: FilterConfig) => void;
    categories: CategoriesFilterFields[];
}

export interface CategoriesFilterFields extends CategoryConfig {
    fields: FilterConfig[];
}

const FilterFieldTabContent = React.forwardRef<HTMLDivElement, FilterFieldTabContentProps>(
    ({ categories, setSelectedFilterField, ...props }, ref) => {
        return (
            <div ref={ref} {...props}>
                <div className="h-[50px] text-[10px] text-ui-300 border-b border-ui-300/[0.32] flex text-sm font-normal leading-tight">
                    <div className="flex-1 flex items-center px-6 border-t border-r border-ui-300/[0.32]">Filter Name</div>
                    <div className="flex-1 flex items-center px-6 border-t border-ui-300/[0.32]">Sample Data</div>
                </div>
                {categories.map(filterCategory => (
                    <Content
                        className="bg-white rounded-b-md overflow-y-auto max-h-[265px]"
                        value={filterCategory.id.toString()}
                        key={filterCategory.id}
                    >
                        <TabContentOption
                            fields={filterCategory.fields}
                            setSelectedFilterField={setSelectedFilterField}
                        />
                    </Content>
                ))}
            </div>
        );
    },
);

FilterFieldTabContent.displayName = "FilterFieldTabContent";

export default FilterFieldTabContent;
