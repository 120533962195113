import Radio from "@/components/atoms/Radio/Radio";
import { FilterEntityTypes } from "@primer/filters/types";

const entityTypeOptions = [
    {
        value: FilterEntityTypes.PERSON,
        description: "People",
    },
    {
        value: FilterEntityTypes.COMPANY,
        description: "Companies",
    },
];

interface EntityTypeSelectProps {
    selectedEntityType: FilterEntityTypes;
    setSelectedEntityType: (entityType: FilterEntityTypes) => void;
}

const EntityTypeSelect = ({ selectedEntityType, setSelectedEntityType }: EntityTypeSelectProps) => {
    return (
        <div className="mb-10">
            <span className="text-ui-900 text-lg">Will be imported as</span>
            <Radio
                className="min-w-fit w-fit flex !gap-6 h-[36px] !my-0 !mx-2"
                items={entityTypeOptions}
                value={selectedEntityType}
                onValueChange={setSelectedEntityType}
            />
        </div>
    );
};

export { EntityTypeSelect };
