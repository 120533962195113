import React, { KeyboardEventHandler, MouseEventHandler } from 'react';
import CloseIcon from "@/assets/icons/close.svg?react";
import { SelectedOption } from './FilterMultiSelect';
import { Tooltip } from '@/components/atoms/Tooltip/Tooltip';
import { AddressLocation, Department, FilterConfig } from '@primer/filters/types';
import { parseFilterName, parseFilterValueLabel } from '@/utils/string';
import ClickAwayListener from 'react-click-away-listener';
import { Input } from '@/components/atoms/Input/Input';

interface SelectedOptionViewerProps {
    option: SelectedOption;
    onRemove: (option: SelectedOption) => void;
    selectedFilterField: FilterConfig;
    updateValue: (oldValue: string | number | AddressLocation | Department, newValue: string) => void;
}

const SelectedOptionViewer: React.FC<SelectedOptionViewerProps> = ({ option, selectedFilterField, onRemove, updateValue }) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [value, setValue] = React.useState(parseFilterValueLabel(option.label ?? option.value));
    const label = option.label
        ? option.label
        : parseFilterName(selectedFilterField, option);

    const onRemoveOption: MouseEventHandler = (event) => {
        event.stopPropagation();
        onRemove(option)
    }

    const handleKeyDown: KeyboardEventHandler = event => {
        const { key } = event;

        switch (key) {
            case "Enter":
                saveChanges();
                break;
            case "Escape":
                event.preventDefault();
                event.stopPropagation();
                discardChanges();
                break;
            default:
                break;
        }
    };

    const saveChanges = () => {
        updateValue(option.value, value);
        setIsEditing(false);
    };

    const discardChanges = () => {
        setValue(parseFilterValueLabel(option.label ?? option.value));
        setIsEditing(false);
    };

    const normalItem = (
        <div
            aria-invalid={option.invalid === true || option.exceeded === true}
            className="truncate flex flex-nowrap items-center whitespace-nowrap h-[28px] rounded-md bg-ui-50 border border-ui-300/[0.32]
                aria-[invalid=true]:bg-error-background aria-[invalid=true]:text-ui-400 aria-[invalid=true]:border-dashed"
        >
            <span className="pl-2 truncate">
                {label}
                {option.category && <span className='ml-1 text-ui-300'>({option.category})</span>}
            </span>
            <div
                role="button"
                className='ml-1 p-1 cursor-pointer'
                onClick={onRemoveOption}>
                <CloseIcon className="text-ui-300" />
            </div>
        </div>
    );
    const errorMessage = option.exceeded ? "Max number of values exceeded" : "Value doesn't exist";
    const invalidItem = (
        <Tooltip content={errorMessage} side="bottom">
            <div onClick={() => setIsEditing(true)}>
                {normalItem}
            </div>
        </Tooltip>
    )
    const editingItem = (
        <ClickAwayListener onClickAway={discardChanges}>
            <Input
                autoFocus
                aria-label="option value"
                name="option value input"
                placeholder="Type in value"
                value={value}
                onBlur={discardChanges}
                onChange={e => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
                className="!h-[28px] bg-error-background border-2 focus:border-error-stroke"
                role="textbox"
            />

        </ClickAwayListener>
    )
    return (
        <>
            {isEditing && editingItem }
            {!isEditing && (option.invalid === true || option.exceeded === true) && invalidItem}
            {!isEditing && option.invalid !== true && option.exceeded !== true && normalItem}
        </>

    )
};

export default SelectedOptionViewer;
