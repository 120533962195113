import cn from "classnames";
import { InvoiceStatusEnum } from "@/types/subscription";

interface InvoiceStatusProps {
    loading?: boolean;
    className?: string;
    status: InvoiceStatusEnum;
}

export const InvoiceStatus = ({ status, className }: InvoiceStatusProps) => {
    const containerClasses = "flex items-center border py-1 px-2 rounded-lg h-6 w-max text-12";

    switch (status) {
        case InvoiceStatusEnum.VOID:
            return (
                <div className={cn(containerClasses, "border-ui-300/[0.19] bg-ui-50", className)}>
                    <span id="invoice-status" className="text-ui-300">
                        Void
                    </span>
                </div>
            );
        case InvoiceStatusEnum.OPEN:
            return (
                <div className={cn(containerClasses, "border-ui-300/[0.19] bg-blue-200", className)}>
                    <span id="invoice-status" className="text-blue-870">
                        Open
                    </span>
                </div>
            );
        case InvoiceStatusEnum.PAID:
            return (
                <div className={cn(containerClasses, "border-ui-300/[0.19] bg-green-100", className)}>
                    <span id="invoice-status" className="text-green-500">
                        Paid
                    </span>
                </div>
            );
        case InvoiceStatusEnum.UNCOLLECTIBLE:
            return (
                <div
                    className={cn(
                        containerClasses,
                        "border-ui-300/[0.19] bg-warning-background text-warning",
                        className,
                    )}
                >
                    <span id="invoice-status">Uncollectible</span>
                </div>
            );
        case InvoiceStatusEnum.DRAFT:
            return (
                <div className={cn(containerClasses, "border-ui-300/[0.19] bg-ui-300/[0.19]", className)}>
                    <span id="invoice-status" className="text-ui-700">
                        Draft
                    </span>
                </div>
            );
    }
};
