import { AudienceShapeHeuristicsSchema, ChartSchema, SummaryCategoryField } from "@/types/audience";

export const formatNumber = (value?: number | null, caps = [100000, 1000000], character = "<"): string => {
    if (value == null && value == undefined) return "N/A";
    if (value <= caps[0]) return value.toLocaleString("en-US", { maximumFractionDigits: 0 });
    if (value < caps[1]) return `${character}${Math.ceil(value / 1000)}k`;
    if (value >= 1000000) return `${character}${Math.ceil(value / 100000) / 10}m`;
    return value.toString();
};

export const formatNumberFloor = (value?: number | null): string => {
    if (value == null && value == undefined) return "N/A";
    if (value <= 1000) return value.toLocaleString("en-US", { maximumFractionDigits: 0 });
    if (value < 1000000) return `${Math.floor((value / 1000) * 10) / 10}k`;
    if (value >= 1000000) return `${Math.floor((value / 1000000) * 10) / 10}m`;
    return value.toString();
};

interface FormatNumberOptions {
    caps?: [number, number];
    character?: string;
    decimals?: number;
}

export const formatDecimalNumber = (value?: number | null, options: FormatNumberOptions = {}): string => {
    const { caps = [100000, 1000000], character = "<", decimals = 0 } = options;

    if (value == null && value == undefined) return "N/A";
    if (value <= caps[0]) return value.toLocaleString("en-US", { maximumFractionDigits: 0 });
    if (value < caps[1])
        return `${character}${(value / 1000).toLocaleString("en-US", { maximumFractionDigits: decimals })}k`;
    if (value >= 1000000)
        return `${character}${(value / 1000000).toLocaleString("en-US", { maximumFractionDigits: decimals })}m`;
    return value.toString();
};

export const formatStripeAmount = (amount: number, fraction = 2) =>
    amount.toLocaleString("en", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
    });

const personSummaries: string[] = [SummaryCategoryField.JOB_TITLE, SummaryCategoryField.SENIORITY];
export const getSummaryPercentage = (
    value: number,
    topSummary: ChartSchema,
    estimate?: AudienceShapeHeuristicsSchema,
): string => {
    const audienceEstimateTotal = personSummaries.includes(topSummary?.category ?? "")
        ? estimate?.people_count
        : estimate?.companies_count;

    // Soon we're going to be able to always get from estimate, this is temporary until we get estimate response
    const total: number = audienceEstimateTotal ?? 1;
    let result = (value * 100) / total;
    let prefix = "";
    if (result > 0 && result < 0.1) {
        prefix = "<";
        result = 0.1;
    } else if (result < 100 && result > 99.9 && result !== total) {
        prefix = ">";
        result = 99.9;
    }
    return prefix + result.toFixed(1) + "%";
};

export const getQuickSummaryPercentage = (topSummary: ChartSchema, value: number): string => {
    const total = topSummary.total ?? 1;
    let result = (value * 100) / total;
    let prefix = "";
    if (result > 0 && result < 0.1) {
        prefix = "<";
        result = 0.1;
    } else if (result < 100 && result > 99.9 && result !== total) {
        prefix = ">";
        result = 99.9;
    }
    return prefix + result.toFixed(1) + "%";
};
