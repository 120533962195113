/* eslint-disable react-hooks/exhaustive-deps */
import CheckmarkIcon from "@/assets/icons/checkmark-outline.svg?react";
import PlusIcon from "@/assets/icons/plus-circle.svg?react";
import MinusIcon from "@/assets/icons/minus-circle.svg?react";
import EnterIcon from "@/assets/icons/enter.svg?react";
import React, { useEffect, useMemo, useState } from 'react';
import { SummaryCriteriaChangeManagerResponse } from "@/hooks/useSummaryCriteriaChangeManager";
import { Button } from "../Button/Button";
import cn from "classnames";

type SummaryCriteriaChangeControllerProps = {
    changeStateManager: SummaryCriteriaChangeManagerResponse;
}

export const SummaryCriteriaChangeController = ({
    changeStateManager,
}: SummaryCriteriaChangeControllerProps) => {
    const { updateSelected, confirmSummaryCriteriaChange, selectionState } = changeStateManager;

    const exclusion = useMemo(() => selectionState.filter(f => f.type === "Exclude"), [selectionState])
    const inclusion = useMemo(() => selectionState.filter(f => f.type === "Include"), [selectionState])

    const cancel = () => {
        updateSelected([]);
    }

    const runNow = () => {
        confirmSummaryCriteriaChange();
    }
    const [keyPressed, setKeyPressed] = useState('');
    useEffect(() => {
        const handleKeyDown = (event: { key: string; }) => {
            setKeyPressed(event.key);
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (!selectionState.length) setTimeout(() => setKeyPressed(''), 100);
    }, [selectionState]);

    const pending = useMemo(() => {
        return (
            <div className="mb-2 ml-2 p-4 w-[400px] text-[14px] bg-ui-900 rounded-lg text-sm text-white">
                <div className="flex w-full">
                    <div className="font-semibold text-[16px] ">
                        Changing Criteria
                    </div>
                </div>
                <div className="text-white mt-4">
                    <div className="flex gap-1">Selected for: {inclusion.length > 0 && <div className="flex gap-1">
                        <PlusIcon className="text-[#00B359]" /> inclusion: {inclusion.length};
                    </div>} {exclusion.length > 0 && <div className="flex gap-1">
                        <MinusIcon className="text-[#FF4D4D]" /> exclusion: {exclusion.length};
                    </div>} </div>
                </div>
                <div className="flex w-full mt-3">
                    <div className="ml-auto flex gap-3">
                        <Button
                            variant="basic"
                            size="custom"
                            className="ml-auto cursor-pointer text-ui-300 flex gap-1"
                            onClick={cancel}
                        >
                            Cancel<div className={cn("border border-ui-200 text-ui-300 rounded-md px-1", {
                                "bg-white text-ui-200": keyPressed === "Escape"
                            })}>Esc</div>
                        </Button>
                        <Button
                            variant="basic"
                            size="custom"
                            className="ml-auto cursor-pointer text-green-100 flex gap-1"
                            onClick={runNow}
                        >
                            <CheckmarkIcon /> Estimate Now <div className={cn("border border-ui-200 text-ui-300 rounded-md flex gap-1 px-1 items-center", {
                                "bg-white text-ui-200": keyPressed === "Enter"
                            })}><EnterIcon />Enter</div>
                        </Button>
                    </div>
                </div>
            </div >);
    }, [selectionState]);

    const handleKeyDown = () => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (selectionState.length === 0) return;

            if (event.key === "Escape") {
                cancel();
            } else if (event.key === "Enter") {
                runNow();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    };

    React.useEffect(() => handleKeyDown(), [selectionState]);

    return <div
        data-visible={selectionState.length > 0}
        className="fixed transition duration-300 ease-in-out bottom-0 left-0 translate-y-full data-[visible=true]:translate-y-0 z-[300]">
        {pending}
    </div>;
};
