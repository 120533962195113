import { CheckIcon } from "@radix-ui/react-icons";

import Checkbox from "@/components/atoms/Checkbox/Checkbox.tsx";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { Destination } from "@/types/audience";
import DestinationConnectLink from "./DestinationConnectLink";
import { ConnectionError } from "@/types/integrations";

export interface DestinationStatusProps {
    service: Destination;
    selected: boolean;
    loading?: boolean;
    disabled?: boolean;
    connected?: boolean;
    error?: ConnectionError;
}

export const DestinationStatus = ({
    service,
    loading,
    connected,
    disabled,
    selected,
    error,
}: DestinationStatusProps) => {
    if (loading) return <Skeleton className="h-5 w-5 rounded-xl" />

    if (!connected && !disabled)
        return <DestinationConnectLink text="Connect" service={service}/>

    if (disabled && selected)
        return <CheckIcon className="w-6 h-6 text-primary" />

    if (!loading && !disabled && !error) return (
        <Checkbox
            aria-label={service}
            checked={selected}
        />
    )

    return null;
}
