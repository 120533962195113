import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownMenu, DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { DropdownMenuContent, DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { ChevronDownIcon } from "@radix-ui/react-icons";

import { AppState } from "@/store";
import { setFieldMappings } from "@/store/csv-import";
import { CsvMappingFields } from "@/types/csv";
import { TableRow, TableCell } from "@/components/atoms/Table";
import { groupsConfig } from "@/utils/csv";

import { CsvDataSample } from "./CsvDataSample";

const fields: Record<CsvMappingFields, string> = {
    email: "Email",
    lastName: "Last Name",
    linkedinUrl: "LinkedIn",
    firstName: "First Name",
    companyDomain: "Company Domain",
};

export const CsvFieldMappingRow = ({ field, samples = [] }: { field: CsvMappingFields, samples?: string[] }) => {
    const { headers = [], fieldMappings, entityType } = useSelector((state: AppState) => state["csv-import"]);

    const dispatch = useDispatch();

    const updateMappings = (field: CsvMappingFields, value: string) => {
        dispatch(setFieldMappings({ ...fieldMappings!, [field]: value }));
    };

    const selected = fieldMappings[field];

    const fieldName = fields[field];

    const mapped = Object.values(fieldMappings);
    const options = headers?.filter(value => !mapped.includes(value));

    const required = useMemo(() => {
        const groupConfig = groupsConfig[entityType];

        if (groupConfig.or.includes(field)) return false;
        if (groupConfig.and?.includes(field)) return !fieldMappings[field];
    }, [entityType, field, fieldMappings]);

    return (
        <TableRow className="border-ui-200 border-opacity-20 !border-b">
            <TableCell className="!px-2 !py-1 w-[212px]">
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <div
                            aria-label="select object"
                            className="bg-white flex items-center p-2 border border-ui-300/[0.32] group rounded-md h-[36px] cursor-pointer aria-expanded:outline aria-expanded:outline-2 aria-expanded:outline-blue-300 aria-expanded:border-0"
                        >
                            <span className="text-14 text-ui-700">{fieldMappings[field]}</span>
                            <ChevronDownIcon className="ml-auto transition-transform duration-200 group-aria-expanded:rotate-180" />
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        align="start"
                        className="bg-white max-h-[400px] overflow-y-auto border-0 data-[side=top]:shadow-dropdown-t data-[side=bottom]:shadow-dropdown-b w-[var(--radix-dropdown-menu-trigger-width)]"
                    >
                        {options?.map((item: string) => (
                            <DropdownMenuItem
                                key={item}
                                aria-selected={item === selected}
                                className="hover:bg-blue-50 px-4 py-2 hover:text-blue-800 hover:cursor-pointer aria-selected:text-blue-800 text-14"
                                onSelect={() => updateMappings(field, item)}
                            >
                                {item}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            </TableCell>
            <TableCell className="!px-4 !py-3 w-[212px]">
                {fieldName} {required && <span className="text-orange-700 text-xs">required</span>}
            </TableCell>
            <TableCell className="!px-4 !py-3 text-ui-300 decoration-dotted truncate w-[212px]">
                <CsvDataSample samples={samples} />
            </TableCell>
        </TableRow>
    );
};
