import { Option } from "@/components/organisms/FilterMultiSelect/FilterMultiSelect";
import { hardcodedFilterFieldValuesMap, isDepartment, isLocation } from "@primer/filters/configs";
import {
    AddressLocation,
    Department,
    FilterConfig,
    FilterDataTypes,
    SourceCriteriaFilterValue,
} from "@primer/filters/types";
import { get } from "lodash";

export const startCaseWords = (str: string) => {
    const symbols = "/ ";
    // Construct a regular expression pattern to match any of the symbols
    const pattern = new RegExp(`([${symbols}])([^${symbols}]*)`, "g");

    // Capitalize the first letter of the string
    const capitalizedString = str.charAt(0).toUpperCase() + str.slice(1);

    // Replace the pattern occurrences while retaining the delimiters
    return capitalizedString.replace(pattern, (_, delimiter, word) => {
        // Capitalize the first character of each word
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        // Return the original delimiter along with the capitalized word
        return delimiter + capitalizedWord;
    });
};

export const parseValue = (value: string | number | AddressLocation | Department): string => {
    if (isLocation(value)) {
        return [value.city, value.state, value.country].filter(Boolean).join("|");
    }
    if (isDepartment(value)) {
        return [value.primary_department, value.primary_sub_department].filter(Boolean).join("|");
    }

    return value?.toString();
};

export const parseFilterName = (
    selectedFilterField: Partial<FilterConfig> | undefined,
    { label, value, category }: SourceCriteriaFilterValue | Option,
    isRepoV2Enabled = false,
): string => {
    if (selectedFilterField?.pureValue && value) return parseValue(value);

    return parseFilterValueLabel(
        value,
        label,
        selectedFilterField?.identifier,
        selectedFilterField?.dataType,
        isRepoV2Enabled,
        category,
    );
};

export const parseFilterValueLabel = (
    value: string | number | AddressLocation | Department,
    label?: string,
    field?: string,
    dataType = FilterDataTypes.STRING,
    isRepoV2Enabled = false,
    category?: string,
): string => {
    const hardcodedValue =
        !isRepoV2Enabled &&
        dataType === FilterDataTypes.STRING &&
        field &&
        get(hardcodedFilterFieldValuesMap, [field, String(value)]);

    if (isRepoV2Enabled && isDepartment(value)) {
        return [label, category].filter(Boolean).join(", ");
    }

    return hardcodedValue || label || startCaseWords(value.toString());
};
