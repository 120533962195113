import ClickAwayListener from "react-click-away-listener";
import { KeyboardEventHandler, useEffect, useState } from "react";
import EditIcon from "@/assets/icons/edit.svg?react";
import { Input } from "@/components/atoms/Input/Input.tsx";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";

interface AudienceNameProps {
    name: string;
    disabled: boolean;
    loading?: boolean;
    onNameChange: (name: string) => void;
}

export const AudienceName = ({ name, disabled, loading = false, onNameChange }: AudienceNameProps) => {
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [value, setValue] = useState("");

    useEffect(() => setValue(name), [name]);

    useEffect(() => {
        if(isInvalid && value !== "") setIsInvalid(false);
    }, [value, isInvalid])

    const saveChanges = () => {
        if (isInEditMode) {
            onNameChange(value);
            setIsInEditMode(false);
        }
    };

    const discardChanges = () => {
        if (isInEditMode) {
            setIsInEditMode(false);
            setValue(name);
        }
    };

    const goToEditMode = () => {
        if (!disabled) {
            setIsInEditMode(true);
        }
    };

    const onClickAway = () => {
        if(value !== "") saveChanges();
        else discardChanges();
    }

    const handleKeyDown: KeyboardEventHandler = event => {
        const { key } = event;

        switch (key) {
            case "Enter":
                if(value === "") setIsInvalid(true);
                else saveChanges();
                break;
            case "Escape":
                discardChanges();
                break;
            default:
                break;
        }
    };

    if (loading) return <Skeleton className={"h-6 w-3/4 rounded-xl"} />;

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <div className="group flex items-center flex-1 min-w-0">
                {isInEditMode ? (
                    <Input
                        autoFocus
                        aria-label="audience name input"
                        name="audience name input"
                        placeholder="Enter audience name"
                        disabled={disabled}
                        value={value}
                        aria-invalid={isInvalid}
                        onChange={e => setValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="w-[520px]"
                        role="textbox"
                    />
                ) : (
                    <span
                        className="flex items-center min-w-0 mr-4"
                        onClick={goToEditMode}
                        tabIndex={0}
                        onKeyDown={event => event.key === "Enter" && goToEditMode()}
                    >
                        <h1 className="text-2xl text-ui-900 font-normal text-nowrap text-ellipsis overflow-hidden">
                            {name}
                        </h1>
                        {!disabled && <EditIcon className="invisible group-hover:visible ml-2" />}
                    </span>
                )}
            </div>
        </ClickAwayListener>
    );
};
