import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import App from "./App.tsx";
import { store } from "@/store";

import "../instrumentation.ts"
import "./index.css";
import { Toaster } from "@/components/atoms/Toast/Toaster.tsx";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <Provider store={store}>
        <Toaster />
        <Elements stripe={stripePromise}>
            <App />
        </Elements>
    </Provider>,
);
