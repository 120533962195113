import { OrganizationSwitcher, UserButton, useOrganization, useUser } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

export const UserProfile = () => {
    const posthog = usePostHog();
    const { user } = useUser();
    const { membership } = useOrganization();
    const role = (membership?.role?.match(/org:(.+)/) || [])[1] || "";

    useEffect(() => {
        return () => {
            posthog.reset();
        };
    }, [posthog]);

    return (
        <div className="flex flex-col mb-10 px-6 whitespace-normal">
            <div className="w-[160px] flex mb-1.5 items-center transition-all group">
                <UserButton />

                <div className="ml-1.5 mt-1 w-[115px]">
                    <p className="text-14 text-white truncate">{user?.firstName || user?.username || "User"}</p>
                    <p data-testid="role" className="text-10 opacity-60 text-blue-300 capitalize">
                        {role}
                    </p>
                </div>
            </div>
            <OrganizationSwitcher hidePersonal />
        </div>
    );
};
