import { useMemo } from "react";
import { ChevronDownIcon } from "@radix-ui/react-icons";

import FilterIcon from "@/assets/icons/filter.svg?react";
import { Button } from "@/components/atoms/Button/Button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/atoms/DropdownMenu/DropdownMenu";
import pluralize from "pluralize";

export enum FirstPartyTab {
    "ALL" = "all",
    "ACCOUNT" = "account",
    "CONTACT" = "contact",
    "LEAD" = "lead",
}

const selectObjectItems = [
    {
        value: FirstPartyTab.ALL,
        description: "Show all",
    },
    {
        value: FirstPartyTab.ACCOUNT,
        description: "Account",
    },
    {
        value: FirstPartyTab.CONTACT,
        description: "Contact",
    },
    {
        value: FirstPartyTab.LEAD,
        description: "Lead",
    },
];

export interface FirstPartyObjectSelectPanelProps {
    selectedCategory: FirstPartyTab;
    setSelectedCategory: (category: FirstPartyTab) => void;
    onSelectAllObjects: (category: FirstPartyTab) => void;
}

const FirstPartyObjectSelectPanel = ({
    selectedCategory,
    setSelectedCategory,
    onSelectAllObjects,
}: FirstPartyObjectSelectPanelProps) => {
    const selectedCategoryLabel = useMemo(
        () => selectObjectItems.find(o => o.value === selectedCategory)?.description,
        [selectedCategory],
    );

    return (
        <div className="flex items-center justify-between bg-ui-50 border-y border-ui-300/[.20] py-1.5 px-5">
            <div className="flex items-center">
                <span className="flex text-14 text-ui-300 mr-3">
                    <FilterIcon className="mr-1" />
                    <span>Object</span>
                </span>
                <div className="w-[200px]">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <div
                                aria-label="select object"
                                className="bg-white flex items-center p-2 border border-ui-300/[0.32] group rounded-md h-[36px] cursor-pointer aria-expanded:outline aria-expanded:outline-2 aria-expanded:outline-blue-300 aria-expanded:border-0"
                            >
                                <span className="text-14 text-ui-700">{selectedCategoryLabel}</span>
                                <ChevronDownIcon className="ml-auto transition-transform duration-200 group-aria-expanded:rotate-180" />
                            </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                            align="start"
                            className="bg-white border-0 data-[side=top]:shadow-dropdown-t data-[side=bottom]:shadow-dropdown-b w-[var(--radix-dropdown-menu-trigger-width)]"
                        >
                            {selectObjectItems.map(item => (
                                <DropdownMenuItem
                                    key={item.value}
                                    aria-selected={item.value === selectedCategory}
                                    className="hover:bg-blue-50 px-4 py-1 hover:text-blue-800 aria-selected:text-blue-800 text-14"
                                    onSelect={() => setSelectedCategory(item.value)}
                                >
                                    {item.description}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
            {selectedCategory !== FirstPartyTab.ALL && (
                <Button variant="secondary" onClick={() => onSelectAllObjects(selectedCategory)}>
                    Select All {pluralize(selectedCategoryLabel!)}
                </Button>
            )}
        </div>
    );
};

export default FirstPartyObjectSelectPanel;
