import { api } from "@/api";
import { CsvFile, FieldMappings } from "@/types/csv";
import { GetImportedDatasetsRequest, getImportedDatasetsResponse } from "@/types/api";

export interface CreateDatasetResponse {
    name: string;
    datasetId: string;
    rawPresignedUrl: string;
    translatedPresignedUrl: string;
}

export interface CreateDatasetRequest {
    name: string;
    inputCount: number;
    validCount: number;
    invalidCount: number;
    fieldMappings: FieldMappings;
    entityType: "person" | "company";
}

export interface ImportCsvRequest {}

export const csvApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getDatasets: builder.query<getImportedDatasetsResponse, GetImportedDatasetsRequest>({
            query: params => {
                const { limit, offset } = params;
                const searchParams = new URLSearchParams();

                if (limit) searchParams.set("limit", String(limit));
                if (offset) searchParams.set("offset", String(offset));

                return `/imported-datasets?${searchParams.size ? searchParams.toString() : ""}`;
            },
            providesTags: ["csv-files"],
        }),
        getDataset: builder.query<CsvFile, { id: string }>({
            query: ({ id }) => `/imported-datasets/${id}`,
        }),

        triggerMappings: builder.mutation<CsvFile, { id: string }>({
            query: ({ id }) => ({ url: `/imported-datasets/${id}/import`, method: "POST" }),
        }),

        importDataset: builder.mutation<CreateDatasetResponse, CreateDatasetRequest>({
            query: params => ({
                url: "/imported-datasets",
                method: "POST",
                body: { ...params },
            }),
            invalidatesTags: ["csv-files"],
        }),
    }),
});

export const { useGetDatasetsQuery, useGetDatasetQuery, useImportDatasetMutation, useTriggerMappingsMutation } =
    csvApiSlice;
