export type Price = {
    id: string;
    audience_limit: number;
    trial_audience_limit?: number;
};

export type ScaleTier = {
    yearly: Price;
};

export type ScalePlan = {
    product_id: string;
    tier1: ScaleTier;
    tier2: ScaleTier;
    tier3: ScaleTier;
    tier4: ScaleTier;
    tier5: ScaleTier;
};

export interface SubscriptionProducts {
    default: Price;
    grow: {
        product_id: string;
        free: {
            monthly: Price;
        };
        tier1: {
            monthly: Price;
            yearly: Price;
        };
        tier2: {
            monthly: Price;
            yearly: Price;
        };
    };
    scale: ScalePlan;
    audiences: {
        product_id: string;
        onetime: Price;
        monthly: Price;
        yearly: Price;
    };
}

export enum SubscriptionStatus {
    ACTIVE = "active",
    UNPAID = "unpaid",
    PAUSED = "paused",
    TRIALING = "trialing",
    CANCELED = "canceled",
    PAST_DUE = "past_due",
    INCOMPLETE = "incomplete",
    INCOMPLETE_EXPIRED = "incomplete_expired",
}

export enum SubscriptionInterval {
    DAY = "day",
    WEEK = "week",
    MONTH = "month",
    YEAR = "year",
}

export interface PaymentMethod {
    id: string;
    card: string;
    last4: string;
    type: string;
    exp_month: number;
    exp_year: number;
    billing_details_email: string;
    billing_details_name: string;
}

export interface SubscriptionItems {
    plan_id: string;
    plan_name: string;
    plan_amount: number;
    product_id: string;
    product_name: string;
    quantity: number;
    price: string;
    id: string;
}

export interface Subscription {
    id: string;
    customer: string;
    plan: string;
    tier: string;
    status: SubscriptionStatus;
    interval: SubscriptionInterval;
    currency: string;
    startDate: number;
    trialEnd: number;
    trialStart: number;
    currentPeriodEnd: number;
    currentPeriodStart: number;
    metadata: Record<string, string>;
    paymentMethod: PaymentMethod;
    subscription_items: SubscriptionItems[];
    cancelAt: number | null;
    cancelAtPeriodEnd: boolean;
}

export interface Invoice {
    id: string;
    organization_id: string;
    invoice_number: string;
    created_at: string;
    updated_at: string;
    pdf_url: string;
    amount: number;
    status: InvoiceStatusEnum;
    recipient_email: string;
    recipient_name: string;
}

export enum InvoiceStatusEnum {
    DRAFT = "draft",
    OPEN = "open",
    PAID = "paid",
    VOID = "void",
    UNCOLLECTIBLE = "uncollectible",
}

export interface GetInvoicesResponse {
    paginationDetails: {
        totalCount: number;
        skippedCount: number;
        pageSize: number;
    };
    items: Invoice[];
}

export interface GetInvoicesRequest {
    offset: number;
    limit: number;
}

export interface SubscriptionUpdateItem {
    id: string;
    quantity: number;
    price: string;
}
