import React, { useMemo } from "react";
import posthog from "posthog-js";
import { uniqWith, isEqual, omit } from "lodash";

import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { ExtraChartValueSchema } from "@/types/audience";
import { SummaryBoxProps } from "./SummaryBox";
import { SelectedForExclusionItem } from "@/hooks/useSummaryCriteriaChangeManager";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import RowContent from "./RowContent";
import RowValueDisplay from "./RowValueDisplay";

export type SummaryRowProps = {
    summaryValue: ExtraChartValueSchema;
} & SummaryBoxProps;

const SummaryRow: React.FC<SummaryRowProps> = (props: SummaryRowProps) => {
    const { summaryValue, summary, summaryChangeStateManager, expand, allowExpand, isExpanding, criteriaAssetsManager } = props;
    const { selectionState, updateSelected } = summaryChangeStateManager;
    const { name, category } = summary;
    const { label, percentage, value, key } = summaryValue;
    const selectedItem = useMemo(() => selectionState.find(s => s.category === (category ?? name) && s.value === key), [category, key, name, selectionState]);
    const [isSelectedForExclusion, setIsSelectedForExclusion] = React.useState<boolean>(
        !!selectedItem && selectedItem.type === 'Exclude'
    );
    const [isSelectedForInclusion, setIsSelectedForInclusion] = React.useState<boolean>(
        !!selectedItem && selectedItem.type === 'Include'
    );

    React.useEffect(() => {
        const changedValue: SelectedForExclusionItem = {
            category: category ?? name!,
            value: key!,
            label,
            type: isSelectedForExclusion ? 'Exclude' : 'Include'
        };
        const comparableChangedValue = omit(changedValue, "type");
        if (isSelectedForExclusion || isSelectedForInclusion) {
            updateSelected(uniqWith([
                ...selectionState,
                changedValue,
            ], isEqual));
        } else if (selectionState.some(s => isEqual(omit(s, "type"), comparableChangedValue))) {
            updateSelected(selectionState.filter(p => !isEqual(omit(p, "type"), comparableChangedValue)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectedForExclusion, isSelectedForInclusion, summary, key]);

    React.useEffect(() => {
        setIsSelectedForExclusion(!!selectedItem && selectedItem.type === "Exclude");
        setIsSelectedForInclusion(!!selectedItem && selectedItem?.type === "Include");
    }, [selectedItem]);

    const isOther = label === "Other";
    const isUnspecified = !!label && label?.startsWith("Unspecified");

    const chartBackgroundColor = React.useMemo(() => {
        const width = Math.max(2, percentage ?? 0.5) + "%";
        if (isSelectedForInclusion) return `linear-gradient(to right, #cbdcde ${width}, #E9F4F5 0%)`;
        if (isSelectedForExclusion) return `linear-gradient(to right, #F9E3E4 ${width}, #FAEEEE 0%)`;

        if (!percentage) return `linear-gradient(to right, #F3F5F6 2%, transparent 0%)`;

        return `linear-gradient(to right, #3366FF1A ${width}, transparent 0%)`;
    }, [isSelectedForInclusion, isSelectedForExclusion, percentage]);

    const internalContent = React.useMemo(() => {
        const parsedLabel = isOther && isExpanding
            ? <Skeleton className="h-3 rounded-xl bg-gray-300 w-[100px]" />
            : label;

        const onExcludeClick = () => {
            if (!isOther) {
                const action = isSelectedForExclusion ? "Unselected" : "Selected";
                posthog.capture(`${action} for exclusion "${summary.name}" item "${label}"`);
                setIsSelectedForExclusion(prev => !prev);
            }
        };
        const onIncludeClick = () => {
            if (isOther && allowExpand && !isExpanding) {
                posthog.capture(`Expanded summary "${summary.name}"`);
                expand();
            } else if (!isOther) {
                const action = isSelectedForInclusion ? "Unselected" : "Selected";
                posthog.capture(`${action} for inclusion "${summary.name}" item "${label}"`);
                setIsSelectedForInclusion(prev => !prev);
            }
        };

        return <RowContent
            onExcludeClick={onExcludeClick}
            onIncludeClick={onIncludeClick}
            chartBackgroundColor={chartBackgroundColor}
            parsedLabel={parsedLabel}
            parsedValue={<RowValueDisplay isOther={isOther} isExpanding={isExpanding} value={value} percentage={percentage} />}
            isOther={isOther}
            preventActions={criteriaAssetsManager.preventCriteriaChange}
            allowExpand={allowExpand}
            isExpanding={isExpanding}
            isUnspecified={isUnspecified}
            isSelectedForExclusion={isSelectedForExclusion}
            isSelectedForInclusion={isSelectedForInclusion}
            name={name}
        />
    }, [isOther, isExpanding, label, chartBackgroundColor, value, percentage, criteriaAssetsManager.preventCriteriaChange, allowExpand, isUnspecified, isSelectedForExclusion, isSelectedForInclusion, name, summary.name, expand]);

    const tooltipedContent = React.useMemo(() => (
        <Tooltip
            content={"Expand"}
            className="max-w-56 text-xs"
            side={isOther ? "bottom" : "right"}
        >
            {internalContent}
        </Tooltip>
    ), [internalContent, isOther]);

    if (!isOther || !allowExpand) return internalContent;

    return tooltipedContent;
};
export default SummaryRow;