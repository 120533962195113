import { useClerk } from "@clerk/clerk-react";
import { Button, ButtonProps } from "@/components/atoms/Button/Button";

export const TalkToSalesButton = (props: ButtonProps) => {
    const { user, organization } = useClerk() || {};

    const { firstName, lastName, primaryEmailAddress } = user || {};
    const email = primaryEmailAddress?.emailAddress || "";

    const onClick = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window?.ChiliPiper.submit("sayprimer", "website-router-book-a-demo", {
            lead: {
                email,
                firstName,
                lastName,
                organization: organization!.name,
            },
        });
    };

    return (
        <Button onClick={onClick} {...props}>
            Talk to Sales
        </Button>
    );
};
