import cn from "classnames";

import { ErrorMessage, FacebookTosErrorMessage } from "../ErrorMessage";
import { ConnectionError } from "@/types/integrations";
import Checkbox from "@/components/atoms/Checkbox/Checkbox";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";

export const SelectAccount = ({
    id,
    error,
    checked,
    retryLoading,
    retryFailed,
    onCheckClick,
    onRetryClick,
    provider_tos_accepted,
    provider_organization_name,
}: {
    id: string;
    checked: boolean;
    retryLoading: boolean;
    retryFailed: boolean;
    onCheckClick: () => void;
    onRetryClick: () => void;
    error?: ConnectionError;
    provider_tos_accepted: boolean;
    provider_organization_name: string | null;
}) => (
    <div
        className={cn("w-full p-4 rounded-lg border-[1.5px] cursor-pointer", {
            "border-blue-800 bg-blue-50": checked,
            "border-opacity-30 border-ui-200": !checked,
        })}
        onClick={onCheckClick}
    >
        <div className="flex items-center justify-between">
            <span className="text-default text-sm leading-[18px]">{provider_organization_name ? `${id} - ${provider_organization_name}` : id}</span>
            {!error ? (
                <Checkbox asRadio={true} checked={checked} onClick={onCheckClick} />
            ) : (
                <div className="w-5 h-5 bg-ui-200 rounded-xl border-2 border-ui-300 border-opacity-30" />
            )}
        </div>
        {error && <ErrorMessage message={error.message} redirect={error.redirectUrl} />}
        {(!error && !provider_tos_accepted && checked) && <FacebookTosErrorMessage retryLoading={retryLoading} retryFailed={retryFailed} provider_account_id={id} onRetry={onRetryClick} />}
    </div>
);

export const SelectAccountsSkeleton = () => (
    <>
        <div className="w-full p-4 rounded-lg border-[1.5px] flex items-center justify-between border-opacity-30 border-ui-200">
            <Skeleton className="h-5 w-80 !rounded-xl" />
            <Skeleton className="h-5 w-5 !rounded-full" />
        </div>
        <div className="w-full p-4 rounded-lg border-[1.5px] flex items-center justify-between border-opacity-30 border-ui-200">
            <Skeleton className="h-5 w-80 !rounded-xl" />
            <Skeleton className="h-5 w-5 !rounded-full" />
        </div>
        <div className="w-full p-4 rounded-lg border-[1.5px] flex items-center justify-between border-opacity-30 border-ui-200">
            <Skeleton className="h-5 w-80 !rounded-xl" />
            <Skeleton className="h-5 w-5 !rounded-full" />
        </div>
    </>
);
