import cn from "classnames";
import IndentIcon from "@/assets/icons/indent.svg?react";
import CloseIcon from "@/assets/icons/close-red.svg?react";
import SyncedIcon from "@/assets/icons/circle-check.svg?react";
import ClockIcon from "@/assets/icons/clock.svg?react";
import InterruptedIcon from "@/assets/icons/interrupted-gray.svg?react";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar";
import { AudienceStatus, Destination } from "@/types/audience";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { AudienceSync } from "@/types/api";
import { getMatchRate } from "@/utils/audience";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";

interface Props {
    eventName: string;
    duration: string | JSX.Element;
    details: string | JSX.Element | null | undefined;
    success?: boolean;
    inProgress?: boolean;
    error?: string | null;
    withBorderBottom?: boolean;
    adAudienceUrl?: string | null;
    stats?: AudienceSync["stats"];
    isLatest?: boolean;
    peopleCount?: number | null;
    destination?: Destination;
}

export const RunEvent = ({
    eventName,
    duration,
    details,
    success,
    inProgress,
    error,
    withBorderBottom,
    adAudienceUrl,
    stats,
    isLatest,
    peopleCount,
    destination,
}: Props) => {
    const baseClass = cn("flex items-center px-4 py-3 border-l border-ui-300/[.32]", {
        "border-b": withBorderBottom,
    });
    const bgClass = !!error && "bg-error-background";
    const isActualMatchRateEnabled = useFeatureFlag(FeatureFlagsEnum.MATCH_RATES_ACTUAL);

    const matchedDestination = {
        "facebook": (
            <div className="flex flex-row items-center">
                {isLatest && !stats?.finalized && <ClockIcon className="mr-0.5 text-ui-300" />}
                {!isLatest && !stats?.finalized && <InterruptedIcon className="mr-0.5" />}
                <Tooltip
                    hidden={stats?.finalized}
                    content={isLatest ? "Audience matching in progress" : "Matching stopped due to a more recent run"}
                >
                    <>
                        <span className="text-ui-700">Meta: </span>
                        <span
                            className={cn("text-sm mr-1", {
                                "font-medium text-ui-900": stats?.finalized,
                                "text-ui-300": !stats?.finalized,
                            })}
                        >
                            {getMatchRate(Destination.META, stats?.adPlainStats, peopleCount || 0)}
                        </span>
                    </>
                </Tooltip>
            </div>
        ),
        "linkedin": (
            <div className="flex flex-row items-center">
                {isLatest && !stats?.finalized && <ClockIcon className="mr-0.5 text-ui-300" />}
                {!isLatest && (!stats || !stats?.finalized) && <InterruptedIcon className="mr-0.5" />}
                <Tooltip
                    hidden={stats?.finalized}
                    content={isLatest ? "Audience matching in progress" : "Matching stopped due to a more recent run"}
                >
                    <>
                        <span className="text-ui-700">LinkedIn: </span>
                        <span
                            className={cn("text-sm mr-1", {
                                "font-medium text-ui-900": stats?.finalized,
                                "text-ui-300": !stats?.finalized,
                            })}
                        >
                            {getMatchRate(Destination.LINKEDIN, stats?.adPlainStats, peopleCount || 0)}
                        </span>
                    </>
                </Tooltip>
            </div>
        ),
        "google": (
            <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center">
                    {isLatest && !stats?.finalized && <ClockIcon className="mr-0.5 text-ui-300" />}
                    {!isLatest && !stats?.finalized && <InterruptedIcon className="mr-0.5" />}
                    <Tooltip
                        hidden={stats?.finalized}
                        content={
                            isLatest ? "Audience matching in progress" : "Matching stopped due to a more recent run"
                        }
                    >
                        <>
                            <span className="text-ui-700">Google Search: </span>
                            <span
                                className={cn("text-sm mr-1", {
                                    "font-medium text-ui-900": stats?.finalized,
                                    "text-ui-300": !stats?.finalized,
                                })}
                            >
                                {getMatchRate(Destination.GOOGLE, stats?.adPlainStats, peopleCount || 0)}
                            </span>
                        </>
                    </Tooltip>
                </div>
                <div className="flex flex-row items-center">
                    {isLatest && !stats?.finalized && <ClockIcon className="mr-0.5 text-ui-300" />}
                    {!isLatest && !stats?.finalized && <InterruptedIcon className="mr-0.5" />}
                    <Tooltip
                        hidden={stats?.finalized}
                        content={
                            isLatest ? "Audience matching in progress" : "Matching stopped due to a more recent run"
                        }
                    >
                        <>
                            <span className="text-ui-700">Youtube, Display: </span>
                            <span
                                className={cn("text-sm mr-1", {
                                    "font-medium text-ui-900": stats?.finalized,
                                    "text-ui-300": !stats?.finalized,
                                })}
                            >
                                {getMatchRate("display", stats?.adPlainStats, peopleCount || 0)}
                            </span>
                        </>
                    </Tooltip>
                </div>
            </div>
        ),
    };

    return (
        <>
            <div className={cn(baseClass, "border-l-0")}></div>
            <div className={cn(baseClass, "flex items-center space-x-1.5")}>
                <IndentIcon title="Run row event indent icon" aria-label="Run row event indent icon" />
                <span className="truncate">{eventName}</span>
            </div>
            <div className={cn(baseClass)}>{duration}</div>
            <div className={cn(baseClass, bgClass, "flex items-center space-x-2")}>
                <span className="min-w-[20px] min-h-[20px]">
                    {error && (
                        <CloseIcon
                            className="w-5 h-5"
                            title={AudienceStatus.INCOMPLETE}
                            aria-label={AudienceStatus.INCOMPLETE}
                        />
                    )}
                    {success && <SyncedIcon title={AudienceStatus.SYNCED} aria-label={AudienceStatus.SYNCED} className="text-green-500" />}
                    {inProgress && <CircularProgressBar size="x-small" />}
                </span>
                <span>{details}</span>
            </div>
            <div className={cn(baseClass)}>
                {isActualMatchRateEnabled && success && adAudienceUrl && (
                    <>
                        {isLatest && !stats ? (
                            <div className="flex flex-row items-center">
                                <CircularProgressBar size="x-small" />
                                <span className="text-sm text-ui-300 ml-1">Matching...</span>
                            </div>
                        ) : (
                            matchedDestination[destination as keyof typeof matchedDestination]
                        )}
                    </>
                )}
            </div>
        </>
    );
};
