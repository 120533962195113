import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import type { AppState } from "@/store";
import { JsonlRecord } from "@/types/csv";
import { getCsvWorker, proxyCallback } from "@/workers";
import { Button } from "@/components/atoms/Button/Button";
import { CsvFileInfo } from "@/components/organisms/csv/CsvFileInfo";
import { ErrorMessages } from "@/components/organisms/csv/ErrorMessages";
import { EntityTypeSelect } from "@/components/organisms/csv/EntityTypeSelect";
import { CsvFieldsMapping } from "@/components/organisms/csv/CsvFieldsMapping";

import {
    setErrors,
    setProgress,
    emptyMappings,
    setEntityType,
    setValidCount,
    setImportError,
    setFieldMappings,
    setValidRecords,
    setValidPercentage,
    setIsValidatingRecords,
} from "@/store/csv-import";
import { useIsValidMapping } from "@/components/organisms/csv/hooks";
import { FilterEntityTypes } from "@primer/filters/types";

export const ImportCsvMapping = () => {
    const { headers, records, fileName, entityType, audienceId, fieldMappings, validPercentage, isValidatingRecords } =
        useSelector((state: AppState) => state["csv-import"]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state: file } = useLocation();

    const updateEntityType = (type: FilterEntityTypes) => dispatch(setEntityType(type));

    const isValidMapping = useIsValidMapping();

    useEffect(() => {
        if (!fileName || !records || !headers) navigate(`/audiences/${audienceId || "new"}`);
    }, []);

    useEffect(() => {
        if (isValidMapping) {
            const validator = getCsvWorker();

            validator.onFinish(
                proxyCallback((validRecords: JsonlRecord[], errors: string[]) => {
                    dispatch(setErrors(errors));
                    dispatch(setValidRecords(validRecords));
                    dispatch(setValidCount(validRecords.length));
                    dispatch(setIsValidatingRecords(false));
                    dispatch(setValidPercentage(Math.floor((validRecords.length / records!.length) * 100)));
                }),
            );

            validator.onUpdate(
                proxyCallback((progress: number) => {
                    dispatch(setProgress(progress));
                }),
            );

            dispatch(setIsValidatingRecords(true));
            validator.validateData({ entityType: entityType!, fieldMappings: fieldMappings!, records: records! });
        }
    }, [fieldMappings, entityType, dispatch, records, isValidMapping, audienceId]);

    const cancelAction = () => {
        dispatch(setErrors([]));
        dispatch(setProgress(0));
        dispatch(setValidCount(0));
        dispatch(setValidPercentage(0));
        dispatch(setImportError(false));
        dispatch(setFieldMappings(emptyMappings));
        dispatch(setEntityType(FilterEntityTypes.PERSON));
        navigate(`/audiences/${audienceId || "new"}`, { replace: true });
    };

    const importAction = () => {
        if (!isValidMapping) return;
        navigate("/audiences/import-loading", { replace: true, state: { file } });
    };

    const onResetMapping = () => {
        dispatch(setFieldMappings(emptyMappings));
        dispatch(setProgress(0));
        dispatch(setErrors([]));
    }

    const importDisabled = !isValidMapping || !validPercentage || isValidatingRecords;

    return (
        <div className="w-full h-full px-12 py-20 flex flex-col items-center gap-6 overflow-y-auto">
            <h1 className="text-ui-700 text-2xl">Importing CSV</h1>

            <CsvFileInfo />

            <div className="w-[700px] px-8 py-6 bg-white rounded-2xl flex-col items-start">
                <EntityTypeSelect selectedEntityType={entityType} setSelectedEntityType={updateEntityType} />

                <div className="flex justify-between items-center">
                    <span className="text-ui-900 text-lg">Fields mapping</span>
                    <span className="text-blue-800 text-sm cursor-pointer" onClick={onResetMapping}>
                        Reset mappings
                    </span>
                </div>

                <CsvFieldsMapping />

                <ErrorMessages />

                <div className="flex justify-between items-center mt-6">
                    <Button variant={"secondary"} onClick={cancelAction}>
                        Cancel
                    </Button>
                    <Button onClick={importAction} disabled={importDisabled}>
                        Import
                    </Button>
                </div>
            </div>
        </div>
    );
};
