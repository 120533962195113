import { useNavigate } from "react-router-dom";
import FilterIcon from "@/assets/icons/filter.svg?react";
import { PopoverClose } from "@/components/atoms/Popover";

export const ActiveAudiencesAction = ({ onSuccess }: { onSuccess: () => void }) => {
    const navigate = useNavigate();

    const seeActiveAudiences = () => {
        navigate("/audiences?status=running&status=synced&status=incomplete&tooltip=filter");
        onSuccess();
    };

    return (
        <PopoverClose>
            <div onClick={seeActiveAudiences} className="py-4 hover:bg-blue-50 transition-colors cursor-pointer">
                <div className="mt-2 px-6 py-2 space-y-2">
                    <div className="flex gap-1 items-center text-primary">
                        <FilterIcon className="text-primary mb-0.5" />
                        <div className="ml-1 font-medium">See Active Audiences</div>
                    </div>
                    <div className="text-left">
                        Active Audiences include those in synced, running, or incomplete status.
                    </div>
                </div>
            </div>
        </PopoverClose>
    );
};
