import loginDemo from "@/assets/images/hero/callout.png";
import {
    MetaIcon,
    LinkedInIcon,
    GoogleIcon,
    DriveIcon,
    YouTubeIcon,
} from "@/assets/images/hero/destinations";
import {
    PilotIcon,
    VouchIcon,
    CyberHavenIcon,
    VerkadaIcon,
    UserGemsIcon,
} from "@/assets/images/hero/clients";

const SideHeroBanner = ({ login = false }: { login?: boolean }) => {
    const callOut = login ? "Build High Match Rate Audiences" : "Unlock 3 Free Audiences for 30 Days"
    return (
        <div className="flex flex-col h-screen">
            <div className="flex-grow flex justify-center items-center relative overflow-hidden">
                <div className="flex h-full">
                    <div className="absolute flex right-0 top-[10%] h-[80%] w-[calc(100%-120px)] rounded-lg ml-[120px]">
                        <img
                            src={loginDemo}
                            className="h-full max-h-[70vh] rounded-lg object-cover object-left"
                            alt="Demo"
                        />
                    </div>
                    <div className="w-52 bg-blue-850 text-white absolute top-[8%] left-[60%] rounded-lg flex flex-col items-center p-3 text-xs">
                        Get instant audience estimate<br />
                        and projected match rate at<br />
                        major ad platforms
                    </div>
                    <div className="w-28 bg-blue-850 text-white absolute top-[22%] left-[3%] rounded-lg p-3 text-xs">
                        Sync audience<br />
                        to major<br />
                        ad platforms
                    </div>
                    <div className="w-36 bg-blue-850 text-white absolute top-[33%] left-[28%] rounded-lg p-3 text-xs">
                        Add criteria to shape<br />
                        your target audience
                    </div>
                    <div className="w-48 bg-blue-850 text-white absolute top-[30%] left-[52%] rounded-lg p-3 text-xs">
                        Interrogate the audience<br />
                        with summary and preview<br />
                        before it’s built and synced<br />
                    </div>
                    <div className="w-[550px] bg-purple-500 text-white absolute bottom-[12%] left-[5%] rounded-2xl flex flex-col items-center px-5 py-8 text-2xl font-normal text-center">
                        {callOut} <br />
                        and Reach Up to 240M B2B Decision-Makers <br />
                        <div className="flex gap-2">
                            on <MetaIcon /> <LinkedInIcon /> <GoogleIcon /> <DriveIcon /> <YouTubeIcon />
                        </div>
                    </div>
                    <div className="flex flex-col items-center w-full text-center justify-end mb-4">
                        <span className="text-purple-300 mt-4">Trusted by world-class marketing teams at</span>
                        <div className="flex gap-8 items-center">
                            <PilotIcon />
                            <VouchIcon />
                            <CyberHavenIcon />
                            <UserGemsIcon />
                            <VerkadaIcon />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SideHeroBanner;
