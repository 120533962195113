import clsx from "clsx";
import { ReactNode } from "react";

interface Props {
    left?: boolean;
    imgUrl: string;
    children: ReactNode;
}

export const SplitPaneLayout = ({ children, imgUrl, left = false }: Props) => {
    return (
        <main className={clsx("flex h-screen", left && "flex-row-reverse")}>
            <section className="flex flex-1 items-center justify-center overflow-auto">{children}</section>
            <aside className="flex-1 bg-gradient-to-b from-dark-900 via-purple-900 to-purple-600 h-full hidden md:block">
                <img draggable="false" className="object-right object-contain w-full h-full" src={imgUrl} />
            </aside>
        </main>
    );
};
