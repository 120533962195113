import { Price, ScalePlan, SubscriptionProducts } from "@/types/subscription";
import { get } from "lodash";

export const getPriceInfo = (
    products: SubscriptionProducts,
    plan: keyof SubscriptionProducts,
    tier: keyof SubscriptionProducts["grow"] | keyof ScalePlan,
    frequency: "yearly" | "monthly",
): Price | null => {
    return get(products, `${plan}.${tier}.${frequency}`, null);
};
