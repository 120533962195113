import { FunctionComponent, ReactNode, SVGProps } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

type IconType = FunctionComponent<
    SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
>;

interface SidebarLinkProps extends NavLinkProps {
    icon: IconType;
    children: ReactNode;
}

const classnames = "transition-all flex items-center w-[136px] p-2 space-x-2.5 hover:bg-blue-850 hover:rounded";

export const SidebarLink = ({ icon: Icon, children, ...props }: SidebarLinkProps) => (
    <NavLink {...props} className={({ isActive }) => `${classnames} ${isActive ? "bg-primary rounded" : ""} w-full`}>
        <Icon className="w-6 h-6" />
        <span className="text-14">{children}</span>
    </NavLink>
);
