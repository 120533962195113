import { useState } from "react";

import { Modal } from "../Modal";
import { useGetCrmSyncQuery } from "@/api/crm";
import { ModalHeader } from "../Modal/ModalHeader";
import TimerIcon from "@/assets/icons/timer.svg?react";
import { Button } from "@/components/atoms/Button/Button";
import { getNameForService, getTypeForService } from "@/config/integrations";
import { Connection, IntegrationType, Service } from "@/types/integrations";

export const ProcessingDataModal = ({ service, connection }: { service: Service; connection?: Connection }) => {
    const [modalShown, setModalShown] = useState(true);
    const serviceName = getNameForService(service);
    const serviceType = getTypeForService(service);

    const { data: crmSync } = useGetCrmSyncQuery(
        { instanceId: connection?.provider_account_id ?? "" },
        {
            skip: !connection?.provider_account_id || serviceType !== IntegrationType.CRM,
            refetchOnMountOrArgChange: true,
        },
    );
    const { isSyncing, latestSyncJobStatus } = crmSync || {};

    if (!isSyncing || latestSyncJobStatus === "succeeded" || !modalShown) return null;

    const dismiss = () => setModalShown(false);

    return (
        <Modal className="w-[480px]">
            <ModalHeader title={`Processing ${serviceName} Data...`} icon={<TimerIcon />} />

            <div className="text-sm text-ui-700 mt-2">
                {`We are processing your ${serviceName} data and will notify you via email once your filters are ready.`} <br />
                <br />
                This may take a few hours based on your record count. Some data may not be available if no corresponding record is found in our database, but we are continually improving to match your records. <br />
                <br />
            </div>

            <div className="flex w-full justify-end mt-16 gap-4">
                <Button onClick={dismiss} variant={"outline"}>
                    Dismiss
                </Button>
            </div>
        </Modal>
    );
};
