import {
    CardCvcElementComponent,
    CardExpiryElementComponent,
    CardNumberElementComponent,
} from "@stripe/react-stripe-js";

interface Props {
    label: string;
    error: string;
    handleChange: (event: any) => void;
    placeholder?: string;
    Element: CardCvcElementComponent | CardNumberElementComponent | CardExpiryElementComponent;
}

export const StripeInputElement = ({ label, Element, handleChange, error, placeholder }: Props) => {
    const errorPosition = label === "CARD NUMBER" ? "-bottom-6" : "-bottom-10";

    return (
        <div className="relative">
            <label className="text-ui-300 text-10 font-medium capitalize">{label}</label>
            <Element
                onChange={handleChange}
                className={`border border-ui-200 px-2 py-2 rounded-lg w-full ${error && "!border-error-stroke"}`}
                options={{
                    style: {
                        base: {
                            fontSize: "16px",
                            color: "#20292C",
                            "::placeholder": {
                                color: "#8A9FA9",
                            },
                        },
                        invalid: {
                            color: "#B22424",
                        },
                    },
                    placeholder,
                }}
            />
            {error && <div className={`absolute left-0 text-sm text-error mt-1 ${errorPosition}`}>{error}</div>}
        </div>
    );
};
