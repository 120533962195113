import { Time, getTimeDifference } from "@/utils/date";
import { useEffect, useState } from "react";

type UseTimerParams = {
    startTime?: Date;
    endTime?: Date;
};

export const useTimer = ({ startTime, endTime }: UseTimerParams): Time => {
    const [currentTimeDiff, setCurrentTimeDiff] = useState<Time>({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        let interval: any = 0;

        if (startTime) {
            const calculateTime = () => {
                const diff = getTimeDifference(startTime, new Date());
                setCurrentTimeDiff(diff);
            };
            calculateTime();
            if (!endTime) {
                interval = setInterval(calculateTime, 1000);
            }
        }

        return () => {
            !endTime && clearInterval(interval);
        };
    }, [startTime, endTime]);

    return currentTimeDiff;
};
