import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CsvFile, FieldMappings, JsonlRecord } from "@/types/csv";
import { FilterEntityTypes } from "@primer/filters/types";

export interface CSVRecord {
    [key: string]: string;
}

export interface CSVImportState {
    entityType: FilterEntityTypes;
    fileName: string | null;
    errors: string[] | null;
    progress: number | null;
    datasetId: string | null;
    headers: string[] | null;
    audienceId: string | null;
    validCount: number | null;
    importError: boolean | null;
    presignedUrl: string | null;
    records: CSVRecord[] | null;
    validRecords: JsonlRecord[] | null;
    fieldMappings: FieldMappings;
    isValidatingRecords: boolean;
    validPercentage: number | null;
    importedDataset: CsvFile | null;
}

export const emptyMappings: FieldMappings = {
    email: "",
    lastName: "",
    firstName: "",
    linkedinUrl: "",
    companyDomain: "",
};

const initialState: CSVImportState = {
    errors: null,
    headers: null,
    records: null,
    fileName: null,
    progress: null,
    datasetId: null,
    audienceId: null,
    validCount: null,
    importError: null,
    presignedUrl: null,
    validPercentage: null,
    validRecords: [],
    isValidatingRecords: false,
    fieldMappings: emptyMappings,
    entityType: FilterEntityTypes.PERSON,
    importedDataset: null,
};

export const csvImportSlice = createSlice({
    name: "csv-import",
    initialState,
    reducers: {
        setHeaders: (state: CSVImportState, action: PayloadAction<string[]>) => {
            state.headers = action.payload;
        },

        setRecords: (state: CSVImportState, action: PayloadAction<CSVRecord[]>) => {
            state.records = action.payload;
        },

        setValidRecords: (state: CSVImportState, action: PayloadAction<JsonlRecord[]>) => {
            state.validRecords = action.payload;
        },

        setFileName: (state: CSVImportState, action: PayloadAction<string>) => {
            state.fileName = action.payload;
        },

        setIsValidatingRecords: (state: CSVImportState, action: PayloadAction<boolean>) => {
            state.isValidatingRecords = action.payload;
        },

        setPresignedUrl: (state: CSVImportState, action: PayloadAction<string>) => {
            state.presignedUrl = action.payload;
        },

        setDatasetId: (state: CSVImportState, action: PayloadAction<string>) => {
            state.datasetId = action.payload;
        },

        setImportedDataset: (state: CSVImportState, action: PayloadAction<CsvFile | null>) => {
            state.importedDataset = action.payload;
        },

        setAudienceId: (state: CSVImportState, action: PayloadAction<string>) => {
            state.audienceId = action.payload;
        },

        setProgress: (state: CSVImportState, action: PayloadAction<number>) => {
            state.progress = action.payload;
        },

        setValidPercentage: (state: CSVImportState, action: PayloadAction<number>) => {
            state.validPercentage = action.payload;
        },

        setValidCount: (state: CSVImportState, action: PayloadAction<number>) => {
            state.validCount = action.payload;
        },

        setEntityType: (state: CSVImportState, action: PayloadAction<FilterEntityTypes>) => {
            state.entityType = action.payload;
        },

        setFieldMappings: (state: CSVImportState, action: PayloadAction<FieldMappings>) => {
            state.fieldMappings = action.payload;
        },

        setErrors: (state: CSVImportState, action: PayloadAction<string[]>) => {
            state.errors = action.payload;
        },

        setImportError: (state: CSVImportState, action: PayloadAction<boolean>) => {
            state.importError = action.payload;
        },
    },
});

export const {
    setErrors,
    setHeaders,
    setRecords,
    setValidRecords,
    setFileName,
    setProgress,
    setDatasetId,
    setImportedDataset,
    setAudienceId,
    setValidCount,
    setEntityType,
    setImportError,
    setPresignedUrl,
    setFieldMappings,
    setValidPercentage,
    setIsValidatingRecords,
} = csvImportSlice.actions;

export default csvImportSlice.reducer;
