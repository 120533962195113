import InfoIcon from "@/assets/icons/info-blue.svg?react";
import WarnIcon from "@/assets/icons/status-warning.svg?react";

interface Props {
    title: string;
    message: string;
    className?: string;
}

export const InfoMessage = ({ title, message, className }: Props) => (
    <div className={`w-full gap-2 p-3 bg-blue-50 border border-blue-200 rounded-lg flex items-start ${className}`}>
        <InfoIcon className="min-w-5 mt-1" />

        <div className="text-blue-870">
            <p className="font-medium">{title}</p>
            <p className="text-sm">{message}</p>
        </div>
    </div>
);

interface WarnProps {
    message: string;
    className?: string;
}

export const WarnMessage = ({ message, className }: WarnProps) => (
    <div className={`w-full gap-2 p-3 bg-yellow-50 border border-yellow-200 rounded-lg flex items-center ${className}`}>
        <WarnIcon className="min-w-5" />

        <div className="text-yellow-800">
            <p className="text-sm">{message}</p>
        </div>
    </div>
);
