import { useClickAway } from "react-use";
import { useEffect, useRef } from "react";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

interface Props {
    content: string;
    anchor: "filter" | "archive";
}

export const TooltipMessageWrapper = ({ anchor, content }: Props) => {
    const [query, setQuery] = useQueryParams(
        { tooltip: withDefault(StringParam, undefined) },
        { removeDefaultsFromUrl: true },
    );

    const isOpen = query.tooltip === anchor;

    const ref = useRef<HTMLDivElement>(null);

    useClickAway(ref, () => {
        setQuery({ tooltip: undefined });
    });

    useEffect(() => {
        isOpen && ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, [ref, isOpen]);

    return (
        <Tooltip className="text-start" side="left" open={isOpen ?? undefined} content={content}>
            <div ref={ref} className="text-10 text-transparent">
                _
            </div>
        </Tooltip>
    );
};
