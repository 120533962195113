import { useEffect } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { useGetInvoicesQuery } from "@/api/users";
import { Main } from "@/components/organisms/Main";
import { usePaginationWithQueryParams } from "@/hooks";
import { useToast } from "@/components/atoms/Toast/useToast";
import Pagination from "@/components/molecules/Pagination/Pagination";
import { InvoicesTable } from "@/components/organisms/settings/invoices/InvoiceTable";

export const Invoices = () => {
    const { toast } = useToast();

    const pagination = usePaginationWithQueryParams({ limit: 10 });
    const { updateTotal, from, limit } = pagination;

    const {
        data,
        isLoading,
        error: getInvoicesError,
    } = useGetInvoicesQuery(
        { limit, offset: from },
        {
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
        },
    );

    if (data?.paginationDetails) updateTotal(data?.paginationDetails?.totalCount || 0, isLoading);

    useEffect(() => {
        if (!getInvoicesError) return;

        const fetchBaseQueryError = getInvoicesError as FetchBaseQueryError;
        toast({ description: fetchBaseQueryError.data as string, variant: "destructive" });
    }, [toast, getInvoicesError]);

    return (
        <>
            <Main>
                <div className="w-full flex flex-col gap-6 px-8 py-6 rounded-2xl shadow-main bg-white">
                    <h2 className="text-ui-700 text-h2">Invoices</h2>
                    <div className="flex-grow">
                        <InvoicesTable invoices={data?.items ?? []} isLoading={isLoading} />
                    </div>
                </div>
            </Main>
            <Pagination {...pagination} className="sticky bottom-0 bg-dark-100 p-2 mx-auto w-full mt-auto" />
        </>
    );
};
