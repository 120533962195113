import queryString from "query-string";
import MetaIcon from "@/assets/icons/facebook.svg?react";
import GoogleIcon from "@/assets/icons/google.svg?react";
import LinkedInIcon from "@/assets/icons/linkedin.svg?react";
import { IntegrationType, Service } from "@/types/integrations";
import SalesforceIcon from "@/assets/icons/salesforce.svg?react";

const oAuthRedirectBase = import.meta.env.VITE_INTEGRATIONS_REDIRECT_BASE;

const getRedirectUri = (service: Service) => `${oAuthRedirectBase}/${service}/connect`;

export const OAuthConfig = {
    [Service.GOOGLE]: {
        clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        host: "https://accounts.google.com/o/oauth2/v2/auth",
    },
    [Service.META]: {
        appId: import.meta.env.VITE_META_APP_ID,
        host: "https://www.facebook.com",
        version: "v14.0",
    },
    [Service.LINKEDIN]: {
        clientId: import.meta.env.VITE_LINKEDIN_CLIENT_ID,
        host: "https://www.linkedin.com/oauth/v2/authorization",
    },
    [Service.SALESFORCE]: {
        clientId: import.meta.env.VITE_SALESFORCE_CLIENT_ID,
        host: "https://login.salesforce.com/services/oauth2/authorize",
    },
};

export const getOAuthUrl = (service: Service): string | null => {
    if (service === Service.GOOGLE) {
        const { clientId, host } = OAuthConfig.google;
        const query = queryString.stringify({
            prompt: "consent",
            response_type: "code",
            access_type: "offline",
            redirect_uri: getRedirectUri(Service.GOOGLE),
            client_id: clientId,
            scope: ["https://www.googleapis.com/auth/adwords", "https://www.googleapis.com/auth/userinfo.profile"].join(
                " ",
            ),
        });

        return `${host}?${query}`;
    }

    if (service === Service.META) {
        const { host, appId, version } = OAuthConfig.meta;
        const path = `${version}/dialog/oauth`;
        const redirectUri = getRedirectUri(Service.META);
        const scopes = ["ads_management","ads_read","business_management"];
        const query = `client_id=${appId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}`;

        return `${host}/${path}?${query}`;
    }

    if (service === Service.LINKEDIN) {
        const { host, clientId } = OAuthConfig.linkedin;

        const query = queryString.stringify({
            client_id: clientId,
            redirect_uri: getRedirectUri(Service.LINKEDIN),
            response_type: "code",
        });
        const scope = [
            "r_emailaddress",
            "r_ads",
            "w_organization_social",
            "rw_ads",
            "r_basicprofile",
            "r_liteprofile",
            "r_ads_reporting",
            "r_organization_social",
            "rw_organization_admin",
            "w_member_social",
            "r_1st_connections_size",
            "rw_dmp_segments",
        ];
        return `${host}?${query}&scope=${scope.join("%20")}`;
    }

    if (service === Service.SALESFORCE) {
        const { host, clientId } = OAuthConfig.salesforce;
        const redirectUri = encodeURIComponent(getRedirectUri(Service.SALESFORCE));
        const scope = encodeURIComponent("api refresh_token");
        const loginUrl = `${host}?prompt=login&response_type=code&scope=${scope}&client_id=${clientId}&redirect_uri=${redirectUri}`;
        return loginUrl;
    }

    return null;
};

export const getIconForService = (service: Service, className: string) => {
    switch (service) {
        case Service.GOOGLE:
            return <GoogleIcon className={className} />;
        case Service.META:
            return <MetaIcon className={className} />;
        case Service.LINKEDIN:
            return <LinkedInIcon className={className} />;
        case Service.SALESFORCE:
            return <SalesforceIcon className={className} />;
        default:
            return null;
    }
};

export const getNameForService = (service: Service) => {
    switch (service) {
        case Service.GOOGLE:
            return "Google Ads";
        case Service.META:
            return "Meta Ads";
        case Service.LINKEDIN:
            return "LinkedIn Ads";
        case Service.SALESFORCE:
            return "Salesforce";
        default:
            return "";
    }
};

export const getTypeForService = (service: Service) => {
    if ([Service.GOOGLE, Service.LINKEDIN, Service.META].includes(service)) return IntegrationType.AD;
    if (service === Service.SALESFORCE) return IntegrationType.CRM;
    return null;
};

export const adPlatformCards = [
    {
        service: Service.META,
        type: IntegrationType.AD,
        name: getNameForService(Service.META),
        connectUrl: getOAuthUrl(Service.META),
        icon: getIconForService(Service.META, "size-12"),
        description: "Sync leads to these accounts as custom audiences. Includes Facebook, Instagram.",
    },

    {
        service: Service.GOOGLE,
        name: getNameForService(Service.GOOGLE),
        type: IntegrationType.AD,
        icon: getIconForService(Service.GOOGLE, "size-12"),
        connectUrl: getOAuthUrl(Service.GOOGLE),
        description:
            "Sync leads to these accounts as customer match audiences. Includes Google Display Network, AdWords, Youtube.",
    },

    {
        service: Service.LINKEDIN,
        name: getNameForService(Service.LINKEDIN),
        type: IntegrationType.AD,
        connectUrl: getOAuthUrl(Service.LINKEDIN),
        icon: getIconForService(Service.LINKEDIN, "size-12"),
        description: "Sync leads to these accounts as matched audiences. ",
    },
];

export const crmCards = [
    {
        service: Service.SALESFORCE,
        type: IntegrationType.CRM,
        name: getNameForService(Service.SALESFORCE),
        connectUrl: getOAuthUrl(Service.SALESFORCE),
        icon: getIconForService(Service.SALESFORCE, "size-12"),
        description: "Import standard Salesforce objects and filter by any field",
    },
];
