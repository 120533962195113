import Image from "@/assets/images/audiences-empty-state.svg?react";
import { Button } from "@/components/atoms/Button/Button";
import { Link } from "react-router-dom";

export const EmptyState = () => {
    return (
        <div className='flex-grow flex flex-col items-center mt-[80px]'>
            <Image />
            <div className='my-4 flex flex-col'>
                <span className='text-center text-ui-900 text-2xl leading-9'>
                    Welcome to Primer Audiences
                </span>
                <span className='mt-2 text-center text-ui-700 leading-tight'>
                    Get started building Ad Audiences to reach your ICP everywhere.
                </span>
            </div>
            <Button asChild variant='secondary'>
                <Link to="/audiences/new">Create Audience</Link>
            </Button>
        </div>
    );
}