import { Destination } from "@/types/audience";

interface DestinationDetails {
    name: string;
    helpText?: string;
} 
interface AdDestinationDetails {
    [Destination.META]: DestinationDetails;
    [Destination.GOOGLE]: DestinationDetails;
    [Destination.LINKEDIN]: DestinationDetails;
} 
interface CrmDestinationDetails {
    [Destination.SALESFORCE]: DestinationDetails;
    [Destination.CSV]: DestinationDetails;
}

export const AD_DESTINATIONS: AdDestinationDetails = {
    [Destination.META]: {
        name: "Meta Ads",
        helpText: "Meta ads includes all destinations available in your ads manager, e.g. Facebook. Instagram.",
    },
    [Destination.GOOGLE]: {
        name: "Google Ads",
        helpText:
            "Google ads includes all destinations available in your ads manager, e.g. Google Display Network, AdWords, Youtube.",
    },
    [Destination.LINKEDIN]: { name: "LinkedIn Ads" },
};

export const CRM_DESTINATIONS: CrmDestinationDetails = {
    [Destination.SALESFORCE]: { name: "Salesforce" },
    [Destination.CSV]: { name: "CSV" },
};

export const DESTINATIONS = {
    ...AD_DESTINATIONS,
    ...CRM_DESTINATIONS,
}
