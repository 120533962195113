import { useLocation } from "react-use";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "@/store";
import {
    setErrors,
    setProgress,
    setValidCount,
    emptyMappings,
    setEntityType,
    setImportError,
    setFieldMappings,
    setValidPercentage,
    setImportedDataset,
} from "@/store/csv-import";
import { formatNumber } from "@/utils/number";
import { transformToJsonl } from "@/utils/jsonl";
import { gzipFile, gzipString } from "@/utils/gzip";
import LoadingImage from "@/assets/images/loading-timer.svg?react";
import { CsvFileInfo } from "@/components/organisms/csv/CsvFileInfo";
import ProgressLoader from "@/components/atoms/ProgressLoader/ProgressLoader";
import {
    useGetDatasetQuery,
    CreateDatasetRequest,
    CreateDatasetResponse,
    useImportDatasetMutation,
    useTriggerMappingsMutation,
} from "@/api/csv";
import { FilterEntityTypes } from "@primer/filters/types";
import { useSaveCriteria } from "./hooks";
import { useToast } from "@/components/atoms/Toast/useToast";

//API endpoint for creating dataset accepts lowercase values
const entityTypes: Record<FilterEntityTypes, "person" | "company"> = {
    [FilterEntityTypes.PERSON]: "person",
    [FilterEntityTypes.COMPANY]: "company",
};

export const ImportLoading = () => {
    const { records, validRecords, fileName, fieldMappings, entityType, validCount } = useSelector(
        (state: AppState) => state["csv-import"],
    );
    const [datasetId, setDatasetId] = useState<string | null>(null);
    const [checkingEnabled, setCheckingEnabled] = useState(false);
    const { toast } = useToast();

    const location = useLocation();
    const { file } = location.state?.usr?.file || {};

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onImportError = useCallback(() => {
        dispatch(setImportError(true));
        navigate("/audiences/import-csv", { state: { file }, replace: true });
    }, [dispatch, file, navigate]);

    const { saveCriteria } = useSaveCriteria({ onImportError });

    const { data: dataset } = useGetDatasetQuery(
        { id: datasetId! },
        { skip: !datasetId || !checkingEnabled, pollingInterval: 5000 },
    );

    const [importDatasetMutation, { error: importDatasetError }] = useImportDatasetMutation();
    const [triggetMappingsMutation, { error: triggerMappingError }] = useTriggerMappingsMutation();

    useEffect(() => {
        if (importDatasetError || triggerMappingError) {
            onImportError();
        }
    }, [
        onImportError,
        importDatasetError,
        triggerMappingError,
    ]);

    const importDataset = useCallback(
        async (req: CreateDatasetRequest): Promise<CreateDatasetResponse> =>
            importDatasetMutation(req).unwrap(),
        [importDatasetMutation],
    );

    const triggerMappings = useCallback(
        async (req: { id: string }) => triggetMappingsMutation(req).unwrap(),
        [triggetMappingsMutation],
    );

    const importing = useRef(false);

    useEffect(() => {
        const importFunc = async () => {
            //Just in case
            if (importing.current) return;
            importing.current = true;

            const jsonl = transformToJsonl(validRecords!);

            const gzippedCsv = await gzipFile(file);
            const gzippedJsonl = gzipString(jsonl);

            //function that calls the APIs in the correct order
            const importData = async (reqData: CreateDatasetRequest) => {
                //creating datasetid and presigned urls
                const created = await importDataset(reqData);
                const { datasetId, rawPresignedUrl, translatedPresignedUrl } = created;

                setDatasetId(datasetId);

                const putWithFetch = async (url: string, blob: Blob, headers: HeadersInit) => {
                    const response = await fetch(url, {
                        method: 'PUT',
                        body: blob,
                        headers,
                    });
                    if (!response.ok) {
                        throw new Error(`Failed to upload to ${url}: ${response.statusText}`);
                    }
                };
                await Promise.all([
                    putWithFetch(rawPresignedUrl, new Blob([gzippedCsv], { type: "text/csv" }), {
                        "Content-Encoding": "gzip",
                        "Content-Type": "text/csv",
                    }),

                    putWithFetch(translatedPresignedUrl, new Blob([gzippedJsonl], { type: "application/jsonl" }), {
                        "Content-Encoding": "gzip",
                        "Content-Type": "application/jsonl",
                    }),
                ]);

                //triggering the entity mappings by hitting /import endpoint
                await triggerMappings({ id: datasetId });
                //setting the flag for enabling the check, which is happening in the useGetDatasetQuery hook
                setCheckingEnabled(true);
            };

            importData({
                fieldMappings,
                name: fileName!,
                validCount: validCount!,
                inputCount: records!.length,
                entityType: entityTypes[entityType],
                invalidCount: records!.length - validCount!,
            }).catch(() => dispatch(setImportError(true)));
        }
        importFunc();
    }, [entityType, fieldMappings, file, fileName, importDataset, records, triggerMappings, validCount, validRecords, toast, dispatch]);

    useEffect(() => {
        const handleStatusChange = async () => {
            if (dataset?.status === "completed") {
                dispatch(setErrors([]));
                dispatch(setProgress(0));
                dispatch(setValidCount(0));
                dispatch(setImportError(false));
                dispatch(setValidPercentage(0));
                dispatch(setFieldMappings(emptyMappings));
                dispatch(setEntityType(FilterEntityTypes.PERSON));
                dispatch(setImportedDataset(dataset));
                await saveCriteria(dataset);
            }

            if (dataset?.status === "failed") {
                onImportError()
            }
        }
        handleStatusChange();
    }, [dataset?.status]);


    return (
        <div className="w-full h-full px-12 py-20 flex flex-col justify-start items-center gap-6">
            <h1 className="text-ui-700 text-2xl">Importing CSV</h1>
            <CsvFileInfo />

            <div className="w-[700px] px-8 py-16 gap-5 bg-white rounded-2xl flex flex-col items-center justify-center">
                <LoadingImage />
                <ProgressLoader />
                <div className="flex flex-col items-center text-ui-700 text-sm">
                    <p>Please wait while Primer finish uploading and entity mapping</p>
                    <p> for {formatNumber(records?.length)} CSV records, this may take several minutes.</p>
                </div>
            </div>
        </div>
    );
};
