import { max } from "lodash";

const model_fb_peak_95 = { intercept: 11.006733045544848, slope: 0.00012495733522428375 };
const model_fb_nonpeak_95 = { intercept: 0.3728747844456688, slope: 8.955937542144059e-5 };
const model_goog_peak_95 = { intercept: 4.021379013756807, slope: 5.944751176267525e-5 };
const model_goog_nonpeak_95 = { intercept: 0.3145524941303399, slope: 9.344611502511763e-5 };
const model_li_peak_95 = { intercept: 11.330109698816223, slope: 1.9873154475569656e-5 };
const model_li_nonpeak_95 = { intercept: 0.30422906449521037, slope: 1.9097295784622083e-5 };

const build_peak = 2.15;
const build_nonpeak = 1.56;

function syncPercentileDuration(destination: string, record_count: number, hour: number) {
    let model_95;

    if (destination === "meta") {
        if (hour === 4 || hour === 5) {
            model_95 = model_fb_peak_95;
        } else {
            model_95 = model_fb_nonpeak_95;
        }
    } else if (destination === "google") {
        if (hour === 4 || hour === 5) {
            model_95 = model_goog_peak_95;
        } else {
            model_95 = model_goog_nonpeak_95;
        }
    } else if (destination === "linkedIn") {
        if (hour === 4 || hour === 5) {
            model_95 = model_li_peak_95;
        } else {
            model_95 = model_li_nonpeak_95;
        }
    } else {
        return 0;
    }

    return model_95.intercept + model_95.slope * record_count;
}

function buildAvgDuration(hour: number) {
    return hour === 4 || hour === 5 ? build_peak : build_nonpeak;
}

export function expectedRunDuration(destinations: string[], requestDate: Date, size: number) {
    const buildDuration = buildAvgDuration(requestDate.getHours());
    const destinationsDurations = destinations.map(d =>
        syncPercentileDuration(d, Math.min(size, 3000000), requestDate.getHours()),
    );

    const fullDuration = buildDuration + (max(destinationsDurations) ?? 0);
    return fullDuration * 60 * 1000;
}
