/* eslint-disable react-hooks/exhaustive-deps */
import postHog from "posthog-js";
import { get, isNil } from "lodash";
import { filterChanged, getAvailableOperators } from "@/utils/filter";
import React, { MouseEventHandler, useCallback, useEffect } from "react";
import ClickAwayListener from "react-click-away-listener";

import CloseIcon from "@/assets/icons/close-black.svg?react";
import FilterField from "@/components/organisms/FilterField/Root";
import { filterConfigs, filterOperatorConfigs, getCSVFilterConfig } from "@primer/filters/configs";
import { FilterCriteriaViewer } from "@/components/organisms/FilterCriteriaViewer/FilterCriteriaViewer";
import { FilterCriteriaValues } from "@/components/molecules/FilterCriteriaValues/FilterCriteriaValues";
import { FilterCriteriaOperator } from "@/components/molecules/FilterCriteriaOperator/FilterCriteriaOperator";

import {
    Filter,
    FilterConfig,
    OperatorConfig,
    FilterOperators,
    FilterDataTypes,
    FilterEntityTypes,
    SourceCriteriaFilterValue,
    SourceCriteria,
} from "@primer/filters/types";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import cn from "classnames";
import { useNavigation } from "@/context/NavigationContext";
import { useSelector } from "react-redux";
import { AppState } from "@/store";
import { usePrevious, useUpdateEffect } from "react-use";
import { useOperator } from "@/hooks/useOperator";
import { Button } from "@/components/atoms/Button/Button";

interface FilterCriteriaBuilderProps {
    index: number;
    audienceType: FilterEntityTypes;
    filter: Partial<Filter>;
    isEditable: boolean;
    disabled: boolean;
    sourceCriteria?: SourceCriteria;
    mappedFirstPartyFilters: {
        lead: {
            [key: string]: FilterConfig;
        };
        account: {
            [key: string]: FilterConfig;
        };
        contact: {
            [key: string]: FilterConfig;
        };
    };
    updateEditableField: (filterId: string, readOnly: boolean) => void;
    saveFilter: (filter: Partial<Filter>) => void;
    removeFilter?: (filterId: string) => void;
}

export const FilterCriteriaBuilder = ({
    index,
    filter,
    audienceType,
    disabled,
    isEditable,
    mappedFirstPartyFilters,
    updateEditableField,
    saveFilter,
    removeFilter,
    sourceCriteria,
}: FilterCriteriaBuilderProps) => {
    const isSummaryEnabled = useFeatureFlag(FeatureFlagsEnum.SUMMARY);
    const isPreviewEnabled = useFeatureFlag(FeatureFlagsEnum.PREVIEW);
    const repoV2Enabled = useFeatureFlag(FeatureFlagsEnum.PITCH_REPO_V2);
    const isSplitView = isSummaryEnabled || isPreviewEnabled;

    const [showEditFields, setShowEditFields] = React.useState(false);
    const [selectedFilterField, setSelectedFilterField] = React.useState<FilterConfig | undefined>(() => {
        if (isNil(filter)) return undefined;

        // first party field
        if (filter.instanceId) {
            return get(mappedFirstPartyFilters, `[${filter.objectType}].[${filter.field}]`, undefined);
        }
        // csv
        if (filter.mappingTable) {
            return getCSVFilterConfig(filter);
        }

        return filterConfigs[filter.field!];
    });

    const [selectedOperator, setSelectedOperator] = React.useState<OperatorConfig | undefined>(
        !filter?.operator ? undefined : filterOperatorConfigs[filter.operator],
    );
    const [selectedValues, setSelectedValues] = React.useState<SourceCriteriaFilterValue[] | undefined>(filter?.values);
    const [newFilter, setNewFilter] = React.useState<Partial<Filter>>(filter);

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);

    // If it's a static option multi select, open dropdown initially
    const [valuesDropdownOpen, setValuesDropdownOpen] = React.useState<boolean>(!!selectedFilterField?.picklistValues);
    const [isValidating, setIsValidating] = React.useState(false);

    const { operators } = useOperator({ selectedFilterField, selectedOperator });

    React.useEffect(() => {
        if (dropdownOpen && filter?.unique_id) updateEditableField(filter.unique_id, true);
    }, [dropdownOpen, filter.unique_id])

    React.useEffect(() => {
        setTimeout(() => {
            setShowEditFields(isEditable);
        }, 200);
    }, [isEditable])
    React.useEffect(() => handleFilterChange(), [newFilter, filter]);
    React.useEffect(() => handleEditableChange(), [showEditFields, selectedValues]);
    React.useEffect(
        () => handleFilterBuilderKeyDown(),
        [dropdownOpen, selectOpen, valuesDropdownOpen, selectedFilterField, selectedValues, showEditFields],
    );

    React.useEffect(() => {
        if (!dropdownOpen && !selectedFilterField && filter?.unique_id) updateEditableField(filter.unique_id, false);
    }, [dropdownOpen, selectedFilterField])

    const prevSelectedOperator = usePrevious(selectedOperator);

    useUpdateEffect(() => {
        const isDateFilter =
            filter?.dataType === FilterDataTypes.DATE || filter?.dataType === FilterDataTypes.DATE_TIME;
        const isPrevOperatorRelativeDateRange =
            prevSelectedOperator?.id === FilterOperators.IS || prevSelectedOperator?.id === FilterOperators.IS_NOT;
        const isCurrentOperatorRelativeDateRange =
            selectedOperator?.id === FilterOperators.IS || selectedOperator?.id === FilterOperators.IS_NOT;

        // due to incompatibility of values, we reset the value in the following cases
        if (
            isDateFilter &&
            ((isPrevOperatorRelativeDateRange && !isCurrentOperatorRelativeDateRange) ||
                (!isPrevOperatorRelativeDateRange && isCurrentOperatorRelativeDateRange))
        ) {
            setSelectedValues([]);
        }
    }, [filter?.dataType, prevSelectedOperator?.id, selectedOperator?.id]);

    const viewMode = React.useMemo(
        () => (!showEditFields || disabled) && !!selectedFilterField?.identifier,
        [showEditFields, disabled, selectedFilterField],
    );

    const { audienceId } = useNavigation();

    const handleFilterChange = () => {
        if (newFilter?.field && filterChanged([newFilter], [filter])) {
            // When a filter is saved, send event to posthog
            postHog.capture("Filter saved", {
                filterType: filter.field,
                audienceId,
            });

            saveFilter(newFilter);
        }
    };

    const handleFilterBuilderKeyDown = () => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (filter.unique_id) {
                if (event.key === "Escape") {
                    if (!valuesDropdownOpen && !selectOpen && !dropdownOpen)
                        updateEditableField(filter.unique_id, false);
                    else if (valuesDropdownOpen) delayClose(() => setValuesDropdownOpen(false));
                    else if (selectOpen) delayClose(() => setSelectOpen(false));
                    else if (dropdownOpen) delayClose(() => setDropdownOpen(false));
                } else if (
                    event.key === "Enter" &&
                    selectedFilterField?.dataType === FilterDataTypes.BOOLEAN &&
                    selectedValues &&
                    selectedValues?.length > 0
                ) {
                    if (!valuesDropdownOpen && !selectOpen && !dropdownOpen)
                        updateEditableField(filter.unique_id, false);
                }
            }
        };

        if (showEditFields) window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    };

    const onSelectedFieldChange = useCallback((value: FilterConfig) => {
        const availableOperators = getAvailableOperators(value, repoV2Enabled);
        setShowEditFields(true);
        setSelectedFilterField(value);
        setSelectedOperator(filterOperatorConfigs[availableOperators[0]]);
        setSelectedValues([]);
        setDropdownOpen(false);
        if (filter.unique_id) updateEditableField(filter.unique_id, true);

        setNewFilter({
            ...newFilter,
            entity_type: value.entityType,
            field: value.identifier,
            operator: availableOperators[0],
            objectType: value?.objectType,
            instanceId: value?.instanceId,
            dataType: value?.dataType,
            path: value?.path,
            mappingTable: value?.mappingTable,
            values: [],
        });
    }, [operators]);

    const onSelectAllObjects = (value: FilterConfig) => {
        setSelectedFilterField(value);
        setSelectedOperator(filterOperatorConfigs[FilterOperators.IS_KNOWN]);
        setDropdownOpen(false);
        setNewFilter({
            ...newFilter,
            entity_type: value.entityType,
            field: value.identifier,
            objectType: value?.objectType,
            instanceId: value?.instanceId,
            mappingTable: value?.mappingTable,
            dataType: value?.dataType,
            path: value?.path,
            operator: FilterOperators.IS_KNOWN,
            values: [],
        });
        if (newFilter.unique_id) updateEditableField(newFilter.unique_id, false);
    };

    const handleEditableChange = () => {
        if (!showEditFields) updateFilterValues();
    };

    const onRemoveFilter: MouseEventHandler = event => {
        event.stopPropagation();
        if (newFilter.unique_id) removeFilter?.(newFilter.unique_id);
    };

    const onClickAway = () => {
        if (filter.unique_id && filter.field) {
            if (!dropdownOpen && !valuesDropdownOpen && !selectOpen) {
                if (filter.unique_id) updateEditableField(filter.unique_id, false);
            }

            updateFilterValues();
        }
    };

    const updateFilterValues = () => {
        setNewFilter({
            ...newFilter,
            ...(selectedValues?.length || filter.values?.length
                ? {
                    values: selectedValues?.map(({ value, label, category, invalid, exceeded }) => ({
                        value,
                        ...(invalid !== undefined && { invalid }),
                        ...(exceeded !== undefined && { exceeded }),
                        ...(label ? { label } : {}),
                        ...(category ? { category } : {}),
                    })),
                }
                : undefined),
        });
    };

    const onDropdownOpen = (open: boolean) => {
        if (!open) delayClose(() => setDropdownOpen(open));
        else setDropdownOpen(open);
    };

    const delayClose = (func: (open: boolean) => void) => {
        setTimeout(func, 200);
    };

    const { toggledLeftBar } = useSelector(
        (state: AppState) => state["audience"],
    );

    useEffect(() => {
        if (toggledLeftBar) {
            setDropdownOpen(false);
            setValuesDropdownOpen(false);
            setSelectOpen(false);
        }
    }, [toggledLeftBar]);

    const removeButton = <div>
        <Button
            variant="basic"
            size="custom"
            content="Delete Filter"
            className="ml-auto mr-[10px] my-1 border-0 cursor-pointer flex items-center px-3 rounded-md hover:bg-blue-50 !px-2 h-[30px]"
            onClick={onRemoveFilter}>
            <CloseIcon className="text-ui-300" />
        </Button>
    </div>;

    const inlineEditView = React.useMemo(() => !isSplitView || (selectedFilterField && operators?.length <= 1), [isSplitView, selectedFilterField, selectedOperator]);

    return (
        <>
            {viewMode && (
                <FilterCriteriaViewer
                    index={index}
                    disabled={disabled}
                    filter={filter}
                    filterDisplayName={selectedFilterField?.displayName}
                    updateEditableField={updateEditableField}
                    onRemoveFilter={removeFilter && onRemoveFilter}
                />
            )}
            {(!viewMode || isValidating) && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        className={cn("flex flex-row gap-2 my-1", {
                            "hidden": isValidating && viewMode,
                            "flex-col": !inlineEditView && !viewMode,
                        })}
                    >
                        <div className={cn("flex gap-2", {
                            "min-w-fit": inlineEditView
                        })}>
                            <div className={cn({
                                "group-[.split]/panel:max-w-[50%]": !inlineEditView,
                                "flex-1": inlineEditView
                            })}>
                                <FilterField
                                    disabled={disabled}
                                    open={dropdownOpen}
                                    setOpen={onDropdownOpen}
                                    entityType={audienceType}
                                    selectedFilterField={selectedFilterField}
                                    setSelectedFilterField={onSelectedFieldChange}
                                    onSelectAllObjects={onSelectAllObjects}
                                    sourceCriteria={sourceCriteria}
                                />
                            </div>
                            {selectedFilterField &&
                                selectedOperator &&
                                operators?.length > 1 && (
                                    <FilterCriteriaOperator
                                        selectedFilterField={selectedFilterField}
                                        selectedOperator={selectedOperator}
                                        setSelectedOperator={setSelectedOperator}
                                        newFilter={newFilter}
                                        setNewFilter={setNewFilter}
                                        selectOpen={selectOpen}
                                        setSelectOpen={open => delayClose(() => setSelectOpen(open))}
                                        setSelectedValues={setSelectedValues}
                                    />
                                )}
                            {selectedFilterField && removeFilter && !inlineEditView && <div className="ml-auto">{removeButton}</div>}
                        </div>
                        {selectedFilterField &&
                            (selectedOperator || selectedFilterField.dataType === FilterDataTypes.BOOLEAN) && (
                                <FilterCriteriaValues
                                    filter={filter}
                                    selectedFilterField={selectedFilterField}
                                    selectedOperator={selectedOperator}
                                    selectedValues={selectedValues ?? []}
                                    dropdownOpen={valuesDropdownOpen}
                                    setIsValidating={setIsValidating}
                                    setSelectedValues={setSelectedValues}
                                    newFilter={newFilter}
                                    setNewFilter={setNewFilter}
                                    updateEditableField={updateEditableField}
                                    setDropdownOpen={open => delayClose(() => setValuesDropdownOpen(open))}
                                />
                            )}

                        {selectedFilterField && removeFilter && inlineEditView && removeButton}
                    </div>
                </ClickAwayListener>
            )}
        </>
    );
};
