import { Link } from "react-router-dom";
import QualificationQuestionsIcon from "@/assets/images/onboarding/qualificationQuestion.svg";
import PrimerLogo from "@/assets/icons/logo.svg?react";
import { SignUp } from "@clerk/clerk-react";

const EmailVerified = () => {
    return (
        <div className="grid grid-cols-[auto_1fr_auto] pl-10 pr-12 py-5">
            <PrimerLogo className="w-[130px] h-[22px]" />
            <div className="mx-auto flex flex-col items-center justify-center gap-y-5 text-ui-900">
                <div className="w-[500px] h-[200px] rounded-3xl bg-ui-300/[0.19]">
                    <img src={QualificationQuestionsIcon} className="w-full h-full" />
                </div>
                <div className="max-w-[500px] flex flex-col gap-y-3 text-base">
                    <h1 className="text-h1 mb-1">Your email is verified!</h1>
                    <p>For security reasons, we can’t automatically log you in on this device.</p>
                    <p>Please <Link to="/login" className="text-blue-800">log in on this device</Link>, or return to the device where you created your account to continue.</p>
                    <p>Thank you for helping us keep your account secure!</p>
                </div>
                <SignUp appearance={{
                    elements: {
                        card: {
                            display: "none",
                        },
                    },
                }} />
            </div>
        </div>
    );
};

export default EmailVerified;
