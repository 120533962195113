import { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { CriteriaEstimateManagerHook } from "@/hooks/useCriteriaAssetsManager";
import PendingClockIcon from "@/assets/icons/pending-clock.svg?react";
import CircleCheck from "@/assets/icons/circle-check.svg?react";
import ErrorIcon from "@/assets/icons/error-ouline.svg?react";

const AudienceEstimateLoading = ({ criteriaAssetsManager }: { criteriaAssetsManager: CriteriaEstimateManagerHook }) => {
    const [loadingState, setLoadingState] = useState({
        isLoading: false,
        isDone: false,
        isHiding: false,
        isError: false,
    });

    const pendingLoading = useMemo(
        () => [
            ...(!criteriaAssetsManager.isLoadingSize && criteriaAssetsManager.isLoadingMatchRate ? ["Match Rate"] : []),
            ...(criteriaAssetsManager.isLoadingSize || criteriaAssetsManager.isLoadingSomeSummary ? ["Summary"] : []),
            ...(criteriaAssetsManager.isLoadingPreview ? ["Preview"] : []),
            ...(criteriaAssetsManager.isLoadingSize ? ["Size"] : []),
        ],
        [criteriaAssetsManager],
    );

    const { hasAnyEstimateMutationError } = criteriaAssetsManager;
    const previousError = useRef<boolean>();

    useEffect(() => {
        if (pendingLoading.length > 0) {
            previousError.current = undefined;
            setLoadingState({ isLoading: true, isDone: false, isHiding: false, isError: false });
        } else if (loadingState.isLoading && pendingLoading.length === 0) {
            previousError.current = undefined;
            setLoadingState({ isLoading: false, isDone: true, isHiding: false, isError: false });

            setTimeout(() => setLoadingState(state => ({ ...state, isHiding: true })), 2300);
            setTimeout(
                () => setLoadingState({ isLoading: false, isDone: false, isHiding: false, isError: false }),
                3000,
            );
        } else if (hasAnyEstimateMutationError && hasAnyEstimateMutationError !== previousError.current) {
            previousError.current = hasAnyEstimateMutationError;
            setLoadingState({ isLoading: false, isDone: true, isHiding: false, isError: true });

            setTimeout(() => setLoadingState(state => ({ ...state, isHiding: true })), 2300);
            setTimeout(
                () => setLoadingState({ isLoading: false, isDone: false, isHiding: false, isError: false }),
                3000,
            );
        }
    }, [pendingLoading, loadingState.isLoading, hasAnyEstimateMutationError, previousError]);

    return (
        (loadingState.isLoading || loadingState.isDone || loadingState.isError) && (
            <div
                className={cn(
                    "flex gap-2 text-sm items-center overflow-hidden px-4 py-2 rounded-lg mr-2 duration-700 transition-all w-[350px] text-ui-700 bg-ui-50",
                    {
                        "!w-0 !px-0": loadingState.isDone && loadingState.isHiding,
                        "animate-pulse": !loadingState.isDone,
                        "bg-warning-background w-auto": loadingState.isError,
                    },
                )}
            >
                {loadingState.isLoading && (
                    <>
                        <PendingClockIcon className="min-w-5 min-h-5" />
                        <span className="text-sm">Loading {pendingLoading.join(", ")}...</span>
                    </>
                )}
                {loadingState.isDone && !loadingState.isError && (
                    <>
                        <CircleCheck className="w-5 h-5" />
                        <span className="text-sm">Done!</span>
                    </>
                )}
                {loadingState.isError && (
                    <>
                        <ErrorIcon className="w-5 h-5" />
                        <span className="text-warning">Failed</span>
                    </>
                )}
            </div>
        )
    );
};

export default AudienceEstimateLoading;
