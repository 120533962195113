import { useSelector } from "react-redux";

import { AppState } from "@/store";
import { formatNumber } from "@/utils/number";
import { ErrorMessage } from "../ErrorMessage";
import CsvFileIcon from "@/assets/icons/csv.svg?react";

export const CsvFileInfo = () => {
    const { fileName, records, importError } = useSelector((state: AppState) => state["csv-import"]);
    const supportLink = (
        <span>There was an error with uploading your file. Please try again or reach out to our &nbsp;
            <a className="text-primary" target="_blank" href="https://support.sayprimer.com/primer/">
                support
            </a>
        </span>
    )

    return (
        <div className="w-[700px] px-8 py-6 bg-white rounded-2xl">
            <div className="flex justify-between items-center text-ui-300 text-10 font-medium">
                <span>FILE NAME</span>
                <span>RECORDS FOUND</span>
            </div>

            <div className="flex justify-between items-center text-ui-900">
                <div className="flex items-center gap-1.5">
                    <CsvFileIcon className="min-w-fit my-1 self-start" />
                    <span>{fileName}</span>
                </div>

                <span className="min-w-[85px] text-end">{formatNumber(records?.length)}</span>
            </div>

            {importError && (
                <ErrorMessage message={supportLink} />
            )}
        </div>
    );
};
