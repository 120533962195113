import { api } from "@/api";
import {
    AudienceShapeEstimates,
    DuplicateAudienceRequest,
    DuplicateAudienceResponse,
    GetAudienceRunsRequest,
    GetAudienceRunsResponse,
    GetAudienceShapeEstimateHeuristicsRequest,
    GetAudiencesRequest,
    GetAudiencesResponse,
    RerunAudienceRequest,
} from "@/types/api";
import {
    CreateAudienceRequest,
    CreateAudienceResponse,
    CreateAudienceShapeRequest,
    FavoriteAudienceRequest,
    GetAudienceRequest,
    GetAudienceResponse,
    RunAudienceRequest,
    UpdateAudienceRequest,
} from "@/types/api.ts";
import { SubscriptionStatisticsOutput } from "@/types/audience";
import { encodeDelimitedArray } from "use-query-params";

export const audiencesApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getAudiences: builder.query<GetAudiencesResponse, GetAudiencesRequest>({
            query: params => {
                const { limit, offset, searchTerm, sort, order, filters } = params;
                const searchParams = new URLSearchParams();

                if (limit) searchParams.set("limit", String(limit));
                if (offset) searchParams.set("offset", String(offset));
                if (searchTerm) searchParams.set("name", searchTerm);
                if (sort) searchParams.set("sort", sort);
                if (order) searchParams.set("order", order);
                if (filters) {
                    searchParams.set("favorite", filters.inFavorites ? "true" : "false");
                    searchParams.set("status", encodeDelimitedArray(filters.status, ",") ?? "");
                    searchParams.set("destinations", encodeDelimitedArray(filters.destinations, ",") ?? "");
                }

                return `/audiences?${searchParams.size ? searchParams.toString() : ""}`;
            },
            providesTags: ["audiences"],
        }),
        getAudience: builder.query<GetAudienceResponse, GetAudienceRequest>({
            query: ({ id }) => `/audiences/${id}`,
            providesTags: (_result: GetAudienceResponse | undefined, _error: unknown, { id }) => [
                { type: "audience", id },
            ],
        }),
        getAudienceRuns: builder.query<GetAudienceRunsResponse, GetAudienceRunsRequest>({
            query: ({ audienceId, offset, limit }) => {
                const searchParams = new URLSearchParams();
                if (limit) searchParams.set("limit", String(limit));
                if (offset) searchParams.set("offset", String(offset));

                return `/audiences/${audienceId}/runs?${searchParams.size ? searchParams.toString() : ""}`;
            },
        }),
        createAudience: builder.mutation<CreateAudienceResponse, CreateAudienceRequest>({
            query: ({ name, destinations, source_criteria }) => ({
                url: `/audiences`,
                method: "POST",
                body: { name, destinations, source_criteria },
            }),
            invalidatesTags: ["audiences"],
        }),
        updateAudience: builder.mutation<CreateAudienceResponse, UpdateAudienceRequest>({
            query: ({ id, ...rest }) => ({
                url: `/audiences/${id}`,
                method: "PATCH",
                body: rest,
            }),
            invalidatesTags: (
                _result: CreateAudienceResponse | undefined,
                _error: unknown,
                { id }: UpdateAudienceRequest,
            ) => ["audiences", { type: "audience", id }, "statistics"],
        }),
        duplicateAudience: builder.mutation<DuplicateAudienceResponse, DuplicateAudienceRequest>({
            query: ({ audienceId }) => ({
                url: `/audiences/${audienceId}`,
                method: "POST",
                body: { audienceId },
            }),
            invalidatesTags: ["audiences"],
        }),
        favoriteAudience: builder.mutation<null, FavoriteAudienceRequest>({
            query: ({ audienceId, favorite }) => ({
                url: `/users/settings`,
                method: "PATCH",
                body: {
                    favorite: { audienceId, favorite },
                },
            }),
            invalidatesTags: (_result: null | undefined, _error: unknown, { audienceId }) => [
                "audiences",
                { type: "audience", id: audienceId },
            ],
        }),
        createAudienceShape: builder.mutation<GetAudienceResponse, CreateAudienceShapeRequest>({
            query: ({ audienceId, updateEstimate, ...body }) => ({
                url: `/audiences/${audienceId}/shape?updateEstimate=` + updateEstimate,
                method: "POST",
                body,
                getMaxTries: () => {
                    // primer-platform communication already has retries configured.
                    // We don't want to retry from FE because every retry creates new audienceShape and will result in a new pagerduty alert
                    // Number of retries = Number of new shapes = Number of new pages
                    return 1;
                },
            }),
            invalidatesTags: (_result: GetAudienceResponse | undefined, _error: unknown, { audienceId }) => [
                { type: "audience", id: audienceId },
                { type: "estimate-heuristics", id: audienceId },
                { type: "primer-filters" },
            ],
        }),
        getAudienceShapeHeuristic: builder.query<AudienceShapeEstimates, GetAudienceShapeEstimateHeuristicsRequest>({
            query: ({ audienceId, shapeId }) => ({
                url: `/audiences/${audienceId}/${shapeId}/estimate/heuristics`,
                method: "GET",
            }),
            providesTags: (_result: AudienceShapeEstimates | undefined, _error: unknown, { audienceId }) => [
                { type: "estimate-heuristics", audienceId },
            ],
        }),
        runAudience: builder.mutation<null, RunAudienceRequest>({
            query: ({ audienceId }) => ({
                url: `/audiences/${audienceId}/run`,
                method: "POST",
                body: {},
            }),
            invalidatesTags: (_result: null | undefined, _error: unknown, { audienceId }) => [
                "audiences",
                "subscription",
                "onboarding",
                { type: "audience", id: audienceId },
                "statistics",
            ],
        }),
        rerunAudience: builder.mutation<null, RerunAudienceRequest>({
            query: ({ audienceId, runId }) => ({
                url: `/audiences/${audienceId}/rerun/${runId}`,
                method: "POST",
                body: {},
            }),
            invalidatesTags: (_result: null | undefined, _error: unknown, { audienceId }) => [
                "audiences",
                { type: "audience", id: audienceId },
            ],
        }),
        statistics: builder.query<SubscriptionStatisticsOutput, void>({
            query: () => `/subscription/statistics`,
            providesTags: ["statistics"],
        }),
    }),
});

export const {
    useGetAudiencesQuery,
    useGetAudienceQuery,
    useGetAudienceRunsQuery,
    useGetAudienceShapeHeuristicQuery,
    useFavoriteAudienceMutation,
    useCreateAudienceMutation,
    useUpdateAudienceMutation,
    useCreateAudienceShapeMutation,
    useRunAudienceMutation,
    useRerunAudienceMutation,
    useDuplicateAudienceMutation,
    usePrefetch,
    useStatisticsQuery,
} = audiencesApiSlice;
