import React from 'react';

interface HighlightProps {
  text: string;
  searchTerm: string;
  className?: string;
}

const Highlight: React.FC<HighlightProps> = ({ text, searchTerm, className }) => {
  if (!searchTerm) return <span>{text}</span>;

  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = text.split(regex);
  return (
    <span>
      {parts.map((part, index) => (
        regex.test(part) ?
          <mark key={index} className={`bg-blue-200 ${className}`}>{part}</mark> :
          <span key={index}>{part}</span>
      ))}
    </span>
  );
};

export default Highlight;
