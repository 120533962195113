import { useNavigate, useParams } from "react-router";

import { Service } from "@/types/integrations";
import { Main } from "@/components/organisms/Main";
import { Header } from "@/components/organisms/Header";
import { Button } from "@/components/atoms/Button/Button";
import { getNameForService } from "@/config/integrations";
import BackIcon from "@/assets/icons/arrow-left.svg?react";
import { Account } from "@/components/organisms/integrations/Account";
import { MatchRateDetails } from "@/components/organisms/integrations/MatchRateDetails";
import { useConnections } from "./ConnectionsContext";

export const IntegrationSettingsPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { connections } = useConnections();
    const goBack = () => navigate("/integrations");

    const service = id ? Object.values(Service).find(s => s === id.toLowerCase()) : null;
    if (!service) goBack();

    const account = connections?.find(({ provider }) => provider === service);

    return (
        <>
            <Header
                title={`${getNameForService(service!)} Integration Settings`}
                leftTools={
                    <Button variant="secondary" onClick={goBack}>
                        <BackIcon />
                    </Button>
                }
            ></Header>
            <Main>
                <Account service={service!} account={account} />
                <MatchRateDetails service={service!} account={account} />
            </Main>
        </>
    );
};
