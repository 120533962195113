import { addMilliseconds, differenceInHours, differenceInMilliseconds, differenceInSeconds } from "date-fns";
import { expectedRunDuration } from "@/utils/runDurationPredict";
import { useEffect, useMemo, useState } from "react";

import HourglassIcon from "@/assets/icons/hourglass.svg?react";
import PendingClockIcon from "@/assets/icons/pending-clock.svg?react";
import ClockOutlinedIcon from "@/assets/icons/clock-outlined.svg?react";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";

export interface AudienceRunningCountDownProps {
    buildRequestedAt: Date;
    size: number;
    destinations: string[];
}

const AudienceRunningCountDown = ({ buildRequestedAt, size, destinations }: AudienceRunningCountDownProps) => {
    const expectedDuration = expectedRunDuration(destinations, buildRequestedAt, size);
    const expectedEndDate = addMilliseconds(buildRequestedAt, expectedDuration);
    const aDecimalPercent = expectedDuration / 100;

    const [percentage, setPercentage] = useState(differenceInMilliseconds(new Date(), buildRequestedAt) / aDecimalPercent);
    const [isOverTime, setIsOverTime] = useState(new Date() > expectedEndDate);
    const [isTooOverTime, setIsTooOverTime] = useState(differenceInHours(new Date(), buildRequestedAt) > 2);

    useEffect(() => {
        const now = new Date();
        const percentageTimerId = setTimeout(() => setPercentage(differenceInMilliseconds(new Date(), buildRequestedAt) / aDecimalPercent), 10);
        setIsOverTime(now > expectedEndDate);
        setIsTooOverTime(differenceInHours(new Date(), buildRequestedAt) > 2);
        return () => clearTimeout(percentageTimerId);
    }, [buildRequestedAt, percentage, expectedEndDate, aDecimalPercent]);

    const durationDifference = useMemo(() => {
        const now = new Date();
        let diffInSeconds = now < expectedEndDate ? differenceInSeconds(expectedEndDate, now) : differenceInSeconds(now, buildRequestedAt);
        const hours = Math.floor(diffInSeconds / 3600);
        diffInSeconds -= hours * 3600;
        const minutes = Math.floor(diffInSeconds / 60);
        const seconds = diffInSeconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expectedEndDate, buildRequestedAt, percentage]);

    const overTimeTooltipContent = <div className="flex flex-col gap-3">
        <div className="flex gap-2 text-white">
            <HourglassIcon className="text-ui-400" /><span>This is taking longer than expected.</span>
        </div>
        <div>We have been notified and are on it. For immediate assistance, please feel free to <a className="text-blue-500" target="_blank" href="mailto:support@sayprimer.com">reach out to support</a> if you need assistance.</div>
    </div>

    const onTimeContent = (<div className="flex gap-2 text-ui-300 text-sm items-center overflow-visible px-4 py-2">
        <PendingClockIcon className="min-w-5 min-h-5" /> <div className="min-w-fit w-full hidden lg:block">Audience will finish in...</div> <div className="ml-auto w-[65px]">{durationDifference}</div>
    </div>);
    const ontimeTooltipContent = (<div className="flex gap-2 text-white">
        <PendingClockIcon className="text-ui-400" /><span>Audience will finish in....</span>
    </div>);

    return (
        <>
            {!isOverTime && <div className="bg-ui-50 text-ui-700 rounded-lg flex justify-start flex-col relative w-fit lg:w-[350px]">
                <div className="bg-ui-200 rounded-lg h-full absolute" style={{
                    width: `${percentage}%`,
                }}>
                </div>
                <Tooltip className="max-w-80" content={ontimeTooltipContent}>
                    <div className="block lg:hidden">{onTimeContent}</div>
                </Tooltip>
                <div className="hidden lg:block">
                    {onTimeContent}
                </div>
            </div>}
            {!isTooOverTime && isOverTime &&
                <div className="bg-ui-50 text-ui-700  rounded-lg flex justify-start flex-col w-fit lg:w-[350px]">
                    <div className="flex gap-2 text-sm items-center overflow-visible px-4 py-2">
                        <ClockOutlinedIcon className="min-w-5 min-h-5" /> <div className="min-w-fit shidden lg:block">Slight delayed, please hold on...</div> <div className="ml-auto w-[65px]">{durationDifference}</div>
                    </div>
                </div>}
            {isTooOverTime &&
                <Tooltip className="!max-w-[291px]" content={overTimeTooltipContent}>
                    <div className="bg-warning-background rounded-lg flex justify-start flex-col hover:bg-warning-active w-fit lg:w-[350px]">
                        <div className="flex gap-2 text-warning text-sm items-center overflow-visible px-4 py-2">
                            <HourglassIcon className="min-w-5 min-h-5" /> <div className="min-w-fit hidden lg:block">Taking longer than expected...</div> <div className="ml-auto w-[65px]">{durationDifference}</div>
                        </div>
                    </div>
                </Tooltip>}
        </>
    );
};

export default AudienceRunningCountDown;
