import { useEffect, useMemo, useState } from "react";
import { GetConnectionsResponse } from "@/types/api";
import { Button } from "@/components/atoms/Button/Button";
import { useUpdateOnboardingMutation } from "@/api/users";
import { useGetConnectionsQuery } from "@/api/integrations";
import InfoIcon from "@/assets/icons/info-outline.svg?react";
import { ConnectionStatus, Service } from "@/types/integrations";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import AddPlatformsIcon from "@/assets/images/onboarding/ad-platfrom-connect.svg";
import { useOnboarding } from "@/components/organisms/onboarding/OnboardingContext";
import { adPlatformCards, getIconForService, getOAuthUrl } from "@/config/integrations";
import { ConnectionStatusPill } from "@/components/organisms/integrations/ConnectionStatus";
import { OnboardingSkeleton, OnboardingWrapper } from "@/components/organisms/onboarding/OnboardingWrapper";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { AmazonIcon, GoogleIcon, LinkedInIcon, MetaIcon, MicrosoftIcon, RedditIcon, RollworksIcon, TikTokIcon, TwitterIcon } from "@/assets/images/onboarding/ad-options";
import { uniq } from "lodash";
import Checkbox from "@/components/atoms/Checkbox/Checkbox";
import { OnboardingStep } from "./Onboarding";
import cn from "classnames";

const NONE_OF_THE_ABOVE = "None of the above";
const options = [
    [
        { value: "Amazon Ads", icon: <AmazonIcon /> },
        { value: "Google Ads", icon: <GoogleIcon /> },
        { value: "LinkedIn Ads", icon: <LinkedInIcon /> },
        { value: "Meta Ads", icon: <MetaIcon /> },
        { value: "Microsoft Ads", icon: <MicrosoftIcon /> },
    ],
    [
        { value: "Reddit Ads", icon: <RedditIcon /> },
        { value: "Rollworks", icon: <RollworksIcon /> },
        { value: "TikTok Ads", icon: <TikTokIcon /> },
        { value: "Twitter Ads", icon: <TwitterIcon /> },
        { value: NONE_OF_THE_ABOVE },
    ]
]

export const AddPlatformsConnect = () => {
    const { onboarding, setCurrentStep } = useOnboarding();
    const [updateOnboarding] = useUpdateOnboardingMutation();

    const [selectedAds, setSelectedAds] = useState<string[] | undefined>([]);
    const [disabledOptions, setDisabledOptions] = useState<string[]>([]);

    const toggleCrm = (crm: string) => {
        if(disabledOptions.includes(crm)) return;

        if (!selectedAds?.includes(crm)) {
            setSelectedAds(prev => uniq([...(prev ?? []), crm]));
        } else {
            setSelectedAds(prev => prev?.filter(c => c !== crm));
        }
    }

    const disableConflictingOptions = (selectedAds?: string[]) => {
        if (selectedAds?.includes(NONE_OF_THE_ABOVE)) {
            setDisabledOptions(options.flat().filter(o => o.value !== NONE_OF_THE_ABOVE).map(o => o.value));
        } else if (!selectedAds || selectedAds?.length === 0) {
            setDisabledOptions([]);
        } else {
            setDisabledOptions([NONE_OF_THE_ABOVE, ...connectedServicesOptions]);
        }
    }

    useEffect(() => {
        disableConflictingOptions(selectedAds);
    }, [selectedAds]);

    const { data: connections, isLoading } = useGetConnectionsQuery(
        { enabled: true },
        { refetchOnMountOrArgChange: true },
    );

    const atLeastOnePlatformConnected = useMemo(
        () =>
            adPlatformCards.some(({ service }) => {
                const item = connections?.find(connection => connection.provider === service);
                return item?.state === ConnectionStatus.CONNECTED || item?.state === ConnectionStatus.INITIATED;
            }),
        [connections],
    );

    const connectedServicesOptions = useMemo(() => options.flat()
        .map(option => {
            const matchingConnection = connections?.find(
                connection =>
                    option.value?.toLowerCase().includes(connection.provider) &&
                    (connection.state === ConnectionStatus.CONNECTED || connection.state === ConnectionStatus.INITIATED)
            );
            return matchingConnection ? option.value : undefined;
        })
        .filter((service): service is string => service !== undefined),
        [connections],
    );

    const automaticallyCheckSelectedAds = (connectedServicesOptions: string[]) => {
        setSelectedAds(prev => [...(prev?.filter(p => p !== NONE_OF_THE_ABOVE) ?? []), ...connectedServicesOptions]);
        setDisabledOptions(prev => [...(prev?.filter(p => p !== NONE_OF_THE_ABOVE) ?? []), ...connectedServicesOptions]);
    }

    useEffect(() => {
        if (connectedServicesOptions && connectedServicesOptions.length > 0) {
            automaticallyCheckSelectedAds(connectedServicesOptions);
        }
    }, [connections]);

    const nextStep = async () => {
        await updateOnboarding({
            id: onboarding.id,
            ...(atLeastOnePlatformConnected && !onboarding.add_platform_connected ? { add_platform_connected: true } : {}),
            ad_sources: selectedAds,
        });

        setCurrentStep(OnboardingStep.ONBOARDING_LAST_STEP);
    };

    useEffect(() => setSelectedAds(uniq([...(onboarding?.ad_sources ?? []), ...(connectedServicesOptions)])), [onboarding]);

    const alreadyConnectedByOtherUser = !isLoading && atLeastOnePlatformConnected && !onboarding.add_platform_connected;

    if (!connections) return <OnboardingSkeleton />;

    return (
        <OnboardingWrapper imgUrl={AddPlatformsIcon}>
            <div className="w-full">
                <h1 className="text-h1 mb-2">Connect your ad accounts</h1>
                <p className="text-16">Send custom audiences to all major ad platforms.</p>
            </div>

            <div className="space-y-2 w-full">
                {adPlatformCards.map(({ service, name }) => (
                    <AddPlatformItem key={service} service={service} name={name} connections={connections} />
                ))}
            </div>

            {alreadyConnectedByOtherUser && (
                <div className="w-full flex items-center p-3 rounded-lg bg-blue-50 border border-blue-200 space-x-2 text-blue-870 !mt-2">
                    <InfoIcon className="mb-0.5" />
                    <div className="text-14">Already connected for your org by another user.</div>
                </div>
            )}

            <hr className="w-full border-t-ui-200 my-2" />

            <div>
                In the future would you like to send audiences to any of these ad platforms?
            </div>
            <div className="px-6 py-4 w-full bg-white border border-ui-200 rounded-lg grid grid-cols-2 gap-3">
                {options.map((optionList, index) => (
                    <div className="flex flex-col gap-3" key={`column-${index}`}>
                        {optionList.map(o => (
                            <div key={o.value} className="flex gap-2 items-center">
                                <Checkbox
                                    value={o.value}
                                    checked={selectedAds?.includes(o.value)}
                                    onCheckedChange={() => toggleCrm(o.value)}
                                    disabled={disabledOptions.includes(o.value)}
                                    customDisabledStyle={true}
                                />
                                <div
                                    className={cn("flex gap-2 items-center", {
                                        "text-ui-300 cursor-default": disabledOptions.includes(o.value),
                                        "cursor-pointer": !disabledOptions.includes(o.value)
                                    })}
                                    role="tab"
                                    onClick={() => toggleCrm(o.value)}
                                >
                                    {!!o.icon && <span className={cn({
                                        "opacity-50": disabledOptions.includes(o.value)
                                    })}>{o.icon}</span>} {o.value}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <Tooltip hidden={!!selectedAds && selectedAds?.length > 0} content={"Please select at least one of the options above."} side="right">
                <Button className="flex items-center gap-0.5" onClick={nextStep} disabled={!selectedAds || selectedAds?.length === 0}>
                    Next
                    <ArrowRightIcon />
                </Button>
            </Tooltip>
        </OnboardingWrapper>
    );
};

interface Props {
    name: string;
    service: Service;
    connections: GetConnectionsResponse | undefined;
}
export const AddPlatformItem = ({ service, name, connections }: Props) => {
    const icon = getIconForService(service, "h-9 w-9");

    const connection = connections?.find(({ provider }) => provider === service);
    const isConnected =
        connection?.state === ConnectionStatus.CONNECTED || connection?.state === ConnectionStatus.INITIATED;

    const connect = () => {
        const connectUrl = getOAuthUrl(service);
        if (connectUrl) window.location.href = connectUrl;
    };

    return (
        <div className="flex items-center justify-between px-6 py-4 bg-white border border-ui-200 rounded-lg">
            <div className="flex items-center gap-4">
                {icon}
                <div>
                    <div className="text-16">{name}</div>
                    <span className="text-10 text-error">{connection?.error?.message}</span>
                </div>
            </div>

            {!isConnected && (
                <Button onClick={connect} attributes={{ "data-ph-capture-attribute-service-name": service }}>
                    Connect
                </Button>
            )}
            {isConnected && <ConnectionStatusPill status={connection.state} service={service} />}
        </div>
    );
};
