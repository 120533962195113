import cn from "classnames";
import { PaginationOutput } from "@/hooks";
import { Button } from "@/components/atoms/Button/Button.tsx";
import ArrowLeftIcon from "@/assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";

const Pagination = ({ total, limit, from, to, prev, next, className }: PaginationOutput) => {
    const perPageCount = to > total ? total : to;
    if (total <= limit) return null;

    return (
        <div className={cn("flex items-center justify-center", className)}>
            <Button disabled={from - limit < 0} variant="secondary" size="icon" title="Previous page" onClick={prev}>
                <ArrowLeftIcon className="w-5 h-5" />
            </Button>
            <span className="text-sm text-ui-700 mx-4">{`${from || 1}-${perPageCount} of ${total}`}</span>
            <Button disabled={from + limit >= total} variant="secondary" size="icon" title="Next page" onClick={next}>
                <ArrowRightIcon className="w-5 h-5" />
            </Button>
        </div>
    );
};

export default Pagination;
