import { Tooltip } from "@/components/atoms/Tooltip/Tooltip.tsx";
import InfoCircledIcon from "@/assets/icons/info.svg?react";
import AccountIcon from "@/components/molecules/AccountIcon/AccountIcon.tsx";
import { Destination } from "@/types/audience";
import cn from "classnames";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { DestinationStatus } from "./DestinationStatus";
import ErrorMessage from "@/components/molecules/ErrorMessage/ErrorMessage";
import { ConnectionError } from "@/types/integrations";
import { Button } from "@/components/atoms/Button/Button";
import { Link } from "react-router-dom";

export interface DestinationProps {
    name: string;
    service: Destination;
    helpText?: string;
    selected: boolean;
    onSelectionChange: (destination: string, selected: boolean) => void;
    loading?: boolean;
    disabled?: boolean;
    connected?: boolean;
    error?: ConnectionError;
}

export const DestinationItem = ({
    name,
    service,
    helpText,
    selected,
    onSelectionChange,
    loading = false,
    disabled = false,
    connected = false,
    error,
}: DestinationProps) => {
    const selectionChangeEnabled = !loading && !disabled && connected && !error;
    const errorMessage = (error?.message || "Connection Error.") + ` Click here to go to integrations page.`

    return (
        <Button
            id={`destination-item-${service}`}
            data-state={selected}
            data-disabled={disabled}
            className={cn("rounded-lg !p-4 h-auto flex items-start justify-center flex-col cursor-default flex-1", {
                "border-2 border-blue-300 bg-blue-50 border-solid": selected, // UI remains same for selected regardless of disabled state
                "my-[1px] mx-[1px]": !selected,
                "border border-ui-300/[.32] border-solid": !selected && !disabled,
                "border border-ui-300/[.32] bg-ui-50 border-dashed": !selected && disabled,
                "hover:bg-blue-50 cursor-pointer": selectionChangeEnabled,
            })}
            onClick={() => {
                if (selectionChangeEnabled) {
                    onSelectionChange(service, !selected);
                }
            }}
            variant="ghost"
            tabIndex={selectionChangeEnabled ? 0 : -1}
        >
            <div className="flex-1 flex items-center justify-between w-full">
                {loading ? (
                    <Skeleton className={"h-4 w-1/2 rounded-xl"} />
                ) : (
                    <span className="flex items-center">
                        <AccountIcon service={service} className="mr-2" disabled={disabled} />
                        <p
                            data-disabled={disabled}
                            className={cn("inline mr-2 text-sm", {
                                "text-ui-300": disabled,
                                "text-ui-700": selected || !disabled,
                            })}
                        >
                            {name}
                        </p>
                        {helpText && (
                            <Tooltip content={helpText}
                                side="right"
                                sideOffset={0}
                                className="w-[300px] whitespace-normal"
                            >
                                <InfoCircledIcon className="text-ui-300/[0.5]" />
                            </Tooltip>
                        )}
                    </span>
                )}
                <DestinationStatus
                    service={service}
                    selected={selected}
                    loading={loading}
                    disabled={disabled}
                    connected={connected}
                    error={error}
                />
                {error && (
                    <Link to="/integrations" className="h-[26px] border border-ui-300/[.32] border-solid rounded-lg hover:border-red">
                        <Tooltip side="bottom" content={errorMessage} className="text-wrap text-left max-w-[300px]" fullWidth>
                            <ErrorMessage className="h-[24px]" error="Error" />
                        </Tooltip>
                    </Link>
                )}
            </div>
        </Button>
    );
};
