import cn from "classnames";
import { Button } from "@/components/atoms/Button/Button";
import { CheckIcon } from '@radix-ui/react-icons';
import { formatStripeAmount } from "../../../../utils/number";
import { TalkToSalesButton } from "./TalkToSalesButton";
import { PlanConfig } from "./UpgradePlanModalV2";
import { SubscriptionItems } from "../../../../types/subscription";

interface Props {
    config: PlanConfig;
    currentAudienceSubscriptionItem?: SubscriptionItems;
    currentPlanLevel: number;
    isTrial: boolean;
    isPausedOrCanceled: boolean;
    onCheckout: (config: PlanConfig) => void;
}

export const PlanCard = ({ config, currentAudienceSubscriptionItem, currentPlanLevel, onCheckout, isTrial, isPausedOrCanceled }: Props) => {
    const { planLevel, audienceLimit, amount, frequency, discount, forAdSpend, originalAmount } = config;
    const discountPercentage = discount && originalAmount ? Math.round((discount / originalAmount) * 100) : undefined;
    const isCurrentPlan = currentPlanLevel === planLevel;
    const audienceLimitMerged = isCurrentPlan && currentAudienceSubscriptionItem && currentAudienceSubscriptionItem.quantity > audienceLimit
        ? currentAudienceSubscriptionItem.quantity
        : audienceLimit;
    const audienceLimitDescription = audienceLimitMerged > 8 ? `+${audienceLimitMerged}` : audienceLimitMerged;

    return (
        <div className="w-[280px] flex flex-col gap-4 border rounded-2xl border-blue-200 z-50">
            <div className="flex gap-2 items-center">
                <div className={cn('w-full h-fit rounded-t-2xl py-4 px-6', {
                    'bg-blue-850': planLevel === 1,
                    'bg-blue-870': planLevel === 2,
                    'bg-ui-900': planLevel === 3
                })}>
                    <div className="flex flex-col">
                        <div className="text-xs font-medium text-white uppercase">For Ad Spend</div>
                        <div className="text-base text-white">{forAdSpend}</div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-5 px-6 py-4">
                <div className="flex flex-col gap-1">
                    <div className="text-xs text-ui-300 uppercase">Audiences Included</div>
                    <div className="text-4xl text-ui-900">{audienceLimitDescription}</div>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="text-xs font-medium text-ui-300 uppercase">Price</div>
                    <div className="text-4xl text-ui-900">{formatStripeAmount(amount, 0)}</div>
                    <div className="flex items-center gap-2">
                        <span className="text-base">per {frequency === 'yearly' ? 'year' : 'month'}</span>
                    </div>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="text-xs font-medium text-ui-300 uppercase">Billed</div>
                    <div className="flex items-center gap-2">
                        <div className="text-base">{frequency === 'yearly' ? 'Yearly' : 'Monthly'}</div>
                        {frequency === 'yearly' && discountPercentage && (
                            <div className="bg-green-100 border border-ui-100/[0.19] text-green-500 text-xs px-1 py-1 rounded">
                                Save {discountPercentage}%
                            </div>
                        )}
                    </div>
                </div>
                {isCurrentPlan && !isTrial && !isPausedOrCanceled &&
                    <Button
                        variant="secondary"
                        disabled={true}
                        className="flex items-center justify-center gap-2 border border-ui-200/32 !bg-blue-50 border-blue-200 !text-blue-800 shadow-none cursor-default"
                    >
                        <CheckIcon className="w-4 h-4" />Your Current Plan
                    </Button>}
                {!isCurrentPlan && (planLevel === 3 || planLevel < currentPlanLevel) && <TalkToSalesButton variant="secondary" />}
                {(!isCurrentPlan || isTrial || isPausedOrCanceled) && planLevel !== 3 && (currentPlanLevel < planLevel || isTrial || isPausedOrCanceled) && <Button
                    variant="secondary"
                    onClick={() => onCheckout(config)}
                >Continue to checkout
                </Button>}
            </div>
        </div>
    );
};
