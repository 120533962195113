import { ChangeEvent } from "react";

import SearchIcon from "@/assets/icons/search.svg?react";
import { Input } from "@/components/atoms/Input/Input";

interface Props {
    title: string;
    value: string;
    placeholder?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Search = ({ title, placeholder, value, onChange }: Props) => (
    <div className="relative  p-2 rounded-lg w-full">
        <SearchIcon className="absolute ml-2 mt-2 z-[1]" />
        <Input
            className="w-full pl-8 h-[36px]"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            title={title}
            showClearIcon
        />
    </div>
);
