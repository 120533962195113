import { api } from "@/api";
import { GetConnectionsRequest, GetConnectionsResponse } from "@/types/api";

export const integrationsApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getConnections: builder.query<GetConnectionsResponse, GetConnectionsRequest | void>({
            query: ({ enabled, state } = {}) => {
                const searchParams = new URLSearchParams();
                if (enabled) searchParams.set("enabled", String(enabled));
                if (state) searchParams.set("state", String(state));

                return `/connections?${searchParams.size ? searchParams.toString() : ""}`;
            },
            providesTags: () => [{ type: "connections" }],
        }),

        retryConnection: builder.mutation({
            query: ({ id }: { id: string }) => ({
                url: `/connections/${id}/retry`,
                method: "POST",
            }),
        }),

        oauthConnect: builder.mutation({
            query: ({ code, provider }) => ({
                url: `/oauth/${provider}`,
                method: "POST",
                body: { code },
            }),
        }),

        toogleConnection: builder.mutation({
            query: ({ connectionId, action }: { connectionId: string | number; action: "enable" | "disable" }) => ({
                url: `/connections/${connectionId}/${action}`,
                method: "PATCH",
            }),
            invalidatesTags: ["connections", "first-party-filters"],
        }),

        deleteConnection: builder.mutation({
            query: ({ connectionId }: { connectionId: string | number }) => ({
                url: `/connections/${connectionId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["connections"],
        }),
    }),
});

export const {
    useGetConnectionsQuery,
    useRetryConnectionMutation,
    useOauthConnectMutation,
    useToogleConnectionMutation,
    useDeleteConnectionMutation,
} = integrationsApiSlice;
