import { captureException } from "@sentry/react";
import { isRejected, Middleware, SerializedError } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery, FetchBaseQueryError, retry } from "@reduxjs/toolkit/query/react";

export const getHttpStatusCode = (error?: FetchBaseQueryError | SerializedError) => {
    if (!error) return undefined;

    const fetchBaseQueryError = error as FetchBaseQueryError;

    if (typeof fetchBaseQueryError.status === "number") return fetchBaseQueryError.status;
    if (fetchBaseQueryError.status === "PARSING_ERROR") return fetchBaseQueryError.originalStatus;

    return undefined;
};

const staggeredBaseQueryWithBailOut = retry(
    fetchBaseQuery({
        baseUrl: import.meta.env.VITE_API_URL,
        prepareHeaders: async headers => {
            const token = await (window as any).Clerk?.session.getToken();
            if (token) headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
        responseHandler: async response => {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                return await response.json();
            } else {
                return await response.text();
            }
        },
    }),
    {
        retryCondition: (error, baseQueryArgs, { attempt }) => {
            const customRetries = baseQueryArgs?.getMaxTries ? baseQueryArgs.getMaxTries(error) : undefined;
            const maxRetries = customRetries ?? 3;
            const canRetry = attempt < maxRetries;

            // all attempts exhausted
            if (!canRetry) return false;

            const statusCode = getHttpStatusCode(error || "");

            if (statusCode) {
                // 500 => any unexpected error from the server
                // 503 => this is sometimes seen when the pods are temporarily unavailable
                // 504 => this code is for handling clickhouse query timeout in audience estimates
                return [500, 503, 504].includes(statusCode);
            }

            // retry all errors where we don't have a status code, typically network error
            return true;
        },
    },
);

export const rtkQueryErrorMiddleware: Middleware = () => next => (action: any) => {
    if (isRejected(action)) {
        const message = action?.payload?.data || action?.error?.message || "Api Error occurred";
        const error = new Error(message);
        error.name = "ApiError";
        captureException(error);
    }
    return next(action);
};

// Initialize api service that we'll inject endpoints into
export const api = createApi({
    baseQuery: staggeredBaseQueryWithBailOut,
    refetchOnFocus: false,
    reducerPath: "api",
    tagTypes: [
        "Users",
        "audiences",
        "audience",
        "connections",
        "statistics",
        "subscription",
        "estimate-heuristics",
        "primer-filters",
        "first-party-filters",
        "onboarding",
        "csv-files",
    ],
    endpoints: () => ({}),
});
