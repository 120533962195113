import StatusWarningIcon from "@/assets/icons/status-warning.svg?react";
import StatusErrorIcon from "@/assets/icons/error-red.svg?react";
import { CriteriaError, ErrorType } from "@/hooks/useAudienceFilterValidation";
import cn from "classnames";

type CriteriaErrorBannerProps = {
    criteriaError?: CriteriaError;
}

const CriteriaErrorBanner = ({ criteriaError }: CriteriaErrorBannerProps) => {
    return (<>
        <p className={cn("flex rounded-[8px] py-2 px-2 mb-2 border-ui-300/[0.19] text-sm items-center", {
            "bg-error-background text-error": criteriaError?.errorType === ErrorType.ERROR,
            "bg-warning-background text-warning": criteriaError?.errorType === ErrorType.WARNING,
        })}>
            {criteriaError?.errorType === ErrorType.ERROR
                ? <StatusErrorIcon className="mr-2 min-w-[20px]" />
                : <StatusWarningIcon className="mr-2 min-w-[20px]" />}
            <span>{criteriaError?.message}</span>
        </p>
    </>);
}

export default CriteriaErrorBanner;
