import { useLocation, useNavigate } from "react-router";

import { AudienceStatus } from "@/types/audience";
import { PopoverClose } from "@/components/atoms/Popover";
import ArchivedIcon from "@/assets/icons/archived.svg?react";

export const ArchiveAudiencesAction = ({ onSuccess }: { onSuccess: () => void }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const triggerArchiveAudienceTooltip = () => {
        const onAudienceDashboard = location.pathname === "/audiences";
        const dashboardPath = location.search && `${location.search}&tooltip=archive`;

        const searchParams = new URLSearchParams(location.search);
        const statusParams = searchParams.getAll("status");

        const activeAudienceStatuses = [AudienceStatus.RUNNING, AudienceStatus.SYNCED, AudienceStatus.INCOMPLETE];
        const isAnyActiveStatusPresent = activeAudienceStatuses.some(status => statusParams.includes(status));

        navigate(`/audiences${onAudienceDashboard && isAnyActiveStatusPresent ? dashboardPath : "?tooltip=archive"}`);
        onSuccess();
    };
    return (
        <PopoverClose>
            <div
                className="py-4 hover:bg-blue-50 transition-colors cursor-pointer border-t border-ui-300/[0.19] rounded-b-lg"
                onClick={triggerArchiveAudienceTooltip}
            >
                <div className="mt-2 px-6 py-2 space-y-2">
                    <div className="flex gap-1 items-center text-primary">
                        <ArchivedIcon className="text-primary mb-0.5" />
                        <div className="ml-1 font-medium">View Audiences to Archive</div>
                    </div>
                    <div className="text-left">
                        Archive unused audiences through the ellipsis menu to free up slots.
                    </div>
                </div>
            </div>
        </PopoverClose>
    );
};
