import { useGetOnboardingQuery } from "@/api/users";
import { Onboarding } from "@/types/api";
import { createContext, useContext, ReactNode } from "react";
import { useQueryParams, withDefault } from "use-query-params";

interface OnboardingContextProps {
    currentStep: number;
    setCurrentStep: (step: number) => void;
    onboarding: Onboarding;
}

const OnboardingContext = createContext<OnboardingContextProps | undefined>(undefined);

const StepParam = withDefault(
    {
        encode(value: number) {
            return `${value}`;
        },
        decode(strValue) {
            const regex = /^[1-5]$/;
            return regex.test(strValue as string) ? parseInt(strValue as string) : 1;
        },
    },
    1,
);

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
    const [query, setQuery] = useQueryParams({ step: StepParam }, { removeDefaultsFromUrl: false });

    const { data: onboarding } = useGetOnboardingQuery();

    const setCurrentStep = (step: number) => {
        setQuery({ step });
    };

    return (
        <OnboardingContext.Provider
            value={{
                currentStep: query.step,
                setCurrentStep,
                onboarding: onboarding!,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};

export const useOnboarding = (): OnboardingContextProps => {
    const context = useContext(OnboardingContext);
    if (context === undefined) {
        throw new Error("useOnboarding must be used within an OnboardingProvider");
    }
    return context;
};
