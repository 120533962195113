import { isNil } from "lodash";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";

import {
    Filter,
    FilterDataTypes,
    FilterEntityTypes,
    FilterOperators,
    GroupLogicalOperator,
} from "@primer/filters/types";

import { useGetAudienceQuery, useCreateAudienceMutation, useCreateAudienceShapeMutation } from "@/api/audiences";
import { AppState } from "@/store";

import {
    AudiencesEnrichmentCriteria,
    CreateAudienceRequest,
    CreateAudienceResponse,
    CreateAudienceShapeRequest,
    CreateAudienceShapeResponse,
} from "@/types/api";
import { SourceCriteriaFilter } from "@/types/audience";
import { CsvFile } from "@/types/csv";

export const combineFilters = (importedDataset: CsvFile, currentFilters?: SourceCriteriaFilter[]): Filter[] => {
    const filters = [...(currentFilters || [])] as Filter[];
    const existingDatasetFilterIdx = filters.findIndex(f => Boolean(f.mappingTable));

    const newFilter = {
        unique_id: uuidv4(),
        entity_type: importedDataset.entityType,
        field: importedDataset.name,
        mappingTable: importedDataset.mappingTable,
        dataType: FilterDataTypes.STRING,
        operator: FilterOperators.IS_WITHIN,
        values: [],
    };
    if (existingDatasetFilterIdx > -1) {
        filters[existingDatasetFilterIdx] = newFilter;
    } else {
        filters.push(newFilter);
    }

    return filters;
};

export const useSaveCriteria = ({ onImportError }: { onImportError: () => void }) => {
    const { audienceId } = useSelector((state: AppState) => state["csv-import"]);
    const { name, destinations } = useSelector((state: AppState) => state["audience"]);
    const navigate = useNavigate();
    const isNewAudience = isNil(audienceId);

    const { data: audience } = useGetAudienceQuery(
        { id: audienceId! },
        {
            skip: isNewAudience,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
        },
    );
    const audienceFiltersRef = useRef(audience?.shape?.source_criteria?.group?.filters);

    const [createAudienceShapeMutation, { error: createAudienceShapeError }] = useCreateAudienceShapeMutation();
    const [createAudienceMutation, { error: createAudienceError }] = useCreateAudienceMutation();
    const createAudienceShape = async (req: CreateAudienceShapeRequest): Promise<CreateAudienceShapeResponse> =>
        createAudienceShapeMutation(req).unwrap();
    const createAudience = async (req: CreateAudienceRequest): Promise<CreateAudienceResponse> =>
        createAudienceMutation(req).unwrap();

    useEffect(() => {
        if (createAudienceShapeError || createAudienceError) {
            onImportError();
        }
    }, [onImportError, createAudienceShapeError, createAudienceError]);

    const saveCriteria = async (importedDataset: CsvFile) => {
        const filters = combineFilters(importedDataset, audienceFiltersRef.current);

        const source_criteria = {
            target_entity_type: FilterEntityTypes.PERSON,
            group: {
                operator: GroupLogicalOperator.AND,
                filters,
                groups: [],
            },
        };

        if (isNewAudience) {
            const newAudience = await createAudience({
                name,
                source_criteria,
                destinations,
            });
            navigate(`/audiences/${newAudience.id}`, {
                replace: true,
                state: {
                    from: location.pathname,
                    audience: newAudience,
                },
            });
        } else {
            await createAudienceShape({
                audienceId,
                enrichment_criteria: {
                    categories: [AudiencesEnrichmentCriteria.AD],
                },
                source_criteria,
                updateEstimate: true,
            });
            navigate(`/audiences/${audienceId}`, { replace: true });
        }
    };

    return { saveCriteria };
};
