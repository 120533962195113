import cn from "classnames";
import InfoIcon from "@/assets/icons/info.svg?react";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import RowAction from "./RowAction";

const RowContent: React.FC<{
    onExcludeClick: () => void;
    onIncludeClick: () => void;
    chartBackgroundColor: string;
    parsedLabel: React.ReactNode;
    parsedValue: React.ReactNode;
    isOther: boolean;
    allowExpand: boolean;
    isExpanding?: boolean;
    isUnspecified: boolean;
    isSelectedForExclusion: boolean;
    isSelectedForInclusion: boolean;
    name?: string;
    preventActions: boolean;
}> = ({
    onExcludeClick,
    onIncludeClick,
    chartBackgroundColor,
    parsedLabel,
    parsedValue,
    isOther,
    allowExpand,
    isExpanding,
    isUnspecified,
    isSelectedForExclusion,
    isSelectedForInclusion,
    name,
    preventActions,
}) => {
    const selectableRowAction = {
        onClick: () => {
            if (!isSelectedForExclusion) onIncludeClick();
        },
        role: "tab",
        tabIndex: 0,
        onKeyDown: (e: any) => {
            if (e.key === " ") {
                isSelectedForExclusion ? onExcludeClick() : onIncludeClick();
                e.preventDefault();
                e.stopEventPropagation();
            } else if (e.key === "Delete") {
                isSelectedForInclusion ? onIncludeClick() : onExcludeClick();
                e.preventDefault();
                e.stopEventPropagation();
            }
        },
    };
    return (
        <div
            {...(!isOther && !preventActions ? selectableRowAction : {})}
            style={{ "--bg-chart-default": chartBackgroundColor } as React.CSSProperties}
            className={cn(
                "text-start group flex gap-[4px] text-ui-700 h-8 text-[14px] w-full rounded-[8px] border border-transparent !bg-origin-border bg-chart-default items-center px-[4px] py-[8px]",
                {
                    "cursor-pointer": ((isOther && allowExpand) || !isSelectedForExclusion) && !preventActions,
                    "hover:bg-blue-50 hover:border-blue-300": !isSelectedForExclusion && !isSelectedForInclusion,
                    "bg-error-background hover:border-error-stroke": isSelectedForExclusion,
                    "bg-green-legacy-light hover:border-green-legacy": isSelectedForInclusion,
                    "hover:text-blue-800 decoration-ui-700 hover:decoration-blue-800": isOther && allowExpand,
                },
            )}
        >
            <div
                style={{ textUnderlineOffset: "3px" }}
                className={cn("truncate", {
                    "flex items-center": isUnspecified || isOther,
                    "underline decoration-dashed ": isOther && allowExpand,
                })}
            >
                <span className="truncate" title={parsedLabel?.toString()}>
                    {parsedLabel}
                </span>
                {isOther && !allowExpand && (
                    <Tooltip
                        content="Expanding 'Other' is limited to 100 values per category."
                        className="max-w-56 text-xs !cursor-default"
                        side="bottom"
                    >
                        <InfoIcon className="hidden group-hover:block ml-1" />
                    </Tooltip>
                )}
                {isUnspecified && (
                    <Tooltip
                        content={`These records do not have a value inside our database for ${name?.replace("Companies By ", "").toLowerCase()}.`}
                        className="max-w-56 text-xs hidden group-hover:flex !cursor-default"
                        side="bottom"
                    >
                        <InfoIcon className="hidden group-hover:block ml-1" />
                    </Tooltip>
                )}
            </div>
            <div className="w-[50px] ml-auto flex justify-end text-right">{parsedValue}</div>

            <RowAction
                disabled={isSelectedForInclusion || preventActions}
                isOther={isOther}
                allowExpand={allowExpand}
                isExpanding={isExpanding}
                isSelectedForExclusion={isSelectedForExclusion}
                onClick={onExcludeClick}
            />
        </div>
    );
};

export default RowContent;
