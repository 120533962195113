import { isEmpty } from "lodash";

export function FinishedDetails(props: {
    error: string | null | undefined;
    onClick: (e: React.MouseEvent) => Promise<void>;
}) {
    return (
        <span>
            <span>
                {isEmpty(props.error) ? (
                    <a className="text-sm text-blue-800 cursor-pointer" onClick={props.onClick}>
                        Audience
                    </a>
                ) : (
                    "Audience"
                )}{" "}
                sync {!isEmpty(props.error) ? "failed" : "succeeded"}
            </span>
            {!isEmpty(props.error) && (
                <p className="text-error [&_a]:underline" dangerouslySetInnerHTML={{ __html: props.error as string }} />
            )}
        </span>
    );
}
