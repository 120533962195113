import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import AccountIcon from "@/components/molecules/AccountIcon/AccountIcon";
import { GetEstimateMatchRateRawResponse } from "@/types/api";
import { Destination, DestinationsSchema } from "@/types/audience";
import GoogleDisplayIcon from "@/assets/icons/google.svg?react";
import GoogleSearchIcon from "@/assets/icons/google2.svg?react";
import YoutubeIcon from "@/assets/icons/youtube.svg?react";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import cn from "classnames";
import { formatDecimalNumber, formatNumberFloor } from "@/utils/number";

interface AudienceEstimateMatchRatesProps {
    matchRate?: GetEstimateMatchRateRawResponse;
    hasSizeError: boolean;
    sizeBelowMinimum: boolean;
    isLoadingMatchRate: boolean;
    isCriteriaAssetsLoading?: boolean;
    destinations?: DestinationsSchema;
    facebookMatchSizeError: boolean;
    maxSupportedSizeError: boolean;
}

const AudienceEstimateMatchRates = ({
    matchRate,
    hasSizeError,
    sizeBelowMinimum,
    isLoadingMatchRate,
    isCriteriaAssetsLoading = false,
    destinations,
    facebookMatchSizeError,
    maxSupportedSizeError,
}: AudienceEstimateMatchRatesProps) => {
    if (
        !destinations?.[Destination.META] &&
        !destinations?.[Destination.GOOGLE] &&
        !destinations?.[Destination.LINKEDIN]
    )
        return null;

    const matchedSizeError = facebookMatchSizeError
        ? "Minimum matched size for Meta Ads is >1.5K records"
        : maxSupportedSizeError
          ? "Refine your audience criteria to see the estimated match rate."
          : "";

    const formatNumber = (
        destination: keyof GetEstimateMatchRateRawResponse,
        matchRate?: GetEstimateMatchRateRawResponse,
    ) => {
        const low = formatNumberFloor(matchRate?.[destination]?.matchRateLow ?? 0);
        const high = formatNumberFloor(matchRate?.[destination]?.matchRateHigh ?? 0);
        return low !== high
            ? `${low}-${high}`
            : `${formatDecimalNumber(matchRate?.[destination]?.matchRateLow ?? 0, {
                  decimals: 2,
                  character: "",
              })}-${formatDecimalNumber(matchRate?.[destination]?.matchRateHigh ?? 0, {
                  decimals: 2,
                  character: "",
              })}`;
    };

    return (
        <div className="grid grid-cols-[max-content_1fr] gap-x-6 gap-y-2">
            <div>
                <div className="text-10 leading-3 spacing tracking-[0.3px] text-ui-300 mb-2">MATCH RATE ESTIMATES</div>
                <div className="flex flex-row gap-x-8">
                    {destinations?.[Destination.META] && (
                        <div className="flex flex-row items-center">
                            <AccountIcon service={Destination.META} className="w-7 h-7 mr-2" />
                            {isLoadingMatchRate ? (
                                <Skeleton className="w-20 h-6" />
                            ) : (
                                <span id="metaMatchRateEstimate" className="text-lg leading-6 text-ui-900">
                                    {isCriteriaAssetsLoading ||
                                    !matchRate ||
                                    maxSupportedSizeError ||
                                    facebookMatchSizeError ||
                                    sizeBelowMinimum
                                        ? "—"
                                        : formatNumber(Destination.META, matchRate)}
                                </span>
                            )}
                        </div>
                    )}
                    {destinations?.[Destination.GOOGLE] && (
                        <Tooltip
                            side="bottom"
                            hidden={
                                isCriteriaAssetsLoading ||
                                isLoadingMatchRate ||
                                !matchRate ||
                                maxSupportedSizeError ||
                                sizeBelowMinimum
                            }
                            content={
                                <div className="flex flex-col gap-3 p-1">
                                    <div className="flex flex-row">
                                        <GoogleSearchIcon className="w-5 h-5 mr-2" />
                                        <span className="min-w-32 text-sm leading-5">Google Search:</span>
                                        <span id="googleSearchMatchRateEstimate">
                                            {isCriteriaAssetsLoading ? "—" : formatNumber("google_search", matchRate)}
                                        </span>
                                    </div>
                                    <div className="flex flex-row">
                                        <YoutubeIcon className="w-5 h-5 mr-2" />
                                        <span className="min-w-32 text-sm leading-5">Youtube:</span>
                                        <span id="youtubeMatchRateEstimate">
                                            {isCriteriaAssetsLoading ? "—" : formatNumber("google_search", matchRate)}
                                        </span>
                                    </div>
                                    <div className="flex flex-row">
                                        <GoogleDisplayIcon className="w-5 h-5 mr-2" />
                                        <span
                                            id="googleDisplayMatchRateEstimate"
                                            className="min-w-32 text-sm leading-5"
                                        >
                                            Google Display:
                                        </span>
                                        <span>
                                            {isCriteriaAssetsLoading ? "—" : formatNumber("google_display", matchRate)}
                                        </span>
                                    </div>
                                </div>
                            }
                        >
                            <div className="flex flex-row items-center">
                                <AccountIcon service={Destination.GOOGLE} className="w-7 h-7 mr-2" />
                                {isLoadingMatchRate ? (
                                    <Skeleton className="w-20 h-6" />
                                ) : (
                                    <span id="googleMatchRateEstimate" className="text-lg leading-6 text-ui-900">
                                        {isCriteriaAssetsLoading ||
                                        !matchRate ||
                                        maxSupportedSizeError ||
                                        sizeBelowMinimum
                                            ? "—"
                                            : formatNumber("google_display", matchRate)}
                                    </span>
                                )}
                            </div>
                        </Tooltip>
                    )}
                    {destinations?.[Destination.LINKEDIN] && (
                        <div className="flex flex-row items-center">
                            <AccountIcon service={Destination.LINKEDIN} className="w-7 h-7 mr-2" />
                            {isLoadingMatchRate ? (
                                <Skeleton className="w-20 h-6" />
                            ) : (
                                <span id="linkedinMatchRateEstimate" className="text-lg leading-6 text-ui-900">
                                    {isCriteriaAssetsLoading || !matchRate || maxSupportedSizeError || sizeBelowMinimum
                                        ? "—"
                                        : formatNumber(Destination.LINKEDIN, matchRate)}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {(facebookMatchSizeError || maxSupportedSizeError) && !isLoadingMatchRate && !isCriteriaAssetsLoading && (
                <div
                    id={maxSupportedSizeError ? "max-size-for-match-rate-warning" : "min-size-for-match-rate-warning"}
                    className={cn("rounded-lg px-4 py-2 bg-ui-50 text-ui-700 text-xs", {
                        "col-span-2": hasSizeError,
                    })}
                >
                    {matchedSizeError}
                </div>
            )}
        </div>
    );
};

export default AudienceEstimateMatchRates;
