import React from "react";
import GoogleIcon from "@/assets/icons/google.svg?react";
import ProgressLoader from "@/components/atoms/ProgressLoader/ProgressLoader.tsx";

interface AccountsRetrievalProps {
    duration?: number; // Duration in seconds (default is 120 seconds)
    apiName?: string;
    HeaderComponent?: React.ComponentType;
}

export const GoogleIconsHeader = () => (
    <div className="flex justify-center mb-6 space-x-1">
        <GoogleIcon className="size-16 bg-white rounded-lg p-2" />
    </div>
);

export const AccountsRetrieval: React.FC<AccountsRetrievalProps> = ({
    duration = 120,
    apiName = "Google",
    HeaderComponent,
}) => {
    return (
        <div className="flex flex-col items-center justify-top h-screen text-gray-800 text-center">
            {HeaderComponent && <HeaderComponent />}
            <h1 className="text-h1 text-ui-700 mb-6">Retrieving Your {apiName} Ads Accounts...</h1>
            <div className="mb-6">
                <ProgressLoader className={"w-[485px]"} />
            </div>

            <p className="text-16 text-ui-700 mb-6">
                This may take up to {Math.round(duration / 60)} minute
                {Math.round(duration / 60) !== 1 ? "s" : ""} as we gather your account details.
                <br />
                Thank you for your patience.
            </p>
        </div>
    );
};

export default AccountsRetrieval;
