import cn from "classnames";
import pluralize from "pluralize";
import upperCase from "lodash/upperCase";

import { formatNumber } from "@/utils/number";
import { CrmMatchRates } from "@/types/audience";
import InfoIcon from "@/assets/icons/info.svg?react";
import PeopleIcon from "@/assets/icons/people.svg?react";
import { FilterEntityTypes } from "@primer/filters/types";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import CompaniesIcon from "@/assets/icons/companies.svg?react";
import StatusErrorIcon from "@/assets/icons/error-red.svg?react";
import StatusWarningIcon from "@/assets/icons/status-warning.svg?react";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { useMemo } from "react";

export interface AudienceSizeProps {
    entityType: FilterEntityTypes;
    className?: string;
    loading?: boolean;
    estimate?: number | null;
    sizeWarning?: boolean;
    sizeError?: boolean;
    hideLabel?: boolean;
    darkText?: boolean;
    showAudienceSize?: boolean;
    crmMatchRates?: CrmMatchRates;
    horizontal?: boolean;
    id?: string;
}

export const AudienceSize = ({
    darkText,
    estimate,
    hideLabel,
    className,
    entityType,
    crmMatchRates,
    loading = false,
    sizeError = false,
    sizeWarning = false,
    showAudienceSize = false,
    horizontal = false,
    id
}: AudienceSizeProps) => {
    const formattedSize = formatNumber(estimate, [100000, 1000000], showAudienceSize ? "" : "<");

    const showError = estimate != null && !loading;
    const showSizeWarning = showError && sizeWarning && id;
    const showSizeError = showError && sizeError && id;

    const tooltipText = useMemo(() => {
        if (!crmMatchRates) return null;

        const { account, contact, lead } = crmMatchRates;

        if (!account && !contact && !lead) return null;

        let base = "This audience is built from\nmatched CRM records in Primer\n";

        if (account) base += `\nAccount: ${account.match_rate?.toString().padStart(3, " ")}% Matched`;
        if (contact) base += `\nContact: ${contact.match_rate?.toString().padStart(3, " ")}% Matched`;
        if (lead) base += `\nLead:    ${lead.match_rate?.toString().padStart(3, " ")}% Matched`;

        return base;
    }, [crmMatchRates]);

    return (
        <div className={className}>
            {!hideLabel && (
                <p
                    className={cn("text-[10px] text-ui-300 uppercase tracking-[0.3px]", {
                        "mb-2": !horizontal,
                    })}
                >
                    {upperCase(pluralize(entityType))}
                    {horizontal ? " ESTIMATES" : ""}
                </p>
            )}
            <div
                aria-controls={showSizeError ? "error" : showSizeWarning ? "warning" : ""}
                className={cn("w-fit rounded-[8px] flex items-center", {
                    "bg-error-background text-error px-2": showSizeError,
                    "bg-warning-background text-warning px-2": !showSizeError && showSizeWarning,
                    "py-1": !horizontal,
                })}
            >
                <div className={cn("",{
                        "mr-2": !horizontal || (showSizeError || showSizeWarning),
                        "opacity-40": horizontal
                    })}>
                    {showSizeError && <StatusErrorIcon className={cn({ "w-7 h-7": horizontal })} />}
                    {!showSizeError && showSizeWarning && <StatusWarningIcon className={cn({ "w-7 h-7": horizontal })} />}
                    {!horizontal && !showSizeError && !showSizeWarning &&
                        (entityType === FilterEntityTypes.COMPANY ? <CompaniesIcon /> : <PeopleIcon />)}
                </div>
                {loading ? (
                    <Skeleton className={cn("h-5 rounded-xl", {
                        "w-[80px] h-9": horizontal,
                        "w-[50px] h-5": !horizontal
                    })} />
                ) : (
                    <div
                        id={`${entityType}AudienceSize`}
                        className={cn({
                            "text-ui-900": darkText,
                            "ml-2 text-sm": !horizontal,
                            "text-[24px]": horizontal,
                        })}
                    >
                        <div className="flex gap-1 items-center">
                            {formattedSize}
                            {crmMatchRates && tooltipText && (
                                <Tooltip content={tooltipText} className="max-w-60 text-xs" side="bottom">
                                    <InfoIcon />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
