import cn from "classnames";
import { CSSProperties, ReactNode } from "react";
import * as Portal from "@radix-ui/react-portal";

interface ModalProps {
    className?: string;
    children: ReactNode;
    style?: CSSProperties;
    onClose?: () => void;
    zIndex?: string;
}

export const Modal = ({ onClose, children, className, style, zIndex = "z-40" }: ModalProps) => {
    const wrapperClassName =
        "fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50";
    const baseClass = "relative bg-white rounded-2xl p-8 shadow-dropdown";

    return (
        <Portal.Root>
            <div onClick={onClose} className={`${wrapperClassName} ${zIndex}`}>
                <div onClick={e => e.stopPropagation()} className={cn(baseClass, className)} style={style}>
                    {children}
                </div>
            </div>
        </Portal.Root>
    );
};
