import { Account } from "@/types/integrations";
import XIcon from "@/assets/icons/close.svg?react";
import { TableRow, TableCell } from "@/components/atoms/Table";
import { ConnectionStatusPill } from "@/components/organisms/integrations/ConnectionStatus";

export const AccountTableRow = ({
    id,
    name,
    userId,
    status,
    service,
    deleteAccount,
}: Account & { deleteAccount: (id: string) => void }) => (
    <TableRow className="border-ui-200">
        <TableCell className="p-4">{id}</TableCell>
        <TableCell className="p-4">{name}</TableCell>
        <TableCell className="p-4">{userId}</TableCell>
        <TableCell className="p-4">
            <ConnectionStatusPill status={status} service={service} />
        </TableCell>
        <TableCell className="p-4">
            <div className="flex justify-end">
                <XIcon className="text-ui-300 cursor-pointer" onClick={() => deleteAccount(id)} />
            </div>
        </TableCell>
    </TableRow>
);
