import { Button } from "@/components/atoms/Button/Button";
import ICPIcon from "@/assets/icons/icp-audience.svg?react";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import VerticalIcon from "@/assets/icons/vertical-audience.svg?react";
import TargetIcon from "@/assets/icons/target-account-audience.svg?react";
import AudienceTypesIcon from "@/assets/images/onboarding/audienceTypes.svg";
import AccelerationIcon from "@/assets/icons/acceleration-audience.svg?react";
import { OnboardingWrapper } from "@/components/organisms/onboarding/OnboardingWrapper";
import {
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
} from "@/components/molecules/Accordion/Accordion";
import { useOnboarding } from "./OnboardingContext";
import { OnboardingStep } from "./Onboarding";
export const AudienceTypes = () => {
    const { setCurrentStep } = useOnboarding();

    return (
        <OnboardingWrapper imgUrl={AudienceTypesIcon}>
            <div>
                <h1 className="text-h1 mb-2">Build powerful custom audiences</h1>
                <p className="text-16">
                    Primer audiences are real LinkedIn profiles matched to major ad platforms using permanent IDs.
                </p>
                <div className="mt-4 text-16">Typical audience types:</div>
            </div>

            <Accordion
                type="single"
                defaultValue="item-1"
                collapsible
                className="w-full text-ui-900 space-y-0.5"
            >
                <AccordionItem
                    value="item-1"
                    className="border px-4 bg-white border-ui-200 rounded-lg data-[state=open]:border-blue-200 data-[state=open]:bg-blue-50"
                >
                    <AccordionTrigger className="hover:no-underline text-14 font-medium">
                        <div className="flex items-center gap-3">
                            <ICPIcon />
                            <span>ICP audience</span>
                        </div>
                    </AccordionTrigger>
                    <AccordionContent>
                        ICP audiences are the broadest in Primer, including any qualified buyer. They usually use
                        criteria like industry, headcount, or revenue.
                    </AccordionContent>
                </AccordionItem>

                <AccordionItem
                    value="item-2"
                    className="border px-4 bg-white border-ui-200 rounded-lg data-[state=open]:border-blue-200 data-[state=open]:bg-blue-50"
                >
                    <AccordionTrigger className="hover:no-underline text-14 font-medium">
                        <div className="flex items-center gap-3">
                            <TargetIcon />
                            <span>Target account audience</span>
                        </div>
                    </AccordionTrigger>
                    <AccordionContent>
                        For ABM, leverage your Salesforce fields to target buyer personas within your target accounts,
                        using job title, department, and seniority criteria.
                    </AccordionContent>
                </AccordionItem>

                <AccordionItem
                    value="item-3"
                    className="border px-4 bg-white border-ui-200 rounded-lg data-[state=open]:border-blue-200 data-[state=open]:bg-blue-50"
                >
                    <AccordionTrigger className="hover:no-underline text-14 font-medium">
                        <div className="flex items-center gap-3">
                            <VerticalIcon />
                            <span>Vertical-specific audience</span>
                        </div>
                    </AccordionTrigger>
                    <AccordionContent>
                        Need industry-specific targeting or expanding to new verticals? Use the industry filter or
                        Salesforce designations to find target companies.
                    </AccordionContent>
                </AccordionItem>

                <AccordionItem
                    value="item-4"
                    className="border px-4 bg-white border-ui-200 rounded-lg data-[state=open]:border-blue-200 data-[state=open]:bg-blue-50"
                >
                    <AccordionTrigger className="hover:no-underline text-14 font-medium">
                        <div className="flex items-center gap-3">
                            <AccelerationIcon />
                            <span>Opportunity acceleration audience</span>
                        </div>
                    </AccordionTrigger>
                    <AccordionContent>
                        Time kills deals. Use Salesforce filters with job title or department to target in-opportunity
                        buying committees and boost conversion rates.
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
            <Button
                className="flex items-center gap-0.5"
                onClick={() => setCurrentStep(OnboardingStep.CRM_CONNECT)}
            >
                Next
                <ArrowRightIcon />
            </Button>
        </OnboardingWrapper>
    );
};
