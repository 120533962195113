import { Destination } from "@/types/audience";

export const MAX_COMPANIES = 3000000;

export const MAX_PEOPLE = 3000000;

export const MAX_MATCH_RATE = 35_00_000;

export const DESTINATIONS_MIN: { [key: string]: number } = {
    [Destination.GOOGLE]: 1000,
    [Destination.META]: 100,
    [Destination.LINKEDIN]: 300,
};

export const MATCHED_SIZE_MIN: { [key: string]: number } = {
    [Destination.GOOGLE]: DESTINATIONS_MIN[Destination.GOOGLE],
    [Destination.META]: 1500,
    [Destination.LINKEDIN]: DESTINATIONS_MIN[Destination.LINKEDIN],
};
