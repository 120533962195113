import { VariantProps, cva } from "class-variance-authority";
import React from "react";
import cn from "classnames";

const circularProgressBarVariants = cva("", {
    variants: {
        size: {
            default: "h-10 w-10",
            small: "h-8 w-8",
            "x-small": "h-6 w-6",
            "xxs-small": "h-4 w-4",
        },
    },
    defaultVariants: {
        size: "default",
    },
});

export interface CircularProgressBarProps
    extends React.ButtonHTMLAttributes<HTMLDivElement>,
        VariantProps<typeof circularProgressBarVariants> {
    text?: string;
}

const CircularProgressBar = React.forwardRef<HTMLDivElement, CircularProgressBarProps>(({ size, ...props }, ref) => {
    return (
        <div className={cn("relative", circularProgressBarVariants({ size }))} {...props} ref={ref}>
            <svg className="w-full h-full animate-rotate" viewBox="0 0 100 100">
                <circle
                    className="text-ui-50 stroke-current"
                    strokeWidth="10"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="transparent"
                />
                <circle
                    className="text-ui-900 stroke-current animate-circle"
                    strokeWidth="10"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="transparent"
                    strokeDasharray="251.2"
                    strokeDashoffset="251.2"
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
});

export default CircularProgressBar;
