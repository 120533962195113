import { useState } from "react";
import { PaymentMethod } from "@/types/subscription";
import { Button } from "@/components/atoms/Button/Button";
import BankCardIcon from "@/assets/icons/bank-card.svg?react";
import { PaymentMethodCard } from "@/components/organisms/settings/PaymentMethodCard";
import { StripePaymentMethod } from "@/components/organisms/subscription/PaymentMethod/StripePaymentMethod";

interface Props {
    paymentMethod: PaymentMethod;
}

export const PaymentMethodInfo = ({ paymentMethod }: Props) => {
    const [editing, setEditing] = useState(false);
    const width = editing ? "w-[500px] mx-auto" : "w-full";

    return (
        <>
            <div className={`${width} flex flex-col gap-6 px-8 py-6 rounded-2xl shadow-main bg-white`}>
                <h2 className="text-h2 text-ui-700">Payment Information</h2>

                {editing ? (
                    <StripePaymentMethod close={() => setEditing(false)} paymentMethod={paymentMethod} />
                ) : Object.keys(paymentMethod).length ? (
                    <PaymentMethodCard edit={() => setEditing(true)} paymentMethod={paymentMethod} />
                ) : (
                    <PaymentMethodEmpty edit={() => setEditing(true)} />
                )}
            </div>

            {editing && (
                <div className="w-[500px] mx-auto flex flex-col items-center text-10 text-ui-300 space-y-2">
                    <div>Your payment information is safely encrypted and stored by our partner Stripe.</div>
                    <img alt="Powered by Stripe" title="Powered by Stripe" src="stripe.png" />
                </div>
            )}
        </>
    );
};

export const PaymentMethodEmpty = ({ edit }: { edit: () => void }) => {
    return (
        <div className="flex gap-4 items-center">
            <BankCardIcon />

            <div className="flex-1 text-14 text-ui-700 truncate">
                Add a credit card to continue using Primer once your free trial ends
            </div>

            <Button onClick={edit}>Add Payment Info</Button>
        </div>
    );
};
