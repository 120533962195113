import { Button } from "@/components/atoms/Button/Button";
import { BrandedModal } from "@/components/organisms/Modal/BrandedModal";
import PlanCanceledIcon from "@/assets/images/plan-cancel.svg?react";
import { Link } from "react-router-dom";

export const CancelFeedbackModal = ({ close }: { close: () => void }) => {
    return (
        <BrandedModal className="w-[480px] px-10 py-10" zIndex="z-50">
            <div className="flex flex-col items-center gap-4 text-ui-900 mt-16">
                <PlanCanceledIcon />

                <div className="text-h2 mt-6 text-ui-900 font-medium">Plan Cancellation Complete</div>
                <p className="text-center text-14 text-ui-700">
                    Your plan has been canceled successfully. If you need assistance or would like to share any
                    additional feedback in the future, please reach out to our{" "}
                    <Link to="mailto:support@sayprimer.com" className="text-primary hover:underline">
                        support team.
                    </Link>
                </p>

                <p className="text-center text-14 text-ui-700">We’re always here to help!</p>

                <div className="mt-6 flex gap-3">
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                </div>
            </div>
        </BrandedModal>
    );
};
