import {
    ChartSchema,
    ExtraChartSchema,
    SourceCriteriaFilter as Filter,
    SummaryCategoryField,
    SummaryCategoryGroupField,
    SummaryCategoryName,
    summaryMetricColumnToNameMapping,
} from "@/types/audience";
import { filterConfigs, filterOperatorConfigs } from "@primer/filters/configs";
import { FilterDataTypes, FilterFields, FilterOperators, SourceCriteriaFilterValue } from "@primer/filters/types";
import { v4 as uuidv4 } from "uuid";
import {
    differenceWith,
    filter,
    first,
    flatMap,
    forEach,
    get,
    indexOf,
    isEqual,
    map,
    maxBy,
    minBy,
    sortBy,
    split,
    trim,
} from "lodash";
import { allowEmpty } from "./audience";
import { SelectedForExclusionItem } from "@/hooks/useSummaryCriteriaChangeManager";
import { createOthers } from "./heuristics";
import { parseFilterValueLabel } from "./string";
import { GetAudienceResponse } from "@/types/api";

export const getNewFilterWithCriteriaChanges = (
    newExclusions: SelectedForExclusionItem[],
    existingFilters: Filter[],
    isRepoV2Enabled: boolean,
) => {
    const newFilters: (Filter | undefined)[] = newExclusions.map(g => {
        const field = parseField(g.category);
        if (!field) return;

        const fieldConfig = filterConfigs[field];
        const operator = parseOperator(field, g, isRepoV2Enabled);
        const filter: Filter = {
            unique_id: uuidv4(),
            entity_type: fieldConfig.entityType,
            field,
            operator,
            values: parseValues(field, operator, g, isRepoV2Enabled),
            isEmpty: false,
            isDuplicated: false,
            dataType: fieldConfig.dataType,
        };

        return filter;
    });
    const validNewFilters = newFilters.filter(f => !!f);
    const validExistingFilters = clearExistingFilterConflicts(validNewFilters, existingFilters);
    const merged = mergeFilters(validExistingFilters ?? [], validNewFilters).filter(f => {
        if (!f.operator || f.isEmpty || allowEmpty(f.operator)) return true;
        const config = filterOperatorConfigs[f.operator];

        const minNumberOfValues = config.numberOfParams ?? 1;
        if (!f.values?.length || f.values.length < minNumberOfValues) return false;
        return true;
    });
    return merged;
};

const parseValues = (
    filterField: FilterFields,
    operator: FilterOperators,
    item: SelectedForExclusionItem,
    isRepoV2Enabled: boolean,
): SourceCriteriaFilterValue[] => {
    const { value, label } = item;
    if (operator === FilterOperators.IS_KNOWN || operator === FilterOperators.IS_UNKNOWN) return [];

    if (!isRepoV2Enabled && (filterField === FilterFields.ANNUAL_REVENUE || filterField === FilterFields.HEADCOUNT)) {
        return buckets[filterField][value]?.map(value => ({
            label: value.toString(),
            value,
            invalid: false,
        }));
    }

    if (filterField === FilterFields.COMPANY_LOCATION)
        return [
            {
                label: label ?? value,
                value: parseLocationValue(value),
                invalid: false,
            },
        ];

    if (filterField === FilterFields.DEPARTMENTS)
        return [
            {
                label: label ?? value,
                value: parseDepartmentValue(value),
                invalid: false,
            },
        ];

    return [
        {
            label: filterField === FilterFields.DOMAIN && !label?.startsWith("Unspecified") ? value : (label ?? value),
            value,
            invalid: false,
        },
    ];
};

const parseLocationValue = (value: string) => {
    const parts = map(split(value, ","), trim);

    const country = parts.pop() ?? value;
    const state = parts.pop() ?? "";
    const city = parts.join(", ");
    return { country, state, city };
};

const parseDepartmentValue = (value: string) => {
    const parts = map(split(value, ","), trim);

    const primary_department = parts.pop() ?? value;
    const primary_sub_department = parts.join(",");
    return { primary_department, primary_sub_department };
};

const parseOperator = (filterField: FilterFields, item: SelectedForExclusionItem, isRepoV2Enabled = false) => {
    const { value } = item;
    if (!isRepoV2Enabled && (filterField === FilterFields.ANNUAL_REVENUE || filterField === FilterFields.HEADCOUNT)) {
        return value === "0"
            ? item.type === "Include"
                ? FilterOperators.IS_UNKNOWN
                : FilterOperators.IS_KNOWN
            : item.type === "Include"
              ? FilterOperators.BETWEEN
              : FilterOperators.NOT_BETWEEN;
    }

    if (item.type === "Include") {
        return FilterOperators.IS;
    }

    return value === "" &&
        filterField !== FilterFields.DEPARTMENTS &&
        filterField !== FilterFields.COMPANY_LOCATION &&
        filterField !== FilterFields.SENIORITY
        ? FilterOperators.IS_KNOWN
        : FilterOperators.IS_NOT;
};

const parseField = (category: string): FilterFields | undefined => {
    switch (category) {
        case SummaryCategoryField.JOB_TITLE:
            return FilterFields.JOB_TITLE;
        case SummaryCategoryField.SENIORITY:
            return FilterFields.SENIORITY;
        case SummaryCategoryField.COMPANY_DOMAIN:
            return FilterFields.DOMAIN;
        case SummaryCategoryField.COMPANY_LOCATION_COUNTRY:
        case SummaryCategoryField.COMPANY_LOCATION_STATE:
        case SummaryCategoryField.COMPANY_LOCATION_CITY:
            return FilterFields.COMPANY_LOCATION;
        case SummaryCategoryField.ANNUAL_REVENUE:
            return FilterFields.ANNUAL_REVENUE;
        case SummaryCategoryField.HEADCOUNT:
            return FilterFields.HEADCOUNT;
        case SummaryCategoryField.INDUSTRY:
            return FilterFields.INDUSTRY;
        case SummaryCategoryField.DEPARTMENT:
        case SummaryCategoryField.SUB_DEPARTMENT:
            return FilterFields.DEPARTMENTS;
    }
};

const buckets: {
    [key: string]: {
        [key: string]: number[];
    };
} = {
    [FilterFields.ANNUAL_REVENUE]: {
        "9": [5000000000, 999999999999],
        "8": [500000000, 4999999999],
        "7": [100000000, 499999999],
        "6": [25000000, 99999999],
        "5": [10000000, 24999999],
        "4": [5000000, 9999999],
        "3": [1000000, 4999999],
        "2": [100000, 999999],
        "1": [1, 99999],
        "0": [],
    },
    [FilterFields.HEADCOUNT]: {
        "9": [10001, 1000000],
        "8": [5001, 10000],
        "7": [1001, 5000],
        "6": [501, 1000],
        "5": [201, 500],
        "4": [51, 200],
        "3": [11, 50],
        "2": [2, 10],
        "1": [1, 1],
        "0": [],
    },
};

const isMidToGreater = (siblingMin: number, filterMin: number, filterMax: number) =>
    siblingMin >= filterMin && siblingMin <= filterMax + 1;
const isMidToLess = (siblingMax: number, filterMin: number, filterMax: number) =>
    siblingMax + 1 >= filterMin && siblingMax <= filterMax;
const updateTwins = (twins: Filter[], filter: Filter, siblings: Filter[]): Filter[] => {
    const newTwins: Filter[] = [];
    const filterMin = filter.values?.[0]?.value as number;
    const filterMax = filter.values?.[1]?.value as number;
    for (const sibling of siblings) {
        if (!twins.some(t => t.unique_id === sibling.unique_id)) {
            const siblingMin = sibling.values?.[0]?.value as number;
            const siblingMax = sibling.values?.[1]?.value as number;
            if (isMidToGreater(siblingMin, filterMin, filterMax) || isMidToLess(siblingMax, filterMin, filterMax)) {
                newTwins.push(sibling);
            }
        }
    }

    return newTwins;
};

const getSiblings = (filters: Filter[], filter: Filter) =>
    filters.filter(f => f.field === filter.field && f.operator === filter.operator);

const mergeSimple = (filters: Filter[], filter: Filter, mergedFilters: Filter[]) => {
    const siblings = getSiblings(filters, filter);
    mergedFilters.push({
        ...filter,
        unique_id: siblings.length === 1 ? siblings?.[0]?.unique_id : uuidv4(),
        values: siblings.flatMap(s => s.values).filter(f => !!f),
    });
    return siblings.map(s => s.unique_id);
};

const getNumericRangeTwins = (filters: Filter[], filter: Filter) => {
    const siblings = getSiblings(filters, filter);
    let twins: Filter[] = [filter];
    let newTwins: Filter[] = updateTwins(twins, filter, siblings);
    while (newTwins.length > 0) {
        twins = twins.concat(newTwins);
        newTwins = [];
        twins.forEach(t => {
            newTwins = newTwins.concat(updateTwins(twins, t, siblings));
        });
    }
    return twins;
};

const mergeNumericRanges = (filters: Filter[], filter: Filter, mergedFilters: Filter[]) => {
    const twins = getNumericRangeTwins(filters, filter);
    const min = minBy(
        twins.flatMap(s => s.values),
        v => v?.value,
    );
    const max = maxBy(
        twins.flatMap(s => s.values),
        v => v?.value,
    );
    const values = [min, max].filter(f => !!f);
    mergedFilters.push({
        ...filter,
        unique_id: twins.length === 0 ? twins?.[0]?.unique_id : uuidv4(),
        values,
    });

    return twins.map(t => t.unique_id);
};

export const checkIsCountry = (values: SourceCriteriaFilterValue | undefined) =>
    values?.label === "Unspecified" ||
    (!values?.label?.startsWith("Unspecified") && !get(values, "value.state") && !get(values, "value.city"));
export const checkIsState = (values: SourceCriteriaFilterValue | undefined, isLevelAbove: boolean) =>
    !isLevelAbove &&
    (values?.label?.startsWith("Unspecified state") ||
        (!values?.label?.startsWith("Unspecified") && !get(values, "value.city")));
export const checkIsCity = (values: SourceCriteriaFilterValue | undefined, isLevelAbove: boolean) =>
    !isLevelAbove && (values?.label?.startsWith("Unspecified city") || !!get(values, "value.city"));
const checkIsDepartment = (values: SourceCriteriaFilterValue | undefined) =>
    values?.label === "Unspecified" ||
    (!values?.label?.startsWith("Unspecified") && !get(values, "value.primary_sub_department"));
const checkIsSubDepartment = (values: SourceCriteriaFilterValue | undefined, isLevelAbove: boolean) =>
    !isLevelAbove &&
    (values?.label?.startsWith("Unspecified Sub Dept.") || !!get(values, "value.primary_sub_department"));

// Will remove previous IS for simple fields
// For Location and Departments will remove previous on same layer, or layer above matching new nested value
const getValidIs = (beforeFilters: Filter[], afterFilters: Filter[]): Filter[] => {
    const beforeWithReplacedIsOperator = beforeFilters
        .map(beforeFilter => {
            const afterFilter = afterFilters.find(
                a => a.field === beforeFilter.field && a.operator === beforeFilter.operator,
            );

            const f = {
                ...beforeFilter,
                isEmpty: afterFilter?.isEmpty ?? beforeFilter.isEmpty,
                customError: afterFilter?.customError ?? beforeFilter.customError,
                isDuplicated: afterFilter?.isDuplicated ?? beforeFilter.isDuplicated,
            };
            const afterFilterWithIs = afterFilters.find(
                a => a.field === f.field && a.operator === f.operator && a.operator === FilterOperators.IS,
            );

            if (!afterFilterWithIs) return f;

            if (f.field === FilterFields.COMPANY_LOCATION) {
                const afterValues = first(afterFilterWithIs.values);

                const afterIsCountry = checkIsCountry(afterValues);
                const afterIsState = checkIsState(afterValues, afterIsCountry);
                const afterIsCity = checkIsCity(afterValues, afterIsCountry || afterIsState);

                const filteredValues = filter(f.values, beforeValue => {
                    const beforeIsCountry = checkIsCountry(beforeValue);
                    const beforeIsState = checkIsState(beforeValue, beforeIsCountry);
                    const beforeIsCity = checkIsCity(beforeValue, beforeIsCountry || beforeIsState);
                    const hasMatchingCountry =
                        (afterIsState || afterIsCity) &&
                        afterFilters.some(af =>
                            af.values?.some(a => get(a, "value.country") === get(beforeValue, "value.country")),
                        );
                    const hasMatchingState =
                        afterIsCity &&
                        afterFilters.some(af =>
                            af.values?.some(
                                a =>
                                    get(a, "value.country") === get(beforeValue, "value.country") &&
                                    get(a, "value.state") === get(beforeValue, "value.state"),
                            ),
                        );
                    if (beforeIsCountry && (afterIsCountry || hasMatchingCountry)) return false;
                    if (beforeIsState && (afterIsState || hasMatchingState)) return false;
                    if (beforeIsCity && afterIsCity) return false;

                    return true;
                });

                return {
                    ...f,
                    values: filteredValues,
                };
            } else if (f.field === FilterFields.DEPARTMENTS) {
                const afterIsSubDepartment = afterFilterWithIs.values?.some(a =>
                    checkIsSubDepartment(a, checkIsDepartment(a)),
                );
                const filteredValues = filter(f.values, beforeValue => {
                    const beforeSubDepartment = checkIsSubDepartment(beforeValue, checkIsDepartment(beforeValue));
                    if (afterIsSubDepartment === beforeSubDepartment) return false;

                    const afterValue = afterFilterWithIs.values?.find(after => {
                        return get(after, "value.primary_department") === get(beforeValue, "value.primary_department");
                    });
                    return !afterValue;
                });

                return {
                    ...f,
                    values: filteredValues,
                };
            }

            return null;
        })
        .filter(v => !!v);

    return beforeWithReplacedIsOperator;
};

const mergeFilters = (beforeFilters: Filter[], afterFilters: Filter[]): Filter[] => {
    let processedFilters: string[] = [];
    const mergedFilters: Filter[] = [];
    const beforeWithReplacedIsOperator = getValidIs(beforeFilters, afterFilters);
    const afterWithoutDupIsUnkown = afterFilters.filter(
        f =>
            !beforeFilters.some(
                a => a.operator === FilterOperators.IS_UNKNOWN && a.field === f.field && f.operator === a.operator,
            ),
    );
    const filters = [...beforeWithReplacedIsOperator, ...afterWithoutDupIsUnkown];

    forEach(filters, filter => {
        if (!processedFilters.includes(filter.unique_id)) {
            if (
                filter.operator &&
                (filter.operator === FilterOperators.IS_NOT || filter.operator === FilterOperators.IS)
            ) {
                processedFilters = processedFilters.concat(mergeSimple(filters, filter, mergedFilters));
            } else if (
                filter.dataType === FilterDataTypes.NUMBER &&
                (filter.operator === FilterOperators.NOT_BETWEEN || filter.operator === FilterOperators.BETWEEN)
            ) {
                processedFilters = processedFilters.concat(mergeNumericRanges(filters, filter, mergedFilters));
            } else {
                mergedFilters.push(filter);
                processedFilters.push(filter.unique_id);
            }
        }
    });

    return mergedFilters;
};

const clearExistingFilterConflicts = (validNewFilters?: Filter[], existingFilters?: Filter[]): Filter[] => {
    if (!validNewFilters?.length || !existingFilters?.length) return existingFilters ?? [];

    const updatedFilters = existingFilters.map(existingFilter => {
        if (existingFilter.operator !== FilterOperators.IS) return existingFilter;

        const opposeFilters = validNewFilters.filter(
            v => v.field === existingFilter.field && v.operator === FilterOperators.IS_NOT,
        );
        if (!opposeFilters?.length) return existingFilter;

        const newValues = differenceWith(
            existingFilter.values,
            flatMap(opposeFilters, opFilter => opFilter.values),
            (value1, value2) => isEqual(value1.value, value2?.value),
        );
        if (isEqual(newValues, existingFilter.values)) return existingFilter;

        const updatedFilter: Filter = {
            ...existingFilter,
            unique_id: uuidv4(),
            values: newValues,
        };
        return updatedFilter;
    });

    return updatedFilters;
};

const getNamesToBeRemoved = (
    withPercentage: ExtraChartSchema[] = [],
    usedFilters: SummaryCategoryName[],
): SummaryCategoryName[] => {
    return [
        ...getLocationNamesToBeRemoved(withPercentage, usedFilters),
        ...getDepartmentNamesToBeRemoved(withPercentage, usedFilters),
    ];
};

// TODO: Use category group instead of name
const getLocationNamesToBeRemoved = (
    withPercentage: ExtraChartSchema[] = [],
    usedFilters: SummaryCategoryName[],
): SummaryCategoryName[] => {
    const options = [
        SummaryCategoryName.COMPANY_LOCATION,
        SummaryCategoryName.COMPANY_COUNTRY,
        SummaryCategoryName.COMPANY_STATE,
        SummaryCategoryName.COMPANY_CITY,
    ];
    const country = withPercentage?.find(w => w.name === SummaryCategoryName.COMPANY_COUNTRY);
    const state = withPercentage?.find(w => w.name === SummaryCategoryName.COMPANY_STATE);
    const hasCity = withPercentage?.some(w => w.name === SummaryCategoryName.COMPANY_CITY);

    if (
        country &&
        !country?.values?.some(v => v.percentage && v.percentage > 80) &&
        !usedFilters.includes(SummaryCategoryName.COMPANY_STATE)
    )
        return options.filter(o => o !== SummaryCategoryName.COMPANY_COUNTRY);
    if (
        state &&
        !state?.values?.some(v => v.percentage && v.percentage > 80) &&
        !usedFilters.includes(SummaryCategoryName.COMPANY_CITY)
    )
        return options.filter(o => o !== SummaryCategoryName.COMPANY_STATE);
    if (hasCity) return options.filter(o => o !== SummaryCategoryName.COMPANY_CITY);

    return options.filter(o => o !== SummaryCategoryName.COMPANY_LOCATION);
};

const getDepartmentNamesToBeRemoved = (
    withPercentage: ExtraChartSchema[] = [],
    usedFilters: SummaryCategoryName[],
): SummaryCategoryName[] => {
    const options = [SummaryCategoryName.DEPARTMENT, SummaryCategoryName.SUB_DEPARTMENT];
    const departments = withPercentage?.find(w => w.name === SummaryCategoryName.DEPARTMENT);

    if (
        departments &&
        !departments?.values?.some(v => v.percentage && v.percentage > 80) &&
        !usedFilters.includes(SummaryCategoryName.SUB_DEPARTMENT)
    )
        return options.filter(o => o !== SummaryCategoryName.DEPARTMENT);

    return options.filter(o => o !== SummaryCategoryName.SUB_DEPARTMENT);
};

export const summaryCategoriesOrdered: string[] = [
    SummaryCategoryGroupField.JOB_TITLE,
    SummaryCategoryGroupField.SENIORITY,
    SummaryCategoryGroupField.DEPARTMENT,
    SummaryCategoryGroupField.COMPANY_DOMAIN,
    SummaryCategoryGroupField.ANNUAL_REVENUE,
    SummaryCategoryGroupField.HEADCOUNT,
    SummaryCategoryGroupField.COMPANY_LOCATION,
    SummaryCategoryGroupField.INDUSTRY,
];
const personSummaries: string[] = [
    SummaryCategoryField.JOB_TITLE,
    SummaryCategoryField.SENIORITY,
    SummaryCategoryField.COMPANY_DOMAIN,
    SummaryCategoryField.DEPARTMENT,
    SummaryCategoryField.SUB_DEPARTMENT,
];

const getUsedFilters = (audience?: GetAudienceResponse): SummaryCategoryName[] => {
    const sourceCriteria = audience?.shape?.source_criteria;
    if (!sourceCriteria) return [];

    const usedFilters: SummaryCategoryName[] = [];
    const locationFilter = sourceCriteria?.group?.filters.find(
        f =>
            !f.isEmpty &&
            !f.isDuplicated &&
            f.field === FilterFields.COMPANY_LOCATION &&
            f.operator === FilterOperators.IS,
    );
    if (locationFilter) {
        if (locationFilter.values?.some(v => !!get(v, "value.city")))
            usedFilters.push(SummaryCategoryName.COMPANY_CITY);
        if (locationFilter.values?.some(v => !!get(v, "value.city") || !!get(v, "value.state")))
            usedFilters.push(SummaryCategoryName.COMPANY_STATE);
    }
    const departmentFilter = sourceCriteria?.group?.filters.find(
        f => !f.isEmpty && !f.isDuplicated && f.field === FilterFields.DEPARTMENTS && f.operator === FilterOperators.IS,
    );
    if (departmentFilter) {
        if (departmentFilter.values?.some(v => get(v, "value.sub_department")))
            usedFilters.push(SummaryCategoryName.SUB_DEPARTMENT);
    }

    return usedFilters;
};

export function parseSummary(
    isLoadingSize: boolean,
    top?: ChartSchema[],
    people_count?: number,
    companies_count?: number,
    audience?: GetAudienceResponse,
    isRepoV2Enabled = false,
) {
    const usedFilters = getUsedFilters(audience);
    const withPercentage: ExtraChartSchema[] =
        top?.map(category => {
            const topSummaryTotal = category.total;
            const audienceEstimateTotal = personSummaries.includes(category?.category ?? "")
                ? people_count
                : companies_count;
            const total: number = topSummaryTotal ?? audienceEstimateTotal ?? 1;

            return {
                category: category.category,
                name: category.name,
                total,
                limit: category.limit,
                allowExpand: (!category.limit || category.limit < 100) && category.values?.length >= 15,
                values: category.values.map(item => ({
                    ...item,
                    label: parseFilterValueLabel(
                        // todo replace with item.label + ',' + category.name
                        String(item.label).toLowerCase(),
                        item.label,
                        category?.name?.toLowerCase(),
                        FilterDataTypes.STRING,
                        isRepoV2Enabled,
                    ),
                    percentage: isLoadingSize ? undefined : (item.value * 100) / total,
                })),
            };
        }) ?? [];

    const namesToBeRemoved = getNamesToBeRemoved(withPercentage, usedFilters);
    const withoutExtraCategories = withPercentage?.filter(
        n => !namesToBeRemoved.includes(n?.name as SummaryCategoryName),
    );
    const withOthers = withoutExtraCategories.map(v => createOthers(v, isLoadingSize)).filter(v => !!v);
    const sorted = sortBy(withOthers, category => {
        const index = indexOf(summaryCategoriesOrdered, category.categoryGroup);
        return index === -1 ? Number.MAX_SAFE_INTEGER : index;
    });
    return sorted;
}

export const parseCategoryName = (categoryGroup?: string): string | undefined => {
    return summaryMetricColumnToNameMapping[categoryGroup as SummaryCategoryGroupField];
};
