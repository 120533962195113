import React, { useState, useEffect, useRef } from "react";
import { Panel, PanelGroup, ImperativePanelHandle } from "react-resizable-panels";
import ResizeHandle from "./ResizeHandle";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { setToggledLeftBar } from "@/store/audience";

interface PanelConfig {
    content: React.ReactNode;
    minSize: number;
    collapsible: boolean;
}

interface ResizablePanelsProps {
    leftPanel: PanelConfig;
    rightPanel: PanelConfig;
}

const ResizablePanels = ({ leftPanel, rightPanel }: ResizablePanelsProps) => {
    const dispatch = useDispatch();
    const calcMinSizePercentage = (size: number) => size / (window.innerWidth-64) * 100;
    const calcMinSizeAbsolute = (size: number) => size * (window.innerWidth-64) / 100;
    const [minSizePercentages, setMinSizePercentages] = useState({
        left: calcMinSizePercentage(leftPanel.minSize),
        right: calcMinSizePercentage(rightPanel.minSize),
    });
    const [leftPanelPercentageSize, setLeftPanelPercentageSize] = useState(leftPanel.minSize);
    const [leftPanelSize, setLeftPanelSize] = useState(calcMinSizePercentage(leftPanel.minSize));
    const [rightPanelPercentageSize, setRightPanelPercentageSize] = useState(rightPanel.minSize);
    const [rightPanelSize, setRightPanelSize] = useState(calcMinSizePercentage(rightPanel.minSize));
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth) {
                setLeftPanelSize(calcMinSizeAbsolute(leftPanelPercentageSize));
                setRightPanelSize(calcMinSizeAbsolute(rightPanelPercentageSize));
                const left = calcMinSizePercentage(leftPanel.minSize);
                const right = calcMinSizePercentage(rightPanel.minSize);
                setMinSizePercentages({
                    left: left,
                    right: left + right > 100 ? 100 - left : right,
                });
            }
        }
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [leftPanel.minSize, leftPanelPercentageSize, rightPanel.minSize, rightPanelPercentageSize]);

    const panelRef = useRef<ImperativePanelHandle>(null);

    const toggleLeft = () => {
        if(panelRef.current?.isCollapsed()) panelRef?.current?.expand();
        else panelRef?.current?.collapse();
    }

    return (
        <div className="w-full">
            <PanelGroup direction="horizontal">
                <Panel
                    ref={panelRef}
                    collapsible={leftPanel.collapsible}
                    defaultSize={minSizePercentages.left}
                    minSize={minSizePercentages.left}
                    order={1}                    
                    onResize={(size) => {
                        setLeftPanelPercentageSize(size);
                        setLeftPanelSize(calcMinSizeAbsolute(size))
                        setIsCollapsed(size === 0);
                        dispatch(setToggledLeftBar(size === 0))
                    }}
                    className={cn("group/panel split", {
                        "hidden-panel": leftPanelSize === 0,
                        "sm-panel": leftPanelSize > 0 && leftPanelSize <= 800,
                        "md-panel": leftPanelSize > 800
                    })}
                >
                    {leftPanel.content}
                </Panel>
                <ResizeHandle isCollapsed={isCollapsed} toggleLeft={toggleLeft} />
                <Panel
                    collapsible={rightPanel.collapsible}
                    minSize={Math.max(minSizePercentages.right, 30)}
                    onResize={(size) => {
                        setRightPanelPercentageSize(size);
                        setRightPanelSize(calcMinSizeAbsolute(size))
                    }}
                    order={2}
                    className={cn("group/panel split", {
                        "hidden-panel": rightPanelSize === 0,
                        "xs-panel": rightPanelSize > 0 && rightPanelSize <= 600,
                        "sm-panel": rightPanelSize > 600 && rightPanelSize <= 900,
                        "md-panel": rightPanelSize > 900 && rightPanelSize <= 1200,
                        "lg-panel": rightPanelSize > 1200 && rightPanelSize <= 1500,
                        "xl-panel": rightPanelSize > 1500
                    })}
                >
                    {rightPanel.content}
                </Panel>
            </PanelGroup>
        </div>
    );
}

export default ResizablePanels;