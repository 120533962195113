import cn from "classnames";
import * as React from "react";
import CloseIcon from "@/assets/icons/close.svg?react";
import { Button } from "../Button/Button";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    defaultClassName?: string;
    containerClassName?: string;
    showClearIcon?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className,
            containerClassName,
            defaultClassName = "flex border rounded-md px-2 h-[36px] items-center text-sm text-ui-900 border-ui-300/[0.32] placeholder:text-dark-400 hover:border-ui-300 focus:border-2 focus:border-blue-300 disabled:cursor-not-allowed disabled:text-ui-300 disabled:bg-ui-300/[0.32] outline-none focus:placeholder:text-transparent aria-[invalid=true]:border-error-stroke aria-[invalid=true]:border-2",
            type,
            ...props
        },
        ref,
    ) => {
        return (
            <div className={`${props.value && props.showClearIcon ? "relative" : ""} ${containerClassName?.toString()}`}>
                <input type={type} className={cn(defaultClassName, className)} ref={ref} {...props} />
                {props.value && props.showClearIcon && (
                    <Button
                        variant="ghost"
                        className="absolute right-2 top-[50%] translate-y-[-50%] !p-0 !h-[20px] w-[20px] flex items-center justify-center"
                        onClick={() =>
                            props.onChange
                                ? props.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>)
                                : undefined
                        }
                    >
                        <CloseIcon className="text-ui-300" />
                    </Button>
                )}
            </div>
        );
    },
);
Input.displayName = "Input";

export { Input };
