import { Card } from "@/components/atoms/Card/Card.tsx";
import { AudienceSize } from "@/components/molecules/AudienceSize/AudienceSize";
import { FilterEntityTypes } from "@primer/filters/types";
import { GetAudienceResponse } from "@/types/api";
import { CrmMatchRates, Destination } from "@/types/audience";
import { useAudienceSizeValidations } from "../../hooks/useAudienceSizeValidations";
import { Separator } from "@/components/atoms/Separator/Separator";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { JoinedCriteriaAssets } from "@/hooks/useCriteriaAssetsManager";
import { useMemo } from "react";
import AudienceEstimateMatchRates from "./AudienceEstimateMatchRates";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import { DESTINATIONS_MIN, MATCHED_SIZE_MIN, MAX_MATCH_RATE } from "@/constants/estimates";
import cn from "classnames";

interface AudienceEstimateCardHorizontalProps {
    audience?: GetAudienceResponse;
    criteriaAssets?: JoinedCriteriaAssets;
    crmMatchRates?: CrmMatchRates;
    isCriteriaAssetsLoading?: boolean;
    isLoadingAudience: boolean;
    isLoadingMatchRate: boolean;
}

export const AudienceEstimateCardHorizontal = ({
    audience,
    criteriaAssets,
    crmMatchRates,
    isLoadingAudience,
    isCriteriaAssetsLoading,
    isLoadingMatchRate,
}: AudienceEstimateCardHorizontalProps) => {
    const { smallAudienceErrorMessage, estimates, showAudienceSize, shouldShowError, exceeded } =
        useAudienceSizeValidations({
            audience,
            criteriaAssets,
            isCriteriaAssetsLoading,
        });
    const isMatchRatesProjectionEnabled = useFeatureFlag(FeatureFlagsEnum.MATCH_RATES_PROJECTION);

    const showMaxSize = useMemo(
        () =>
            shouldShowError &&
            audience?.id &&
            audience?.shape?.source_criteria?.group?.filters?.some(f => !f.isEmpty) &&
            (exceeded[FilterEntityTypes.COMPANY] || exceeded[FilterEntityTypes.PERSON]),
        [shouldShowError, audience?.id, audience?.shape?.source_criteria, exceeded],
    );
    const showMinSize = useMemo(
        () => !showMaxSize && shouldShowError && audience?.id && smallAudienceErrorMessage,
        [showMaxSize, shouldShowError, audience?.id, smallAudienceErrorMessage],
    );

    const hasSizeErrorOrWarning = !!showMaxSize || !!showMinSize;

    const facebookMatchSizeError = useMemo(
        () =>
            !!criteriaAssets?.match_rate &&
            !!audience?.destinations?.[Destination.META] &&
            !!estimates?.person &&
            estimates?.person > DESTINATIONS_MIN[Destination.META] &&
            estimates?.person < MATCHED_SIZE_MIN[Destination.META] &&
            !isLoadingMatchRate &&
            !isCriteriaAssetsLoading,
        [
            audience?.destinations,
            criteriaAssets?.match_rate,
            estimates?.person,
            isCriteriaAssetsLoading,
            isLoadingMatchRate,
        ],
    );

    const maxSupportedSizeError = useMemo(
        () =>
            !!estimates?.person &&
            estimates?.person > MAX_MATCH_RATE &&
            !isLoadingMatchRate &&
            !isCriteriaAssetsLoading,
        [estimates?.person, isCriteriaAssetsLoading, isLoadingMatchRate],
    );

    const hasMatchRateError = facebookMatchSizeError || maxSupportedSizeError;

    return (
        <Card id="horizontal-estimate-card" className={"flex flex-row w-full pt-6 pb-6 pl-8 pr-8 overflow-x-auto"}>
            <div
                className={cn("grid gap-x-6 gap-y-2", {
                    "grid-cols-[repeat(2,max-content)]":
                        !hasSizeErrorOrWarning || (hasSizeErrorOrWarning && hasMatchRateError),
                    "grid-cols-[repeat(3,max-content)]": hasSizeErrorOrWarning && !hasMatchRateError,
                })}
            >
                <AudienceSize
                    entityType={FilterEntityTypes.COMPANY}
                    estimate={estimates[FilterEntityTypes.COMPANY]}
                    sizeWarning={showAudienceSize ? false : exceeded[FilterEntityTypes.COMPANY]}
                    className="text-nowrap"
                    horizontal={true}
                    loading={isCriteriaAssetsLoading || isLoadingAudience}
                    showAudienceSize={showAudienceSize}
                    id={audience?.id}
                />
                <AudienceSize
                    entityType={FilterEntityTypes.PERSON}
                    estimate={estimates[FilterEntityTypes.PERSON]}
                    sizeWarning={
                        showAudienceSize ? false : exceeded[FilterEntityTypes.PERSON] || !!smallAudienceErrorMessage
                    }
                    sizeError={showAudienceSize ? false : !!smallAudienceErrorMessage}
                    className="text-nowrap"
                    horizontal={true}
                    loading={isCriteriaAssetsLoading || isLoadingAudience}
                    showAudienceSize={showAudienceSize}
                    crmMatchRates={crmMatchRates}
                    id={audience?.id}
                />
                {showMaxSize && (
                    <div
                        id="max-size-warning"
                        className={cn(
                            "px-[16px] py-[12px] rounded-lg max-w-[210px] h-[53px] bg-warning-background text-warning",
                            {
                                "col-span-2": hasSizeErrorOrWarning && hasMatchRateError,
                            },
                        )}
                    >
                        <div
                            className="text-xs text-start line-clamp-2"
                            title="Audience sizes are cut off at a maximum of 3M records."
                        >
                            Audience sizes are cut off at a maximum of 3M records.
                        </div>
                    </div>
                )}
                {showMinSize && (
                    <Tooltip
                        side="bottom"
                        content={smallAudienceErrorMessage!}
                        className="text-wrap text-left !max-w-[300px]"
                        fullWidth
                    >
                        <div
                            id="min-size-warning"
                            className={cn(
                                "px-[16px] py-[12px] rounded-lg max-w-[210px] h-[53px] bg-error-background text-error",
                                {
                                    "col-span-2": hasSizeErrorOrWarning && hasMatchRateError,
                                },
                            )}
                        >
                            <div className="text-xs text-start line-clamp-2">{smallAudienceErrorMessage}</div>
                        </div>
                    </Tooltip>
                )}
            </div>
            {isMatchRatesProjectionEnabled && (
                <div className="flex flex-row">
                    <Separator orientation="vertical" className="!h-auto mx-6" />
                    <AudienceEstimateMatchRates
                        matchRate={criteriaAssets?.match_rate}
                        hasSizeError={hasSizeErrorOrWarning}
                        sizeBelowMinimum={!!showMinSize}
                        isLoadingMatchRate={isLoadingMatchRate}
                        isCriteriaAssetsLoading={isCriteriaAssetsLoading}
                        destinations={audience?.destinations}
                        facebookMatchSizeError={facebookMatchSizeError}
                        maxSupportedSizeError={maxSupportedSizeError}
                    />
                </div>
            )}
        </Card>
    );
};
