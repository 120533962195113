import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { formatNumberFloor } from "@/utils/number";

const RowValueDisplay: React.FC<{ isOther: boolean; isExpanding?: boolean; value: number; percentage?: number }> = ({
    isOther,
    isExpanding,
    value,
    percentage
}) => {
    const parsedValue = isOther && isExpanding
        ? <Skeleton className="h-3 rounded-xl bg-gray-300 w-8" />
        : formatNumberFloor(value);

    const parsePercentage = () => {
        if (percentage === null || percentage === undefined) return parsedValue;

        const fixedPercentage = percentage.toFixed(1);
        const percentageParsed = fixedPercentage === '0.0' ? '<0.1' : fixedPercentage;
        const parsedPercentage = isOther && isExpanding
            ? <Skeleton className="h-3 rounded-xl bg-gray-300 w-8" />
            : percentageParsed + "%";

        return parsedPercentage;
    }
    const parsedPercentage = parsePercentage();

    return (
        <>
            <span className="block group-hover:hidden">{parsedPercentage}</span>
            <span className="hidden group-hover:block">{parsedValue}</span>
        </>
    );
};

export default RowValueDisplay;
