import CloseIcon from "@/assets/icons/close.svg?react";

interface Props {
    title: string;
    icon?: JSX.Element;
    onClose?: () => void;
}

export const ModalHeader = ({ title, onClose, icon }: Props) => (
    <div className="flex justify-between pb-2">
        <div className="flex items-center gap-2">
            {icon}
            <span className="text-lg">{title}</span>
        </div>
        {onClose && <CloseIcon className="text-ui-300 cursor-pointer pt-2" width={22} height={22} onClick={onClose} />}
    </div>
);
