import { formatDate } from "date-fns";
import { Link } from "react-router-dom";

import StatusWarningIcon from "@/assets/icons/status-warning.svg?react";
import { getRelativeDateString } from "@/utils/date";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";

type SFDCConnectionWarningProps = {
    lastSyncedAt?: string;
}

const SFDCConnectionWarning = ({ lastSyncedAt = "" }: SFDCConnectionWarningProps) => {
    const lastSynced = lastSyncedAt ? (
        <Tooltip side="bottom" content={formatDate(new Date(lastSyncedAt), "yyyy-MM-dd hh:mm:ss aa")}>
            <span>{getRelativeDateString(new Date(lastSyncedAt))}.</span>
        </Tooltip>
    ) : null;

    return (
        <p className="flex rounded-[8px] py-2 px-2 mb-5 border-ui-300/[0.19] bg-warning-background text-warning text-sm items-center">
            <StatusWarningIcon className="mr-2 min-w-[20px]" />
            <span>
                Salesforce connection lost.&nbsp;
                {lastSynced ? (
                    <>Last sync: {lastSynced}</>
                ) : ""}
                To update your data <Link to="/integrations" className="text-blue-800">go to Integrations page</Link> and reconnect.
            </span>
        </p>
    )
}

export default SFDCConnectionWarning;
