import { TableHead } from "@/components/atoms/Table";
import { GetAudienceSortColumn, GetAudienceSortOrder } from "@/types/api";
import { AudienceTableProps } from "./AudienceTable";
import cn from "classnames";
import SortedDownIcon from "@/assets/icons/sorted-down.svg?react";
import SortedUpIcon from "@/assets/icons/sorted-up.svg?react";
import SortUpDownIcon from "@/assets/icons/sort-up-down.svg?react";
import SortedDownFilledIcon from "@/assets/icons/sorted-down-filled.svg?react";
import SortedUpFilledIcon from "@/assets/icons/sorted-up-filled.svg?react";
import SortUpDownFilledIcon from "@/assets/icons/sort-up-down-filled.svg?react";
import { Button } from "@/components/atoms/Button/Button";
import { useState } from "react";

interface AudienceTableHeaderProps {
    text?: string;
    className?: string;
    sortConfig?: {
        currentSort: AudienceTableProps["currentSort"];
        handleSort: AudienceTableProps["handleSort"];
        columnKey: GetAudienceSortColumn;
    };
}

export const AudienceTableHeader = ({ text, className, sortConfig }: AudienceTableHeaderProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const sortIcon = () => {
        if (isHovered && !isActiveSort) {
            return <SortUpDownFilledIcon />;
        } else if (isHovered && isActiveSort && sortConfig.currentSort.order === GetAudienceSortOrder.DESC) {
            return <SortedDownFilledIcon />;
        } else if (isHovered && isActiveSort && sortConfig.currentSort.order === GetAudienceSortOrder.ASC) {
            return <SortedUpFilledIcon />;
        } else if (!isActiveSort) {
            return <SortUpDownIcon />;
        } else if (isActiveSort && sortConfig.currentSort.order === GetAudienceSortOrder.DESC) {
            return <SortedDownIcon />;
        } else if (isActiveSort && sortConfig.currentSort.order === GetAudienceSortOrder.ASC) {
            return <SortedUpIcon />;
        }
    };

    const isActiveSort = sortConfig && sortConfig?.currentSort.column === sortConfig?.columnKey;
    const contentClassname = `text-ui-700 text-xs font-medium leading-none tracking-tight !p-2 h-[36px]
        justify-start items-center flex rounded-lg border border-transparent`;

    const sortableContentClassname = `${isActiveSort && "!text-blue-800 bg-blue-50"}
    hover:!text-blue-800 hover:bg-blue-50 hover:border-blue-200`;

    let content = <span className={contentClassname}>{text}</span>;

    if (sortConfig !== undefined) {
        content = (
            <Button
                variant="ghost"
                onClick={() => {
                    if (sortConfig) {
                        sortConfig.handleSort(
                            sortConfig.columnKey,
                            !isActiveSort || (isActiveSort && sortConfig.currentSort.order === GetAudienceSortOrder.ASC)
                                ? GetAudienceSortOrder.DESC
                                : GetAudienceSortOrder.ASC,
                        );
                    }
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={cn(contentClassname, sortableContentClassname)}
                title={`Sort By ${sortConfig.columnKey}`}
            >
                <span className="mr-1">{text}</span>
                {sortIcon()}
            </Button>
        );
    }

    return <TableHead className={cn(`!px-0`, className)}>{content}</TableHead>;
};
