import { Button } from "@/components/atoms/Button/Button";
import { BrandedModal } from "@/components/organisms/Modal/BrandedModal";
import { UpgradePlan } from "@/components/organisms/subscription/UpgradePlan";

export const OutOfAudiencesModal = ({
    close,
}: {
    close: () => void;
}) => {
    return (
        <BrandedModal className="w-[480px] px-10 py-10">
            <div className="flex flex-col gap-2 text-ui-900">
                <h2 className="text-h2 font-medium z-50">You're out of audiences but that's ok</h2>
                <div className="text-14">Archive an active audience or upgrade your plan now to submit this audience.</div>
                <div className="mt-8 flex gap-3 self-end">
                    <Button variant="secondary" onClick={close}>
                        Dismiss
                    </Button>
                    <UpgradePlan triggerProps={{ variant: "primary" }} onClose={close} />
                </div>
            </div>
        </BrandedModal>
    );
};
