import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { TableCell, TableRow } from "@/components/atoms/Table/Table";

interface AudienceTableLoadingRowProps {
    index: number;
}

export const AudienceTableLoadingRow = ({ index }: AudienceTableLoadingRowProps) => {
    // There is a three row sequence to create a staggered effect in the table
    const sequence = index % 3;

    return (
        <TableRow className="border-dark-500 border-opacity-20 py-6 h-[68px]">
            <TableCell className="w-[52px] block mt-[8px]">
                <div className="flex justify-center">
                    <Skeleton className="h-5 w-5 rounded-xl" />
                </div>
            </TableCell>
            <TableCell className="w-full">
                <div className="flex justify-between">
                    <Skeleton
                        className={`h-4 ${sequence === 1 && "w-[60%]"} ${sequence === 2 && "w-[50%]"} ${sequence === 0 && "w-[70%]"} rounded-xl`}
                    />
                </div>
            </TableCell>
            <TableCell>
                {sequence === 1 ? (
                    <div className="flex">
                        <Skeleton className="h-5 w-5 rounded-xl mr-2" />
                        <Skeleton className="h-5 w-5 rounded-xl mr-2" />
                        <Skeleton className="h-5 w-5 rounded-xl" />
                    </div>
                ) : (
                    <div className="flex">
                        <Skeleton className="h-5 w-5 rounded-xl mr-2" />
                        <Skeleton className="h-5 w-5 rounded-xl" />
                    </div>
                )}
            </TableCell>
            <TableCell>
                <div className="flex gap-2">
                    <Skeleton
                        className={`h-4 ${sequence === 1 && "w-[70px]"} ${sequence === 2 && "w-[60px]"} ${sequence === 0 && "w-[80px]"} rounded-xl`}
                    />
                    <Skeleton
                        className={`h-4 ${sequence === 1 && "w-[70px]"} ${sequence === 2 && "w-[50px]"} ${sequence === 0 && "w-[60px]"} rounded-xl`}
                    />
                </div>
            </TableCell>
            <TableCell>
                <Skeleton className={`h-4 w-[124px] rounded-xl`} />
            </TableCell>
            <TableCell>
                <Skeleton
                    className={`h-4 ${sequence === 1 && "w-[90px]"} ${sequence === 2 && "w-[70px]"} ${sequence === 0 && "w-[80px]"} rounded-xl`}
                />
            </TableCell>
            <TableCell />
            <TableCell>
                <div className="flex justify-end">
                    <Skeleton className="h-5 w-10 rounded-xl" />
                </div>
            </TableCell>
        </TableRow>
    );
};
