import { Button } from "@/components/atoms/Button/Button";
import Checkbox from "@/components/atoms/Checkbox/Checkbox";
import { ReactNode } from "react";

interface FilterListItemProps {
    checked: boolean;
    handleCheck: (checked: boolean) => void;
    icon: ReactNode;
    text: ReactNode;
}

export const FilterListItem = ({ checked, handleCheck, icon, text }: FilterListItemProps) => {
    return (
        <Button variant="ghost" className="!flex items-center !p-0 py-2" onClick={() => handleCheck(!checked)}>
            <Checkbox checked={checked} className="mr-4" />
            <div className="mr-2">{icon}</div>
            <div className="text-sm leading-tight">{text}</div>
        </Button>
    );
};
