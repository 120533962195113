import { Main } from "@/components/organisms/Main";
import { Header } from "@/components/organisms/Header";
import { adPlatformCards, crmCards } from "@/config/integrations";
import { IntegrationGroup } from "@/components/organisms/integrations/IntegrationGroup";
import { ConnectionsProvider } from "./ConnectionsContext";

export const IntegrationsPage = () => {
    return (
        <>
            <ConnectionsProvider>
                <Header title="Integrations"></Header>
                <Main>
                    <IntegrationGroup services={adPlatformCards} />
                    <IntegrationGroup services={crmCards} />
                </Main>
            </ConnectionsProvider>
        </>
    );
};
