import cn from "classnames";
import * as React from "react";
import { Root, Trigger, Anchor, Content, Portal, Close } from "@radix-ui/react-popover";

const Popover = Root;

const PopoverTrigger = Trigger;

const PopoverAnchor = Anchor;

const PopoverClose = Close;

const PopoverContent = React.forwardRef<
    React.ElementRef<typeof Content>,
    React.ComponentPropsWithoutRef<typeof Content>
>(({ className, align = "start", sideOffset = 16, ...props }, ref) => (
    <Portal>
        <Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={cn(
                "z-50 bg-white rounded-lg shadow flex-col justify-start items-start inline-flex data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                className,
            )}
            {...props}
        />
    </Portal>
));
PopoverContent.displayName = Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor, PopoverClose };
