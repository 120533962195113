import { MeterProvider, PeriodicExportingMetricReader } from "@opentelemetry/sdk-metrics";
import { OTLPMetricExporter } from "@opentelemetry/exporter-metrics-otlp-http";
import { Resource } from "@opentelemetry/resources";
import opentelemetry from "@opentelemetry/api";
import { SEMRESATTRS_DEPLOYMENT_ENVIRONMENT, SEMRESATTRS_SERVICE_NAME } from "@opentelemetry/semantic-conventions";

const {
    VITE_ENVIRONMENT,
    VITE_SERVICE_NAME,
    VITE_API_URL,
    VITE_INSTRUMENTATION_ENABLED: instEnabledEnv,
} = import.meta.env;

const instEnabled = !!instEnabledEnv && (instEnabledEnv === "1" || instEnabledEnv.toLowerCase() === "true");
if (instEnabled && !!VITE_ENVIRONMENT && !!VITE_SERVICE_NAME && !!VITE_API_URL) {
    const resource = new Resource({
        [SEMRESATTRS_SERVICE_NAME]: VITE_SERVICE_NAME,
        [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: VITE_ENVIRONMENT,
    });

    const OTLP_HEADERS = {};

    const metricReader = new PeriodicExportingMetricReader({
        exporter: new OTLPMetricExporter({
            url: `${VITE_API_URL}/web-vitals`,
            headers: OTLP_HEADERS,
        }),
        exportIntervalMillis: 30000,
    });

    const myServiceMeterProvider = new MeterProvider({
        resource,
        readers: [metricReader],
    });

    opentelemetry.metrics.setGlobalMeterProvider(myServiceMeterProvider);
}
