// ----------------------------------------------------------------------------
// FILTER CONFIG
// ----------------------------------------------------------------------------
export enum FilterDataTypes {
    STRING = "string",
    NUMBER = "number",
    ARRAY = "array",
    LOCATION = "location",
    BOOLEAN = "boolean",
    DATE = "date",
    DATE_TIME = "date-time",
    CSV = "csv",
}

// ----------------------------------------------------------------------------
// FILTER CRITERIA
// ----------------------------------------------------------------------------
export enum FilterOperators {
    IS = "is",
    IS_NOT = "is_not",
    CONTAINS = "contains",
    NOT_CONTAINS = "not_contains",
    GE = "ge",
    LE = "le",
    BETWEEN = "between",
    NOT_BETWEEN = "not_between",
    IS_KNOWN = "is_known",
    IS_UNKNOWN = "is_unknown",
    IS_WITHIN = "is_within",
    EXCLUDE = "exclude",
}

export enum RelativeDateRangeOperator {
    WITHIN_LAST = "within_last",
    EXACT_DATE = "exact_date",
}

export enum RelativeDateRangeUnit {
    DAYS = "days",
    WEEKS = "weeks",
    MONTHS = "months",
    YEARS = "years",
}

export enum GroupLogicalOperator {
    AND = "and",
    OR = "or",
}

export enum FilterEntityTypes {
    COMPANY = "company",
    PERSON = "person",
}

export enum FilterFields {
    PERSON_LOCATION = "person_location",
    COMPANY_LOCATION = "company_location",
    DOMAIN = "domain",
    INDUSTRY = "industry",
    KEYWORDS = "keywords",
    TECHNOLOGIES = "technologies",
    DEPARTMENTS = "departments",
    JOB_TITLE = "job_title",
    SENIORITY = "seniority",
    ANNUAL_REVENUE = "annual_revenue",
    FOUNDED_YEAR = "founded_year",
    HEADCOUNT = "headcount",
    CSV = "csv",
}

export enum FilterCategory {
    RECENTLY_USED = "recently_used",
    PRIMER = "primer",
    SALESFORCE = "salesforce",
    AUDIENCES = "audiences",
    CSV_FILES = "csv_files",
}

export interface AddressLocation {
    city?: string;
    state?: string;
    country: string;
}

export interface Department {
    primary_department: string;
    primary_sub_department?: string;
}

export interface SourceCriteriaFilterValue {
    value: string | number | AddressLocation | Department;
    label?: string;
    category?: string;
    invalid?: boolean;
    exceeded?: boolean;
}

export type Filter = {
    unique_id: string;
    entity_type: FilterEntityTypes;
    field: FilterFields | string;
    operator: FilterOperators;
    values: SourceCriteriaFilterValue[];
    isEmpty?: boolean;
    isDuplicated?: boolean;
    customError?: string;
    dataType: FilterDataTypes;

    // for first-party
    instanceId?: string;
    objectType?: string;
    path?: string;

    // for datasets
    mappingTable?: string;
};

export type Group = {
    operator: GroupLogicalOperator;
    filters: Filter[];
    groups: Group[];
};

export type SourceCriteria = {
    target_entity_type: FilterEntityTypes;
    group: Group; // Always have at least one root group
};

export type FilterPicklistObject = {
    value: string;
    text?: string;
    category?: string;
};

export type CategoryConfig = {
    id: FilterCategory;
    name: string;
    disabled: boolean;
};

export type FilterConfig = {
    // Can be a dynamic identifier if options come from an API
    identifier: FilterFields | string;
    dataType: FilterDataTypes;
    entityType: FilterEntityTypes;
    allowedOperators: FilterOperators[];
    tableName?: string;
    alternativeColumnName?: Partial<Record<FilterOperators, string>>;
    repoV2ColumnName?: string;
    name: string;
    displayName?: string;
    tooltip: string;
    sampleData?: string;
    allowCustomInput?: boolean;
    categories: FilterCategory[];
    join?: string;
    split?: RegExp;
    pureValue?: boolean;
    props?: object;
    picklistValues?: string[];

    // for first-party
    instanceId?: string;
    objectType?: string;
    path?: string;

    // for csv files
    mappingTable?: string;
};

export type OperatorConfig = {
    id: FilterOperators;
    name: string;
    customName?: Partial<{ [key in FilterFields | string]?: string }>;
    placeholders: string[];
    query: { [key in FilterDataTypes]?: string };
    numberOfParams?: number;
    queryValuesBuilder?: { [key in FilterDataTypes]?: (values: string[], query: string) => string };
    isValid: (filter: Filter) => boolean;
    join?: string;
    tooltip: string;
};

export enum FirstPartySyncObject {
    LEAD = "lead",
    CONTACT = "contact",
    ACCOUNT = "account",
    CAMPAIGN = "campaign",
    CAMPAIGN_MEMBER = "campaign_member",
    OPPORTUNITY = "opportunity",
}

export enum FirstPartySyncObjectMeta {
    DESCRIBE = "describe",
}

export const objectIdMapping = {
    [FirstPartySyncObject.ACCOUNT]: "company_id",
    [FirstPartySyncObject.CONTACT]: "id",
    [FirstPartySyncObject.LEAD]: "id",
};
