import cn from "clsx";

interface Props {
    selectedTab: string;
    selectTab: (option: string) => void;
    options: { key: string; value: string }[];
}

export const Tabs = ({ options, selectedTab, selectTab }: Props) => {
    return (
        <div className="flex space-x-6 text-ui-700">
            {options.map(({ key, value }) => {
                const selected = selectedTab === key;
                return (
                    <div
                        key={key}
                        onClick={() => selectTab(key)}
                        className={cn("pb-3 pt-0.5 cursor-pointer", selected && "border-b-2 border-primary")}
                    >
                        <span
                            className={cn(
                                "text-16 px-2 py-1 border border-transparent transition-all hover:text-primary hover:bg-blue-50 hover:border hover:border-blue-200 hover:rounded-lg",
                                selected && "text-primary",
                            )}
                        >
                            {value}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
