import { useTimer } from "@/hooks/useTimer";
import { formatTimeToShortNotation } from "@/utils/date";

type Props = {
    startTime?: Date;
}

export const DurationTimer = ({ startTime }: Props) => {
    const time = useTimer({ startTime });

    return formatTimeToShortNotation(time);
}
