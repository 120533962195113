import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useGetOnboardingQuery, useUpdateOnboardingMutation } from "@/api/users";
import { adPlatformCards } from "@/config/integrations";
import RocketIcon from "@/assets/icons/rocket.svg?react";
import CircleIcon from "@/assets/icons/circle.svg?react";
import { useGetConnectionsQuery } from "@/api/integrations";
import { ConnectionStatus, Service } from "@/types/integrations";
import CheckedIcon from "@/assets/icons/circle-check-2.svg?react";
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from "@/components/atoms/Popover";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import { useWindowFocus } from "../../../hooks/useWindowFocus";
import ClickAwayListener from "react-click-away-listener";
import { addWeeks } from "date-fns";

export const OnboardingHub = () => {
    const navigate = useNavigate();
    const windowFocused = useWindowFocus();
    const { width, height } = useWindowSize();

    const { data: onboarding } = useGetOnboardingQuery();
    const { data: connections, isLoading } = useGetConnectionsQuery(
        { enabled: true },
        { refetchOnMountOrArgChange: true },
    );
    const [updateOnboarding] = useUpdateOnboardingMutation();

    const salesForceConnection = connections?.find(({ provider }) => provider === Service.SALESFORCE);

    const isSalesForceConnected =
        salesForceConnection?.state === ConnectionStatus.CONNECTED ||
        salesForceConnection?.state === ConnectionStatus.INITIATED;

    const atLeastOnePlatformConnected = useMemo(
        () =>
            adPlatformCards.some(
                ({ service }) =>
                    connections?.find(connection => connection.provider === service)?.state ===
                    ConnectionStatus.CONNECTED,
            ),
        [connections],
    );

    let finishedCount = isSalesForceConnected
        ? atLeastOnePlatformConnected
            ? 2
            : 1
        : atLeastOnePlatformConnected
            ? 1
            : 0;

    finishedCount = onboarding?.audience_run ? finishedCount + 1 : finishedCount;
    finishedCount = onboarding?.ad_destination_visited ? finishedCount + 1 : finishedCount;

    const percentage =
        finishedCount === 1 ? 25 : finishedCount === 2 ? 50 : finishedCount === 3 ? 75 : finishedCount === 4 ? 100 : 0;

    const [open, setOpen] = useState(false);
    const openClass = open
        ? "!bg-blue-50 !border-blue-300 !text-primary hover:!bg-purple-500 hover:!text-white hover:!border-transparent"
        : "";

    const redirectToIntegrations = () => {
        navigate("/integrations");
    };

    const redirectToAudiences = () => {
        if (onboarding?.audience_id) navigate(`/audiences/${onboarding.audience_id}`);
        else navigate(`/audiences`);
    };

    const redirectToAudiencesRunsModal = () => {
        if (onboarding?.audience_id)
            navigate(`/audiences/${onboarding.audience_id}?showRuns=${onboarding.audience_run ? "yes" : "no"}`, {
                replace: true,
            });
        else navigate(`/audiences`);
    };

    const [confettiHasFallen, setConfettiHasFallen] = useState(false);

    useEffect(() => {
        if (finishedCount !== 4 || !windowFocused || !open) return;
        const timer = setTimeout(() => {
            setConfettiHasFallen(true);
        }, 10000);

        return () => clearTimeout(timer);
    }, [finishedCount, windowFocused, open]);

    const handlePopover = async () => {
        if (onboarding && finishedCount === 4 && open) {
            await updateOnboarding({ id: onboarding!.id, hub_closed: true });
        }
        setOpen(!open);
    };

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | null = null;

        if (open) {
            timer = setTimeout(() => {
                setOpen(false);
            }, 30000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [open]);

    if (!onboarding || isLoading || new Date(onboarding.created_at) < addWeeks(new Date(), -1)) return null;
    if (!onboarding.onboarding_completed || onboarding.hub_closed) return null;

    function handleClickAway(event: any): void {
        if (event.target.id === "open-hub") return;
        setOpen(false);
    }

    return (
        <>
            <Popover open={open}>
                <PopoverTrigger className="absolute bottom-[18px] right-20" onClick={handlePopover} id="open-hub">
                    <div
                        className={`flex items-center px-4 py-3.5 shadow-main bg-purple-600 rounded-3xl text-white border border-transparent hover:!bg-purple-500 transition-colors ${openClass}`}
                    >
                        <RocketIcon />
                        <div className="text-14 ml-1.5">{`Finish set up: ${finishedCount}/4`}</div>
                    </div>
                </PopoverTrigger>

                <PopoverContent
                    align="end"
                    className="py-2 rounded-lg shadow-dropdown w-[300px] text-ui-700 -mb-1.5 !z-30"
                    style={{ backgroundImage: "linear-gradient(45deg, rgba(255,255,255) 62%, rgba(214,228,255) 100%)" }}
                >
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <>
                            <div className="px-6 py-4 w-full border-b border-ui-300/[0.19]">
                                <div className="text-h2 font-medium mb-4">Getting started!</div>
                                <div className="text-12 mb-2">{`${percentage}% complete`}</div>
                                <div className="h-1 rounded bg-ui-200 mt-2 w-full">
                                    <div style={{ width: `${percentage}%` }} className="h-full rounded bg-blue-800"></div>
                                </div>
                            </div>

                            <HubItem checked={isSalesForceConnected} item="Connect your CRM" action={redirectToIntegrations} />
                            <HubItem
                                checked={atLeastOnePlatformConnected}
                                item="Connect ad destination"
                                action={redirectToIntegrations}
                            />
                            <HubItem
                                checked={!!onboarding?.audience_run}
                                item="Run your first audience"
                                action={redirectToAudiences}
                            />
                            <HubItem
                                checked={!!onboarding?.ad_destination_visited}
                                item="See audience in ad account"
                                action={redirectToAudiencesRunsModal}
                                disabled={!onboarding.audience_run}
                            />
                        </>
                    </ClickAwayListener>
                </PopoverContent>
            </Popover>

            {finishedCount === 4 && windowFocused && open && (
                <Confetti
                    width={width}
                    height={height}
                    opacity={0.6}
                    gravity={0.1}
                    numberOfPieces={confettiHasFallen ? 0 : 300}
                />
            )}
        </>
    );
};

interface Props {
    checked: boolean;
    item: string;
    action?: () => void;
    disabled?: boolean;
}

export const HubItem = ({ checked, item, action, disabled = false }: Props) => {
    const checkedClass = checked ? "group text-green-500" : "hover:text-primary hover:bg-blue-50 cursor-pointer";
    return (
        <PopoverClose disabled={checked || disabled} className="w-full">
            <div
                className={`flex items-center group px-6 py-3 space-x-2 ${checkedClass} ${disabled && "opacity-40"}`}
                onClick={() => !checked && !disabled && action?.()}
            >
                {checked ? <CheckedIcon /> : <CircleIcon className="group-hover:text-primary mb-0.5" />}
                <div>{item}</div>
            </div>
        </PopoverClose>
    );
};
