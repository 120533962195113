import { useGetConnectionsQuery } from "@/api/integrations";
import { Destination } from "@/types/audience";
import { Connection } from "@/types/integrations";
import { useMemo } from "react";

type ConnectionsHash = {
    [key in Destination]: Connection | null;
};

const useGetConnectionsHash = (): [connectionsHash: ConnectionsHash, isLoading: boolean] => {
    const { data, isLoading } = useGetConnectionsQuery({ enabled: true });

    const connectionsHash = useMemo(() => {
        const connectionsHash: ConnectionsHash = {
            csv: null,
            google: null,
            linkedIn: null,
            meta: null,
            salesforce: null,
        };

        data?.forEach((connection: Connection) => {
            if (connection.provider === "linkedin") {
                connectionsHash[Destination.LINKEDIN] = connection;
            } else {
                connectionsHash[connection.provider as Destination] = connection;
            }
        });

        return connectionsHash;
    }, [data]);

    return [connectionsHash, isLoading];
};

export { useGetConnectionsHash };
