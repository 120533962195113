import { AudienceTableRow } from "./AudienceTableRow";
import { TableBody, TableHeader, TableRow } from "@/components/atoms/Table";
import { Audience, AudienceStatus } from "@/types/audience";
import { AudienceTableLoadingRow } from "./AudienceTableLoadingRow";
import { GetAudienceSortColumn, GetAudienceSortOrder } from "@/types/api";
import { AudienceTableHeader } from "./AudienceTableHeader";
import { useEffect, useState } from "react";

export interface AudienceTableProps {
    data: Audience[];
    isLoading: boolean;
    handleSort: (sortColumn: GetAudienceSortColumn, sortOrder: GetAudienceSortOrder) => void;
    currentSort: { column: GetAudienceSortColumn; order: GetAudienceSortOrder };
    handleFavoriteCacheUpdate: (id: Audience["id"], isFavorite: boolean) => void;
    handleViewRuns: (audienceId: string) => void;
    warningsContainerHeight: number;
}

export const AudienceTable = ({
    data,
    isLoading,
    handleSort,
    currentSort,
    handleFavoriteCacheUpdate,
    handleViewRuns,
    warningsContainerHeight,
}: AudienceTableProps) => {
    const activeAudienceStatuses = [AudienceStatus.RUNNING, AudienceStatus.SYNCED, AudienceStatus.INCOMPLETE];
    const firstActiveAudience = data.find(({ status }) => activeAudienceStatuses.includes(status));

    const updateHeight = () => setTableHeight(window.innerHeight - 206 - warningsContainerHeight);
    const [tableHeight, setTableHeight] = useState(window.innerHeight - 206 - warningsContainerHeight);
    useEffect(() => {
        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warningsContainerHeight]);

    return (
        <div className="mx-1.5 !p-0 md:mx-4 lg:mx-12">
            <table style={{ height: tableHeight }} className="block w-full min-w-[1168px] caption-bottom text-sm overflow-auto bg-white rounded-lg border border-ui-300/[0.32]">
                <TableHeader className="w-full">
                    <TableRow className="sticky top-0 h-[48px] bg-white shadow-[inset_0_-2px_0_theme(colors.dark.500/0.2)] z-[1] !border-b-0">
                        <AudienceTableHeader className="w-[52px]" />
                        <AudienceTableHeader
                            className={"w-[55%] max-w-[1060px]"}
                            text={"AUDIENCE"}
                            sortConfig={{
                                handleSort: handleSort,
                                currentSort: currentSort,
                                columnKey: GetAudienceSortColumn.NAME,
                            }}
                        />
                        <AudienceTableHeader text={"DESTINATION"} className={"w-[10%] max-w-[200px]"} />
                        <AudienceTableHeader
                            text={"SIZE"}
                            sortConfig={{
                                handleSort: handleSort,
                                currentSort: currentSort,
                                columnKey: GetAudienceSortColumn.SIZE,
                            }}
                            className={"w-[15%] max-w-[240px]"}
                        />
                        <AudienceTableHeader
                            text={"STATUS"}
                            sortConfig={{
                                handleSort: handleSort,
                                currentSort: currentSort,
                                columnKey: GetAudienceSortColumn.STATUS,
                            }}
                            className={"w-[15%] max-w-[240px]"}
                        />
                        <AudienceTableHeader
                            text={"LAST UPDATED"}
                            sortConfig={{
                                handleSort: handleSort,
                                currentSort: currentSort,
                                columnKey: GetAudienceSortColumn.UPDATED_AT,
                            }}
                            className={"w-[10%] max-w-[200px]"}
                        />
                        <AudienceTableHeader className="w-[80px]" />
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {isLoading &&
                        [...Array(50).keys()].map((_, i) => <AudienceTableLoadingRow key={i} index={i + 1} />)}
                    {!isLoading &&
                        data?.map(audience => (
                            <AudienceTableRow
                                key={audience.id}
                                firstActiveAudience={firstActiveAudience}
                                audience={audience}
                                handleFavoriteCacheUpdate={handleFavoriteCacheUpdate}
                                handleViewRuns={handleViewRuns}
                            />
                        ))}
                </TableBody>
            </table>
        </div>
    );
};
