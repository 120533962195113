import * as React from "react";
import { Input, InputProps } from "../Input/Input";

export interface NumberInputProps extends Omit<InputProps, "onChange" | "type"> {
    onChange?: (eventValue: string) => void;
    addCommas?: boolean;
}

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
    ({ onChange, value, addCommas, ...props }, ref) => {
        const [updatedValue, setUpdatedValue] = React.useState(value);

        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const cleanedValue = event.target.value.replace(/[^0-9]/g, '');
            setUpdatedValue(cleanedValue);
            onChange?.(cleanedValue);
        };

        React.useEffect(() => {
            if (value !== undefined && value !== value) {
                setUpdatedValue(value.toString());
            }
        }, [value]);

        const getDisplayValue = () => {
            if (!addCommas) {
                return updatedValue;
            } else if (addCommas && typeof updatedValue === "number") {
                return new Intl.NumberFormat().format(updatedValue);
            } else if (addCommas && typeof updatedValue === "string") {
                if (updatedValue.length === 0) {
                    return "";
                } else {
                    return new Intl.NumberFormat().format(parseInt(updatedValue));
                }
            }
        };

        return <Input {...props} ref={ref} value={getDisplayValue()} type="text" onChange={handleInputChange} />;
    },
);

NumberInput.displayName = "NumberInput";

export { NumberInput };
