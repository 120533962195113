import { useUpdateAudienceMutation } from "@/api/audiences";

export const useUpdateAudience = (audienceId?: string) => {
    const [mutate, ...rest] = useUpdateAudienceMutation();

    const unarchiveAudience = async () => {
        if (audienceId) {
            await mutate({ id: audienceId, archived: false });
        }
    };

    const archiveAudience = async () => {
        if (audienceId) {
            await mutate({ id: audienceId, archived: true });
        }
    };

    return {
        mutate,
        unarchiveAudience,
        archiveAudience,
        ...rest,
    };
};
