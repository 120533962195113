import StatusWarningIcon from "@/assets/icons/status-warning.svg?react";
import cn from "classnames";
import { AccessibleIcon } from "@radix-ui/react-accessible-icon";

export interface RepoRctBannerProps {
    className?: string;
}

const RepoRcBanner = ({ className }: RepoRctBannerProps) => {
    return (
        <div className={cn("flex bg-yellow-50 p-3 rounded-lg", className)}>
            <AccessibleIcon label="StatusWarning">
                <StatusWarningIcon className="h-5 w-5 mr-2" />
            </AccessibleIcon>

            <p className="text-sm text-ui-700">
                You are using the repository release candidate.
            </p>
        </div>
    );
};

export default RepoRcBanner;
