import { useSelector } from "react-redux";

import WarningIcon from "@/assets/icons/warning.svg?react";
import { Table, TableBody, TableHead, TableHeader, TableRow, TableCell } from "@/components/atoms/Table";
import { CsvMappingFields } from "@/types/csv";

import { CsvFieldMappingRow } from "./CsvFieldMappingRow";
import { AppState } from "@/store";
import { useCsvSampleData } from "./hooks";
import { groupsConfig } from "@/utils/csv";

export const CsvFieldsMapping = () => {
    const { entityType, fieldMappings } = useSelector((state: AppState) => state["csv-import"]);
    const groupConfig = groupsConfig[entityType];

    const orGroupMapped = !groupConfig.or || groupConfig.or.some(field => fieldMappings[field]);
    const samples = useCsvSampleData();

    return (
        <Table className="mt-2">
            <TableHeader className="bg-ui-50 border-ui-300 border-b  max-w-[600px]">
                <TableRow className="h-[48px] border-dark-500 border-opacity-20">
                    <TableHead className="!p-4 w-[196px] text-ui-700 font-medium uppercase text-start">Csv Field</TableHead>
                    <TableHead className="!p-4 text-ui-700 font-medium uppercase text-start">
                        Primer Field
                    </TableHead>
                    <TableHead className="!p-4 text-ui-700 font-medium uppercase text-start">
                        Csv Data Preview
                    </TableHead>
                </TableRow>
            </TableHeader>

            <TableBody>
                {groupConfig.and && groupConfig.and.length > 0 && groupConfig.and.map((field: CsvMappingFields) => (
                    <CsvFieldMappingRow key={field} field={field} samples={samples[field]} />
                ))}
                {!orGroupMapped && (
                    <TableRow className="h-[48px] border-dark-500 border-opacity-20">
                        <TableCell colSpan={3} className="align-bottom !pb-1">
                            <span className="flex items-end gap-2 px-2 text-orange-700">
                                <WarningIcon />
                                <span>Please map at least one field below</span>
                            </span>
                        </TableCell>
                    </TableRow>
                )}
                {groupConfig.or.length > 0 && groupConfig.or.map((field: CsvMappingFields) => (
                    <CsvFieldMappingRow key={field} field={field} samples={samples[field]} />
                ))}
            </TableBody>
        </Table>
    );
};
