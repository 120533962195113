import { SplitPaneLayout } from "@/components/layout";
import { OrganizationList, RedirectToSignIn, useAuth, useClerk } from "@clerk/clerk-react";

import JoinGraphic from "@/assets/icons/organization-join.svg";
import { useCheckUserExistQuery } from "@/api/users";
import { useEffect, useState } from "react";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar";

export const OrganizationPick = () => {
    const { signOut } = useClerk();
    const { isSignedIn, isLoaded } = useAuth();
    const [userExists, setUserExists] = useState<boolean>(false);

    const {
        data: exists,
        isLoading,
    } = useCheckUserExistQuery(
        undefined,
        {
            pollingInterval: 1000 * 2,
            skip: !!userExists || !isSignedIn
        },
    );

    useEffect(() => {
        if (exists) setUserExists(exists);
    }, [exists]);

    if (!isLoaded) {
        return <div className="w-full flex justify-center">
            <CircularProgressBar className="w-8 h-8" />
        </div>;
    }

    if (!isSignedIn && isLoaded) return <RedirectToSignIn />;

    return (
        <SplitPaneLayout imgUrl={JoinGraphic} left>
            <div className="relative [&_#back-to-login]:has-[.cl-organizationList-root]:inline-block">
                {userExists && <OrganizationList
                    afterSelectOrganizationUrl="/audiences?signed=true"
                    afterCreateOrganizationUrl="/audiences?signed=true"
                    hidePersonal
                />}
                {!userExists && <OrganizationList
                    appearance={{
                        elements: {
                            main: { display: "none" },
                        },
                    }}
                />}
                {(isLoading || !userExists) && <div className="w-full flex justify-center"><CircularProgressBar className="w-8 h-8" /></div>}
                <button
                    onClick={() => signOut()}
                    id="back-to-login"
                    className="absolute hidden top-12 right-8 z-10 text-xs leading-6 font-medium text-primary hover:text-blue-850 hover:underline tracking-wider focus:outline-none"
                >
                    BACK TO LOGIN
                </button>
            </div>
        </SplitPaneLayout>
    );
};
