import { useSignIn } from "@clerk/clerk-react";

import { DoublePaneLayout } from "@/components/layout";
import SideHeroBanner from "@/SideHeroBanner";
import CustomLogin from "./CustomLogin";

const Login = () => {
    const { isLoaded } = useSignIn();
    return (
        <DoublePaneLayout>
            <CustomLogin isLoaded={isLoaded} />
            <SideHeroBanner login />
        </DoublePaneLayout>
    );
};

export default Login;
