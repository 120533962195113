import { ReactNode } from "react";
import { Modal } from "@/components/organisms/Modal";
import CloseIcon from "@/assets/icons/close.svg?react";
import PrimerLogoIcon from "@/assets/icons/primer-union-logo.svg?react";

interface Props {
    className: string;
    isLarge?: boolean;
    children: ReactNode;
    onClose?: () => void;
    zIndex?: string;
}

export const BrandedModal = ({ className, isLarge = false, children, onClose, zIndex }: Props) => {
    const iconClass = isLarge ? "w-[270px] h-[280px]" : "w-[120px] h-[120px]";
    const iconSrc = isLarge ? "/primer-first-letter-large.png" : "/primer-first-letter.png";

    return (
        <Modal
            onClose={onClose}
            className={`overflow-hidden ${className}`}
            zIndex={zIndex}
            style={{ backgroundImage: "linear-gradient(45deg, rgba(255,255,255) 62%, rgba(214,228,255) 100%)" }}
        >
            <img className={`absolute right-0 top-0 ${iconClass}`} src={iconSrc} />

            <div className="flex justify-between">
                <PrimerLogoIcon />
                {onClose && <CloseIcon className="text-ui-300 cursor-pointer font-semibold z-10" onClick={onClose} />}
            </div>

            <div className={`min-h-32 ${isLarge ? "mt-10" : "mt-6"}`}>{children}</div>
        </Modal>
    );
};
