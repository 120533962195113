import { ReactNode } from "react";
import { PostHog } from "posthog-js";
import { useClerk } from "@clerk/clerk-react";
import { PostHogProvider } from "posthog-js/react";
import { useBootstrapFeatureFlagsQuery, useGetOnboardingQuery } from "@/api/users";
import { PostHogPersonProperties } from "@/types/api.ts";
import { FeatureFlagsEnum } from "@/hooks";

export const PosthogMiddleware = ({ children }: { children: ReactNode }) => {
    const { user, organization } = useClerk();

    const getPersonProperties = (): PostHogPersonProperties => {
        const { id, firstName, lastName, primaryEmailAddress } = user!;
        const email = primaryEmailAddress?.emailAddress || "";

        return {
            ClerkUserId: id,
            email,
            name: `${firstName} ${lastName}`,
            ClerkOrganizationId: organization!.id,
            CompanyName: organization!.name,
            CompanyWebsite: email.split("@")?.[1] || "",
        };
    };

    const isDevelopmentMode = import.meta.env.MODE === "development";

    const { data, isLoading, isFetching, isError } = useBootstrapFeatureFlagsQuery(getPersonProperties(), {
        skip: isDevelopmentMode,
    });

    const isOnboardingEnabled = !!data?.[FeatureFlagsEnum.ONBOARDING_PITCH];

    const { isLoading: isOnboardingLoading } = useGetOnboardingQuery(undefined, {
        skip: !isOnboardingEnabled,
    });

    if (isDevelopmentMode) return children;

    const identifyUser = (posthog: PostHog) => {
        const personProperties = getPersonProperties();
        posthog.identify(personProperties.ClerkUserId, personProperties);
    };

    if (isLoading || isFetching || isOnboardingLoading) return null;

    return (
        <PostHogProvider
            apiKey={import.meta.env.VITE_POSTHOG_KEY}
            options={{
                api_host: import.meta.env.VITE_POSTHOG_PROXY,
                ui_host: import.meta.env.VITE_POSTHOG_HOST,
                loaded: identifyUser,
                bootstrap: { distinctID: user!.id, isIdentifiedID: true, featureFlags: !data || isError ? {} : data },
                // Will keep /decide running, but without any feature flag requests
                advanced_disable_feature_flags: true,
                capture_pageleave: false,
            }}
        >
            {children}
        </PostHogProvider>
    );
};
