import GrowPlanIcon from "@/assets/icons/grow-plan.svg?react";

export const TrialNotStartedBanner = () => {
    return (
        <div className="flex items-center mx-12 mb-2 px-6 py-4 bg-blue-900 space-x-6 rounded-md">
            <div className="flex items-center gap-3 shrink-0">
                <GrowPlanIcon />

                <div>
                    <div className="text-white text-16 font-medium">Primer Grow</div>
                    <div className="text-12 text-center text-ui-900 bg-yellow-500 px-2 py-0.5 rounded font-medium">
                        FREE TRIAL
                    </div>
                </div>
            </div>

            <p data-testid="trial-text" className="flex-1 flex flex-col text-14 text-white truncate">
                Your free trial begins with your first audience sync! Get 30 days with up to 3 audiences.
            </p>

            <div className="max-w-[200px] text-yellow-500">
                Trial starts after the first audience sync
            </div>
        </div>
    );
};
