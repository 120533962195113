import { useTimer } from "@/hooks/useTimer";

const formatPadStart = (diff: number) => String(diff).padStart(2, "0")

interface TimerProps {
    startTime?: Date;
}

const Timer = ({ startTime }: TimerProps) => {
    const diff = useTimer({ startTime });

    return (
        <span className="text-ui-300 text-sm mx-2 text-center min-w-16">
            {`${formatPadStart(diff.hours)}:${formatPadStart(diff.minutes)}:${formatPadStart(diff.seconds)}`}
        </span>
    );
};

export default Timer;
