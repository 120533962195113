import { TrialNotStartedBanner } from "./TrialNotStartedBanner";
import { useStatisticsQuery } from "@/api/audiences";
import { useGetSubscriptionProductsQuery, useSubscriptionQuery } from "@/api/users";
import { SubscriptionStatus } from "@/types/subscription";
import { SubscriptionExpiredBanner } from "./SubscriptionExpiredBanner";
import { useEffect } from "react";
import { useToast } from "@/components/atoms/Toast/useToast";
import { TrialStartedBanner } from "./TrialStartedBanner";
import { getPriceInfo } from "../utils";

export const SubscriptionBanner = () => {
    const { toast } = useToast();
    const { data: subscriptionProducts } = useGetSubscriptionProductsQuery();
    const { data: subscription, isLoading, isError } = useSubscriptionQuery(undefined);
    const { data: statistics } = useStatisticsQuery(void true, { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (!isError) return;

        toast({
            variant: "destructive",
            description:
                "Error while getting user subscription. All audiences have stopped updating. Please contact support",
        });
    }, [toast, isError]);

    if (isLoading || isError) return null;

    const render = () => {
        const freeTierPrice = subscriptionProducts ? getPriceInfo(subscriptionProducts, "grow", "free", "monthly") : undefined;
        const currentSubscriptionItem = !subscriptionProducts ? undefined : subscription?.subscription_items.find(({ product_id }) =>
            subscriptionProducts?.grow?.product_id && subscriptionProducts?.scale?.product_id && 
            [subscriptionProducts.grow.product_id, subscriptionProducts.scale.product_id].includes(product_id),
        );
        const isFree = subscription && currentSubscriptionItem?.plan_id === freeTierPrice?.id;
        if (isFree) {
            return <TrialNotStartedBanner />;
        }

        if (subscription?.status === SubscriptionStatus.ACTIVE) {
            return null;
        }
        
        if (subscription?.status === SubscriptionStatus.TRIALING) {
            return <TrialStartedBanner subscription={subscription} audiencesLimit={statistics?.audiencesMaxLimit} />;
        }

        return <SubscriptionExpiredBanner status={subscription?.status || SubscriptionStatus.INCOMPLETE} />;
    };
    return <>{render()}</>;
};
