import * as React from "react";
import cn from "classnames";

/**
 * Refer to https://ui.shadcn.com/docs/components/card for more details
 */
const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("rounded-2xl pt-6 pr-8 pb-6 pl-8 bg-white shadow-card text-ui-", className)}
        {...props}
    />
));

Card.displayName = "Card";

export { Card };
