import { Link } from "react-router-dom";
import postHog from "posthog-js";
import { Button } from "@/components/atoms/Button/Button";
import { Destination } from "@/types/audience";

const DestinationConnectLink = ({ text, service }: { text: string; service: Destination }) => (
    <Button
        onClick={() => {
            postHog.capture("Destination connected", { buttonClass: "connect-destination", buttonAttribute: service });
        }}
        className="!py-0 h-auto"
        variant="ghost"
    >
        <Link to="/integrations" className="text-sm text-blue-800">
            {text}
        </Link>
    </Button>
);

export default DestinationConnectLink;
