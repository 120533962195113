/* eslint-disable react-hooks/exhaustive-deps */
import {
    CriteriaAssets,
    GetAudienceResponse,
    GetEstimateMatchRateRawResponse,
    GetEstimateMatchRateRequest,
    GetEstimatePreviewRawResponse,
    GetEstimatePreviewRequest,
    GetEstimatePreviewResponse,
    GetEstimateSizeRawResponse,
    GetEstimateSizeRequest,
    GetEstimateSizeResponse,
    GetEstimateSummaryRequest,
    GetEstimateSummaryRequestV2,
    GetEstimateSummaryResponse,
} from "@/types/api";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import {
    useGetCriteriaEstimateMatchRateMutation,
    useGetCriteriaEstimatePreviewMutation,
    useGetCriteriaEstimateSizeMutation,
    useGetCriteriaEstimateSummaryV2Mutation,
} from "@/api/criteriaAssets";
import {
    AudienceEstimatePreview,
    ChartSchema,
    ExtraChartSchema,
    SummaryCategoryField,
    SummaryCategoryGroupField,
} from "@/types/audience";
import { isEqual, omit, uniqBy, size as getSize, merge, isNumber, uniq } from "lodash";
import { parseSummary } from "@/utils/summary";
import { usePostHog } from "posthog-js/react";
import { FeatureFlagsEnum, useFeatureFlag } from "./useFeatureFlag";
import { FilterEntityTypes } from "@primer/filters/types";

export type CriteriaEstimateManagerHook = {
    size: GetEstimateSizeResponse | undefined;
    preview: AudienceEstimatePreview | undefined;
    match_rate: GetEstimateMatchRateRawResponse | undefined;
    summaries: ExtraChartSchema[];
    criteriaAssets?: JoinedCriteriaAssets;
    isLoadingSomeEstimateAsset: boolean;
    isLoadingSize: boolean;
    isLoadingPreview: boolean;
    isLoadingMatchRate: boolean;
    isLoadingSomeSummary: boolean;
    summariesLoading: string[];
    summariesExpanding: string[];
    callEstimatePreview: (body?: Partial<GetEstimatePreviewRequest>) => Promise<void>;
    callAllEstimates: (
        body: Partial<GetEstimateSizeRequest>,
        overrides?: RequestOverrides,
    ) => Promise<[void, void, void]>;
    expandSummary: (body: Partial<GetEstimateSummaryRequest>) => void;
    preventCriteriaChange: boolean;
    hasAnyEstimateMutationError: boolean;
};

export type RequestOverrides = {
    previewParams?: Partial<GetEstimatePreviewRequest>;
    summaryParams?: {
        limits: { [key in SummaryCategoryGroupField]?: number };
    };
};

export type JoinedCriteriaAssets = {
    heuristics?: {
        top: ChartSchema[] | undefined;
    };
    preview?: AudienceEstimatePreview | undefined;
    companies_count?: number | undefined;
    people_count?: number | undefined;
    match_rate?: GetEstimateMatchRateRawResponse;
};

export const useCriteriaAssetsManager = ({
    audienceId,
    shapeId,
    audience,
    preventCriteriaChange,
}: {
    audienceId?: string;
    shapeId?: string;
    audience?: GetAudienceResponse;
    preventCriteriaChange: boolean;
}): CriteriaEstimateManagerHook => {
    const { id } = useParams();
    const posthog = usePostHog();
    const isMatchRatesProjectionEnabled = useFeatureFlag(FeatureFlagsEnum.MATCH_RATES_PROJECTION);
    const isRepoV2Enabled = useFeatureFlag(FeatureFlagsEnum.PITCH_REPO_V2);
    const [lastStoredCriteriaAssets, setLastStoredCriteriaAssets] = useState<JoinedCriteriaAssets | undefined>();
    const [size, setSize] = useState<GetEstimateSizeResponse | undefined>();
    const [isLoadingSize, setIsLoadingSize] = useState(false);
    const [preview, setPreview] = useState<GetEstimatePreviewResponse | undefined>();
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [match_rate, setMatchRate] = useState<GetEstimateMatchRateRawResponse | undefined>();
    const [isLoadingMatchRate, setIsLoadingMatchRate] = useState(false);
    const [summariesLoading, setSummariesLoading] = useState<SummaryCategoryGroupField[]>([]);
    const [summariesExpanding] = useState<SummaryCategoryGroupField[]>([]);
    const [rawSummaries, setRawSummaries] = useState<GetEstimateSummaryResponse[] | undefined>([]);
    const [summaries, setSummaries] = useState<ExtraChartSchema[]>([]);

    const [getCriteriaEstimateSizeMutation, { isError: isSizeMutationError }] = useGetCriteriaEstimateSizeMutation();
    const getCriteriaEstimateSize = async (req: GetEstimateSizeRequest): Promise<GetEstimateSizeRawResponse> =>
        getCriteriaEstimateSizeMutation(req).unwrap();

    const [getCriteriaEstimatePreviewMutation, { isError: isPreviewMutationError }] =
        useGetCriteriaEstimatePreviewMutation();
    const getCriteriaEstimatePreview = async (req: GetEstimatePreviewRequest): Promise<GetEstimatePreviewRawResponse> =>
        getCriteriaEstimatePreviewMutation(req).unwrap();

    const [getCriteriaEstimateMatchRateMutation, { isError: isMatchRateMutationError }] =
        useGetCriteriaEstimateMatchRateMutation();
    const getCriteriaEstimateMatchRate = async (
        req: GetEstimateMatchRateRequest,
    ): Promise<GetEstimateMatchRateRawResponse> => getCriteriaEstimateMatchRateMutation(req).unwrap();

    const [getCriteriaEstimateSummaryMutation, { isError: isSummaryMutationError }] =
        useGetCriteriaEstimateSummaryV2Mutation();
    const getCriteriaEstimateSummary = async (
        req: GetEstimateSummaryRequestV2,
    ): Promise<GetEstimateSummaryResponse[]> => getCriteriaEstimateSummaryMutation(req).unwrap();

    useEffect(() => loadSize(), [size, audienceId, audience]);
    useEffect(() => loadPreview(), [preview, audienceId, audience]);
    useEffect(() => loadMatchRate(), [match_rate, audienceId, audience]);
    useEffect(() => loadSummaries(), [rawSummaries, audienceId, audience]);
    useEffect(
        () =>
            setSummaries(
                parseSummary(
                    isLoadingSize,
                    rawSummaries,
                    size?.people_count,
                    size?.companies_count,
                    audience,
                    isRepoV2Enabled,
                ),
            ),
        [isLoadingSize, isRepoV2Enabled, rawSummaries, size],
    );
    useEffect(
        () =>
            setLastStoredCriteriaAssets(
                audience?.shape?.heuristics
                    ? {
                          people_count: audience.shape.heuristics?.people_count,
                          companies_count: audience.shape.heuristics?.companies_count,
                          preview: audience.shape.heuristics?.preview,
                          heuristics: {
                              top: audience.shape.heuristics?.heuristics?.top,
                          },
                          match_rate: audience.shape.heuristics?.match_rate,
                      }
                    : undefined,
            ),
        [audience],
    );

    const safeRequestIdTracker = useRef({
        size: 0,
        preview: 0,
        match_rate: 0,
        people_summary: 0,
        companies_summary: 0,
        summaries: {
            [SummaryCategoryGroupField.ANNUAL_REVENUE]: 0,
            [SummaryCategoryGroupField.COMPANY_LOCATION]: 0,
            [SummaryCategoryGroupField.COMPANY_DOMAIN]: 0,
            [SummaryCategoryGroupField.HEADCOUNT]: 0,
            [SummaryCategoryGroupField.INDUSTRY]: 0,
            [SummaryCategoryGroupField.JOB_TITLE]: 0,
            [SummaryCategoryGroupField.SENIORITY]: 0,
            [SummaryCategoryGroupField.DEPARTMENT]: 0,
        },
    });

    const loadSize = () => {
        if (!size && ((!audienceId && !id) || audience)) {
            if (isOldEstimate() || !isNumber(audience?.shape?.heuristics?.people_count)) callEstimateSize();
            else {
                posthog.capture("Reused Estimate", {
                    asset: CriteriaAssets.SIZE,
                    audienceId: audience?.id,
                    shapeId: audience?.shape?.id,
                    lastPulled: audience?.shape?.heuristics?.created_at,
                });
                setSize(audience?.shape?.heuristics);
            }
        }
    };

    const loadPreview = () => {
        if (!preview && !!((!audienceId && !id) || audience)) {
            const hasSizeButNoPreview =
                audience?.shape?.heuristics?.people_count && !getSize(audience?.shape?.heuristics?.preview?.data);
            const previewHasFilter =
                audience?.shape?.heuristics?.people_count &&
                Number(audience?.shape?.heuristics?.preview?.count || 0) < audience?.shape?.heuristics?.people_count;
            const previewNotOnFirstPage =
                getSize(audience?.shape?.heuristics?.preview?.data) &&
                (audience?.shape?.heuristics?.preview?.offset || 0) > 0;

            if (
                isOldEstimate() ||
                !audience?.shape?.heuristics?.preview ||
                hasSizeButNoPreview ||
                previewHasFilter ||
                previewNotOnFirstPage
            )
                callEstimatePreview();
            else {
                posthog.capture("Reused Estimate", {
                    asset: CriteriaAssets.PREVIEW,
                    audienceId: audience.id,
                    shapeId: audience.shape?.id,
                    lastPulled: audience?.shape?.heuristics?.created_at,
                });
                setPreview(audience?.shape?.heuristics?.preview);
            }
        }
    };

    const loadMatchRate = () => {
        if (!!size?.people_count && !match_rate && !!((!audienceId && !id) || audience)) {
            if (isOldEstimate() || (!audience?.shape?.heuristics?.match_rate && size.people_count <= 3500000))
                callEstimateMatchRate();
            else {
                posthog.capture("Reused Estimate", {
                    asset: CriteriaAssets.MATCH_RATE,
                    audienceId: audience?.id,
                    shapeId: audience?.shape?.id,
                    lastPulled: audience?.shape?.heuristics?.created_at,
                });
                setMatchRate(audience?.shape?.heuristics?.match_rate);
            }
        }
    };

    const loadSummaries = () => {
        if (!rawSummaries?.length && ((!audienceId && !id) || audience)) {
            const emptyOrOldSummary =
                isOldEstimate() ||
                !audience?.shape?.heuristics?.heuristics?.top?.length ||
                audience?.shape?.heuristics?.heuristics?.top?.some(t => !t.category) ||
                uniq(audience.shape.heuristics.heuristics.top.map(t => t.category)).length <
                    Object.values(SummaryCategoryField).length - (isRepoV2Enabled ? 0 : 2);

            if (emptyOrOldSummary) callAllSumaries();
            else {
                posthog.capture("Reused Estimate", {
                    asset: CriteriaAssets.SUMMARY,
                    audienceId: audience?.id,
                    shapeId: audience?.shape?.id,
                    lastPulled: audience?.shape?.heuristics?.created_at,
                });
                setRawSummaries(audience?.shape?.heuristics?.heuristics?.top);
            }
        }
    };

    const isOldEstimate = () => {
        return false;
    };

    const callEstimateSize = async (body?: Partial<GetEstimateSizeRequest>) => {
        setIsLoadingSize(true);
        const safeRequestId = new Date().getTime();
        safeRequestIdTracker.current.size = safeRequestId;
        try {
            const result = await getCriteriaEstimateSize({
                audience_id: id,
                shape_id: shapeId,
                ...body,
            });
            if (safeRequestIdTracker.current.size === safeRequestId) {
                setSize({
                    people_count: Number(result.people_count),
                    companies_count: Number(result.companies_count),
                });
            }
        } catch {
            setSize({
                people_count: undefined,
                companies_count: undefined,
            });
        } finally {
            setIsLoadingSize(false);
            safeRequestIdTracker.current.size = 0;
        }
    };

    const callEstimatePreview = async (body?: Partial<GetEstimatePreviewRequest>) => {
        setIsLoadingPreview(true);
        const safeRequestId = new Date().getTime();
        safeRequestIdTracker.current.preview = safeRequestId;
        try {
            const result = await getCriteriaEstimatePreview({
                audience_id: id,
                shape_id: shapeId,
                ...body,
            });
            if (safeRequestIdTracker.current.preview === safeRequestId) {
                setPreview({
                    data: result.data,
                    count: result.count,
                    offset: Number(result.offset),
                });
            }
        } catch {
            setPreview({
                data: [],
                count: "0",
                offset: 0,
            });
        } finally {
            setIsLoadingPreview(false);
            safeRequestIdTracker.current.preview = 0;
        }
    };

    const callEstimateMatchRate = async () => {
        // we only call match rate when size is less than 3.5M
        if (
            !isMatchRatesProjectionEnabled ||
            !size?.people_count ||
            size?.people_count > 3500000 ||
            !audienceId ||
            !shapeId
        )
            return;
        setIsLoadingMatchRate(true);
        const safeRequestId = new Date().getTime();
        safeRequestIdTracker.current.match_rate = safeRequestId;
        try {
            const result = await getCriteriaEstimateMatchRate({
                audience_id: audienceId,
                shape_id: shapeId,
                people_count: size.people_count,
            });
            if (safeRequestIdTracker.current.match_rate === safeRequestId) {
                setMatchRate(result);
                safeRequestIdTracker.current.match_rate = 0;
            }
        } catch {
            setMatchRate(undefined);
        } finally {
            setIsLoadingMatchRate(false);
            safeRequestIdTracker.current.match_rate = 0;
        }
    };

    useEffect(() => {
        callEstimateMatchRate();
    }, [size]);

    const getCategoriesByEntity = (entityType: FilterEntityTypes): SummaryCategoryGroupField[] => {
        switch (entityType) {
            case FilterEntityTypes.COMPANY:
                return [
                    SummaryCategoryGroupField.COMPANY_LOCATION,
                    SummaryCategoryGroupField.ANNUAL_REVENUE,
                    SummaryCategoryGroupField.HEADCOUNT,
                    SummaryCategoryGroupField.INDUSTRY,
                ];
            case FilterEntityTypes.PERSON:
                return [
                    SummaryCategoryGroupField.DEPARTMENT,
                    SummaryCategoryGroupField.COMPANY_DOMAIN,
                    SummaryCategoryGroupField.JOB_TITLE,
                    SummaryCategoryGroupField.SENIORITY,
                ];
            default:
                return [];
        }
    };

    const callEstimateSummary = async (body: Partial<GetEstimateSummaryRequestV2>) => {
        if (!body.entity_type) return;

        const safeRequestId = new Date().getTime();
        const loadingKey = body.entity_type === FilterEntityTypes.PERSON ? "people_summary" : "companies_summary";

        const categories = getCategoriesByEntity(body.entity_type);
        // const setLoadingStateFunction = expand ? setSummariesExpanding : setSummariesLoading;
        safeRequestIdTracker.current[loadingKey] = safeRequestId;
        setSummariesLoading(prev => [...prev, ...categories]);
        const result = await getCriteriaEstimateSummary({
            audience_id: id,
            shape_id: shapeId,
            ...body,
        });

        if (
            safeRequestIdTracker.current[loadingKey] === safeRequestId ||
            safeRequestIdTracker.current.companies_summary === safeRequestId
        ) {
            setRawSummaries(prev => uniqBy([...result, ...(prev ?? [])], "category"));
            setSummariesLoading(prev => prev.filter(p => !categories.includes(p)));
            safeRequestIdTracker.current[loadingKey] = 0;
        }
    };

    const expandSummary = (body: Partial<GetEstimateSummaryRequest>) => {
        callEstimateSummary({
            ...body,
            limit: 100,
        });
    };

    const callAllSumaries = async (body?: Partial<GetEstimateSummaryRequestV2>) => {
        try {
            await Promise.all(
                Object.values(FilterEntityTypes).map(entity_type =>
                    callEstimateSummary({ ...body, entity_type, limit: 100 }),
                ),
            );
        } catch {
            const categories = [
                ...getCategoriesByEntity(FilterEntityTypes.COMPANY),
                ...getCategoriesByEntity(FilterEntityTypes.PERSON),
            ];
            setSummariesLoading([]);
            setRawSummaries(
                categories.map(c => ({
                    category: c,
                    categoryGroup: c,
                    name: c,
                    limit: 0,
                    total: 0,
                    allowExpand: false,
                    values: [],
                })),
            );
        }
    };

    const callAllEstimates = async (body: Partial<GetEstimateSizeRequest>, overrides: RequestOverrides = {}) => {
        return Promise.all([
            callEstimateSize(body),
            callEstimatePreview(merge(body, overrides?.previewParams)),
            callAllSumaries(body),
        ]);
    };

    const isLoadingSomeSummary = useMemo(() => summariesLoading.length > 0, [summariesLoading]);

    const isLoadingSomeEstimateAsset = useMemo(
        () => isLoadingSize || isLoadingPreview || isLoadingSomeSummary,
        [isLoadingSize, isLoadingPreview, isLoadingSomeSummary],
    );

    const criteriaAssets = useMemo(
        () =>
            !!size || !!preview || !!match_rate || (!!rawSummaries?.length && rawSummaries?.length > 0)
                ? {
                      people_count: size?.people_count,
                      companies_count: size?.companies_count,
                      ...{ preview: preview },
                      ...{
                          heuristics: {
                              top: rawSummaries,
                          },
                      },
                      match_rate,
                  }
                : undefined,
        [size, preview, match_rate, rawSummaries],
    );

    useEffect(() => {
        const estimateFields = omit(lastStoredCriteriaAssets, "finished_at");
        if (
            audienceId &&
            shapeId &&
            summariesLoading.length === 0 &&
            rawSummaries?.length &&
            rawSummaries?.length > 0 &&
            summariesExpanding.length === 0 &&
            rawSummaries?.length &&
            rawSummaries?.length > 0 &&
            !isLoadingSize &&
            !isLoadingPreview &&
            !!criteriaAssets &&
            !isEqual(criteriaAssets, estimateFields)
        ) {
            setLastStoredCriteriaAssets(criteriaAssets);
        }
    }, [
        audience,
        criteriaAssets,
        isLoadingSize,
        isLoadingPreview,
        isLoadingMatchRate,
        summariesLoading,
        summariesExpanding,
    ]);

    return {
        size,
        preview,
        match_rate,
        summaries,
        isLoadingSomeEstimateAsset,
        isLoadingSomeSummary,
        isLoadingSize,
        isLoadingPreview,
        isLoadingMatchRate,
        summariesLoading,
        summariesExpanding,
        criteriaAssets,
        preventCriteriaChange,
        callEstimatePreview,
        callAllEstimates,
        expandSummary,
        hasAnyEstimateMutationError:
            isSizeMutationError || isPreviewMutationError || isMatchRateMutationError || isSummaryMutationError,
    };
};
