const Loader = () => {
    return (
        <div className="flex items-center justify-center gap-1">
            <div
                className={"w-1 h-1 rounded bg-ui-700 animate-loadingDots"}
                style={{ animationDelay: "0ms" }} // animation delay is not available in tailwind yet
            />
            <div
                className={"w-1 h-1 rounded bg-ui-700 animate-loadingDots"}
                style={{ animationDelay: "380ms" }} // animation delay is not available in tailwind yet
            />
            <div
                className={"w-1 h-1 rounded bg-ui-700 animate-loadingDots"}
                style={{ animationDelay: "760ms" }} // animation delay is not available in tailwind yet
            />
        </div>
    );
};

export default Loader;
