import cn from "classnames";
import { capitalize } from "lodash";

import { loadStatusClass } from "@/utils/integrations";
import { ConnectionStatus, Service } from "@/types/integrations";

export const ConnectionStatusPill = ({ status, service }: { status: ConnectionStatus; service: Service }) => {
    const baseClass = "px-2 py-1 text-sm rounded-xl capitalize";
    const statusClass = loadStatusClass(status);

    return (
        <span className={cn(baseClass, statusClass)} id={`${service}${capitalize(status)}`}>
            {status}
        </span>
    );
};
