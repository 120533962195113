import FacebookIcon from "@/assets/icons/facebook.svg?react";
import GoogleIcon from "@/assets/icons/google.svg?react";
import LinkedinIcon from "@/assets/icons/linkedin.svg?react";
import SalesforceIcon from "@/assets/icons/salesforce.svg?react";
import CSVIcon from "@/assets/icons/csv.svg?react";
import FacebookDisabledIcon from "@/assets/icons/facebook-disabled.svg?react";
import GoogleDisabledIcon from "@/assets/icons/google-disabled.svg?react";
import LinkedinDisabledIcon from "@/assets/icons/linkedin-disabled.svg?react";
import SalesforceDisabledIcon from "@/assets/icons/salesforce-disabled.svg?react";
import CSVDisabledIcon from "@/assets/icons/csv-disabled.svg?react";
import cn from "classnames";
import { Destination } from "@/types/audience";
import { AccessibleIcon } from "@radix-ui/react-accessible-icon";

export interface AccountIconProps {
    service: Destination;
    className?: string;
    disabled?: boolean;
}

const AccountIcon = ({ service, className, disabled = false }: AccountIconProps) => {
    const cls = cn("w-5 h-5", className);
    const title = `${service}${disabled ? "-disabled" : ""}`;
    let Icon;

    switch (service) {
        case Destination.META:
            Icon = disabled ? FacebookDisabledIcon : FacebookIcon;
            break;
        case Destination.GOOGLE:
            Icon = disabled ? GoogleDisabledIcon : GoogleIcon;
            break;
        case Destination.LINKEDIN:
            Icon = disabled ? LinkedinDisabledIcon : LinkedinIcon;
            break;
        case Destination.SALESFORCE:
            Icon = disabled ? SalesforceDisabledIcon : SalesforceIcon;
            break;
        case Destination.CSV:
            Icon = disabled ? CSVDisabledIcon : CSVIcon;
            break;
    }

    return (
        <AccessibleIcon label={title} key={title}>
            <Icon className={cls} title={title} aria-label={title} />
        </AccessibleIcon>
    );
};

export default AccountIcon;
