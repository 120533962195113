import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { api } from "@/api";
import { useCancelSubscriptionMutation } from "@/api/users";
import { Button } from "@/components/atoms/Button/Button";
import { useToast } from "@/components/atoms/Toast/useToast";
import { BrandedModal } from "@/components/organisms/Modal/BrandedModal";
import { CancelFeedbackModal } from "@/components/organisms/subscription/CancelPlan/CancelFeedbackModal";

export const CancelPlanModal = ({ close }: { close: () => void }) => {
    const { toast } = useToast();
    const dispatch = useDispatch();
    const [canceling, setCanceling] = useState(false);
    const [subscriptionCanceledModal, setSubscriptionCanceledModal] = useState(false);

    const [cancelSubscription, { error: cancelSubscriptionMethodError }] = useCancelSubscriptionMutation();

    const cancel = async () => {
        setCanceling(true);
        await cancelSubscription();

        setTimeout(() => {
            setSubscriptionCanceledModal(true);
            dispatch(api.util.invalidateTags(["subscription"]));
        }, 3000);
    };

    useEffect(() => {
        if (!cancelSubscriptionMethodError) return;
        const fetchBaseQueryError = cancelSubscriptionMethodError as FetchBaseQueryError;
        setCanceling(false);

        toast({
            variant: "destructive",
            description: (fetchBaseQueryError.data as string) || "An error occurred. Please retry",
        });
    }, [toast, cancelSubscriptionMethodError]);

    return (
        <BrandedModal className="w-[480px] px-10 py-10" zIndex="z-40">
            <div className="flex flex-col gap-2 text-ui-900">
                <h2 className="text-h2 font-medium z-50">Are you sure you want to cancel your plan?</h2>
                <div className="text-14">
                    You will no longer be able to sync or update your audiences, but you'll still have access to your
                    account.
                </div>
                <div className="mt-8 flex gap-3 self-end">
                    <Button variant="secondary" onClick={close} disabled={canceling}>
                        Dismiss
                    </Button>
                    <Button loading={canceling} onClick={cancel}>
                        Cancel Plan
                    </Button>
                </div>
            </div>
            {subscriptionCanceledModal && <CancelFeedbackModal close={close} />}
        </BrandedModal>
    );
};
