import { TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/atoms/Table";
import LocationIcon from "@/assets/icons/location.svg?react";
import FileIcon from "@/assets/icons/file-gray.svg?react";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { formatNumber } from "@/utils/number";
import { AudienceShapeHeuristicsSchema } from "@/types/audience";
import { useWindowSize } from "react-use";
import { useEffect, useState } from "react";
import { usePagination } from "@/hooks";
import Pagination from "@/components/molecules/Pagination/Pagination";
import PreviewSkeletonRow from "./PreviewSkeletonRow";
import { isEmpty, upperFirst } from "lodash";
import { PREVIEW_MAX_RECORDS, PREVIEW_PAGE_SIZE } from "@primer/filters/configs";

const COLUMNS_V2 = ["NAME", "JOB TITLE", "DEPARTMENT", "COMPANY", "HEADCOUNT", "INDUSTRY", "KEYWORDS"];

interface PreviewCardProps {
    preview?: AudienceShapeHeuristicsSchema["preview"];
    isPreviewLoading?: boolean;
    onPageChange: (offset: number) => void;
}

export function PreviewCard({ preview, isPreviewLoading = false, onPageChange }: PreviewCardProps) {
    const [tableHeight, setTableHeight] = useState(0);
    const { width, height } = useWindowSize();
    const { data: previewData, count: previewCount, offset } = preview || {};
    const pagination = usePagination({ limit: PREVIEW_PAGE_SIZE });
    const { updateTotal, setCurrentPage, from } = pagination;

    useEffect(() => {
        const headerHeight = document.getElementById("audience-header")?.clientHeight || 0;
        const estimateCardHeight = document.getElementById("horizontal-estimate-card")?.clientHeight || 0;
        const summaryPreviewTabHeight = document.getElementById("summary-preview-tab")?.clientHeight || 0;

        setTableHeight(height - headerHeight - estimateCardHeight - summaryPreviewTabHeight - 104);
    }, [height]);

    useEffect(() => {
        setCurrentPage(Math.floor((offset || 0) / PREVIEW_PAGE_SIZE));
    }, [offset, setCurrentPage]);

    useEffect(() => {
        updateTotal(Math.min(PREVIEW_MAX_RECORDS, Number(previewCount)), isPreviewLoading);
    }, [previewData?.length, isPreviewLoading, updateTotal, previewCount]);

    const isXlScreen = width > 1920;

    return (
        <div>
            <div style={{ height: tableHeight }}>
                {!isPreviewLoading && previewData?.length === 0 ? (
                    <div className="flex flex-col justify-center items-center bg-ui-100 h-full border rounded-lg border-ui-200/[0.32]">
                        <FileIcon className="h-16" />
                        <span className="leading-7 text-lg text-ui-700 mt-4">No records to display</span>
                        <span className="leading-5 text-sm text-ui-300">
                            Try adjusting your search or filtering criteria.
                        </span>
                    </div>
                ) : (
                    <table
                        style={{ height: tableHeight }}
                        className="mb-2 overflow-auto block w-full border rounded-lg border-ui-200/[0.32]"
                    >
                        <TableHeader className="w-full">
                            <TableRow className="sticky top-0 bg-white shadow-[inset_0_-2px_0_theme(colors.dark.500/0.2)] z-10 !border-b-0">
                                {COLUMNS_V2.map((c, index) => (
                                    <TableHead
                                        className={`text-ui-300 text-xs font-medium ${index === 0 ? "pl-4 pr-6" : "pl-2 pr-6"}`}
                                    >
                                        {c}
                                    </TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {isPreviewLoading
                                ? Array(50)
                                      .fill("")
                                      .map(() => <PreviewSkeletonRow />)
                                : previewData?.map(person => (
                                      <TableRow
                                          className="text-ui-900 border-dark-500 border-opacity-20 hover:border-transparent hover:bg-blue-50"
                                          key={person.id}
                                      >
                                          <TableCell className="pl-4 pr-6 !py-0 flex-col text-nowrap overflow-hidden text-ellipsis max-w-60 xl:max-w-80">
                                              <Tooltip className="max-w-sm text-wrap" content={""} hidden={true}>
                                                  <div className="min-h-16 flex flex-col items-start justify-center">
                                                      <span className="text-sm">
                                                          {person.first_name && person.last_name
                                                              ? `${person.first_name} ${person.last_name}.`
                                                              : "—"}
                                                      </span>
                                                      {person.location && (
                                                          <span className="text-[10px] mt-1 flex flex-row items-center leading-3">
                                                              <LocationIcon className="mr-1 h-3" /> {person.location}
                                                          </span>
                                                      )}
                                                  </div>
                                              </Tooltip>
                                          </TableCell>
                                          <TableCell className="text-nowrap text-sm !py-0 pl-2 pr-6 max-w-[30ch] xl:max-w-[60ch]">
                                              <Tooltip
                                                  className="max-w-sm text-wrap"
                                                  content={person.job_title || ""}
                                                  hidden={(person.job_title?.length || 0) <= (isXlScreen ? 60 : 30)}
                                              >
                                                  <div className="min-h-16 flex flex-col items-start justify-center">
                                                      <span>
                                                          {person.job_title
                                                              ? person.job_title?.length > (isXlScreen ? 60 : 30)
                                                                  ? person.job_title.substring(
                                                                        0,
                                                                        isXlScreen ? 60 : 30,
                                                                    ) + "..."
                                                                  : person.job_title
                                                              : "—"}
                                                      </span>
                                                      {person.seniority && (
                                                          <span className="text-[10px] mt-1 flex flex-row items-center leading-3">
                                                              <span className="text-ui-300 mr-1">Seniority:</span>
                                                              {upperFirst(person.seniority)}
                                                          </span>
                                                      )}
                                                  </div>
                                              </Tooltip>
                                          </TableCell>
                                          <TableCell className="text-nowrap text-sm !py-0 pl-2 pr-6 max-w-[30ch] xl:max-w-[60ch]">
                                              <div className="min-h-16 flex flex-col items-start justify-center">
                                                  <span>
                                                      {person.primary_department
                                                          ? person.primary_department?.length > (isXlScreen ? 60 : 30)
                                                              ? person.primary_department.substring(
                                                                    0,
                                                                    isXlScreen ? 60 : 30,
                                                                ) + "..."
                                                              : person.primary_department
                                                          : "—"}
                                                  </span>
                                                  {person.primary_sub_department && (
                                                      <span className="text-[10px] mt-1 flex flex-row items-center leading-3">
                                                          <span className="text-ui-300 mr-1">Sub:</span>
                                                          {upperFirst(person.primary_sub_department)}
                                                      </span>
                                                  )}
                                              </div>
                                          </TableCell>
                                          <TableCell className="text-nowrap overflow-hidden text-ellipsis max-w-72 lg:max-w-80 xl:max-w-96 flex-col !py-0 pl-2 pr-6">
                                              <Tooltip
                                                  className="max-w-sm text-wrap"
                                                  content={
                                                      <div className="flex flex-col">
                                                          <div className="text-white text-lg mb-2">
                                                              {person.company_name}
                                                          </div>
                                                          <div>
                                                              <span className="text-ui-300">Domain: </span>
                                                              {person.company_domain ? (
                                                                  <a
                                                                      href={`http://${person.company_domain}`}
                                                                      target="_blank"
                                                                      className={"text-blue-500"}
                                                                  >
                                                                      {person.company_domain}
                                                                  </a>
                                                              ) : (
                                                                  "—"
                                                              )}
                                                          </div>
                                                          <div>
                                                              <span className="text-ui-300">Location: </span>
                                                              <span className="text-white">
                                                                  {person.company_location || "—"}
                                                              </span>
                                                          </div>
                                                          <div>
                                                              <span className="text-ui-300">Annual revenue: </span>
                                                              <span className="text-white">
                                                                  {person.company_annual_revenue_bucket}
                                                              </span>
                                                          </div>
                                                          <div>
                                                              <span className="text-ui-300">Founded year: </span>
                                                              <span className="text-white">
                                                                  {person.company_founded_year || "—"}
                                                              </span>
                                                          </div>
                                                          <div>
                                                              <span className="text-ui-300">Technologies: </span>
                                                              <span className="text-white text-wrap">
                                                                  {person.company_technologies &&
                                                                  !isEmpty(person.company_technologies)
                                                                      ? person.company_technologies.length > 25
                                                                          ? `${person.company_technologies
                                                                                .slice(0, 25)
                                                                                .join(
                                                                                    ", ",
                                                                                )} and ${person.company_technologies.length - 25} more`
                                                                          : person.company_technologies.join(", ")
                                                                      : "—"}
                                                              </span>
                                                          </div>
                                                      </div>
                                                  }
                                                  hidden={!person.company_name}
                                              >
                                                  <div className="min-h-16 flex flex-col items-start justify-center text-sm">
                                                      {person.company_domain ? (
                                                          <a
                                                              href={`http://${person.company_domain}`}
                                                              target="_blank"
                                                              className="text-blue-800"
                                                          >
                                                              {person.company_name}
                                                          </a>
                                                      ) : (
                                                          <span>{person.company_name}</span>
                                                      )}
                                                      {person.company_location && (
                                                          <span className="text-[10px] mt-1 flex flex-row items-center leading-3">
                                                              <LocationIcon className="mr-1 h-3" />{" "}
                                                              {person.company_location}
                                                          </span>
                                                      )}
                                                  </div>
                                              </Tooltip>
                                          </TableCell>
                                          <TableCell className="text-nowrap text-sm !py-0 pl-2 pr-6">
                                              <Tooltip content={""} hidden={true}>
                                                  <div className="flex flex-col">
                                                      <span>
                                                          {person.company_headcount_bucket
                                                              ? person.company_headcount_bucket
                                                              : person.company_headcount
                                                                ? formatNumber(
                                                                      person.company_headcount,
                                                                      [100000, 1000000],
                                                                      "",
                                                                  )
                                                                : "—"}
                                                      </span>
                                                      <span className="h-3 mt-1" />
                                                  </div>
                                              </Tooltip>
                                          </TableCell>
                                          <TableCell className="text-nowrap text-sm !py-0 pl-2 pr-6 max-w-[30ch] xl:max-w-[60ch]">
                                              <div className="flex flex-col">
                                                  <span className="text-sm">{person.company_industry}</span>
                                                  <span className="h-3 mt-1" />
                                              </div>
                                          </TableCell>
                                          <TableCell className="text-nowrap text-sm !py-0 pl-2 pr-6 max-w-[60ch] xl:max-w-[90ch]">
                                              <Tooltip
                                                  className="max-w-sm text-wrap"
                                                  content={person.company_keywords?.join(", ") || ""}
                                                  hidden={
                                                      (person.company_keywords?.join(", ")?.length || 0) <=
                                                      (isXlScreen ? 90 : 60)
                                                  }
                                              >
                                                  <div className="flex flex-col">
                                                      <span>
                                                          {person.company_keywords &&
                                                          person.company_keywords?.length > 0
                                                              ? person.company_keywords.join(", ").length >
                                                                (isXlScreen ? 90 : 60)
                                                                  ? person.company_keywords
                                                                        ?.join(", ")
                                                                        .substring(0, isXlScreen ? 90 : 60) + "..."
                                                                  : person.company_keywords?.join(", ")
                                                              : "—"}
                                                      </span>
                                                      <span className="h-3 mt-1" />
                                                  </div>
                                              </Tooltip>
                                          </TableCell>
                                      </TableRow>
                                  ))}
                        </TableBody>
                    </table>
                )}
            </div>
            <div className="mt-2">
                {/* <span className="">Only up to 500 records available for preview</span> */}
                <Pagination
                    {...pagination}
                    next={() => onPageChange(from + PREVIEW_PAGE_SIZE)}
                    prev={() => onPageChange(from - PREVIEW_PAGE_SIZE)}
                />
            </div>
        </div>
    );
}
