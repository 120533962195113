import cn from "classnames";

import ErrorIcon from "@/assets/icons/error-red.svg?react";
import { AccessibleIcon } from "@radix-ui/react-accessible-icon";

interface ErrorMessageProps {
    error: string;
    className?: string;
}

const ErrorMessage = ({ error, className = "" }: ErrorMessageProps) => {
    return (
        <div className={cn("w-full py-2 px-4 rounded-lg items-center bg-error-background inline-flex text-wrap", className)}>
            <AccessibleIcon label="Error">
                <ErrorIcon className="min-w-[20px] min-h-[20px]" title="Error" aria-label="Error" />
            </AccessibleIcon>
            <span className="text-sm text-error ml-2 text-left">{error}</span>
        </div>
    );
};

export default ErrorMessage;
