import { useMemo } from "react";
import { useSelector } from "react-redux";

import { CsvMappingFields } from "@/types/csv";
import { AppState } from "@/store";
import { groupsConfig } from "@/utils/csv";

export const useCsvSampleData = () => {
    const { records, fieldMappings } = useSelector((state: AppState) => state["csv-import"]);

    const result = useMemo(() => {
        const samples: { [key in CsvMappingFields]?: string[] } = {};

        for (const [key, mappedField] of Object.entries(fieldMappings)) {
            samples[key as CsvMappingFields] = [];
            const seen = new Set();
            for (const record of records || []) {
                const value = record[mappedField];
                if (value && !seen.has(value)) {
                    samples[key as CsvMappingFields]!.push(value);
                    seen.add(value);
                }
                if (samples[key as CsvMappingFields]!.length === 3) {
                    break;
                }
            }
        }

        return samples;
    }, [records, fieldMappings]);

    return result;
};

export const useIsValidMapping = () => {
    const { fieldMappings, entityType } = useSelector((state: AppState) => state["csv-import"]);

    return useMemo(() => {
        const { or, and = [] } = groupsConfig[entityType];

        return (
            and.every((field: CsvMappingFields) => fieldMappings[field]) &&
            or.some((field: CsvMappingFields) => fieldMappings[field])
        );
    }, [entityType, fieldMappings]);
};
