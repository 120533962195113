import LogoIcon from "@/assets/icons/logo-basic.svg?react";
import DisconnectIcon from "@/assets/icons/disconnect.svg?react";
import AlertGradientIcon from "@/assets/icons/alert-gradient.svg?react";
import { Link } from "react-router-dom";

const EmailVerificationLinkExpired = () => {
    return (
        <div className="flex items-center flex-col h-screen">
            <div className="max-w-fit flex flex-col text-center items-center justify-center my-auto mx-auto">
                <LogoIcon />
                <div className="flex flex-col my-10 gap-2">
                    <span className="text-4xl mb-4">Oops! Link Expired...</span>
                    <span className="text-lg text-ui-700">
                        The link you used to login has expired.<br />
                        If issue persists, please try to <Link to="/login" className="text-blue-800">login</Link> on Primer again or reach out to our <Link to="https://support.sayprimer.com/primer/" className="text-blue-800">support</Link>.
                    </span>
                </div>
                <div className="relative flex flex-col gal-10 mb-4">
                    <DisconnectIcon className="text-blue-200" />
                    {<AlertGradientIcon className="absolute top-[-13px] right-[-13px]" />}
                </div>
            </div>
        </div>
    );
};


export default EmailVerificationLinkExpired;
