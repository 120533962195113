import { isNil } from "lodash";

import { InfoMessage } from "../InfoMessage";
import { getTypeForService } from "@/config/integrations";
import { useGetCrmMatchRatesQuery, useGetCrmSyncQuery } from "@/api/crm";
import { Connection, IntegrationType, Service } from "@/types/integrations";
import { Table, TableHeader, TableRow, TableHead, TableCell } from "@/components/atoms/Table";
import { CrmSyncStatus } from "@/types/api";
import { getPastRelativeDateString } from "@/utils/date";

const columns = ["Object name", "Synced", "Match rate", "Matched", "Unmatched"];

export const MatchRateDetails = ({ service, account }: { service: Service; account?: Connection }) => {
    const serviceType = getTypeForService(service);
    if (serviceType !== IntegrationType.CRM) return null;
    
    const { provider_account_id } = account || {};

    const { data: crmMatchRates, isLoading: crmMatchRatesLoading } = useGetCrmMatchRatesQuery(
        { instanceId: provider_account_id! },
        {
            skip: !provider_account_id || serviceType !== IntegrationType.CRM,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
            pollingInterval: 30000,
        },
    );

    const { data: crmSync, isLoading: crmSyncLoading } = useGetCrmSyncQuery(
        { instanceId: provider_account_id! },
        {
            skip: !provider_account_id || serviceType !== IntegrationType.CRM,
            refetchOnMountOrArgChange: true,
        },
    );

    if(crmMatchRatesLoading || crmSyncLoading) return null;

    const { status, isSyncing, latestSyncJobStatus } = crmSync || {};

    if (status != CrmSyncStatus.ACTIVE || isSyncing || latestSyncJobStatus !== "succeeded")
        return null;

    if (Object.values(crmMatchRates || {}).every(isNil)) return null;

    return (
        <div className="flex flex-col gap-6 bg-white w-full px-8 py-6 rounded-2xl">
            <span className="text-lg text-ui-700 font-normal">Match Rate Details</span>

            <InfoMessage
                title="We match your Salesforce data against our data sources to empower filtering by additional attributes"
                message="Match rate varies based data availability and we are always expanding our sources to find better matches."
            />

            <Table>
                <TableHeader className="bg-ui-50 border-ui-300 border-b">
                    <TableRow className="h-[48px] border-dark-500 border-opacity-20">
                        {columns.map((text, index) => (
                            <TableHead key={index} className="!p-4 text-ui-700 font-medium uppercase text-start">
                                {text}
                            </TableHead>
                        ))}
                    </TableRow>
                </TableHeader>

                {crmMatchRates &&
                    Object.entries(crmMatchRates)
                        .filter((crmMatchRatesEntry) => !isNil(crmMatchRatesEntry[1]))
                        .map(
                            ([type, { match_rate = 0, source_count = 0, target_count = 0, updated_at }]) => (
                                <TableRow key={type} className="border-ui-200">
                                    <TableCell className="p-4 capitalize">{type}</TableCell>
                                    <TableCell className="p-4">{getPastRelativeDateString(new Date(updated_at))}</TableCell>
                                    <TableCell className="p-4">{`${match_rate}%`}</TableCell>
                                    <TableCell className="p-4">
                                        {target_count}
                                        <span className="text-ui-300"> (of {source_count})</span>
                                    </TableCell>
                                    <TableCell className="p-4">
                                        {source_count - target_count}
                                        <span className="text-ui-300"> (of {source_count})</span>
                                    </TableCell>
                                </TableRow>
                            ),
                    )}
            </Table>
        </div>
    );
};
