import React from 'react';
import { List, Trigger } from '@radix-ui/react-tabs';
import { useGetFilterCategories } from '@/hooks/useGetFilterCategories';

const FilterFieldTabHeader = React.forwardRef<HTMLDivElement>(({ ...props }, ref) => {
  const filterCategories = useGetFilterCategories();

  return (
    <List ref={ref} className="grid grid-cols-4 gap-6 pt-2" {...props}>
      { filterCategories.map((filterCategory) => 
          <Trigger
            disabled={filterCategory.disabled}
            className="flex px-2 py-2 items-center justify-center text-[15px] text-ui-700 cursor-pointer
              border-b-2 data-[state=inactive]:border-transparent data-[state=active]:border-blue-600 data-[state=active]:text-primary
              disabled:text-ui-300 disabled:cursor-default"
            value={filterCategory.id.toString()} key={filterCategory.id}>
            { filterCategory.name }
          </Trigger>
      )}
    </List>
  )}
);

FilterFieldTabHeader.displayName = 'FilterFieldTabHeader';

export default FilterFieldTabHeader;
