import clsx from "clsx";
import { ReactNode } from "react";

interface Props {
    children: ReactNode[];
    hide2ndPanel?: boolean;
}

export const DoublePaneLayout = ({ children, hide2ndPanel }: Props) => {
    const [firstChild, secondChild] = children;

    return (
        <main className={clsx("flex h-screen")}>
            <section className="flex flex-1 items-center justify-center overflow-auto">
                {firstChild}
            </section>
            {!hide2ndPanel && (
                <aside className="flex-1 bg-gradient-to-b from-dark-900 via-purple-900 to-purple-600 h-full hidden md:block">
                    {secondChild}
                </aside>
            )}
        </main>
    );
};
