import { Destination, DestinationsSchema } from "@/types/audience";
import { getNewAudienceName } from "@/utils/audience";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AudienceState {
    name: string;
    destinations: DestinationsSchema;
    toggledLeftBar: boolean;
}

const initialState: AudienceState = {
    name: getNewAudienceName(),
    destinations: {
        [Destination.GOOGLE]: false,
        [Destination.META]: false,
        [Destination.LINKEDIN]: false,
    },
    toggledLeftBar: false,
};

export const audienceSlice = createSlice({
    name: "audience",
    initialState,
    reducers: {
        setName: (state: AudienceState, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setDestinations: (state: AudienceState, action: PayloadAction<DestinationsSchema>) => {
            state.destinations = action.payload;
        },
        setToggledLeftBar: (state: AudienceState, action: PayloadAction<boolean>) => {
            state.toggledLeftBar = action.payload;
        },
    },
});

export const { setName, setDestinations, setToggledLeftBar } = audienceSlice.actions;

export default audienceSlice.reducer;
