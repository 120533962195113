export enum Service {
    "GOOGLE" = "google",
    "META" = "meta",
    "LINKEDIN" = "linkedin",
    "SALESFORCE" = "salesforce",
}

export enum IntegrationType {
    "AD" = "Ad Platform",
    "CRM" = "CRM",
}

export enum ConnectionStatus {
    "INITIATED" = "initiated",
    "CONNECTED" = "connected",
    "FAILED" = "failed",
}

export interface Account {
    id: string;
    name: string;
    userId: string;
    service: Service;
    status: ConnectionStatus;
}

export interface Connection {
    id: string;
    user_id: string;
    provider: string;
    enabled: boolean;
    created_at: string;
    updated_at: string;
    connection_id: string;
    state: ConnectionStatus;
    organization_id: string;
    error?: ConnectionError;
    provider_user_id: string;
    provider_account_id: string;
    provider_organization_name?: string;
    provider_tos_accepted?: boolean;
}

export interface ConnectionError {
    provider: string;
    provider_account_id: string;
    redirectUrl: string | null;
    message: string;
}
