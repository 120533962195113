import { useNavigate } from "react-router";

import { Button } from "@/components/atoms/Button/Button";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";
import SettingsIcon from "@/assets/icons/settings.svg?react";
import { IntegrationType, Service } from "@/types/integrations";
import { useConnections } from "@/pages/integrations/ConnectionsContext";
import { ConnectionStatusPill } from "@/components/organisms/integrations/ConnectionStatus";

interface Props {
    name: string;
    service: Service;
    description?: string;
    type: IntegrationType;
    availableSoon?: boolean;
    icon: JSX.Element | null;
    connectUrl: string | null;
    featureFlag?: FeatureFlagsEnum;
}

export const IntegrationCard = ({
    name,
    connectUrl,
    icon,
    description,
    service,
    type,
    availableSoon,
    featureFlag,
}: Props) => {
    const navigate = useNavigate();

    const flagEnabled = useFeatureFlag(featureFlag || "", false);

    const { connections } = useConnections();

    const update = () => {
        navigate(`/integrations/${service}/settings`);
    };

    const status = connections?.filter(({ provider }) => provider === service)?.[0]?.state;

    const connect = () => {
        if (connectUrl) window.location.href = connectUrl;
    };

    return (
        <div className="p-10 w-[360px] h-[300px] rounded-2xl flex flex-col justify-between items-start bg-white">
            <div className="flex justify-between items-start w-full">
                {icon}
                <span className="text-ui-300 text-sm">{type}</span>
            </div>

            {/* Main section */}
            <div className="mt-6">
                <div className="flex w-full gap-2">
                    <span className="text-lg font-medium text-ui-700">{name}</span>
                    {status && <ConnectionStatusPill status={status} service={service} />}
                </div>

                <span className="mt-2 text-sm">{description}</span>
            </div>

            {/* Connect / update / availableSoon */}
            <div className="mt-auto mb-0">
                {availableSoon || (featureFlag && !flagEnabled) ? (
                    <span className="text-ui-300 text-sm">Available soon...</span>
                ) : status ? (
                    <Button variant="secondary" className="gap-1" id={`${service}Update`} onClick={update}>
                        <SettingsIcon className="text-ui-700" />
                        <span>Update</span>
                    </Button>
                ) : (
                    <Button
                        variant="primary"
                        id={`${service}Connect`}
                        onClick={connect}
                        attributes={{ "data-ph-capture-attribute-service-name": service }}
                    >
                        Connect
                    </Button>
                )}
            </div>
        </div>
    );
};
