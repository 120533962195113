import { usePrevious } from "react-use";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useQueryParams, withDefault } from "use-query-params";

interface BaseOptions {
    limit?: number;
}

interface Options extends BaseOptions {
    currentPage: number;
    setCurrentPage: (page: number) => void;
}

export interface PaginationOutput {
    to: number;
    from: number;
    total: number;
    limit: number;
    prev: () => void;
    next: () => void;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    updateTotal: (total: number, loading: boolean) => void;
    hasNext: boolean;
    hasPrevious: boolean;
    className?: string;
}

const PageParam = withDefault(
    {
        encode(value: number) {
            return `${value + 1}`;
        },
        decode(strValue) {
            const regex = /^[1-9]\d*$/;
            return regex.test(strValue as string) ? parseInt(strValue as string) - 1 : 0;
        },
    },
    0,
);

export function usePaginationWithQueryParams(options?: BaseOptions) {
    const { limit = 50 } = options || {};
    const [query, setQuery] = useQueryParams({ page: PageParam }, { removeDefaultsFromUrl: true });
    const { page: currentPage } = query;

    const setCurrentPage = useCallback((page: number) => setQuery({ page }), [setQuery]);

    return useBasePagination({ limit, currentPage, setCurrentPage });
}

export function usePagination(options: BaseOptions) {
    const { limit = 50 } = options || {};
    const [currentPage, setCurrentPage] = useState(0);

    return useBasePagination({ limit, currentPage, setCurrentPage });
}

export function useBasePagination(options: Options): PaginationOutput {
    const { limit = 50, currentPage = 1, setCurrentPage } = options || {};

    const { from, to } = useMemo(() => {
        const from = currentPage * limit;
        const to = from + limit;
        return { from, to };
    }, [limit, currentPage]);

    const [total, _setTotal] = useState<number>(0);
    const prevTotal = usePrevious(total);

    useEffect(() => {
        const numOfPages = Math.ceil(total / limit);
        const invalidPage = numOfPages && numOfPages <= currentPage;
        const totalChanged = prevTotal && prevTotal !== total;

        if (totalChanged || invalidPage) setCurrentPage(0);
    }, [total, currentPage, prevTotal, limit, setCurrentPage]);

    const updateTotal = (newTotal: number, loading: boolean) => !loading && newTotal !== total && _setTotal(newTotal);

    const prev = () => {
        if (from - limit < 0) return;
        setCurrentPage(currentPage - 1);
    };

    const next = () => {
        if (from + limit >= total) return;
        setCurrentPage(currentPage + 1);
    };

    const hasNext = to < total;
    const hasPrevious = from > 0;

    return {
        to,
        from,
        prev,
        next,
        total,
        limit,
        currentPage,
        updateTotal,
        setCurrentPage,
        hasNext,
        hasPrevious,
    };
}
