import { useDispatch, useSelector } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineSlices, configureStore } from "@reduxjs/toolkit";

import { audienceSlice } from "./audience";
import { csvImportSlice } from "./csv-import";
import { api, rtkQueryErrorMiddleware } from "@/api";

// `combineSlices` automatically combines the reducers using their `reducerPath`s,
const rootReducer = combineSlices(audienceSlice, csvImportSlice, api);
export type RootState = ReturnType<typeof rootReducer>;

export const makeStore = (preloadedState?: Partial<RootState>) => {
    const store = configureStore({
        reducer: rootReducer,
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: getDefaultMiddleware => {
            return getDefaultMiddleware().concat(api.middleware, rtkQueryErrorMiddleware);
        },
        preloadedState,
    });
    // configure listeners using the provided defaults
    // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
    setupListeners(store.dispatch);
    return store;
};

export const store = makeStore();

export type AppStore = typeof store;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = AppStore["dispatch"];

export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
