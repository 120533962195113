import queryString from "query-string";
import { usePrevious } from "react-use";
import { StrictMode, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { QueryParamProvider } from "use-query-params";
import { ErrorBoundary, setContext } from "@sentry/react";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { ClerkProvider, RedirectToSignIn, useAuth, useOrganization, useUser } from "@clerk/clerk-react";

import { INTERCOM_APP_ID } from "./constants/intercom";

import { AppHelmet, Navigation } from "@/components/layout";
import { PosthogMiddleware } from "@/components/layout/PosthogMiddleware";
import { OnboardingPage } from "@/components/organisms/onboarding/Onboarding";
import { OnboardingMiddleware } from "@/components/layout/OnboardingMiddleware";
import { ErrorBoundaryFallback } from "@/components/layout/ErrorBoundaryFallback";

import Login from "@/pages/Login";
import Signup from "@/pages/Signup";
import { Settings } from "@/pages/Settings";
import { ImportLoading } from "./pages/csv/ImportLoading";
import { ConnectPage } from "./pages/integrations/Connect";
import { OrganizationPick } from "@/pages/OrganizationList";
import { ImportCsvMapping } from "./pages/csv/ImportCsvMapping";
import { AudienceMain } from "@/pages/AudienceNew/AudienceMain.tsx";
import { AudienceNew } from "@/pages/AudienceNew/AudienceNew.tsx";
import { IntegrationsPage } from "./pages/integrations/Integrations";
import { IntegrationSettingsPage } from "./pages/integrations/IntegrationSettings";
import { AudiencesDashboard } from "@/pages/AudiencesDashboard/AudiencesDashboard";
import { ConnectionsProvider } from "./pages/integrations/ConnectionsContext";
import { FeatureFlagsEnum, useFeatureFlag } from "./hooks";
import { NavigationProvider } from "./context/NavigationContext";

const AuthenticationWrapper = () => {
    const { isSignedIn, orgId } = useAuth();
    const { organization } = useOrganization();
    const { user } = useUser();
    const location = useLocation();
    const prevOrg = usePrevious(orgId);

    useEffect(() => {
        if (user && !!(window as any).Intercom) {
            (window as any).Intercom("boot", {
                app_id: INTERCOM_APP_ID,
                email: user.primaryEmailAddress?.emailAddress,
                user_id: user.id,
            });
        }
        if (user) {
            setContext("Primer", {
                email: user.primaryEmailAddress?.emailAddress,
                user_id: user.id,
                organization_id: organization?.id,
                organization_name: organization?.name,
            });
        }
    }, [isSignedIn, user, organization]);

    if (isSignedIn) {
        const hasOrganization = !!organization;

        if (!hasOrganization) {
            return <Navigate to="/organization-list" replace />;
        } else {
            // Reload page when organization is switched
            if (prevOrg && prevOrg !== orgId) window.location.href = window.location.href.split("?")[0];

            return (
                <PosthogMiddleware>
                    <Navigation>
                        <ErrorBoundary key={location.pathname} fallback={ErrorBoundaryFallback}>
                            <StrictMode>
                                <Outlet />
                            </StrictMode>
                        </ErrorBoundary>
                    </Navigation>
                </PosthogMiddleware>
            );
        }
    }

    return <RedirectToSignIn />;
};

const App = () => {
    const isSummaryEnabled = useFeatureFlag(FeatureFlagsEnum.SUMMARY);
    const isPreviewEnabled = useFeatureFlag(FeatureFlagsEnum.PREVIEW);
    const isSplitView = isSummaryEnabled || isPreviewEnabled;

    const audiencePage = isSplitView
        ? <AudienceMain />
        : <AudienceNew />

    return (
        <HelmetProvider>
            <ClerkProvider
                publishableKey={import.meta.env.VITE_PUBLIC_CLERK_PUBLISHABLE_KEY}
                signInUrl="/login"
                signUpUrl="/signup"
                afterSignInUrl={window.location.href}
                afterSignUpUrl="/organization-list"
                appearance={{
                    variables: {
                        colorPrimary: "#3366FF",
                        colorDanger: "#B22424",
                        colorWarning: "#66361F",
                    },
                }}
            >
                <BrowserRouter>
                    <NavigationProvider>
                        <AppHelmet />
                        <QueryParamProvider
                            adapter={ReactRouter6Adapter}
                            options={{
                                searchStringToObject: queryString.parse,
                                objectToSearchString: queryString.stringify,
                            }}
                        >
                            <Routes>
                                <Route path="/login/*" element={<Login />} />
                                <Route path="/signup/*" element={<Signup />} />
                                <Route path="/organization-list/*" element={<OrganizationPick />} />
                                <Route element={<AuthenticationWrapper />}>
                                    <Route element={<OnboardingMiddleware />}>
                                        <Route path="audiences/:id" element={audiencePage} />
                                        <Route path="audiences/new" element={audiencePage} />
                                        <Route path="audiences/import-csv" element={<ImportCsvMapping />} />
                                        <Route path="audiences/import-loading" element={<ImportLoading />} />
                                        <Route path="/" element={<AudiencesDashboard />} />
                                        <Route path="audiences/*" element={<AudiencesDashboard />} />

                                        <Route path="integrations" element={<IntegrationsPage />} />
                                        <Route path="integrations/:id/connect" element={<ConnectPage />} />
                                        <Route path="integrations/:id/settings" element={
                                            <ConnectionsProvider>
                                                <IntegrationSettingsPage />
                                            </ConnectionsProvider>
                                        } />

                                        <Route path="settings/*" element={<Settings />} />
                                        <Route path="onboarding/*" element={<OnboardingPage />} />
                                        <Route path="*" element={<Navigate to="audiences" replace />} />
                                    </Route>
                                </Route>
                            </Routes>
                        </QueryParamProvider>
                    </NavigationProvider>
                </BrowserRouter>
            </ClerkProvider>
        </HelmetProvider>
    );
};

export default App;
