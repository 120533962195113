import postHog from "posthog-js";
import { useEffect, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { PaymentMethod } from "@/types/subscription";
import { useAddPaymentMethodMutation } from "@/api/users";
import { Button } from "@/components/atoms/Button/Button";
import { useToast } from "@/components/atoms/Toast/useToast";
import { StripeInputElement } from "@/components/organisms/subscription/PaymentMethod/StripeInputElement";

interface Props {
    close: () => void;
    onSuccess?: () => void;
    paymentMethod?: PaymentMethod;
}

export const StripePaymentMethod = ({ paymentMethod, onSuccess, close }: Props) => {
    const { toast } = useToast();

    const stripe = useStripe();
    const elements = useElements();

    const { last4, exp_month = "MM", exp_year = "YY", billing_details_name = "" } = paymentMethod || {};

    const splitted = billing_details_name.split(" ");

    const [name, setName] = useState({
        firstName: splitted?.[0] || "",
        lastName: splitted.length > 1 ? splitted.filter((_, i) => i !== 0).join(" ") : "",
    });

    const handleNameChange = (event: any) =>
        setName({
            ...name,
            [event.target.name]: event.target.value,
        });

    const [errors, setErrors] = useState({
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
    });

    const handleStripeFieldChange = (event: any) => {
        if (event.error)
            setErrors(errors => ({
                ...errors,
                [event.elementType]: event.error.message,
            }));
        else
            setErrors(errors => ({
                ...errors,
                [event.elementType]: "",
            }));
    };

    const isFormValid = Object.values(errors).every(error => error === "");

    const [addPaymentMethod, { isLoading: isSaving, error: addPaymentMethodError }] = useAddPaymentMethodMutation();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardNumberElement)!;

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                name: `${name.firstName} ${name.lastName}`,
            },
        });

        if (error) {
            toast({ description: error.message || "An error occurred. Please retry", variant: "destructive" });
        } else {
            await addPaymentMethod(paymentMethod.id);

            postHog.capture(`Payment added`, {
                paymentMethodId: paymentMethod.id,
            });

            toast({
                variant: "default",
                description: "Payment Method updated successfully",
            });
            onSuccess ? onSuccess() : close();
        }
    };

    useEffect(() => {
        if (!addPaymentMethodError) return;
        const fetchBaseQueryError = addPaymentMethodError as FetchBaseQueryError;

        toast({
            variant: "destructive",
            description: (fetchBaseQueryError.data as string) || "An error occurred. Please retry",
        });
    }, [toast, addPaymentMethodError]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="text-ui-300 text-10 font-medium mt-4" htmlFor="firstName">
                    FIRST NAME
                </label>
                <input
                    id="firstName"
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={name.firstName}
                    onChange={handleNameChange}
                    className="border rounded-lg border-ui-200 w-full py-2 px-2 text-ui-900 focus:outline-none focus:shadow-outline"
                />
            </div>

            <div className="mb-4">
                <label className="text-ui-300 text-10 font-medium mt-4" htmlFor="lastName">
                    LAST NAME
                </label>
                <input
                    id="lastName"
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={name.lastName}
                    onChange={handleNameChange}
                    className="border rounded-lg border-ui-200 w-full py-2 px-2 text-ui-900 focus:outline-none focus:shadow-outline"
                />
            </div>

            <StripeInputElement
                label="CARD NUMBER"
                error={errors.cardNumber}
                Element={CardNumberElement}
                handleChange={handleStripeFieldChange}
                placeholder={last4 ? `**** **** **** ${last4}` : "1234 1234 1234 1234"}
            />

            <div className="flex gap-6 mt-4">
                <div className="w-1/2">
                    <StripeInputElement
                        label="EXPIRATION DATE"
                        error={errors.cardExpiry}
                        Element={CardExpiryElement}
                        handleChange={handleStripeFieldChange}
                        placeholder={`${exp_month} / ${exp_year}`}
                    />
                </div>
                <div className="w-1/2">
                    <StripeInputElement
                        error={errors.cardCvc}
                        label="SECURITY CODE"
                        Element={CardCvcElement}
                        handleChange={handleStripeFieldChange}
                    />
                </div>
            </div>
            <div className="flex justify-between mt-10">
                <Button variant="secondary" type="button" onClick={close}>
                    Cancel
                </Button>
                <Button type="submit" disabled={!stripe || !isFormValid} loading={isSaving}>
                    Submit
                </Button>
            </div>
        </form>
    );
};
