import ErrorIcon from "@/assets/icons/error-red.svg?react";
import WarningIcon from "@/assets/icons/warning.svg?react";
import { ReactNode } from "react";
import { Button } from "../atoms/Button/Button";
import RerunIcon from "@/assets/icons/rerun.svg?react";

interface Props {
    message: ReactNode;
    action?: JSX.Element;
    redirect?: string | null;
}

export const ErrorMessage = ({ message, action, redirect }: Props) => (
    <div className="w-full my-2 p-2 flex justify-between items-center rounded-lg bg-error-background">
        <div className="flex items-center">
            <div className="mr-2 self-start">
                <ErrorIcon className="min-w-[20px]" />
            </div>
            <div className="flex gap-2 items-center">
                <span className="text-error text-sm">
                    {message ? message : null}
                    {redirect ? (
                        <>
                            {" "}
                            Please follow this{" "}
                            <a href={redirect} className="text-primary" target="_blank">
                                link
                            </a>{" "}
                            for details and then retry connection.
                        </>
                    ) : null}
                </span>
            </div>
        </div>
        {action}
    </div>
);

export const WarningMessage = ({ message, action }: Props) => (
    <div className="w-full my-2 p-2 flex justify-between items-center rounded-lg bg-warning-background">
        <div className="flex items-center">
            <div className="mr-2 self-start">
                <WarningIcon className="min-w-[20px]" />
            </div>
            <div className="flex gap-2 items-center">
                <span className="text-warning text-sm">
                    {message ? message : null}
                </span>
            </div>
        </div>
        {action}
    </div>
);

interface FacebookTosErrorProps {
    provider_account_id: string;
    retryLoading: boolean;
    retryFailed: boolean;
    onRetry: () => void;
}

export const FacebookTosErrorMessage = ({ provider_account_id, retryLoading, retryFailed, onRetry }: FacebookTosErrorProps) => (
    <div className="w-full my-2 p-2 flex justify-between items-center rounded-lg bg-error-background">
        <div className="flex items-center">
            <div className="mr-2 self-start">
                <ErrorIcon className="min-w-[20px]" />
            </div>
            <div className="flex gap-2 items-center">
                { !retryFailed ? 
                    <span className="text-error text-sm">
                        This account Terms of Service are not accepted. <br/>
                        {" "}
                        Please follow this{" "}
                        <a href={`https://www.facebook.com/ads/manage/customaudiences/tos?${provider_account_id}`} className="text-primary" target="_blank">
                            link
                        </a>{" "}
                        for details and then retry connection.
                    </span>
                :
                    <span className="text-error text-sm">
                        The Terms of Service are still not accepted. Please try again<br/>
                        {" "}
                        using the same{" "}
                        <a href={`https://www.facebook.com/ads/manage/customaudiences/tos?${provider_account_id}`} className="text-primary" target="_blank">
                            link
                        </a>{" "}
                        or view our{" "}
                        <a href={"https://support.sayprimer.com/primer/troubleshooting-integrations/error-facebook-custom-audiences-not-enabled"} className="text-primary" target="_blank">
                            support
                        </a>{" "}
                        article.
                    </span>
                }                
            </div>
        </div>
        <Button loading={retryLoading} variant="secondary" className="gap-1 " onClick={onRetry}>
            <RerunIcon />
            <span>Retry</span>
        </Button>
    </div>
);

export const EmptyAccountListWarning =  ({ serviceName, onCancel }: { serviceName: string, onCancel: () => void }) => (
    <>
        <div className="w-[700px] px-8 py-10 mt-6 flex-flex-col items-center justify-start bg-white shadow-main rounded-2xl space-y-4">
            <WarningMessage message={`No ${serviceName} Ad Accounts Found.`} />
            <div className="text-sm text-ui-700 mt-2">
            We couldn't find any {serviceName} Ad Accounts associated with your {serviceName} profile. This might be because: <br/><br/>
            <ul className="list-disc">
                <li className="ml-6">You don't have any {serviceName} Ad Accounts yet.</li>
                <li className="ml-6">Your {serviceName} profile doesn't have the necessary permissions to access Ad Accounts.</li>
            </ul>
            <br/>
            If you believe this is a mistake or if you need assistance, please check your {serviceName} account settings or contact our support team for help.
            </div>
            <div className="flex justify-end">
                <div className="flex items-center space-x-4">
                    <Button variant="secondary" onClick={onCancel}>
                        Back
                    </Button>
                </div>
            </div>
        </div>
    </>
);
