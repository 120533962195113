export interface RouteMetadata {
    title: string;
    description: string;
}

export const routeMetadata: Record<string, RouteMetadata> = {
    "/login": {
        title: "Your Primer Account: Sign in or Create | sayprimer.com",
        description:
            "Sign in to your Primer Account and start building B2B audiences with higher than standard match rates. Primer powers today’s most efficient growth teams.",
    },
    "/signup": {
        title: "Create Your Primer Account | sayprimer.com",
        description:
            "Create an account to see Primer in action: build a sample audience, try out the advertising destinations, and more. Try full capabilities, free, for 30-days.",
    },
    "/": {
        title: "Primer",
        description: "",
    },
};

export default routeMetadata;
