import NotFavoriteIcon from "@/assets/icons/favorite-false.svg?react";
import FavoriteIcon from "@/assets/icons/favorite-true.svg?react";
import { Button } from "@/components/atoms/Button/Button";
import { AccessibleIcon } from "@radix-ui/react-accessible-icon";
import cn from "classnames";
import { MouseEventHandler } from "react";

interface AudienceFavoriteProps {
    favorite: boolean;
    disabled: boolean;
    className?: string;
    onAudienceFavorite: (favorite: boolean) => void;
    buttonClassName?: string;
}

const AudienceFavorite = ({
    favorite,
    disabled,
    className,
    onAudienceFavorite,
    buttonClassName,
}: AudienceFavoriteProps) => {
    const baseClasses = "w-5 h-5 cursor-pointer focus:outline-none";
    const audienceFavorite: MouseEventHandler = event => {
        event.stopPropagation();
        if (!disabled) onAudienceFavorite(!favorite);
    };

    let icon = (
        <AccessibleIcon label="Not a favorite audience">
            <NotFavoriteIcon
                aria-disabled={disabled}
                title="Not a favorite audience"
                aria-label="Not a favorite audience"
                tabIndex={0}
                className={cn(baseClasses, className)}
            />
        </AccessibleIcon>
    );

    if (favorite) {
        icon = (
            <AccessibleIcon label="Favorite audience">
                <FavoriteIcon
                    aria-disabled={disabled}
                    title="Favorite audience"
                    aria-label="Favorite audience"
                    tabIndex={0}
                    className={cn(baseClasses, className, "text-blue-800")}
                />
            </AccessibleIcon>
        );
    }

    return (
        <Button variant="ghost" className={cn("!p-0 h-auto", buttonClassName)} onClick={audienceFavorite}>
            {icon}
        </Button>
    );
};

export default AudienceFavorite;
