import { SignUp, useSignUp } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import LogoIcon from "@/assets/icons/logo-basic.svg?react";
import { Button } from "@/components/atoms/Button/Button";
import cn from "classnames";
import EmailIcon from "@/assets/icons/email.svg?react";
import CircularProgressBar from "@/components/atoms/CircularProgressBar/CircularProgressBar";
import EmailFullIcon from "@/assets/icons/email-full.svg?react";
import DisconnectIcon from "@/assets/icons/disconnect.svg?react";
import GradientIcon from "@/assets/icons/gradient.svg?react";
import AlertGradientIcon from "@/assets/icons/alert-gradient.svg?react";
import { useCallback, useEffect, useState } from "react";

const onTimeLinkStyle = {
    expired: false,
    headerText: "Almost there!",
    headerSubText: <>
        Check your email on your laptop<br />
        or desktop and click the link to continue to Primer.
    </>,
    icon: EmailFullIcon,
    iconGradient: GradientIcon,
};
const expiredLinkStyle = {
    expired: true,
    headerText: "Oops! Link Expired...",
    headerSubText: <>
        The link you used to login has expired.<br />
        Pleas try submitting a new link by clickling on the button below.
    </>,
    icon: DisconnectIcon,
    iconGradient: AlertGradientIcon,
}

const CustomSignUp = ({
    isLoaded,
    openEmail,
    isWaitingForCode = false
}: {
    isLoaded: boolean;
    openEmail: boolean;
    isWaitingForCode?: boolean
}) => {
    const { signUp } = useSignUp();
    const [fieldsVisible, setFieldsVisible] = useState(false);
    const [style, setStyle] = useState(onTimeLinkStyle);

    const checkNoLongerExpired = useCallback(async () => {
        const delay = async (ms: number) => await new Promise((resolve) => setTimeout(resolve, ms));
        while (style.expired) {
            const expiration = signUp?.verifications?.emailAddress?.expireAt;
            if (expiration) {
                const isExpired = expiration <= new Date();
                setStyle(isExpired ? expiredLinkStyle : onTimeLinkStyle);
                if (!isExpired) break;
            }
            await delay(1000);
        }
    }, [signUp, style]);

    useEffect(() => {
        const expiration = signUp?.verifications?.emailAddress?.expireAt;
        if (!expiration) return;

        setStyle(expiration <= new Date() ? expiredLinkStyle : onTimeLinkStyle);
        const expireInterval = expiration.getTime() - new Date().getTime();
        setTimeout(() => {
            setStyle(expiration <= new Date() ? expiredLinkStyle : onTimeLinkStyle);
            checkNoLongerExpired();
        }, expireInterval);
    }, [checkNoLongerExpired, signUp]);

    return (
        <div className={cn("px-4 md:px-0", {
            "[&_.cl-form]:hidden": !fieldsVisible && !openEmail,
        })}>
            <div className="sign-up-page flex items-center flex-col [&_.cl-socialButtonsBlockButton>div]:w-fit">
                <div className="max-w-[400px] flex flex-col text-center items-center justify-center">
                    <LogoIcon />
                    {isWaitingForCode && <>
                        <div className="flex flex-col my-10 gap-2">
                            <span className="text-4xl mb-4 font-eighties">{style.headerText}</span>
                            <span className="text-lg text-ui-700">{style.headerSubText}</span>
                        </div>
                        <div className="relative flex flex-col gal-10 mb-4">
                            <style.icon className="text-blue-200" />
                            {<style.iconGradient className="absolute top-[-13px] right-[-13px]" />}
                        </div>
                    </>}
                    {!isWaitingForCode && <div className="flex flex-col my-10 gap-2">
                        <span className="text-[44px] font-eighties font-semibold">Sign up for Primer</span>
                        <span className="text-lg text-ui-700">and start building powerful custom audiences
                            that match to major ad platforms:</span>
                    </div>}
                </div>

                {isLoaded && <SignUp
                    appearance={{
                        elements: {
                            dividerRow: { display: fieldsVisible && !isWaitingForCode ? "flex" : "none" },
                            dividerText: { display: "none" },
                            footer: { display: "none" },
                            header: { display: "none" },
                            logoBox: { display: "none" },
                            socialButtonsBlockButton: {
                                justifyContent: "center",
                            },
                            socialButtonsBlockButtonText: {
                                fontSize: "14px",
                            },
                            card: {
                                width: '400px',
                                padding: '0px',
                                boxShadow: "none",
                                backgroundColor: "transparent",
                            },
                            verificationLinkStatusIconBox: { display: "none", marginTop: "5px" },
                            ...(isWaitingForCode ? {
                                alert: { display: "none", },
                                identityPreview: { display: "none", },
                                formHeader: { display: "none", },
                                form: {
                                    display: "flex",
                                    alignItems: "center",
                                },
                            } : {}),
                        },
                    }}
                />}
                {!isWaitingForCode && isLoaded && !fieldsVisible && !openEmail && <Button variant="basic" className="mt-2 group w-full border border-ui-200 border-opacity-[5%] rounded-md flex gap-4 h-fit py-[10px]" onClick={() => setFieldsVisible(true)}>
                    <EmailIcon className="text-blue-800" /> Sign up with Email <div className="h-1 w-[16px]"></div>
                </Button>}

                {!isLoaded && <CircularProgressBar className="w-8 h-8" />}
                {!isWaitingForCode && <div className="max-w-[400px] text-ui-700 text-center text-sm mt-8">
                    <p className="px-10 md:px-0">
                        By signing up, you agree to our{' '}
                        <a href="https://www.sayprimer.com/privacy-policy" target="_blank" className="text-blue-800">
                            privacy policy
                        </a>{' '}
                        and{' '}
                        <a href="https://www.sayprimer.com/terms-of-service" target="_blank" className="text-blue-800">
                            terms.
                        </a>
                    </p>
                    <p className="mt-8 md:mt-12">
                        Already have an account?{' '}
                        <Link to="/sign-in" className="text-blue-800">
                            Sign In.
                        </Link>
                    </p>

                </div>}
            </div>
        </div>
    );
};

export default CustomSignUp;
